<template>
  <div class="page_section vessel_owners__page_content bg-gray-lightest">
<!--    <button class="" v-on:click="reSave">reSave</button>-->
    <div class="box-border vessel_owners__page_container-edit" :class="{'without_trials' : !getPermissionsByType('show_accounts_in_all_operations')}">

      <div class="flex items-center relative p-t-8 p-b-3">
        <edit-title title="Edit SOA Sublet"/>

        <div class="text-align-right text-gray-text fz13 m-l-auto" :style="{width: getCurrencyWidth}" @click="$refs.dropCurrency.openList()">
          Debit, {{editData.currency}}
          <svg class="m-l-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.72443 4.39844C2.45716 4.39844 2.32331 4.72158 2.5123 4.91057L5.78804 8.18631C5.9052 8.30346 6.09515 8.30346 6.21231 8.18631L9.48803 4.91057C9.67702 4.72158 9.54317 4.39844 9.2759 4.39844H2.72443Z" fill="#9AA3B0"/></svg>
        </div>
        <div class="text-align-right text-gray-text fz13" :style="{width: getCurrencyWidth}" @click="$refs.dropCurrency.openList()">
          Credit, {{editData.currency}}
          <svg class="m-l-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.72443 4.39844C2.45716 4.39844 2.32331 4.72158 2.5123 4.91057L5.78804 8.18631C5.9052 8.30346 6.09515 8.30346 6.21231 8.18631L9.48803 4.91057C9.67702 4.72158 9.54317 4.39844 9.2759 4.39844H2.72443Z" fill="#9AA3B0"/></svg>
        </div>
        <counterparties-drop
          class="hide_input vessel_owners__select_currency"
          ref="dropCurrency"
          :removeSelectItemBeforeClick="false"
          :list="valutaList"
          :activeItem="activeCurrency"
          @select="selectedCurrency"
          title="Select currency"
        ></counterparties-drop>
        <div style="width: 9%" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>
        <div style="width: 10%" v-else></div>
      </div>

      <edit-hire-table
          @change-date-hire="changeDateHire++"
          ref="tableHire"
        :activeCurrency="editData.currency"
        :hireData="editData.charter_hire"
        @on-delete="onDeleteEditBlock"
        @on-add="onAddHire"
      />

      <edit-bunkers-on-table 
        v-if="editData.bunkers_delivery.length"
        ref="bunkersOn"
        :editData="editData"
        :bunkersOnData="editData.bunkers_delivery"
        :emptyBunkers="emptyBunkersDelivery"
        :hireData="editData.charter_hire"
      />

      <edit-bunkers-on-table-c-e-v
          :changeDateHire="changeDateHire"
          v-if="editData.bunkers_delivery.length"
        ref="bunkersOnCEV"
        :editData="editData"
        :bunkersOnData="editData.bunkers_delivery"
        :emptyBunkers="emptyBunkersDelivery"
        :hireData="editData.charter_hire"
      ></edit-bunkers-on-table-c-e-v>

      <edit-balast-table 
      v-if="isShowBlock('balast')" 
      :balastData="editData.balast_bonus"
      @on-delete="onDeleteEditBlock"
      />

      <edit-deposit-table 
        v-if="isShowBlock('deposit')"
        :chrtrsData="editData.chrtrs"
        @on-delete="onDeleteEditBlock"
      />

      <edit-bunkers-table 
        v-if="isShowBlock('bunkers')"
        :activeCurrency="editData.currency"
        :bunkersData="editData.bunkers[0]"
        @on-delete="onDeleteEditBlock"
      />

      <edit-bunkers-supply-table
        v-if="isShowBlock('bunkers_supply')"
        :activeCurrency="editData.currency"
        :bunkersData="editData.bunkers[1]"
        @on-delete="onDeleteEditBlock"
      />
      
      <edit-off-hire-table 
        v-if="isShowBlock('off_hire')"
        :activeCurrency="editData.currency"
        :hireOffData="editData.hire_off"
        :hireData="editData.charter_hire"
        ref="tableOffHire"
        @on-add="onAddOffHire"
        @on-delete-block="onDeleteOffHire"
        @on-delete="onDeleteEditBlock"
      />

      <edit-speed-table 
        v-if="isShowBlock('speed')"
        :activeCurrency="editData.currency"
        :speedData="editData.speed_claim"
        :hireData="editData.charter_hire"
        @on-add="onAddSpeed"
        @on-delete-block="onDeleteSpeed"
        @on-delete="onDeleteEditBlock"
      />

      <edit-charterers-table
        v-if="isShowBlock('charterers')"
        ref="charterers"
        :expensesData="editData.expenses"
        @on-delete="onDeleteEditBlock"
      />

      <edit-owners-table
        v-if="isShowBlock('owners')"
        ref="owners"
        :expensesData="editData.expenses"
        @on-delete="onDeleteEditBlock"
      />

      <edit-remittances-table
        v-if="isShowRemitances"
        :additional="editData.additional_info"
        ref="remittances"
      />

      <div 
        class="vessel_owners__table_wide bg-white rounded-lg overflow-hidden m-t-6 m-b-6 fz13 border border-dark-gray"
        :class="{
          'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
        }"
      >
        <div class="p-x-2 p-y-3 bg-gray-dark border-b border-r colspan-1-5 fw500">TOTAL</div>
        <div class="p-x-2 p-y-3 bg-gray-dark border-b border-r text-align-center fw500">{{countTotalDebit() | formatNumberTwo}}</div>
        <div class="p-x-2 p-y-3 bg-gray-dark border-b border-r text-align-center fw500">{{countTotalCredit() | formatNumberTwo}}</div>
        <div class="bg-gray-dark border-b border-r"></div>
        <div class="bg-gray-dark border-b"></div>

        <div class="p-x-2 p-y-3 bg-gray-dark border-r colspan-1-5 fw500">BALANCE DUE TO {{countTotalCredit() >= countTotalDebit() ? 'CHARTERS' : 'OWNERS'}}</div>
        <div class="p-x-2 p-y-3 bg-gray-dark border-r text-align-center fw500">
          <template v-if="editData.balance >= 0">{{Math.abs(countTotalBalance()) | formatNumberTwo}}</template>
        </div>
        <div class="p-x-2 p-y-3 bg-gray-dark border-r text-align-center fw500">
          <template v-if="editData.balance < 0">{{Math.abs(countTotalBalance()) | formatNumberTwo}}</template>
        </div>
        <div class="bg-gray-dark border-r"></div>
        <div class="bg-gray-dark"></div>
      </div>

      <div class="flex items-start justify-between m-b-16 bg-white p-6 rounded-lg shadow">
        <div class="w-7/12">
          <linked-wrap 
            v-if="!getPermissionHideLinkedOperation()" 
            :linkedPayments="editData.linked_payments" 
          />

          <div class="block-input soa_due_date m-t-6">
            <p class="m-b-3 fz16 fw500">Invoice date</p>
            <date-picker
              placeholder="Choose date"
              class="datepickeer w-full"
              v-model="invoiceDate"
              format="DD.MM.YYYY"
              value-type="YYYY-MM-DD"
            ></date-picker>
          </div>

          <label class="block-input m-t-6">
            <p class="m-b-3 fz16 fw500">Note</p>
            <textarea
              rows="4"
              maxlength="3000"
              class="block-input_content rounded-lg"
              placeholder="Write a total comment"
              v-model="editData.comment"
            ></textarea>
          </label>

          <div class="block-input soa_due_date m-t-6">
            <p class="m-b-3 fz16 fw500">Due date</p>
            <date-picker
                placeholder="Choose date"
                class="datepickeer w-full"
                v-model="dueDate"
                format="DD.MM.YYYY"
            ></date-picker>
          </div>

          <counterparties-drop
              class="w-full vessel_owners_drop m-t-6"
              ref="dropRequisites"
              :list="mapRequisites"
              :dont-select="true"
              :activeItem="activeRequisite"
              @select="selectedRequisite"
              :noCheckName="true"
              title="Bank Account"
          ></counterparties-drop>
          <div v-if="selectRequisite">
            <div class="m-t-2" v-if="selectRequisite.account_number">
              <p class="text-gray-text fz13">Account Number</p>
              <p class="fz14">{{selectRequisite.account_number}}</p>
            </div>
            <div class="m-t-2" v-if="selectRequisite.iban">
              <p class="text-gray-text fz13">IBAN</p>
              <p class="fz14">{{selectRequisite.iban}}</p>
            </div>
            <div class="m-t-2" v-if="selectRequisite.description">
              <p class="text-gray-text fz13">Description</p>
              <p class="fz14">{{selectRequisite.description}}</p>
            </div>
            <div class="m-t-2" v-if="selectRequisite.bank">
              <p class="text-gray-text fz13">Bank</p>
              <p class="fz14">{{selectRequisite.bank}}</p>
            </div>
            <div class="m-t-2" v-if="selectRequisite.bank_address">
              <p class="text-gray-text fz13">Bank Address</p>
              <p class="fz14">{{selectRequisite.bank_address}}</p>
            </div>
            <div class="m-t-2" v-if="selectRequisite.clearing_number">
              <p class="text-gray-text fz13">Clearing number</p>
              <p class="fz14">{{selectRequisite.clearing_number}}</p>
            </div>
            <div class="m-t-2" v-if="selectRequisite.bic">
              <p class="text-gray-text fz13">BIC / SWIFT</p>
              <p class="fz14">{{selectRequisite.bic}}</p>
            </div>
            <div class="m-t-2" v-if="selectRequisite.currency">
              <p class="text-gray-text fz13">Currency</p>
              <p class="fz14">{{selectRequisite.currency}}</p>
            </div>
          </div>

          <div class="flex flex-wrap">
            <p class="w-full m-b-3 m-t-6 fz16 fw500">Export settings</p>
            <div class="w-1/3 block-radio-button">
              <button class="radio-button fz13 text-dark" v-on:click="export_settings.stamp = true">
                <svg  v-if="!export_settings.stamp" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#9AA3B0" stroke-width="2"/>
                </svg>
                <svg v-if="export_settings.stamp" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#094172" stroke-width="2"/>
                  <circle cx="9" cy="9" r="5" fill="#094172"/>
                </svg>
                Stamp enabled
              </button>
              <button class="radio-button fz13 text-dark" v-on:click="export_settings.stamp = false">
                <svg  v-if="export_settings.stamp" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#9AA3B0" stroke-width="2"/>
                </svg>
                <svg v-if="!export_settings.stamp" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#094172" stroke-width="2"/>
                  <circle cx="9" cy="9" r="5" fill="#094172"/>
                </svg>
                Stamp disabled
              </button>
            </div>
            <div class="w-1/3 block-radio-button">
              <button class="radio-button fz13 text-dark" v-on:click="export_settings.color = true">
                <svg  v-if="!export_settings.color" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#9AA3B0" stroke-width="2"/>
                </svg>
                <svg v-if="export_settings.color" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#094172" stroke-width="2"/>
                  <circle cx="9" cy="9" r="5" fill="#094172"/>
                </svg>
                Color
              </button>
              <button class="radio-button fz13 text-dark" v-on:click="export_settings.color = false">
                <svg v-if="export_settings.color" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#9AA3B0" stroke-width="2"/>
                </svg>
                <svg v-if="!export_settings.color" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#094172" stroke-width="2"/>
                  <circle cx="9" cy="9" r="5" fill="#094172"/>
                </svg>
                Black and white
              </button>
            </div>
            <div class="w-1/3 block-radio-button">
              <button class="radio-button fz13 text-dark" v-on:click="export_settings.bank_details = true">
                <svg v-if="!export_settings.bank_details" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#9AA3B0" stroke-width="2"/>
                </svg>
                <svg v-if="export_settings.bank_details" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#094172" stroke-width="2"/>
                  <circle cx="9" cy="9" r="5" fill="#094172"/>
                </svg>
                Bank details enabled
              </button>
              <button class="radio-button fz13 text-dark" v-on:click="export_settings.bank_details = false">
                <svg v-if="export_settings.bank_details" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#9AA3B0" stroke-width="2"/>
                </svg>
                <svg v-if="!export_settings.bank_details" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#094172" stroke-width="2"/>
                  <circle cx="9" cy="9" r="5" fill="#094172"/>
                </svg>
                Bank details disabled
              </button>
            </div>
          </div>

          <files-attach :files="editData.files"/>
        </div>
      </div>
      <div><!-- space --></div>
    </div>

    <edit-actions @on-save="onSave" @selectedBlock="selectedBlock" :blocks="getExistBlocks" :style="{width: 'calc(100% - 258px)'}"/>

    <modal-slots
      :showMod="isShowModalDelete"
      :isBorder="false"
      textForSecondButton="Delete"
      @apply="deleteBlockAfterModal"
      @close="isShowModalDelete = false"
    >
      <template v-slot:title>
        <h2 class="fw500">SOA Sublet</h2>
      </template>

      <p class="m-b-4 p-x-6 fz14">Are you sure you want to delete this block?</p>
    </modal-slots>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop'
import editTitle from '@/components/vessels/owners/edit/editTitle'

import editHireTable from '@/components/vessels/owners/edit/editHireTable'
import editBunkersOnTable from '@/components/vessels/owners/edit/editBunkersOnTable'
import editBalastTable from '@/components/vessels/owners/edit/editBalastTable'
import editDepositTable from '@/components/vessels/owners/edit/editDepositTable'
import editBunkersTable from '@/components/vessels/owners/edit/editBunkersTable'
import editBunkersSupplyTable from '@/components/vessels/owners/edit/editBunkersSupplyTable'
import editOffHireTable from '@/components/vessels/owners/edit/editOffHireTable'
import editSpeedTable from '@/components/vessels/owners/edit/editSpeedTable'
import editCharterersTable from '@/components/vessels/owners/edit/editCharterersTable'
import editOwnersTable from '@/components/vessels/owners/edit/editOwnersTable'
import editRemittancesTable from '@/components/vessels/owners/edit/editRemittancesTable'
import filesAttach from '@/components/vessels/owners/filesAttach'
import editActions from '@/components/vessels/owners/edit/editActions'
import linkedWrap from '@/components/entries/linked/linkedWrap'
import modalSlots from '@/components/modal/modal-slots'
import EditBunkersOnTableCEV from "@/components/vessels/owners/edit/editBunkersOnTableCEV";

export default {
  components: {
    EditBunkersOnTableCEV,
    counterpartiesDrop,
    editTitle,
    editHireTable,
    editBunkersOnTable,
    editBalastTable,
    editDepositTable,
    editBunkersTable,
    editBunkersSupplyTable,
    editOffHireTable,
    editSpeedTable,
    editCharterersTable,
    editOwnersTable,
    editRemittancesTable,
    editActions,
    filesAttach,
    DatePicker,
    linkedWrap,
    modalSlots
  },
  data() {
    return {
      reSaveMode: false,
      changeDateHire: -23423,
      isShowModalDelete: false,
      isShowRemitances: false,
      deleteType: null,
      blocks: [
        {
          type: 'balast',
          name: 'Ballast Bonus'
        },
        {
          type: 'deposit',
          name: 'Deposit Paid by Chrts / Revert to Chrts'
        },
        {
          type: 'bunkers',
          name: 'Excess bunker on redelivery'
        },
        {
          type: 'bunkers_supply',
          name: 'Bunker Supply by Owners'
        },
        {
          type: 'off_hire',
          name: 'Off-Hire'
        },
        {
          type: 'speed',
          name: 'Speed Claim'
        },
        {
          type: 'charterers',
          name: 'Charterers Expenses'
        },
        {
          type: 'owners',
          name: 'Owners Expenses'
        },
        {
          type: 'remittances',
          name: 'Remmitances Done'
        },
      ],
      attachBlocks: [
        {
          type: 'hire',
          name: 'Charter Hire'
        },
        {
          type: 'bunkers_on',
          name: 'Bunkers on Delivery'
        }
      ],
      dueDate: '',
      invoiceDate: '',
      selectRequisite: null,
      activeRequisite: null,
      activeCurrency: {name:'USD', id:'USD'},
      emptyBunkersDelivery: [
        {
          bunkers_type: 'DELIVERY',
          fuel_type: 'VLSFO',
          debit: '',
          credit: '',
          additional_info: {
            price: '',
            quantity: '',
            is_row: true,
            trs_type: 'revenues'
          }
        },
        {
          bunkers_type: 'DELIVERY',
          fuel_type: 'LSMGO',
          debit: '',
          credit: '',
          additional_info: {
            price: '',
            quantity: '',
            checkboxes: 'offhire',
            is_row: true,
            trs_type: 'revenues'
          }
        },
        {
          bunkers_type: 'REDELIVERY',
          fuel_type: 'VLSFO',
          debit: '',
          credit: '',
          additional_info: {
            price: '',
            quantity: '',
            is_row: true,
            trs_type: 'expenses'
          }
        },
        {
          bunkers_type: 'REDELIVERY',
          fuel_type: 'LSMGO',
          debit: '',
          credit: '',
          additional_info: {
            price: '',
            quantity: '',
            checkboxes: 'offhire',
            is_row: true,
            trs_type: 'expenses'
          }
        },
        {
          bunkers_type: 'C/E/V',
          debit: '',
          credit: '',
          additional_info: {
            rate: '',
            select: 'rate1',
            is_row: true,
            trs_type: 'revenues'
          }
        },
      ],
      editData: {
        linked_payments: [],
        currency: 'USD',
        total_debit: 0,
        total_credit: 0,
        balance: 0,
        comment: '',
        additional_info: {
          remittances: [],
          isDeliveryRoll: false,
          isRedeliveryRoll: false,
        },
        contract: 0,
        files: [],
        charter_hire: [],
        bunkers_delivery: [],
        balast_bonus: {
          commision_percentage: '',
          balast_debit: '',
          balast_credit: '',
          commision_debit: '',
          commision_credit: '',
          gross_balast_debit: '',
          gross_balast_credit: '',
          balast_type: 'Gross balast bonus',
          additional_info: {
            is_bonus: true,
            is_commission: true,
            trs_type_bonus: 'revenues',
            trs_type_commission: 'expenses'
          }
        },
        chrtrs: {
          deposit_paid_debit: '',
          deposit_paid_credit: '',
          deposit_revert_debit: '',
          deposit_revert_credit: '',
          additional_info: {
            is_paid: true,
            is_revert: true,
            trs_type_paid: 'revenues',
            trs_type_revert: 'expenses'
          }
        },
        bunkers: [
          {
            bunker_type: 'bunkers',
            lsmgo_debit: '',
            lsmgo_credit: '',
            vlsfo_debit: '',
            vlsfo_credit: '',
            additional_info: {
              lsmgo_quantity: '',
              lsmgo_price: '',
              vlsfo_quantity: '',
              vlsfo_price: '',
              is_vlsfo: true,
              is_lsmgo: true,
              trs_type_vlsfo: 'expenses',
              trs_type_lsmgo: 'expenses'
            }
          },
          {
            bunker_type: 'bunkers_supply',
            lsmgo_debit: '',
            lsmgo_credit: '',
            vlsfo_debit: '',
            vlsfo_credit: '',
            additional_info: {
              lsmgo_quantity: '',
              lsmgo_price: '',
              vlsfo_quantity: '',
              vlsfo_price: '',
              comment: '',
              is_vlsfo: true,
              is_lsmgo: true,
              trs_type_vlsfo: 'revenues',
              trs_type_lsmgo: 'revenues'
            }
          }
        ],
        hire_off: [],
        speed_claim: [],
        expenses: [
          {
            expense_type: 'charterer_expense',
            debit: '',
            credit: '',
            is_commision: false,
            is_comment: true,
            additional_info: {
              comment: '',
              commision: '',
              title: ''
            }
          }
        ],
        bank_accounts: null,
        due_date: ''
      },
      export_settings: {
        stamp: true,
        color: true,
        bank_details: true,
      },
    }
  },
  watch: {
    $route: async function () {
      if(this.reSaveMode) {
        this.showLoad()
        if(!this.trials.length)
          await this.axiosGetTrials()
        await this.checkSubcontract()
        this.hideLoad()

        if(!this.owners || this.owners.contract.id != this.$route.params.subId)
          await this.axiosOpenOwnersById(this.$route.params.subId)

        if(this.owners) this.export_settings.stamp = this.owners.stamp;
        if(this.owners) this.export_settings.color = this.owners.color;
        if(this.owners) this.export_settings.bank_details = this.owners.bank_details

        if(!this.requisites)
          await this.axiosGetRequisites()

        this.selectedBlock({type: 'remittances',name: 'Remmitances Done'})

        if(this.owners) {
          this.onEditOwners()
        }else{
          this.editData.bunkers_delivery = this.emptyBunkersDelivery
        }

        if(!this.editData.charter_hire.length)
          this.onAddHire()
        if(!this.editData.hire_off.length)
          this.onAddOffHire()
        if(!this.editData.speed_claim.length)
          this.onAddSpeed()

        this.isShowRemitances = true

        setTimeout(()=> {
          this.onSave()
        }, 5000)

      }
    }
  },
  computed: {
    ...mapState({
      activeContract: state => state.contract.activeContract,
      idsReSave: state => state.invoice.idsReSave,
      trials: state => state.trialBalance.trials,
      owners: state => state.owners.activeOwners,
      contract: state => state.contract.activeContract,
      sublet: state => state.contract.activeSubcontract,
      valutaList: state => state.payment.valutaList,
      requisites: state => state.banks.requisites
    }),
    ...mapGetters(['getPermissionsByType']),
    getExistBlocks() {
      return this.blocks.filter(el => {
        for (let i = 0; i < this.attachBlocks.length; i++) {
          const element = this.attachBlocks[i];
          if(element.type == el.type){
            return false
          }
        }
        return true
      })
    },
    getTrialsInDrop() {
      return this.trials.map(el => {
        return {id: el.id, name: (this.getPermissionsByType('show_dop_info_for_acc_in_payments') ?  el.account_id+': '+el.name : el.name)}
      })
    },
    mapRequisites() {
      if(!this.requisites) return []
      
      return this.requisites.map(el=>{
        let name = (el.account && (this.getTrialsInDrop.filter(a=> a.id === el.account.id)).length) ? this.getTrialsInDrop.filter(a=> a.id === el.account.id)[0].name : el.holder
        return {
          name: el.cash.name+', '+name,
          id: el.id
        }
      })
    },
    getCurrencyWidth() {
      return this.getPermissionsByType('show_accounts_in_all_operations') ? '13.45%' : '14.9%'
    }
  },
  methods: {
    ...mapMutations(['changeContractBread', 'showLoad', 'hideLoad', 'deleteReseveId']),
    ...mapActions(['axiosAddOwners', 'axiosOpenOwnersById', 'axiosGetTrials', 'checkSubcontract', 'axiosGetRequisites', 'axiosOpenContractById']),
    async reSave() {
      console.log(`reSave start`, this.idsReSave)
      this.reSaveMode = this.idsReSave.length !== 0
      if(!this.reSaveMode) {
        return null
      }
      let item = this.idsReSave[0]
      window.location.href = window.location.href.replace('vessels/'+this.activeContract.id, 'vessels/'+item.idContract);

      await this.axiosOpenContractById(item.idContract)
      setTimeout(()=> {
        this.$router.push({name: this.$route.params.name, params: {subId: item.idCargo, id: item.idContract}})
        this.deleteReseveId(item.idCargo)
        console.log('reSave end', this.idsReSave)
      }, 1000)

    },
    getPermissionHideLinkedOperation() {
      return this.getPermissionsByType('hide_link_operations_in_payments')
    },
    isShowBlock(block) {
      let result = this.attachBlocks.filter(el => {
        return el.type == block
      })
      if(result.length)
        return true
      return false
    },
    selectedBlock(block) {
      this.attachBlocks.push(block)
    },
    selectedCurrency(currency) {
      this.editData.currency = currency.name
    },
    selectedRequisite(acc) {
      if(acc && acc.id) {
        this.selectRequisite = this.requisites.find(el=>{
          return el.id == acc.id
        })
      } else {
        this.selectRequisite = null
      }
    },
    
    onDeleteEditBlock(type, row) {
      this.deleteType = {
        type,
        row
      }
      this.isShowModalDelete = true
    },
    deleteBlockAfterModal() {
      if(this.deleteType.row || this.deleteType.row == 0){
        switch (this.deleteType.type) {
          case 'hire':
            this.onDeleteHire(this.deleteType.row)
            break;
          case 'off_hire':
            this.onDeleteOffHire(this.deleteType.row)
            break;
          case 'speed':
            this.onDeleteSpeed(this.deleteType.row)
            break;
          case 'charterers':
            this.$refs.charterers.onDelete(this.deleteType.row)
            break;
          case 'owners':
            this.$refs.owners.onDelete(this.deleteType.row)
            break;
        
          default:
            break;
        }
      }else{
        let index = this.attachBlocks.findIndex(el => {
          return el.type == this.deleteType.type
        })
        if(index >= 0)
          this.attachBlocks.splice(index, 1)
      }

      this.deleteType = null
    },

    onAddHire() {
      this.editData.charter_hire.push({
        hire_debit: '',
        hire_credit: '',
        commision_debit: '',
        commision_credit: '',
        broker_commision_debit: '',
        broker_commision_credit: '',
        index_hire_selected: -1,
        additional_info: {
          rate: '',
          from_time: '',
          from_date: '',
          to_time: '',
          to_date: '',
          broker_commision: '',
          commision: '',
          isOpenMenu: false,
          days: '',
          is_days: true,
          is_commission: true,
          is_broker: true,
          trs_type_days: 'revenues',
          trs_type_commission: 'expenses',
          trs_type_broker: 'expenses',
        }
      })
    },
    onDeleteHire(i) {
      this.editData.charter_hire.splice(i, 1)
      if(!this.editData.charter_hire.length)
        this.onAddHire()
    },

    onAddSpeed() {
      this.editData.speed_claim.push({
        time_lost_debit: '',
        time_lost_credit: '',
        commision_debit: '',
        commision_credit: '',
        cev_debit: '',
        cev_credit: '',
        hsfo_debit: '',
        hsfo_credit: '',
        lsmgo_debit: '',
        lsmgo_credit: '',
        index_hire_selected: -1,
        additional_info: {
          title: '',
          hours: '',
          days: '',
          commision: '',
          rate: '',
          hsfo_price: '',
          hsfo_quantity: '',
          lsmgo_price: '',
          lsmgo_quantity: '',
          comment: '',
          is_days: true,
          is_commission: true,
          is_cev: true,
          is_hsfo: true,
          is_lsmgo: true,
          trs_type_days: 'expenses',
          trs_type_commision: 'revenues',
          trs_type_cev: 'expenses',
          trs_type_hsfo: 'expenses',
          trs_type_lsmgo: 'expenses',
        }
      })
      // this.editData.speed_claim.forEach((item, index)=> {
      //   item.index_hire_selected = index;
      // })
    },
    onDeleteSpeed(i) {
      this.editData.speed_claim.splice(i, 1)
      if(!this.editData.speed_claim.length)
        this.onAddSpeed()
    },

    onAddOffHire() {
      this.editData.hire_off.push({
        hire_debit: '',
        hire_credit: '',
        commision_debit: '',
        commision_credit: '',
        cev_debit: '',
        cev_credit: '',
        hsfo_debit: '',
        hsfo_credit: '',
        lsmgo_debit: '',
        lsmgo_credit: '',
        index_hire_selected: -1,
        additional_info: {
          title: '',
          from_time: '',
          from_date: '',
          to_time: '',
          to_date: '',
          days: '',
          commision: '',
          rate: '',
          time_type: 'LT',
          isOpenMenu: false,
          hsfo_price: '',
          hsfo_quantity: '',
          lsmgo_price: '',
          lsmgo_quantity: '',
          comment: '',
          is_days: true,
          is_commission: true,
          is_cev: true,
          is_hsfo: true,
          is_lsmgo: true,
          trs_type_days: 'expenses',
          trs_type_commision: 'revenues',
          trs_type_cev: 'expenses',
          trs_type_hsfo: 'expenses',
          trs_type_lsmgo: 'expenses',
        }
      })
      // this.editData.hire_off.forEach((item, index)=> {
      //   item.index_hire_selected = index;
      // })
    },
    onDeleteOffHire(i) {
      this.editData.hire_off.splice(i, 1)
      if(!this.editData.hire_off.length)
        this.onAddOffHire()
    },

    countTotalDebit() {
      let summ = 0

      this.editData.charter_hire.forEach(el => {
        let hire = el.hire_debit && el.additional_info.is_days ? +el.hire_debit : 0
        let commision = el.commision_debit && el.additional_info.is_commission ? +el.commision_debit : 0
        let broker_commision = el.broker_commision_debit && el.additional_info.is_broker ? +el.broker_commision_debit : 0
        summ += commision + broker_commision + hire
      })
      
      this.editData.bunkers_delivery.forEach(el => {
        summ += el.debit && el.additional_info.is_row ? +el.debit : 0
      })

      this.attachBlocks.forEach(el => {
        if(el.type == 'balast'){
          summ += this.editData.balast_bonus.additional_info.is_bonus ? this.formatStrToNumb(this.editData.balast_bonus.gross_balast_debit) : 0
          summ += +this.editData.balast_bonus.commision_debit && this.editData.balast_bonus.additional_info.is_commission ? +this.editData.balast_bonus.commision_debit : 0
        }

        if(el.type == 'deposit'){
          summ += this.editData.chrtrs.additional_info.is_paid ? this.formatStrToNumb(this.editData.chrtrs.deposit_paid_debit) : 0
          summ += this.editData.chrtrs.additional_info.is_revert ? this.formatStrToNumb(this.editData.chrtrs.deposit_revert_debit) : 0
        }

        if(el.type == 'bunkers'){
          let bunkers = this.editData.bunkers[0]
          let vlsfo_credit = bunkers.vlsfo_debit && bunkers.additional_info.is_vlsfo ? +bunkers.vlsfo_debit : 0
          let lsmgo_credit = bunkers.lsmgo_debit && bunkers.additional_info.is_lsmgo ? +bunkers.lsmgo_debit : 0
          summ += vlsfo_credit + lsmgo_credit
        }
        
        if(el.type == 'bunkers_supply'){
          let bunkers = this.editData.bunkers[1]
          let vlsfo_credit = bunkers.vlsfo_debit && bunkers.additional_info.is_vlsfo ? +bunkers.vlsfo_debit : 0
          let lsmgo_credit = bunkers.lsmgo_debit && bunkers.additional_info.is_lsmgo ? +bunkers.lsmgo_debit : 0
          summ += vlsfo_credit + lsmgo_credit
        }

        if(el.type == 'off_hire'){
          this.editData.hire_off.forEach(hire => {
            let debit = hire.hire_debit && hire.additional_info.is_days ? +hire.hire_debit : 0
            let commision = hire.commision_debit && hire.additional_info.is_commission ? +hire.commision_debit : 0
            let cev = hire.cev_debit && hire.additional_info.is_cev ? +hire.cev_debit : 0
            let hsfo = hire.hsfo_debit && hire.additional_info.is_hsfo ? +hire.hsfo_debit : 0
            let lsmgo = hire.lsmgo_debit && hire.additional_info.is_lsmgo ? +hire.lsmgo_debit : 0
            summ += debit + commision + cev + hsfo + lsmgo
          })
        }

        if(el.type == 'speed'){
          this.editData.speed_claim.forEach(item => {
            let debit = item.time_lost_debit && item.additional_info.is_days ? +item.time_lost_debit : 0
            let commision = item.commision_debit && item.additional_info.is_commission ? +item.commision_debit : 0
            let cev = item.cev_debit && item.additional_info.is_cev ? +item.cev_debit : 0
            let hsfo = item.hsfo_debit && item.additional_info.is_hsfo ? +item.hsfo_debit : 0
            let lsmgo = item.lsmgo_debit && item.additional_info.is_lsmgo ? +item.lsmgo_debit : 0
            summ += debit + commision + cev + hsfo + lsmgo
          })
        }

        if(el.type == 'charterers'){
          this.editData.expenses.forEach(item => {
            if(item.expense_type == 'charterer_expense'){
              summ += item.additional_info.is_row ? this.formatStrToNumb(item.debit) : 0
            }
          })
        }

        if(el.type == 'owners'){
          this.editData.expenses.forEach(item => {
            if(item.expense_type == 'owners_expense'){
              summ += item.additional_info.is_row ? this.formatStrToNumb(item.debit) : 0
              summ += parseFloat(item.commision_debit) && item.additional_info.is_commission ? +item.commision_debit : 0
            }
          })
        }

        if(el.type == 'remittances'){
          this.editData.additional_info.remittances.forEach(el=>{
            summ += el.debit && el.is_row ? +el.debit : 0
          })
        }
      })

      summ = summ ? summ : 0
      this.editData.total_debit = summ
      return summ
    },
    countTotalCredit() {
      let summ = 0

      this.editData.charter_hire.forEach(el => {
        let hire = el.hire_credit && el.additional_info.is_days ? +el.hire_credit : 0
        let commision = el.commision_credit && el.additional_info.is_commission ? +el.commision_credit : 0
        let broker_commision = el.broker_commision_credit && el.additional_info.is_broker ? +el.broker_commision_credit : 0
        summ += commision + broker_commision + hire
      })

      this.editData.bunkers_delivery.forEach(el => {
        summ += el.credit && el.additional_info.is_row ? +el.credit : 0
      })

      this.attachBlocks.forEach(el => {
        if(el.type == 'balast'){
          summ += this.editData.balast_bonus.additional_info.is_bonus ? this.formatStrToNumb(this.editData.balast_bonus.commision_credit) : 0
          summ += +this.editData.balast_bonus.gross_balast_credit && this.editData.balast_bonus.additional_info.is_commission ? +this.editData.balast_bonus.gross_balast_credit : 0
        }

        if(el.type == 'deposit'){
          summ += this.editData.chrtrs.additional_info.is_paid ? this.formatStrToNumb(this.editData.chrtrs.deposit_paid_credit) : 0
          summ += this.editData.chrtrs.additional_info.is_revert ? this.formatStrToNumb(this.editData.chrtrs.deposit_revert_credit) : 0
        }
        
        if(el.type == 'bunkers'){
          let bunkers = this.editData.bunkers[0]
          let vlsfo_credit = bunkers.vlsfo_credit && bunkers.additional_info.is_vlsfo ? +bunkers.vlsfo_credit : 0
          let lsmgo_credit = bunkers.lsmgo_credit && bunkers.additional_info.is_lsmgo ? +bunkers.lsmgo_credit : 0
          summ += vlsfo_credit + lsmgo_credit
        }
        
        if(el.type == 'bunkers_supply'){
          let bunkers = this.editData.bunkers[1]
          let vlsfo_credit = bunkers.vlsfo_credit && bunkers.additional_info.is_vlsfo ? +bunkers.vlsfo_credit : 0
          let lsmgo_credit = bunkers.lsmgo_credit && bunkers.additional_info.is_lsmgo ? +bunkers.lsmgo_credit : 0
          summ += vlsfo_credit + lsmgo_credit
        }


        if(el.type == 'off_hire'){
          this.editData.hire_off.forEach(hire => {
            summ += hire.hire_credit && hire.additional_info.is_days ? +hire.hire_credit : 0
            summ += hire.commision_credit && hire.additional_info.is_commission ? +hire.commision_credit : 0
            summ += hire.cev_credit && hire.additional_info.is_cev ? +hire.cev_credit : 0
            summ += hire.hsfo_credit && hire.additional_info.is_hsfo ? +hire.hsfo_credit : 0
            summ += hire.lsmgo_credit && hire.additional_info.is_lsmgo ? +hire.lsmgo_credit : 0
          })
        }

        if(el.type == 'speed'){
          this.editData.speed_claim.forEach(item => {
            summ += item.time_lost_credit && item.additional_info.is_days ? +item.time_lost_credit : 0
            summ += item.commision_credit && item.additional_info.is_commission ? +item.commision_credit : 0
            summ += item.cev_credit && item.additional_info.is_cev ? +item.cev_credit : 0
            summ += item.hsfo_credit && item.additional_info.is_hsfo ? +item.hsfo_credit : 0
            summ += item.lsmgo_credit && item.additional_info.is_lsmgo ? +item.lsmgo_credit : 0
          })
        }

        if(el.type == 'charterers'){
          this.editData.expenses.forEach(item => {
            if(item.expense_type == 'charterer_expense'){
              summ += item.additional_info.is_row ? this.formatStrToNumb(item.credit) : 0
            }
          })
        }

        if(el.type == 'owners'){
          this.editData.expenses.forEach(item => {
            if(item.expense_type == 'owners_expense'){
              summ += item.additional_info.is_row ? this.formatStrToNumb(item.credit) : 0
              summ += parseFloat(item.commision_credit) && item.additional_info.is_commission ? +item.commision_credit : 0
            }
          })
        }

        if(el.type == 'remittances'){
          this.editData.additional_info.remittances.forEach(el=>{
            summ += el.credit && el.is_row ? +el.credit : 0
          })
        }
      })

      summ = summ ? summ : 0
      this.editData.total_credit = summ
      return summ
    },
    countTotalBalance() {
      let summ = this.editData.total_debit - this.editData.total_credit

      summ = summ ? summ : 0
      this.editData.balance = summ
      return summ
    },

    fixIsNan(obj) {
      Object.entries(obj).forEach(([key, value]) => {
        if(key !== 'index_hire_selected') {
          if(value && typeof value == 'object'){
            this.fixIsNan(obj[key])
          }else if(typeof value != 'boolean'){
            if(value == '' && key != 'comment' && key != 'title')  obj[key] = 0
            if(value && value.toString() == 'NaN')
              obj[key] = parseFloat(value) ? value : 0
          }
        } else {
          if((''+obj[key]).length === 0) {
            obj[key] = -1
          }
        }
      });
    },
    fixIsZero(obj) {
        Object.entries(obj).forEach(([key, value]) => {
          if(key !== 'index_hire_selected') {
            if(value && typeof value == 'object'){
              this.fixIsZero(obj[key])
            }else{
              if(value === 0)  obj[key] = ''
            }
          }

      });
    },
    formatNumbTwo(obj) {
      Object.entries(obj).forEach(([key, value]) => {
        if(value && typeof value == 'object'){
          this.formatNumbTwo(obj[key])
        }else{
          if(typeof value == 'number' && key != 'id'){
            obj[key] = value.toFixed(2)
          }
        }
      });
    },

    formatStrToNumb(str) {
      let result = str
      if(result && typeof result == 'string'){
        result = result.replaceAll(',','')
        return parseFloat(result)
      }else if(result && typeof result == 'number'){
        return result
      }else{
        return 0
      }
    },
    formatNumbToStr(numb) {
      let result = numb
      if(result && typeof result == 'number'){
        result = result.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
      }else if(result && typeof result == 'string'){
        result= parseFloat(result)
        return result.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
      }else{
        return ''
      }
    },
    fixBeforeSave(obj) {
      if(obj.balast_bonus){
        obj.balast_bonus.gross_balast_debit = this.formatStrToNumb(obj.balast_bonus.gross_balast_debit)
        obj.balast_bonus.gross_balast_credit = this.formatStrToNumb(obj.balast_bonus.gross_balast_credit)
      }
      if(obj.chrtrs){
        obj.chrtrs.deposit_paid_debit = this.formatStrToNumb(obj.chrtrs.deposit_paid_debit)
        obj.chrtrs.deposit_paid_credit = this.formatStrToNumb(obj.chrtrs.deposit_paid_credit)
        obj.chrtrs.deposit_revert_debit = this.formatStrToNumb(obj.chrtrs.deposit_revert_debit)
        obj.chrtrs.deposit_revert_credit = this.formatStrToNumb(obj.chrtrs.deposit_revert_credit)
      }

      obj?.expenses.forEach(el=>{
        el.debit = this.formatStrToNumb(el.debit)
        el.credit = this.formatStrToNumb(el.credit)
      })
    },
    fixBeforeEdit(obj) {
      if(obj.balast_bonus){
        obj.balast_bonus.gross_balast_debit = this.formatNumbToStr(obj.balast_bonus.gross_balast_debit)
        obj.balast_bonus.gross_balast_credit = this.formatNumbToStr(obj.balast_bonus.gross_balast_credit)
      }
      if(obj.chrtrs){
        obj.chrtrs.deposit_paid_debit = this.formatNumbToStr(obj.chrtrs.deposit_paid_debit)
        obj.chrtrs.deposit_paid_credit = this.formatNumbToStr(obj.chrtrs.deposit_paid_credit)
        obj.chrtrs.deposit_revert_debit = this.formatNumbToStr(obj.chrtrs.deposit_revert_debit)
        obj.chrtrs.deposit_revert_credit = this.formatNumbToStr(obj.chrtrs.deposit_revert_credit)
      }

      obj?.expenses.forEach(el=>{
        el.debit = this.formatNumbToStr(el.debit)
        el.credit = this.formatNumbToStr(el.credit)
      })
    },

    async onSave() {
      let saveObj = {
        stamp: this.export_settings.stamp,
        color: this.export_settings.color,
        bank_details: this.export_settings.bank_details,
        due_date: this.dueDate ? this.dueDate : null,
        invoice_date: this.invoiceDate ? this.invoiceDate : null,
        bank_accounts: this.selectRequisite ? this.selectRequisite.id : null,
        currency: this.editData.currency,
        total_debit: this.editData.total_debit,
        total_credit: this.editData.total_credit,
        balance: this.editData.balance,
        comment: this.editData.comment,
        additional_info: {
          remittances: this.editData.additional_info.remittances,
          isDeliveryRoll: this.editData.additional_info.isDeliveryRoll,
          isRedeliveryRoll: this.editData.additional_info.isRedeliveryRoll
        },
        contract: this.$route.params.subId,
        files: this.editData.files.map(el=>{
          return el.id
        }),
        charter_hire: this.editData.charter_hire,
        bunkers_delivery: this.editData.bunkers_delivery,
        bunkers: [],
        expenses: [],
        linked_payments: this.editData.linked_payments.map(el => el.id),
        linked_invoices: [],
        linked_owners: []
      }

      this.attachBlocks.forEach(el => {
        if(el.type == 'balast'){
          saveObj.balast_bonus = this.editData.balast_bonus
        }
        if(el.type == 'deposit'){
          saveObj.chrtrs = this.editData.chrtrs
        }
        if(el.type == 'bunkers'){
          saveObj.bunkers = [...saveObj.bunkers, ...this.editData.bunkers.filter(item=>{
            return item.bunker_type == 'bunkers'
          })]
        }
        if(el.type == 'bunkers_supply'){
          saveObj.bunkers = [...saveObj.bunkers, ...this.editData.bunkers.filter(item=>{
            return item.bunker_type == 'bunkers_supply'
          })]
        }
        if(el.type == 'off_hire'){
          saveObj.hire_off = this.editData.hire_off
        }
        if(el.type == 'speed'){
          saveObj.speed_claim = this.editData.speed_claim
        }
        if(el.type == 'charterers'){
          saveObj.expenses = [...saveObj.expenses, ...this.editData.expenses.filter(item=>{
            return item.expense_type == 'charterer_expense'
          })]
        }
        if(el.type == 'owners'){
          saveObj.expenses = [...saveObj.expenses, ...this.editData.expenses.filter(item=>{
            return item.expense_type == 'owners_expense'
          })]
        }
      })
      console.log(saveObj)
      saveObj = JSON.stringify(saveObj)
      saveObj = JSON.parse(saveObj)
      //console.log(saveObj)
      this.fixIsNan(saveObj)
      this.fixBeforeSave(saveObj)
      await this.axiosAddOwners(saveObj)
      if(!this.reSaveMode) {
        this.$router.push(`/vessels/${this.$route.params.id}/open/charterers/${this.$route.params.subId}/soa`)
      }

    },

    onEditOwners() {
      this.fixIsZero(this.owners)
      this.editData.comment = this.owners.comment
      this.editData.linked_payments = this.owners.linked_payments
      this.editData.additional_info.isDeliveryRoll = this.owners.additional_info?.isDeliveryRoll
      this.editData.additional_info.isRedeliveryRoll = this.owners.additional_info?.isRedeliveryRoll
      this.editData.additional_info.remittances = this.owners.additional_info?.remittances

      this.activeCurrency = {
        name: this.owners.currency,
        id: this.owners.currency
      }

      if(this.owners.due_date)
        this.dueDate = (new Date(this.owners.due_date))
      if(this.owners.invoice_date)
        this.invoiceDate = this.owners.invoice_date

      if(this.owners.bank_accounts && this.requisites){
        this.selectRequisite = this.requisites.find(el=>{
          return el.id == this.owners.bank_accounts
        })
        this.activeRequisite = {
          name: (this.selectRequisite.account && this.getTrialsInDrop.filter(a=> a.id === this.selectRequisite.account.id).length) ? this.getTrialsInDrop.filter(a=> a.id === this.selectRequisite.account.id)[0].name : this.selectRequisite.holder,
          id: this.selectRequisite.id
        }
      }

      this.editData.files = this.owners.files
      this.editData.charter_hire = JSON.parse(JSON.stringify(this.owners.charter_hire))

      if(this.owners.bunkers_delivery.length)
        this.editData.bunkers_delivery = this.owners.bunkers_delivery

      if(this.owners.balast_bonus){
        this.selectedBlock({type: 'balast',name: 'Ballast Bonus'})
        this.editData.balast_bonus = this.owners.balast_bonus
      }
      if(this.owners.chrtrs){
        this.selectedBlock({type: 'deposit',name: 'Deposit Paid by Chrts / Revert to Chrts'})
        this.editData.chrtrs = this.owners.chrtrs
      }

      if(this.owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers'}).length){
        this.selectedBlock({type: 'bunkers',name: 'Bunkers'})
        this.editData.bunkers[0] = this.owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers'})[0]
      }

      if(this.owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers_supply'}).length){
        this.selectedBlock({type: 'bunkers_supply',name: 'Bunker Supply by Owners'})
        this.editData.bunkers[1] = this.owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers_supply'})[0]
      }

      if(this.owners.hire_off.length){
        this.selectedBlock({type: 'off_hire',name: 'Off-Hire'})
        this.editData.hire_off = this.owners.hire_off
      }
      if(this.owners.speed_claim.length){
        this.selectedBlock({type: 'speed',name: 'Speed Claim'})
        this.editData.speed_claim = this.owners.speed_claim
      }

      if(this.owners.expenses.filter(el=>{return el.expense_type == 'charterer_expense'}).length){
        this.selectedBlock({type: 'charterers',name: 'Charterers Expenses'})

        let arr = this.editData.expenses.filter(el=>{
          return el.expense_type != 'charterer_expense'
        })
        this.editData.expenses = [...arr, ...this.owners.expenses.filter(item=>{
          return item.expense_type == 'charterer_expense'
        })]
      }
      if(this.owners.expenses.filter(el=>{return el.expense_type == 'owners_expense'}).length){
        this.selectedBlock({type: 'owners',name: 'Owners Expenses'})

        let arr = this.editData.expenses.filter(el=>{
          return el.expense_type != 'owners_expense'
        })
        this.editData.expenses = [...arr, ...this.owners.expenses.filter(item=>{
          return item.expense_type == 'owners_expense'
        })]
      }

      this.$refs.remittances?.onCheckIsRow(this.owners.additional_info.remittances)
      this.$refs.bunkersOn?.checkIsRow()
      this.$refs.bunkersOnCEV?.checkIsRow()

      // this.formatNumbTwo(this.editData)
      // this.fixBeforeEdit(this.editData)
    }
  },
  updated() {
    setTimeout(()=> {
      let inputs = document.querySelectorAll('.vessel_owners__page_container-edit input:not([type=checkbox], [type=radio], [disabled=disabled], .dropdown-menu input)')
      inputs.forEach((el, i)=> {
        el.setAttribute('tabindex', i)
      })
    }, 1000)
  },
  async beforeMount() {
    document.addEventListener('keydown',function(event){
      if(event.keyCode === 9 && event.target) {
        let element = event.target
        if(element.hasAttribute('tabindex')) {
          let inputs = document.querySelectorAll('.vessel_owners__page_container-edit input:not([type=checkbox], [type=radio], [disabled=disabled], .dropdown-menu input)')
          let nextElement = inputs[Number(element.getAttribute('tabindex'))+1]
          // let currentElement = inputs[Number(element.getAttribute('tabindex'))]
          // console.log(currentElement, nextElement)
          nextElement.focus()
        }
      }
    });
    this.showLoad()
    if(!this.trials.length)
      await this.axiosGetTrials()
    await this.checkSubcontract()
    this.hideLoad()

    if(!this.owners || this.owners.contract.id != this.$route.params.subId)
      await this.axiosOpenOwnersById(this.$route.params.subId)
      
    if(this.owners) this.export_settings.stamp = this.owners.stamp;
    if(this.owners) this.export_settings.color = this.owners.color;
    if(this.owners) this.export_settings.bank_details = this.owners.bank_details

    if(!this.requisites)
      await this.axiosGetRequisites()

    this.selectedBlock({type: 'remittances',name: 'Remmitances Done'})

    if(this.owners) {
      this.onEditOwners()
    }else{
      this.editData.bunkers_delivery = this.emptyBunkersDelivery
    }

    if(!this.editData.charter_hire.length)
      this.onAddHire()
    if(!this.editData.hire_off.length)
      this.onAddOffHire()
    if(!this.editData.speed_claim.length)
      this.onAddSpeed()

    this.isShowRemitances = true
  },
}
</script>
<style lang="scss">
.soa_due_date{
  .mx-datepicker{
    width: 100%;
  }
  .mx-input{
    height: 36px;
    background-color: var(--color-input);
  }
}
.vessel_owners__page_container-edit{
  .vessel_owners_drop .counterparties-drop_title{
    margin-bottom: 0.75rem !important;
    font-size: 1rem !important;
    line-height: 1.22rem !important;
    font-weight: 500 !important;
    color: var(--color-dark);
  }
  .linked_wrap{
    >p{
      margin-bottom: 0.75rem !important;
      font-size: 1rem !important;
      line-height: 1.22rem !important;
      font-weight: 500 !important;
      color: var(--color-dark) !important;
    }
    >div.bg-white{
      background-color: var(--color-input);
    }
  }
  .counterparties-user_block__title_text{
    margin-bottom: 0.75rem !important;
    font-size: 1rem !important;
    line-height: 1.22rem !important;
    font-weight: 500 !important;
    color: var(--color-dark) !important;
  }
}
</style>
<style lang="scss" scoped>
.w50P-25  {
  width: calc(50% - 25px);
}
.block-radio-button {
  p {
    height: 17px;
    margin-bottom: 24px;
  }
}
.radio-button {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-right: 7px;
  }
  margin-bottom: 16px;
}
/deep/ {
  .vessel_owners__table_wide {
    &>div {
      word-break: break-all;
      text-align: left;
    }
  }
}
</style>