<template>
  <div class="block-payments"  :class="{'account-invoice' : $route.path.indexOf('accounting-invoice') >= 0, 'mb0': getNextLinkPayment }" v-if="mountedEnd">
<!--    overflow-scroll-->
<!--    {{getPermissionShowDopColumnsFull()}}-->
    <app-loader v-if="showLoaderButton"></app-loader>
    <div
        v-if="!showFullFunctional && (paymentsList.length !== 0 || (notePayment.length !== 0 && $route.path.indexOf('cashflow/list') >= 0))"
        class="titles-for-column "
        :class="{'one-element': paymentsList.length === 1}"
    >
      <div class="list-columns">
        <div class="list-columns__column block-for-checkbox" v-if="$route.path.indexOf('archive') >= 0 || $route.path.indexOf('open/accounting-invoice') >=0">
          <div class="">
            <button class="button-checkbox" v-on:click="selectCheckboxAll">
              <svg v-if="selectedCheckboxes.length === 0" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
              </svg>
              <svg v-if="selectedCheckboxes.length !==0" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4.5C0 2.01472 2.01472 0 4.5 0H13.5C15.9853 0 18 2.01472 18 4.5V13.5C18 15.9853 15.9853 18 13.5 18H4.5C2.01472 18 0 15.9853 0 13.5V4.5Z" fill="#094172"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 1.4C2.78792 1.4 1.4 2.78792 1.4 4.5V13.5C1.4 15.2121 2.78792 16.6 4.5 16.6H13.5C15.2121 16.6 16.6 15.2121 16.6 13.5V4.5C16.6 2.78792 15.2121 1.4 13.5 1.4H4.5ZM4.5 0C2.01472 0 0 2.01472 0 4.5V13.5C0 15.9853 2.01472 18 4.5 18H13.5C15.9853 18 18 15.9853 18 13.5V4.5C18 2.01472 15.9853 0 13.5 0H4.5Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.6687 8.99922C4.6687 8.4711 5.09683 8.04297 5.62495 8.04297H12.375C12.9031 8.04297 13.3312 8.4711 13.3312 8.99922C13.3312 9.52734 12.9031 9.95547 12.375 9.95547H5.62495C5.09683 9.95547 4.6687 9.52734 4.6687 8.99922Z" fill="white"/>
              </svg>
            </button>
          </div>
          <div v-if="paymentBreakdown === true"></div>
          <div v-for="(data, i) in ArrayCheckBox" :key="i" class="">
            <button class="button-checkbox" v-on:click="selectCheckbox(data.id)">
              <svg v-if="selectedCheckboxes.indexOf(data.id) === -1" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
              </svg>
              <svg class="active-checkbox-style" v-if="selectedCheckboxes.indexOf(data.id) !== -1" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="18" height="18" rx="4" fill="#094172"/>
                <path d="M14.5 5.02141C14.1868 4.67334 13.67 4.66368 13.3456 4.99985C13.3388 5.00688 13.3321 5.01407 13.3255 5.02141L7.76395 10.9193L4.88355 8.7889C4.55922 8.45274 4.04241 8.46239 3.72918 8.81046C3.42361 9.15001 3.42361 9.68829 3.72918 10.0278L7.18677 12.7777C7.50559 13.1198 8.02236 13.1198 8.34114 12.7777L14.4799 6.26035C14.8042 5.92414 14.8132 5.36948 14.5 5.02141Z" fill="white"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="list-columns__column due-date" v-if="paymentBreakdown !== true">
          <div class="" v-tooltip.top="'Due Date <br>(dd.mm.yyyy)'">
            <div>
              <p class="fz12 text-white-always fw600">Due Date <br><span class="fz12">(dd.mm.yyyy)</span></p>
              <button class="sort-button" v-on:click="changeDueDateSort" :class="{'r180': dueDateSort}">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"/>
                </svg>
              </button>
            </div>
          </div>
          <div class="" v-for="(data, i) in ArrayDueDate" :key="i" v-on:click="setFocused(data.id, 'execute_date')" v-on:dblclick="(e)=> {inputFocus(e, data.id, 'execute_date')}">
            <input type="text" class="fz13 border-2-transparent" :key="keyRenderDueDate" :class="{'text-red' : compareDate(data.execute_date) && data.status !== 'paid', 'border-select': focusedElement.id === data.id && focusedElement.name === 'execute_date'}" v-model.lazy="data.execute_date" @focus="inputFocusEditText(data.execute_date)" @blur="changeDueDate(data.execute_date, i)" :disabled="getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'execute_date') || getPermissionBlockBreakDown()">
          </div>
        </div>
        <div class="list-columns__column vessel-state-and-member" v-if="((activeColumns.filter(a=> a.name === 'Vessel, CP Date').length) && getPermissionShowVesselAndCpDate()) || paymentBreakdown === true">
          <div class="" v-tooltip.top="'Vessel, <br>CP Date'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600">Vessel, <br>CP Date</p>
            </div>
          </div>
          <div class="element-center" v-tooltip.top="'BALANCE ON ACCOUNT'" v-on:click="setFocused(-1, 'ship')" v-if="paymentBreakdown === true">
            <div :class="{'border-select': focusedElement.id === -1 && focusedElement.name === 'ship'}" class="border-2-transparent flex align-items-center">
              <p class="fz13 text-dark fw700 payment-list-stroke__title-text text-dots-overflow">BALANCE ON ACC.</p>
            </div>
          </div>
          <div class="" v-on:dblclick="(e)=> {inputFocus(null, data.id, 'ship'); openAsync('ship'+i)}" v-for="(data, i) in ArrayVessel" v-on:click="setFocused(data.id, 'ship')" :key="i" :class="{'list-show-up': paymentsList.length - (i+1) < 7 && i >=7}">
<!--            <template v-if="defer(i)">-->
<!--            <div class="border-2-transparent" v-show="!(focusedElement.id === data.id && focusedElement.name === 'ship')" v-tooltip.top="data.ship ? (data.ship.cp_date ? ( data.ship.name + ' (' + getFormatDate(data.ship.cp_date) + ')' ): data.ship.name) : ''">-->
<!--              <p class="text-info-list fz13 text-dark">{{data.ship ? (data.ship.cp_date ? ( data.ship.name + ' (' + getFormatDate(data.ship.cp_date) + ')' ): data.ship.name) : ''}}</p> {{getasdasd()}}-->
<!--            </div>-->
            <p class="fz13 text-dark" v-tooltip.top="data.ship ? (data.ship.cp_date ? ( data.ship.name + ' (' + getFormatDate(data.ship.cp_date) + ')' ): data.ship.name) : ''">{{data.ship ? ''+data.ship.name + ' (' + getFormatDate(data.ship.cp_date) + ')' : ''}}</p>
              <async-select
                  v-if="(focusedElement.id === data.id && focusedElement.name === 'ship')"
                  :disabled="getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'ship') || getPermissionBlockBreakDown()"
                  v-tooltip.top="data.ship ? (data.ship.cp_date ? ( data.ship.name + ' (' + getFormatDate(data.ship.cp_date) + ')' ): data.ship.name) : ''"
                  class="select-list-payment border-2-transparent"
                  :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'ship'}"
                  :id="'sd238472839482sd22'+i"
                  label=""
                  :ref="'ship'+i"
                  url="/contract/dropdown"
                  :params="{is_archive: false, contract_type: ['SHIP']}"
                  :allowEmpty="true"
                  :placeholder="''"
                  :afterWatchKey="'cp_date'"
                  :closeOnSelect="true"
                  v-model="data.ship"
                  :after-watch-key-brackets="'cp_date'"
                  @on-select="changePaymentData(data.id, 'ship', 'ship', data.ship, 'Vessel, CP Date', i)"
              />
<!--            </template>-->
            </div>
        </div>
        <div class="list-columns__column account" v-if="paymentBreakdown !== true">
          <div class="" v-tooltip.top="'Reference'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600 text-dots-overflow">Reference</p>
            </div>
          </div>
          <div class="" v-on:dblclick="(e)=> {inputFocus(null, data.id, 'account'); openAsync('account'+i)}" v-for="(data, i) in ArrayReference" :key="i" v-on:click="setFocused(data.id, 'account')" :class="{'list-show-up': paymentsList.length - (i+1) < 7 && i >=7}">
            <p class="fz13 text-dark" v-tooltip.top="data.account_name">{{data.account_name}}</p>
            <async-select
                v-if="(focusedElement.id === data.id && focusedElement.name === 'account')"
                :disabled="getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'account')"
                  v-tooltip.top="getTextAccountTitle(data.account)"
                  class="select-list-payment border-2-transparent multiselect-padding-svg"
                  :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'account'}"
                  v-model="data.account"
                  :allowEmpty="true"
                  label=""
                  :ref="'account'+i"
                  :is-multiple="false"
                  :beforeWatchKey="getPermissionShowDopInfoAcc() ? 'account_id' : null"
                  url="/payment/account"
                  :placeholder="''"
                  :params="{is_archive: false}"
                  :closeOnSelect="true"
                @changeTextSelectInput="changePaymentData(data.id, 'account_name', 'text', $event, 'Reference text', i)"
                @on-select="changePaymentData(data.id, 'account', 'object', data.account, 'Reference', i)"
                :searchInputActive="true"
                :searchInputText="data.account_name"
              ></async-select>
            </div>
        </div>
        <div class="list-columns__column additionally" v-if="paymentBreakdown !== true">
          <div class="" v-tooltip.top="'Additionally'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600 text-dots-overflow">Additionally</p>
            </div>
          </div>
          <div class="" v-on:dblclick="(e)=> {inputFocus(e, data.id, 'additionally')}" v-for="(data, i) in ArrayAdditionally" :key="i" v-on:click="setFocused(data.id, 'additionally')" v-tooltip.top="data.additionally">
            <input type="text" :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'additionally'}" maxlength="15" class="fz14 border-2-transparent uppercase" v-model.lazy="data.additionally" @blur="changePaymentData(data.id, 'additionally', 'text', data.additionally, 'Additionally', i)" :disabled="getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'additionally')">
          </div>
        </div>
        <div class="list-columns__column currency">
          <div class="" v-tooltip.top="'Currency'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600">Cur.</p>
            </div>
          </div>
          <div class="element-center" v-on:click="setFocused(-1, 'currency')" v-if="paymentBreakdown === true">
            <div :class="{'border-select': focusedElement.id === -1 && focusedElement.name === 'currency'}" class="border-2-transparent">
              <p class="fz13 text-dark"> </p>
            </div>
          </div>
          <div class="" v-on:dblclick="(e)=> {inputFocus(null, data.id, 'currency'); openDropDownCounter('currency'+i)}" v-for="(data, i) in ArrayCurrency" :key="i" v-on:click="setFocused(data.id, 'currency')" :class="{'list-show-up': paymentsList.length - (i+1) < 7 && i >=7}">
              <counterparties-drop
                  :ref="'currency'+i"
                  :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'currency'}"
                  class="valute-drop-currency border-2-transparent"
                  title=""
                  :list="valutaList"
                  :removeSelectItemBeforeClick="false"
                  :activeItem="{name: paymentsList[i].currency, id: paymentsList[i].currency}"
                  :placeholder="''"
                  :is-disabled="getBoolDontSelect({bank: paymentsList[i].bank, cash: paymentsList[i].cash}) || getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'currency') || getPermissionBlockBreakDown()"
                  @beforeSelect="indexBeforeChangeCurrency = i"
                  @select="changeCurrency"
                  @afterClose="changePaymentData(data.id, 'currency', 'text', data.currency, 'Currency', i)"
              ></counterparties-drop>
          </div>
        </div>
        <div class="list-columns__column payment">
          <div class="" v-tooltip.top="$route.path.indexOf('accounting-invoice')>=0 ? 'Bill' : 'Payments'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600"><span v-if="$route.path.indexOf('accounting-invoice')>=0" class="fw600">Bill</span><span v-if="$route.path.indexOf('accounting-invoice')===-1" class="fw600">Payments</span></p>
            </div>
          </div>
          <div class="element-center" v-on:click="setFocused(-1, 'payment_amount_expanse')" v-if="paymentBreakdown === true">
            <div :class="{'border-select': focusedElement.id === -1 && focusedElement.name === 'payment_amount_expanse'}" class="border-2-transparent">
              <p class="fz13 text-dark"> </p>
            </div>
          </div>
          <div class="position-relative" v-for="(data, i) in ArrayPayments" :key="i" v-on:dblclick="(e)=> {inputFocus(null, data.id, 'payment_amount_expanse'); focusInModuleInput('payment_amount_expanse'+i)}" v-tooltip.top="data.payment_amount_expanse !== null ? data.payment_amount_expanse : ''" v-on:click="setFocused(data.id, 'payment_amount_expanse'); setSelectedForCalc(data, i, 'expense')" v-on:mousedown="setExpenseDownItem(i)" v-on:mouseup="setExpenseUpItem(i)">
            <div class="title-calc text-white fz12 fw400 d-flex align-items-center" v-if="showTitleCal(i, 'expense')">
              Total: {{amountCalculate | formatNumberTwo}}
            </div>
            <input-format-number @paste="deleteValuePaymentAfterPast('expense',i)" :copyPaste="true" class="border-2-transparent" :ref="'payment_amount_expanse'+i" :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'payment_amount_expanse', 'border-select-left': selectedPaymentIds.indexOf(data.id) >= 0, 'border-select-bottom': selectedPaymentIds.indexOf(data.id) >= 0 && (i === paymentsList.length - 1 || selectedPaymentIds.indexOf(paymentsList[i+1].id) === -1), 'border-select-top': selectedPaymentIds.indexOf(data.id) >= 0 && (i === 0 || selectedPaymentIds.indexOf(paymentsList[i-1].id) === -1), 'border-select-right': selectedPaymentIds.indexOf(data.id) >= 0 && selectedIncomeIds.indexOf(data.id) === -1}" :isShowZero="true" :inputClass="'inputStyleNone align-right-input' + (data.payment_amount_expanse !== 0 ? ' fw700' : '')" v-model="data.payment_amount_expanse" :disabled="getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'payment_amount_expanse') || getPermissionBlockBreakDown()" @blur="changePaymentData(data.id, 'payment_amount_expanse', 'number', data.payment_amount_expanse, 'Amount', i)"></input-format-number>
          </div>
        </div>
        <div class="list-columns__column income">
          <div class="" v-tooltip.top="$route.path.indexOf('accounting-invoice')>=0 ? 'Invoice' : 'Incomes'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600"><span v-if="$route.path.indexOf('accounting-invoice')>=0" class="fw600">Invoice</span><span v-if="$route.path.indexOf('accounting-invoice')===-1" class="fw600">Incomes</span></p>
            </div>
          </div>
          <div class="element-center" v-on:click="setFocused(-1, 'payment_amount_income')" v-if="paymentBreakdown === true">
            <div :class="{'border-select': focusedElement.id === -1 && focusedElement.name === 'payment_amount_income'}" class="border-2-transparent">
              <p class="fz13 text-dark"> </p>
            </div>
          </div>
          <div v-for="(data, i) in ArrayIncomes" class="position-relative" :key="i" v-on:dblclick="(e)=> {inputFocus(null, data.id, 'payment_amount_income'); focusInModuleInput('payment_amount_income'+i)}" v-tooltip.top="data.payment_amount_income !== null ? data.payment_amount_income : ''" v-on:click="setFocused(data.id, 'payment_amount_income'); setSelectedForCalc(data, i, 'income')" v-on:mousedown="setIncomeDownItem(i)" v-on:mouseup="setIncomeUpItem(i)">
            <div class="title-calc text-white fz12 fw400 d-flex align-items-center" v-if="showTitleCal(i, 'income')">
              Total: {{amountCalculate | formatNumberTwo}}
            </div>
            <input-format-number @paste="deleteValuePaymentAfterPast('income',i)" :copyPaste="true" class="border-2-transparent" :ref="'payment_amount_income'+i" :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'payment_amount_income', 'border-select-right': selectedIncomeIds.indexOf(data.id) >= 0, 'border-select-bottom': selectedIncomeIds.indexOf(data.id) >= 0 && (i === paymentsList.length - 1 || selectedIncomeIds.indexOf(paymentsList[i+1].id) === -1), 'border-select-top': selectedIncomeIds.indexOf(data.id) >= 0 && (i === 0 || selectedIncomeIds.indexOf(paymentsList[i-1].id) === -1), 'border-select-left': selectedPaymentIds.indexOf(data.id) === -1 && selectedIncomeIds.indexOf(data.id) >=0}" :isShowZero="true" :inputClass="'inputStyleNone align-right-input' + (data.payment_amount_income !== 0 ? ' fw700' : '') + ((data.status === 'paid' && data.payment_amount_income !== 0) ? ' text-green-dark' : '')" v-model="data.payment_amount_income" :disabled="getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'payment_amount_income') || getPermissionBlockBreakDown()" @blur="changePaymentData(data.id, 'payment_amount_income', 'number', data.payment_amount_income, 'Amount', i)"></input-format-number>
          </div>
        </div>
        <div class="list-columns__column converted" v-if="activeColumns.filter(a=> a.name === 'USD amount').length && paymentBreakdown !== true">
          <div class="" v-tooltip.top="'USD amount'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600">USD amount</p>
            </div>
          </div>
          <div class="position-relative" v-for="(data, i) in ArrayUSD" :key="i" v-on:dblclick="(e)=> {inputFocus(null, data.id, 'currency_amount'); focusInModuleInput('currency_amount'+i)}" v-on:click="setFocused(data.id, 'currency_amount'); setSelectedConvert(data, i)" v-on:mousedown="setConcertedDownItem(i)" v-on:mouseup="setConvertedUpItem(i)">
            <div class="title-calc text-white fz12 fw400 d-flex align-items-center" v-if="showTitleCalcUSD() === i">
              Total: {{amountCalculateUSD | formatNumberTwo}}
            </div>
            <input-format-number :copyPaste="true" :ref="'currency_amount'+i" class="border-2-transparent" :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'currency_amount', 'border-select-right': selectedConvertedIds.indexOf(data.id) >= 0, 'border-select-bottom': selectedConvertedIds.indexOf(data.id) >= 0 && (i === paymentsList.length - 1 || selectedConvertedIds.indexOf(paymentsList[i+1].id) === -1), 'border-select-top': selectedConvertedIds.indexOf(data.id) >= 0 && (i === 0 || selectedConvertedIds.indexOf(paymentsList[i-1].id) === -1), 'border-select-left': selectedConvertedIds.indexOf(data.id) >=0}" v-tooltip.top="data.currency_amount ? ''+getNumberFormat(data.currency_amount) : ''" :isShowZero="true" :inputClass="'inputStyleNone align-right-input' + ((data.status === 'paid' && (data.payment_amount_income && ArrayIncomes[i].payment_amount_income !== 0)) ? ' text-green-dark' : '')" v-model="data.currency_amount" :disabled="getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'currency_amount')" @blur="changePaymentData(data.id, 'currency_amount', 'number', data.currency_amount, 'USD amount', i)"></input-format-number>
          </div>
        </div>
        <div class="list-columns__column status" v-if="$route.name !== 'Accounting Invoice' && paymentBreakdown !== true">
          <div class="" v-tooltip.top="'Status'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600">*Status</p>
              <button class="sort-button" v-on:click="changeStatusSort" :class="{'r180': !statusSort}">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"/>
                </svg>
              </button>
            </div>
          </div>
          <div class="" v-for="(data, i) in ArrayStatus" :key="i" v-on:click="setFocused(data.id, 'status')">
            <div class="border-2-transparent" :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'status'}">
              <p class="fz14" :class="{'rotate180': data.payment_type === 'expense', 'paid': data.status.toLowerCase() === 'paid',
               'not_paid_red': data.status.toLowerCase() === 'not_paid' && data.payment_type === 'expense', 'not_paid_green': data.status.toLowerCase() === 'not_paid' && data.payment_type === 'income', 'in_progress': data.status.toLowerCase() === 'in_progress',
               'open': data.status.toLowerCase() === 'open'}">
                {{getTextForStatus(data.status)}}
              </p>
            </div>
          </div>
        </div>
        <div class="list-columns__column paid-date" v-if="paymentBreakdown !== true">
<!--          v-if="cashFlow === true && $route.name !== 'Accounting Invoice'"-->
          <div class="" v-tooltip.top="'Paid Date <br>(dd.mm.yyyy)'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600">Paid Date <br><span class="fz12">(dd.mm.yyyy)</span></p>
            </div>
          </div>
          <div class="" v-for="(data, i) in ArrayPaidDate" :key="i" v-on:click="setFocused(data.id, 'paid_date')">
<!--            <p class="fz13 text-dark" v-if="data.client !== null">-->
            <p class="fz13 text-dark border-2-transparent" :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'paid_date'}">
              {{getFormatDate(data.paid_date)}}
            </p>
          </div>
        </div>
        <div class="list-columns__column counterparty">
          <div class="" v-tooltip.top="'Counterparty'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600 text-dots-overflow">Counterparty</p>
            </div>
          </div>
          <div class="element-center" v-on:click="setFocused(-1, 'client')" v-if="paymentBreakdown === true">
            <div :class="{'border-select': focusedElement.id === -1 && focusedElement.name === 'client'}" class="border-2-transparent">
              <p class="fz13 text-dark"> </p>
            </div>
          </div>
          <div class="" v-on:dblclick="(e)=> {inputFocus(null, data.id, 'client'); openAsync('client'+i)}" v-for="(data, i) in ArrayCounterparty" :key="i" :class="{'list-show-up': paymentsList.length - (i+1) < 7 && i >=7}" v-on:click="setFocused(data.id, 'client')">
<!--            <template v-if="defer(i)">-->
            <p class="fz13 text-dark" v-tooltip.top="data.client_name !== null ? data.client_name : ''">{{data.client_name !== null ? data.client_name : ''}}</p>
            <async-select
                v-if="(focusedElement.id === data.id && focusedElement.name === 'client')"
                  :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'client'}"
                  :searchInputActive="true"
                  :searchInputText="data.client_name"
                  :disabled="getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'client') || getPermissionBlockBreakDown()"
                  v-tooltip.top="data.client_name !== null ? data.client_name : ''"
                  class="select-list-payment border-2-transparent multiselect-padding-svg"
                  v-model="data.client"
                  :allowEmpty="true"
                  :closeOnSelect="true"
                  label=""
                  :ref="'client'+i"
                  url="/client/dropdown"
                  :placeholder="''"
                  :params="{ordering: 'company_name', is_archived: false}"
                  watchKey="company_name"
                  @changeTextSelectInput="changePaymentData(data.id, 'client_name', 'text', $event, 'Counterparty text', i)"
                  @on-select="changePaymentData(data.id, 'client', 'object', data.client, 'Counterparty', i)"
              ></async-select>
<!--            </template>-->
            <svg v-if="data.client" class="counterparty-svg" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 4.5L5.84211 2M3 4.5L5.84211 7M3 4.5H13" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13 11.5L10.1579 9M13 11.5L10.1579 14M13 11.5H3" stroke="#9AA3B0" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div class="list-columns__column account" v-if="paymentBreakdown === true">
          <div class="" v-tooltip.top="'Reference'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600 text-dots-overflow">Reference</p>
            </div>
          </div>
          <div class="element-center" v-on:click="setFocused(-1, 'account')" v-if="paymentBreakdown === true">
            <div :class="{'border-select': focusedElement.id === -1 && focusedElement.name === 'account'}" class="border-2-transparent">
              <p class="fz13 text-dark"> </p>
            </div>
          </div>
          <div class="" v-on:dblclick="(e)=> {inputFocus(null, data.id, 'account'); openAsync('account'+i)}" v-for="(data, i) in ArrayReference" :key="i" v-on:click="setFocused(data.id, 'account')" :class="{'list-show-up': paymentsList.length - (i+1) < 7 && i >=7}">
            <p class="fz13 text-dark" v-tooltip.top="data.account_name">{{data.account_name}}</p>
            <async-select
                v-if="(focusedElement.id === data.id && focusedElement.name === 'account')"
                  :disabled="getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'account') || getPermissionBlockBreakDown()"
                  v-tooltip.top="data.account_name"
                  class="select-list-payment border-2-transparent multiselect-padding-svg"
                  :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'account'}"
                  v-model="data.account"
                  :allowEmpty="true"
                  label=""
                  :ref="'account'+i"
                  :is-multiple="false"
                  :beforeWatchKey="getPermissionShowDopInfoAcc() ? 'account_id' : null"
                  url="/payment/account"
                  :placeholder="''"
                  :params="{is_archive: false}"
                  :closeOnSelect="true"
                @changeTextSelectInput="changePaymentData(data.id, 'account_name', 'text', $event, 'Reference text', i)"
                @on-select="changePaymentData(data.id, 'account', 'object', data.account, 'Reference', i)"
                :searchInputActive="true"
                :searchInputText="data.account_name"
              ></async-select>
          </div>
        </div>
        <div class="list-columns__column due-date" v-if="paymentBreakdown === true">
          <div class="" v-tooltip.top="'Due Date <br>(dd.mm.yyyy)'">
            <div>
              <p class="fz12 text-white-always fw600">Due Date <br><span class="fz12">(dd.mm.yyyy)</span></p>
            </div>
          </div>
          <div class="element-center" v-on:click="setFocused(-1, 'execute_date')" v-if="paymentBreakdown === true">
            <div :class="{'border-select': focusedElement.id === -1 && focusedElement.name === 'execute_date'}" class="border-2-transparent">
              <p class="fz13 text-dark">{{new Date() | formatDateCpDate}}</p>
            </div>
          </div>
          <div class="" v-for="(data, i) in ArrayDueDate" :key="i" v-on:click="setFocused(data.id, 'execute_date')" v-on:dblclick="(e)=> {inputFocus(e, data.id, 'execute_date')}">
            <input type="text" class="fz13 border-2-transparent" :key="keyRenderDueDate" :class="{'text-red' : compareDate(data.execute_date) && data.status !== 'paid', 'border-select': focusedElement.id === data.id && focusedElement.name === 'execute_date'}" v-model.lazy="data.execute_date" @focus="inputFocusEditText(data.execute_date)" @blur="changeDueDate(data.execute_date, i)" :disabled="getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'execute_date') || getPermissionBlockBreakDown()">
          </div>
        </div>
        <div class="list-columns__column bank-and-cash-account" v-if="(activeColumns.filter(a=> a.name === 'Bank and Cash Accounts').length || ($route.path.indexOf('open/cash-flow') === -1 && $route.path.indexOf('cashflow/list') === -1 && $route.path.indexOf('counterparties') === -1)) && getPermissionShowDopColumnsFull().such_columns.indexOf('Bank and cash accounts') === -1 && paymentBreakdown !== true">
          <div class="" v-tooltip.top="'Bank and Cash Accounts'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600">Bank and Cash Accounts</p>
            </div>
          </div>
          <div class="" v-on:dblclick="(e)=> {inputFocus(null, data.id, 'bank'); openAsync('bank'+i)}" v-for="(data, i) in ArrayBankCash" :key="i" :class="{'list-show-up': paymentsList.length - (i+1) < 7 && i >=7}" v-on:click="setFocused(data.id, 'bank')">
            <!--            <p class="fz14 text-dark">{{getTextBankAndCashAccounts(data).name}}</p>-->
<!--            <template v-if="defer(i)">-->
            <p class="fz13 text-dark" v-tooltip.top="data.bank ? (Number.isInteger(data.bank.account) ? data.bank.name : (data.bank.account.account_id + ': ' + data.bank.account.name)) : data.cash ? (Number.isInteger(data.cash.account) ? data.cash.name : data.cash.account.name) : ''">{{data.bank ? (Number.isInteger(data.bank.account) ? data.bank.name : (data.bank.account.account_id + ': ' + data.bank.account.name)) : data.cash ? (Number.isInteger(data.cash.account) ? data.cash.name : data.cash.account.name) : ''}}</p>
              <bank-and-cash-select
                  v-if="(focusedElement.id === data.id && focusedElement.name === 'bank')"
                  :ref="'bank'+i"
                  :disabled="(getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status)) || getPermissionBlockChangeBank(data.status) || (activeElement.id !== data.id || activeElement.name !== 'bank')"
                  v-tooltip.top="data.bank ? (Number.isInteger(data.bank.account) ? data.bank.name : (data.bank.account.account_id + ': ' + data.bank.account.name)) : data.cash ? (Number.isInteger(data.cash.account) ? data.cash.name : data.cash.account.name) : ''"
                  class="select-list-payment border-2-transparent"
                  :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'bank'}"
                  :key="'sd33l3l2l3klklg'+i"
                  :placeholder="''"
                  label=""
                  :closeOnSelect="true"
                  :allow-empty="true"
                  :selected-requisites="data.bank"
                  :selected-banks="data.cash"
                  @onOpen="indexBeforeChangeBank = i"
                  @onSetBank="setCash"
                  @onSetRequisite="setBank"
              ></bank-and-cash-select>
<!--            </template>-->
          </div>
        </div>
        <div class="list-columns__column remark" v-if="paymentBreakdown !== true">
          <div class="" v-tooltip.top="'Remarks'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600 text-dots-overflow">Remarks</p>
            </div>
          </div>
          <div class="element-center" v-on:dblclick="(e)=> {inputFocus(e, data.id, 'remarks')}" v-for="(data, i) in ArrayRemarks" :key="i" v-tooltip.top="data.comment ? ''+data.comment : ''" v-on:click="setFocused(data.id, 'remarks')">
            <div class="">
              <input type="text" :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'remarks'}" class="fz14 border-2-transparent" v-model.lazy="data.comment" @blur="changePaymentData(data.id, 'comment', 'text', data.comment, 'Remark', i)" :disabled="getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'remarks')">
            </div>
          </div>
        </div>
        <div class="list-columns__column attachment"  v-if="((activeColumns.filter(a=> a.name === 'Attachments').length && $route.path.indexOf('counterparties') >= 0) || $route.path.indexOf('counterparties') === -1) && paymentBreakdown !== true">
          <div class="" v-tooltip.top="'Payment <br>instructions'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600" >Paym. <br>instruct.</p>
            </div>
          </div>
          <div class="element-center" v-for="(data, i) in ArrayInstructions" :key="i" v-on:click="setFocused(data.id, 'files')">
            <div class="element-center border-2-transparent" :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'files'}">
              <button class="border-2-transparent payment-list-stroke__wrap-text fz14 text-dark add-title" v-if="Array.isArray(data.files) === true" title-text="View Attachment">
                <svg v-if="data.status === 'paid' && data.files.length" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5.7C5 4.20883 6.20883 3 7.7 3H13.3418L19.4 9.22821V16.7C19.4 18.1912 18.1912 19.4 16.7 19.4H8.06611C6.41648 19.4 5.07635 18.0681 5.06617 16.4185L5 5.7Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5.7C5 4.20883 6.20883 3 7.7 3H13.3418L19.4 9.22821V16.7C19.4 18.1912 18.1912 19.4 16.7 19.4H15.2C14.8134 19.4 14.5 19.0866 14.5 18.7C14.5 18.3134 14.8134 18 15.2 18H16.7C17.418 18 18 17.418 18 16.7V10.2125H14.0462C13.1073 10.2125 12.3462 9.45138 12.3462 8.5125V4.4H7.7C6.98203 4.4 6.4 4.98203 6.4 5.7V8.7C6.4 9.0866 6.0866 9.4 5.7 9.4C5.3134 9.4 5 9.0866 5 8.7V5.7ZM13.7462 5.42358L17.0426 8.8125H14.0462C13.8805 8.8125 13.7462 8.67818 13.7462 8.5125V5.42358Z" fill="#2C2C2C"/>
                  <path d="M6.17355 16.8696V13.5868C6.17355 12.7104 5.46313 12 4.58678 12V12C3.71042 12 3 12.7104 3 13.5868V18C3 19.6569 4.34315 21 6 21V21C7.65685 21 9 19.6569 9 18V12" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round"/>
                </svg>
                <svg v-else-if="data.files.length" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5.7C5 4.20883 6.20883 3 7.7 3H13.3418L19.4 9.22821V16.7C19.4 18.1912 18.1912 19.4 16.7 19.4H8.06611C6.41648 19.4 5.07635 18.0681 5.06617 16.4185L5 5.7Z" fill="#E9ED22"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5.7C5 4.20883 6.20883 3 7.7 3H13.3418L19.4 9.22821V16.7C19.4 18.1912 18.1912 19.4 16.7 19.4H15.2C14.8134 19.4 14.5 19.0866 14.5 18.7C14.5 18.3134 14.8134 18 15.2 18H16.7C17.418 18 18 17.418 18 16.7V10.2125H14.0462C13.1073 10.2125 12.3462 9.45138 12.3462 8.5125V4.4H7.7C6.98203 4.4 6.4 4.98203 6.4 5.7V8.7C6.4 9.0866 6.0866 9.4 5.7 9.4C5.3134 9.4 5 9.0866 5 8.7V5.7ZM13.7462 5.42358L17.0426 8.8125H14.0462C13.8805 8.8125 13.7462 8.67818 13.7462 8.5125V5.42358Z" fill="#2C2C2C"/>
                  <path d="M6.17355 16.8696V13.5868C6.17355 12.7104 5.46313 12 4.58678 12V12C3.71042 12 3 12.7104 3 13.5868V18C3 19.6569 4.34315 21 6 21V21C7.65685 21 9 19.6569 9 18V12" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="list-columns__column bank-receipt" v-if="($route.name !== 'Accounting Invoice' && ((activeColumns.filter(a=> a.name === 'Bank Receip').length && $route.path.indexOf('counterparties') >= 0 ) || $route.path.indexOf('counterparties') === -1)) && paymentBreakdown !== true">
          <div class="" v-tooltip.top="'Bank Receipt'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600">Bank Receipt</p>
            </div>
          </div>
          <div class=" element-center" v-for="(data, i) in ArrayReceipt" :key="i" v-on:click="setFocused(data.id, 'receipt_files')">
            <div class="border-2-transparent element-center" :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'receipt_files'}">
              <button v-if="data.receipt_files.length !== 0" class=" payment-list-stroke__wrap-text fz14 text-dark add-title" title-text="View bank receipt">
                <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.99609 5.7C4.99609 4.20883 6.20492 3 7.69609 3L13.342 3L19.4002 9.22821V16.6896C19.4002 18.1808 18.1914 19.3896 16.7002 19.3896H8.06219C6.41257 19.3896 5.07244 18.0577 5.06225 16.4081L4.99609 5.7Z" fill="#37D379"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5.7C5 4.20883 6.20883 3 7.7 3H13.3418L19.4 9.22821V16.7C19.4 18.1912 18.1912 19.4 16.7 19.4H15.2C14.8134 19.4 14.5 19.0866 14.5 18.7C14.5 18.3134 14.8134 18 15.2 18H16.7C17.418 18 18 17.418 18 16.7V10.2125H14.0462C13.1073 10.2125 12.3462 9.45138 12.3462 8.5125V4.4H7.7C6.98203 4.4 6.4 4.98203 6.4 5.7V8.7C6.4 9.0866 6.0866 9.4 5.7 9.4C5.3134 9.4 5 9.0866 5 8.7V5.7ZM13.7462 5.42358L17.0426 8.8125H14.0462C13.8805 8.8125 13.7462 8.67818 13.7462 8.5125V5.42358Z" fill="#2C2C2C"/>
                  <path d="M5.67355 16.8696V13.5868C5.67355 12.7104 4.96313 12 4.08678 12V12C3.21042 12 2.5 12.7104 2.5 13.5868V18C2.5 19.6569 3.84315 21 5.5 21V21C7.15685 21 8.5 19.6569 8.5 18V12" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="list-columns__column comment-from-accounting" v-if="($route.name !== 'Accounting Invoice' && ((activeColumns.filter(a=> a.name === 'Comment from accounting').length && $route.path.indexOf('counterparties') >= 0) || $route.path.indexOf('counterparties') === -1)) && paymentBreakdown !== true">
          <div class="" v-tooltip.top="'Accounting remarks'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600">Account. remarks</p>
            </div>
          </div>
          <div class=" element-center" v-for="(data, i) in ArrayRemarks" v-on:click="setFocused(data.id, 'has_messages')" :key="i">
            <div class="border-2-transparent element-center" :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'has_messages'}">
              <button class="payment-list-stroke__wrap-text fz14 text-dark add-title"  title-text="View comment">
                <svg v-if="data.has_messages" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0835 6.61999C20.0835 5.41084 19.076 4.40332 17.8668 4.40332H6.1335C4.92435 4.40332 3.91683 5.41084 3.91683 6.61999V14.6867C3.91683 15.8958 4.92435 16.9033 6.1335 16.9033H6.60572V18.5978C6.60572 19.0081 6.8579 19.3769 7.24122 19.5253L7.24467 19.5266L7.24468 19.5266C7.3588 19.5701 7.47918 19.5922 7.60016 19.5922C7.83687 19.5922 8.06877 19.5074 8.25101 19.3495L11.8501 16.9033H17.8668C19.076 16.9033 20.0835 15.8958 20.0835 14.6867V6.61999ZM17.8668 5.90332C18.2475 5.90332 18.5835 6.23927 18.5835 6.61999V14.6867C18.5835 15.0674 18.2475 15.4033 17.8668 15.4033H11.6193C11.469 15.4033 11.3221 15.4485 11.1977 15.533L8.10572 17.6346V16.1533C8.10572 15.7391 7.76993 15.4033 7.35572 15.4033H6.1335C5.75278 15.4033 5.41683 15.0674 5.41683 14.6867V6.61999C5.41683 6.23927 5.75278 5.90332 6.1335 5.90332H17.8668Z" fill="#4B4B4B"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 9C7.75 8.58579 8.08579 8.25 8.5 8.25H15.5C15.9142 8.25 16.25 8.58579 16.25 9C16.25 9.41421 15.9142 9.75 15.5 9.75H8.5C8.08579 9.75 7.75 9.41421 7.75 9Z" fill="#4B4B4B"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 12C7.75 11.5858 8.08579 11.25 8.5 11.25H12C12.4142 11.25 12.75 11.5858 12.75 12C12.75 12.4142 12.4142 12.75 12 12.75H8.5C8.08579 12.75 7.75 12.4142 7.75 12Z" fill="#4B4B4B"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="list-columns__column settled-vsl" v-if="(activeColumns.filter(a=> a.name === 'Settled vsl').length && getPermissionShowDopColumnsFull().such_columns.indexOf('Settled Vsl') === -1) && paymentBreakdown !== true">
          <div class="" v-tooltip.top="'Settled vessel'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600">Settled vsl</p>
            </div>
          </div>
          <div class="" v-on:dblclick="(e)=> {inputFocus(null, data.id, 'settled_ship'); openAsync('settled_ship'+i)}" v-for="(data, i) in ArraySettledVessel" :key="i" :class="{'list-show-up': paymentsList.length - (i+1) < 7 && i >=7}" v-on:click="setFocused(data.id, 'settled_ship')">
<!--            <template v-if="defer(i)">-->
            <p class="fz13 text-dark" v-tooltip.top="data.settled_ship !== null ? (data.settled_ship.name + ' (' + getFormatDate(data.settled_ship.cp_date) + ')') : ''">{{data.settled_ship !== null ? (data.settled_ship.name + ' (' + getFormatDate(data.settled_ship.cp_date) + ')') : ''}}</p>
            <async-select
                v-if="(focusedElement.id === data.id && focusedElement.name === 'settled_ship')"
                  :ref="'settled_ship'+i"
                  :disabled="getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'settled_ship')"
                  v-tooltip.top="data.settled_ship !== null ? (data.settled_ship.name + ' (' + getFormatDate(data.settled_ship.cp_date) + ')') : ''"
                  class="select-list-payment border-2-transparent"
                  :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'settled_ship'}"
                  placeholder=""
                  :id="'sd211s44d22'"
                  label=""
                  url="/contract/dropdown"
                  :after-watch-key-brackets="'cp_date'"
                  :params="{is_archive: false, contract_type: ['SHIP'], ordering: 'name'}"
                  :allowEmpty="true"
                  :closeOnSelect="true"
                  v-model="data.settled_ship"
                  @on-select="changePaymentData(data.id, 'settled_ship', 'object', data.settled_ship, 'Settled Ship', i)"
              />
<!--            </template>-->
          </div>
        </div>
        <div class="list-columns__column disbursement" v-if="activeColumns.filter(a=> a.name === 'Disbursements').length && paymentBreakdown !== true">
          <div class="" v-tooltip.top="'Disbursements'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600 text-dots-overflow">Disbursements</p>
            </div>
          </div>
          <div class="" v-on:dblclick="(e)=> {inputFocus(null, data.id, 'ports'); openAsync('ports'+i)}" v-for="(data, i) in ArrayDisbursements" :key="i" :class="{'list-show-up': paymentsList.length - (i+1) < 7 && i >=7}" v-on:click="setFocused(data.id, 'ports')">
<!--            <template v-if="defer(i)">-->
            <p class="fz13 text-dark" v-tooltip.top="getTextFromPorts(data.ports)">{{getTextFromPorts(data.ports)}}</p>
              <async-select
                  v-if="(focusedElement.id === data.id && focusedElement.name === 'ports')"
                  :ref="'ports'+i"
                  :afterWatchKeyBrackets="'country'"
                  :disabled="getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'ports')"
                  v-tooltip.top="getTextFromPorts(data.ports)"
                  class="select-list-payment border-2-transparent"
                  :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'ports'}"
                  :is-multiple="false"
                  v-model="data.ports"
                  :allowEmpty="true"
                  label=""
                  url="/port/dropdown"
                  :placeholder="''"
                  :closeOnSelect="true"
                  :params="{is_archived: false}"
                  @on-select="changePaymentData(data.id, 'ports', 'object', data.ports, 'Disbursements',i)"
              ></async-select>
<!--            </template>-->
          </div>
        </div>
        <div class="list-columns__column real-account" v-if="(activeColumns.filter(a=> a.name === 'Real Account').length && getPermissionShowDopColumnsFull().such_columns.indexOf('Real Account') === -1) && paymentBreakdown !== true">
          <div class="" v-tooltip.top="'Real Account'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600">Real Account</p>
            </div>
          </div>
          <div class="" v-on:dblclick="(e)=> {inputFocus(null, data.id, 'real_account'); openAsync('real_account'+i)}" v-for="(data, i) in ArrayRealAccount" :key="i" :class="{'list-show-up': paymentsList.length - (i+1) < 7 && i >=7}" v-on:click="setFocused(data.id, 'real_account')">
<!--            <template v-if="defer(i)">-->
            <p class="fz13 text-dark" v-tooltip.top="data.real_account !== null ? data.real_account.company_name : ''">{{data.real_account !== null ? data.real_account.company_name : ''}}</p>
              <async-select
                v-if="(focusedElement.id === data.id && focusedElement.name === 'real_account')"
                  :ref="'real_account'+i"
                  :disabled="getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'real_account')"
                  v-tooltip.top="data.real_account !== null ? data.real_account.company_name : ''"
                  class="select-list-payment border-2-transparent"
                  :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'real_account'}"
                  v-model="data.real_account"
                  :allowEmpty="true"
                  :closeOnSelect="true"
                  label=""
                  url="/client/dropdown"
                  :placeholder="''"
                  :params="{ordering: 'company_name', is_archived: false}"
                  watchKey="company_name"
                  @on-select="changePaymentData(data.id, 'real_account', 'object', data.real_account, 'Real Account', i)"
              ></async-select>
<!--            </template>-->
          </div>
        </div>
        <div class="list-columns__column statement-member" v-if="$route.path.indexOf('accounting-invoice') === -1 && activeColumns.filter(a=> a.name === 'Statement Member').length && getPermissionShowDopColumnsFull().such_columns.indexOf('Statement member') === -1 && paymentBreakdown !== true">
          <div class="" v-tooltip.top="'Statement Member'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600">Statem. Member</p>
            </div>
          </div>
          <div class="position-relative" v-on:dblclick="(e)=> {inputFocus(null, data.id, 'statement_member'); openDropDownCounter('statement_member'+i)}" v-tooltip.top="(''+data.statement_member).toLowerCase() !== 'invalid date' ? getFormatDate(data.statement_member) : ''" v-for="(data, i) in ArrayStatementMember" :key="i" :class="{'list-show-up': paymentsList.length - (i+1) < 7 && i >=7}" v-on:click="setFocused(data.id, 'statement_member')">
            <div class="border-2-transparent" :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'statement_member'}">
<!--              <template v-if="defer(i)">-->
                <counterparties-drop
                    :ref="'statement_member'+i"
                    :classButtonText="'fz13'"
                    :is-disabled="getPermissionEditPaymentInStatusPaid(data.status) || getPermissionsBlockChangeListInStatusPaid(data.status) || (activeElement.id !== data.id || activeElement.name !== 'statement_member')"
                    v-show="$route.name !== 'Accounting Invoice' && (data.statement_member || (focusedElement.id === data.id && focusedElement.name === 'statement_member'))"
                    title=""
                    :formatCpDate="true"
                    class="valute-drop-currency"
                    :placeholder="''"
                    :list="getCPDateListStatement(paymentsList[i].ship, paymentsList[i].client, paymentsList[i].real_account)"
                    :active-item="{name: (''+data.statement_member).toLowerCase() !== 'invalid date' ? data.statement_member : '', id: data.statement_member}"
                    @beforeSelect="indexBeforeChangeStatementMember = i"
                    @select="selectCPDateStatement"
                    :dont-select="true"
                    @close="changePaymentData(data.id, 'statement_member', 'text', data.statement_member, 'Statement Member', i)"
                ></counterparties-drop>
<!--              </template>-->
            </div>
          </div>
        </div>
        <div class="list-columns__column remark" v-if="paymentBreakdown === true">
          <div class="" v-tooltip.top="'Balance, USD'">
            <div>
              <p class="payment-list-stroke__title-text fz12 text-white-always fw600 text-dots-overflow">Balance, USD</p>
            </div>
          </div>
          <div class="element-center" v-tooltip.top="getAmountBalance ? getFormatNumberTwo(getAmountBalance) : ''" v-on:click="setFocused(-1, 'balance')" v-if="paymentBreakdown === true">
            <div :class="{'border-select': focusedElement.id === -1 && focusedElement.name === 'balance'}" class="border-2-transparent">
              <p class="fz13 text-dark align-right-input">{{getAmountBalance | formatNumberTwo}}</p>
            </div>
          </div>
          <div class="element-center" v-for="(data, i) in ArrayBalances" :key="i" v-tooltip.top="data.balance ? ''+getFormatNumberTwo(data.balance) : ''" v-on:click="setFocused(data.id, 'balance')">
            <div :class="{'border-select': focusedElement.id === data.id && focusedElement.name === 'balance'}" class="border-2-transparent">
              <p class="fz13 text-dark align-right-input">{{ data.balance | formatNumberTwo}}</p>
            </div>
          </div>
        </div>
        <div class="list-columns__column end-block">
          <div class="">

          </div>
          <div class="" v-if="paymentBreakdown === true">

          </div>
          <div class="" v-for="(data, i) in ArrayEnd" :key="i">

          </div>
        </div>


      </div>
    </div>
    <div class="block-dop-right" v-if="paymentsList.length !== 0">
      <div class="end-block">
        <button class="end-block__dop-button" v-if="nameColumns.length !== 0 && paymentBreakdown !== true">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6.66668 5.33332H9.33325C9.70129 5.33332 10 5.63202 10 5.99994C10 6.36798 9.70129 6.66668 9.33325 6.66668H6.66668V9.33325C6.66668 9.70129 6.36798 10 5.99994 10C5.63202 10 5.33332 9.70129 5.33332 9.33325V6.66668H2.66662C2.2987 6.66668 2 6.36798 2 5.99994C2 5.63202 2.2987 5.33332 2.66662 5.33332H5.33332V2.66662C5.33332 2.2987 5.63202 2 5.99994 2C6.36798 2 6.66668 2.2987 6.66668 2.66662V5.33332Z" fill="#9AA3B0"/>
          </svg>
        </button>
          <counterparties-drop
              v-if="nameColumns.length !== 0 && paymentBreakdown !== true"
              class="drop-colums"
              :is-multiple="true"
              :list="nameColumns"
              :title="''"
              :active-item="activeColumnsDefault"
              @close="setActiveColumns"
          ></counterparties-drop>
      </div>
      <div class="end-block" v-if="paymentBreakdown === true"></div>
      <div class="end-block" v-for="(data, i) in ArrayEnd" :key="i">
        <app-loader v-if="data.showLoader" class="end-block__loader"></app-loader>
        <button class="option" v-on:click="unarchivedThis(data.id)" v-if="$route.path.indexOf('archive')>=0 && getPermissionsForUnArchive()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"/>
            <path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#9AA3B0"/>
          </svg>
        </button>
        <router-link :to="$route.path.indexOf('payment/list')>=0 ? {name: 'Payment List Detail', params: {id: data.id}} : $route.path.indexOf('payment/instructions') >=0 ? {name: 'Payment Instructions Detail', params: {id: data.id}} : {name: 'Payment Detail', params: {id: data.id}}" class="link-block" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('cash-flow') === -1 && $route.path.indexOf('accounting-invoice') === -1 && $route.path.indexOf('cashflow') === -1 && $route.path.indexOf('counterparties') === -1">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
          </svg>
        </router-link>
        <router-link :to="{name: 'Detail Cash Flow', params: {paymentCashFlowID: data.id}}" class="link-block" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('cash-flow') !== -1">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
          </svg>
        </router-link>
        <router-link :to="'accounting-invoice/'+ data.id+ '/detail'" class="link-block" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('accounting-invoice') !== -1">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
          </svg>
        </router-link>
        <router-link :to="'detail/'+ data.id" class="link-block" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('cashflow/list') !== -1">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
          </svg>
        </router-link>
        <router-link :to="{name: 'CashFlow Diagram detail', params: {id: data.id}}" class="link-block" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('cashflow/diagram') !== -1">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
          </svg>
        </router-link>

        <router-link :to="{name: 'Counterparties Payment Detail', params: {idPayment: data.id}}" class="link-block" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('counterparties') !== -1">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
          </svg>
        </router-link>
      </div>

    </div>
<!--    {{activeColumns}}-->
    <div class="no-payments" v-if="paymentsList.length === 0 && (notePayment.length === 0 || $route.path.indexOf('cashflow/list') === -1)">
      <svg width="320" height="160" viewBox="0 0 320 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34.5905 121.467C34.3947 121.36 34.1483 121.218 33.9803 121.068C32.3841 119.647 30.2728 119.641 28.6707 121.068C27.6139 122.009 26.2481 122.009 25.1914 121.068C24.4072 120.37 23.4893 120.001 22.5366 120.001C21.5839 120.001 20.6659 120.37 19.8818 121.068C18.8249 122.009 17.4592 122.009 16.4024 121.068C14.8062 119.647 12.695 119.641 11.0929 121.068C10.9403 121.204 10.7216 121.344 10.4243 121.498C10.0574 121.687 9.89796 122.172 10.0682 122.581C10.2385 122.989 10.6739 123.167 11.0409 122.977C11.4518 122.765 11.7591 122.563 12.008 122.341C13.0648 121.4 14.4305 121.4 15.4874 122.341C17.0836 123.762 19.1948 123.768 20.7969 122.341C21.8537 121.4 23.2194 121.4 24.2763 122.341C25.8726 123.762 27.9838 123.768 29.5859 122.341C30.6427 121.4 32.0084 121.4 33.0653 122.341C33.3426 122.588 33.6652 122.777 33.9443 122.93C34.3073 123.129 34.7463 122.962 34.9247 122.558C35.1031 122.154 34.9536 121.666 34.5905 121.467Z" fill="#DEE3EA"/>
        <path d="M42.4095 72.467C42.6053 72.3598 42.8517 72.2176 43.0197 72.068C44.6159 70.6466 46.7272 70.6414 48.3293 72.068C49.3861 73.009 50.7519 73.009 51.8086 72.068C52.5928 71.3698 53.5107 71.0007 54.4634 71.0007C55.4161 71.0007 56.3341 71.3698 57.1182 72.068C58.1751 73.009 59.5408 73.009 60.5976 72.068C62.1938 70.6467 64.305 70.6414 65.9071 72.068C66.0597 72.2038 66.2784 72.3444 66.5757 72.498C66.9426 72.6875 67.102 73.1722 66.9318 73.5806C66.7615 73.989 66.3261 74.1665 65.9591 73.977C65.5482 73.7648 65.2409 73.5628 64.992 73.3411C63.9352 72.4001 62.5695 72.4 61.5126 73.3411C59.9164 74.7625 57.8052 74.7677 56.2031 73.3411C55.1463 72.4001 53.7806 72.4 52.7237 73.3411C51.1274 74.7625 49.0162 74.7677 47.4141 73.3411C46.3573 72.4001 44.9916 72.4 43.9347 73.3411C43.6574 73.588 43.3348 73.7774 43.0557 73.9302C42.6927 74.1289 42.2537 73.9623 42.0753 73.5583C41.8969 73.1542 42.0464 72.6657 42.4095 72.467Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M32 31.2C32.6627 31.2 33.2 30.6627 33.2 30C33.2 29.3373 32.6627 28.8 32 28.8C31.3373 28.8 30.8 29.3373 30.8 30C30.8 30.6627 31.3373 31.2 32 31.2ZM32 33C33.6569 33 35 31.6569 35 30C35 28.3431 33.6569 27 32 27C30.3431 27 29 28.3431 29 30C29 31.6569 30.3431 33 32 33Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M299 120.8C298.337 120.8 297.8 121.337 297.8 122C297.8 122.663 298.337 123.2 299 123.2C299.663 123.2 300.2 122.663 300.2 122C300.2 121.337 299.663 120.8 299 120.8ZM299 119C297.343 119 296 120.343 296 122C296 123.657 297.343 125 299 125C300.657 125 302 123.657 302 122C302 120.343 300.657 119 299 119Z" fill="#DEE3EA"/>
        <path d="M284.409 27.467C284.605 27.3598 284.852 27.2176 285.02 27.068C286.616 25.6466 288.727 25.6414 290.329 27.068C291.386 28.009 292.752 28.009 293.809 27.068C294.593 26.3698 295.511 26.0007 296.463 26.0007C297.416 26.0007 298.334 26.3698 299.118 27.068C300.175 28.009 301.541 28.009 302.598 27.068C304.194 25.6467 306.305 25.6414 307.907 27.068C308.06 27.2038 308.278 27.3444 308.576 27.498C308.943 27.6875 309.102 28.1722 308.932 28.5806C308.762 28.989 308.326 29.1665 307.959 28.977C307.548 28.7648 307.241 28.5628 306.992 28.3411C305.935 27.4001 304.569 27.4 303.513 28.3411C301.916 29.7625 299.805 29.7677 298.203 28.3411C297.146 27.4001 295.781 27.4 294.724 28.3411C293.127 29.7625 291.016 29.7677 289.414 28.3411C288.357 27.4001 286.992 27.4 285.935 28.3411C285.657 28.588 285.335 28.7774 285.056 28.9302C284.693 29.1289 284.254 28.9623 284.075 28.5583C283.897 28.1542 284.046 27.6657 284.409 27.467Z" fill="#DEE3EA"/>
        <path d="M247.409 69.467C247.605 69.3598 247.852 69.2176 248.02 69.068C249.616 67.6466 251.727 67.6414 253.329 69.068C254.386 70.009 255.752 70.009 256.809 69.068C257.593 68.3698 258.511 68.0007 259.463 68.0007C260.416 68.0007 261.334 68.3698 262.118 69.068C263.175 70.009 264.541 70.009 265.598 69.068C267.194 67.6467 269.305 67.6414 270.907 69.068C271.06 69.2038 271.278 69.3444 271.576 69.498C271.943 69.6875 272.102 70.1722 271.932 70.5806C271.762 70.989 271.326 71.1665 270.959 70.977C270.548 70.7648 270.241 70.5628 269.992 70.3411C268.935 69.4001 267.569 69.4 266.513 70.3411C264.916 71.7625 262.805 71.7677 261.203 70.3411C260.146 69.4001 258.781 69.4 257.724 70.3411C256.127 71.7625 254.016 71.7677 252.414 70.3411C251.357 69.4001 249.992 69.4 248.935 70.3411C248.657 70.588 248.335 70.7774 248.056 70.9302C247.693 71.1289 247.254 70.9623 247.075 70.5583C246.897 70.1542 247.046 69.6657 247.409 69.467Z" fill="#DEE3EA"/>
        <path d="M106 55.9875V114.438C106 122.169 112.268 128.438 120 128.438H176.725M106 55.9875V34.625C106 26.893 112.268 20.625 120 20.625H128.425M106 55.9875H214.675M214.675 55.9875V34.625C214.675 26.893 208.407 20.625 200.675 20.625H192.25M214.675 55.9875V93.9375M136.188 20.625H156.025M163.788 20.625H184.488" stroke="#DEE3EA" stroke-width="2"/>
        <path d="M136.188 70.6504V106.013" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <path d="M160.338 70.6504V106.013" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <path d="M195.269 79.7061L125.406 79.7061" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <path d="M167.669 96.9561L125.406 96.9561" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <path d="M184.487 70.6504V86.6066" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <rect x="128.562" y="13" width="7.4875" height="18.7" rx="3.74375" stroke="#DEE3EA" stroke-width="2"/>
        <rect x="156.162" y="13" width="7.4875" height="18.7" rx="3.74375" stroke="#DEE3EA" stroke-width="2"/>
        <rect x="184.625" y="13" width="7.4875" height="18.7" rx="3.74375" stroke="#DEE3EA" stroke-width="2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M176.725 98.4084V145.649C176.725 148.419 179.999 149.824 181.946 147.888L186.791 143.07C187.893 141.975 189.62 141.878 190.833 142.844L198.981 149.335C200.093 150.222 201.656 150.222 202.769 149.335L210.916 142.844C212.129 141.878 213.856 141.975 214.958 143.07L219.804 147.888C221.75 149.824 225.025 148.419 225.025 145.649V98.4084C225.025 95.9392 223.059 93.9375 220.634 93.9375H181.116C178.69 93.9375 176.725 95.9392 176.725 98.4084Z" stroke="#DEE3EA" stroke-width="2"/>
        <path d="M202 118.55H200C198.896 118.55 198 117.699 198 116.65V115.7C198 114.651 198.896 113.8 200 113.8H202C203.105 113.8 204 114.651 204 115.7C204 116.225 204.448 116.65 205 116.65C205.552 116.65 206 116.225 206 115.7C206 113.601 204.209 111.9 202 111.9V110.95C202 110.425 201.552 110 201 110C200.448 110 200 110.425 200 110.95V111.9C197.791 111.9 196 113.601 196 115.7V116.65C196 118.749 197.791 120.45 200 120.45H202C203.105 120.45 204 121.301 204 122.35V123.3C204 124.349 203.105 125.2 202 125.2H200C198.896 125.2 198 124.349 198 123.3C198 122.775 197.552 122.35 197 122.35C196.448 122.35 196 122.775 196 123.3C196 125.399 197.791 127.1 200 127.1V128.05C200 128.575 200.448 129 201 129C201.552 129 202 128.575 202 128.05V127.1C204.209 127.1 206 125.399 206 123.3V122.35C206 120.251 204.209 118.55 202 118.55Z" fill="#DEE3EA"/>
      </svg>
      <p class="no-payments__title fz18 fw500 text-dark" v-if="paymentInstructions !== true">The list is empty</p>
      <p class="no-payments__title fz18 fw500 text-dark" v-if="paymentInstructions === true">Payment Instructions are empty</p>
      <button class="blue-button-text no-payments__button fz14" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('counterparties/') === -1 && $route.path.indexOf('cashflow/diagram') === -1" v-on:click="addNewPayment">Create a new</button>
    </div>
    <div class="bottom-panel" v-if="($route.path.indexOf('archive') >= 0 || $route.path.indexOf('open/accounting-invoice') >=0) && selectedCheckboxes.length">
      <p class="fz13 fw500 text text-gray">Selected <span>{{selectedCheckboxes.length}}/{{paymentsList.length}}</span></p>
      <button class="unarchive fz14 fw500" v-on:click="unArchivedSelected" v-if="getPermissionsForUnArchive() === true">
        <svg v-if="$route.path.indexOf('archive') >= 0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"/>
          <path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#9AA3B0"/>
        </svg>
        <svg v-if="$route.path.indexOf('archive') === -1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#9AA3B0"/>
        </svg>

        {{$route.path.indexOf('archive') >= 0 ? 'Unarchive' : 'Archive'}}
      </button>
      <button class="cancel" v-on:click="clearAllSelected">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
          <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
        </svg>
      </button>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Vue from 'vue';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import AppLoader from "@/components/loader";
Vue.prototype.$moment = moment;
import Defer from '@/mixins/Defer'
import InputFormatNumber from "@/components/reuse/inputFormatNumber";
import BankAndCashSelect from "@/components/reuse/bankAndCashSelect";
import AsyncSelect from "@/components/reuse/asyncSelect";
import CounterpartiesDrop from "@/components/counterparties/counterpartiesAdd/counterpartiesDrop";
export default {
  mixins: [
    Defer()
  ],
name: "payment-list-component",
  components: {
    CounterpartiesDrop,
    AsyncSelect,
    BankAndCashSelect,
    InputFormatNumber,
    AppLoader,
  },
  props:["paymentInstructions", "cashFlow", "paymentBreakdown"],
  computed: {
    ...mapGetters(["getNextLinkPayment", "getFilterDataPaymentAxios", "getPermissionsFullByType", "getPermissionsByType", "getPaymentListOriginal", "getDataContractV", "getAmountBalance"]),
    ...mapState({
      dataNewPayment: state => state.payment.dataNewPayment,
      pushDataPaymentList: state => state.payment.pushDataPaymentList,
    }),
    selectedIncomeIds() {
      return this.selectedIncome.map(a=> {return a.id})
    },
    selectedPaymentIds() {
      return this.selectedPayment.map(a=> {return a.id})
    },
    selectedConvertedIds() {
      return this.selectedConverted.map(a=> {return a.id})
    },
    amountCalculate() {
      let ammo = 0;
      this.selectedPayment.forEach(item=> {
        ammo = ammo - parseFloat(item.amount);
      })
      this.selectedIncome.forEach(item=> {
        ammo = ammo + parseFloat(item.amount);
      })
      return ammo;
    },
    amountCalculateUSD() {
      let ammo = 0;
      this.selectedConverted.forEach(item=> {
        if(item.payment_type == 'expense') {
          ammo = ammo - parseFloat(item.amount);
        } else {
          ammo = ammo + parseFloat(item.amount);
        }
      })
      return ammo
    },
  },
  data() {
    return {
      editText: '',
      paymentsList: [],
      keyCtrlDown: false,
      selectedConverted: [],
      selectedIncome: [],
      selectedPayment: [],
      focusedElement: {
        id: null,
        name: '',
      },
      activeElement: {
        id: null,
        name: '',
      },
      keyRenderDueDate: -2348723,
      statusSort: true,
      dueDateSort: true,
      indexBeforeChangeStatementMember: null,
      showLoaderButton: false,
      indexBeforeChangeCurrency: null,
      indexBeforeChangeBank: null,
      showTimePanel: false,
      activeCurrency: null,
      changeOn: false,
      activeShip: null,
      activeAccount: null,
      activeCPDate: null,
      notePayment: [],
      handlers: ['r'],
      list: [],
      drag: false,
      showFullFunctional: false,
      keyRenderCpDate: -342,
      nameColumns: [
        {name: "Statement Member", id: '3224s'+0},
        {name: "Settled vsl", id: '324s'+0},
        {name: "USD amount", id: '324s'+1},
        {name: "Disbursements", id: '324s'+2},
        {name: "Real Account", id: '324s'+3},
      ],
      valutaList: [
        {
          "name": "USD",
          "id": "USD"
        },
        {
          "name": "ZMW",
          "id": "ZMW"
        },
        {
          "name": "ZAR",
          "id": "ZAR"
        },
        {
          "name": "YER",
          "id": "YER"
        },
        {
          "name": "XPF",
          "id": "XPF"
        },
        {
          "name": "XOF",
          "id": "XOF"
        },
        {
          "name": "XCD",
          "id": "XCD"
        },
        {
          "name": "XAF",
          "id": "XAF"
        },
        {
          "name": "WST",
          "id": "WST"
        },
        {
          "name": "VUV",
          "id": "VUV"
        },
        {
          "name": "VND",
          "id": "VND"
        },
        {
          "name": "VES",
          "id": "VES"
        },
        {
          "name": "UZS",
          "id": "UZS"
        },
        {
          "name": "UYU",
          "id": "UYU"
        },
        {
          "name": "UGX",
          "id": "UGX"
        },
        {
          "name": "UAH",
          "id": "UAH"
        },
        {
          "name": "TZS",
          "id": "TZS"
        },
        {
          "name": "TWD",
          "id": "TWD"
        },
        {
          "name": "TVD",
          "id": "TVD"
        },
        {
          "name": "TTD",
          "id": "TTD"
        },
        {
          "name": "TRY",
          "id": "TRY"
        },
        {
          "name": "TOP",
          "id": "TOP"
        },
        {
          "name": "TND",
          "id": "TND"
        },
        {
          "name": "TMT",
          "id": "TMT"
        },
        {
          "name": "TJS",
          "id": "TJS"
        },
        {
          "name": "THB",
          "id": "THB"
        },
        {
          "name": "SZL",
          "id": "SZL"
        },
        {
          "name": "SYP",
          "id": "SYP"
        },
        {
          "name": "STN",
          "id": "STN"
        },
        {
          "name": "SSP",
          "id": "SSP"
        },
        {
          "name": "SRD",
          "id": "SRD"
        },
        {
          "name": "SOS",
          "id": "SOS"
        },
        {
          "name": "SLL",
          "id": "SLL"
        },
        {
          "name": "SHP",
          "id": "SHP"
        },
        {
          "name": "SGD",
          "id": "SGD"
        },
        {
          "name": "SEK",
          "id": "SEK"
        },
        {
          "name": "SDG",
          "id": "SDG"
        },
        {
          "name": "SCR",
          "id": "SCR"
        },
        {
          "name": "SBD",
          "id": "SBD"
        },
        {
          "name": "SAR",
          "id": "SAR"
        },
        {
          "name": "RWF",
          "id": "RWF"
        },
        {
          "name": "RUB",
          "id": "RUB"
        },
        {
          "name": "RSD",
          "id": "RSD"
        },
        {
          "name": "RON",
          "id": "RON"
        },
        {
          "name": "QAR",
          "id": "QAR"
        },
        {
          "name": "PYG",
          "id": "PYG"
        },
        {
          "name": "PRB",
          "id": "PRB"
        },
        {
          "name": "PND",
          "id": "PND"
        },
        {
          "name": "PLN",
          "id": "PLN"
        },
        {
          "name": "PKR",
          "id": "PKR"
        },
        {
          "name": "PHP",
          "id": "PHP"
        },
        {
          "name": "PGK",
          "id": "PGK"
        },
        {
          "name": "PEN",
          "id": "PEN"
        },
        {
          "name": "PAB",
          "id": "PAB"
        },
        {
          "name": "OMR",
          "id": "OMR"
        },
        {
          "name": "NZD",
          "id": "NZD"
        },
        {
          "name": "NPR",
          "id": "NPR"
        },
        {
          "name": "NOK",
          "id": "NOK"
        },
        {
          "name": "NIO",
          "id": "NIO"
        },
        {
          "name": "NGN",
          "id": "NGN"
        },
        {
          "name": "NAD",
          "id": "NAD"
        },
        {
          "name": "MZN",
          "id": "MZN"
        },
        {
          "name": "MYR",
          "id": "MYR"
        },
        {
          "name": "MXN",
          "id": "MXN"
        },
        {
          "name": "MWK",
          "id": "MWK"
        },
        {
          "name": "MVR",
          "id": "MVR"
        },
        {
          "name": "MUR",
          "id": "MUR"
        },
        {
          "name": "MRU",
          "id": "MRU"
        },
        {
          "name": "MOP",
          "id": "MOP"
        },
        {
          "name": "MNT",
          "id": "MNT"
        },
        {
          "name": "MMK",
          "id": "MMK"
        },
        {
          "name": "MKD",
          "id": "MKD"
        },
        {
          "name": "MGA",
          "id": "MGA"
        },
        {
          "name": "MDL",
          "id": "MDL"
        },
        {
          "name": "MAD",
          "id": "MAD"
        },
        {
          "name": "LYD",
          "id": "LYD"
        },
        {
          "name": "LSL",
          "id": "LSL"
        },
        {
          "name": "LRD",
          "id": "LRD"
        },
        {
          "name": "LKR",
          "id": "LKR"
        },
        {
          "name": "LBP",
          "id": "LBP"
        },
        {
          "name": "LAK",
          "id": "LAK"
        },
        {
          "name": "KZT",
          "id": "KZT"
        },
        {
          "name": "KYD",
          "id": "KYD"
        },
        {
          "name": "KWD",
          "id": "KWD"
        },
        {
          "name": "KRW",
          "id": "KRW"
        },
        {
          "name": "KPW",
          "id": "KPW"
        },
        {
          "name": "KMF",
          "id": "KMF"
        },
        {
          "name": "KHR",
          "id": "KHR"
        },
        {
          "name": "KGS",
          "id": "KGS"
        },
        {
          "name": "KES",
          "id": "KES"
        },
        {
          "name": "JPY",
          "id": "JPY"
        },
        {
          "name": "JOD",
          "id": "JOD"
        },
        {
          "name": "JMD",
          "id": "JMD"
        },
        {
          "name": "ISK",
          "id": "ISK"
        },
        {
          "name": "IRR",
          "id": "IRR"
        },
        {
          "name": "IQD",
          "id": "IQD"
        },
        {
          "name": "INR",
          "id": "INR"
        },
        {
          "name": "ILS",
          "id": "ILS"
        },
        {
          "name": "IDR",
          "id": "IDR"
        },
        {
          "name": "HUF",
          "id": "HUF"
        },
        {
          "name": "HTG",
          "id": "HTG"
        },
        {
          "name": "HRK",
          "id": "HRK"
        },
        {
          "name": "HNL",
          "id": "HNL"
        },
        {
          "name": "HKD",
          "id": "HKD"
        },
        {
          "name": "GYD",
          "id": "GYD"
        },
        {
          "name": "GTQ",
          "id": "GTQ"
        },
        {
          "name": "GNF",
          "id": "GNF"
        },
        {
          "name": "GMD",
          "id": "GMD"
        },
        {
          "name": "GIP",
          "id": "GIP"
        },
        {
          "name": "GHS",
          "id": "GHS"
        },
        {
          "name": "GGP",
          "id": "GGP"
        },
        {
          "name": "GEL",
          "id": "GEL"
        },
        {
          "name": "GBP",
          "id": "GBP"
        },
        {
          "name": "FOK",
          "id": "FOK"
        },
        {
          "name": "FKP",
          "id": "FKP"
        },
        {
          "name": "FJD",
          "id": "FJD"
        },
        {
          "name": "EUR",
          "id": "EUR"
        },
        {
          "name": "ETB",
          "id": "ETB"
        },
        {
          "name": "ERN",
          "id": "ERN"
        },
        {
          "name": "EGP",
          "id": "EGP"
        },
        {
          "name": "DZD",
          "id": "DZD"
        },
        {
          "name": "DOP",
          "id": "DOP"
        },
        {
          "name": "DKK",
          "id": "DKK"
        },
        {
          "name": "DJF",
          "id": "DJF"
        },
        {
          "name": "CZK",
          "id": "CZK"
        },
        {
          "name": "CVE",
          "id": "CVE"
        },
        {
          "name": "CUP",
          "id": "CUP"
        },
        {
          "name": "CUC",
          "id": "CUC"
        },
        {
          "name": "CRC",
          "id": "CRC"
        },
        {
          "name": "COP",
          "id": "COP"
        },
        {
          "name": "CNY",
          "id": "CNY"
        },
        {
          "name": "CLP",
          "id": "CLP"
        },
        {
          "name": "CKD",
          "id": "CKD"
        },
        {
          "name": "CHF",
          "id": "CHF"
        },
        {
          "name": "CDF",
          "id": "CDF"
        },
        {
          "name": "CAD",
          "id": "CAD"
        },
        {
          "name": "BZD",
          "id": "BZD"
        },
        {
          "name": "BYN",
          "id": "BYN"
        },
        {
          "name": "BWP",
          "id": "BWP"
        },
        {
          "name": "BTN",
          "id": "BTN"
        },
        {
          "name": "BSD",
          "id": "BSD"
        },
        {
          "name": "BRL",
          "id": "BRL"
        },
        {
          "name": "BOB",
          "id": "BOB"
        },
        {
          "name": "BND",
          "id": "BND"
        },
        {
          "name": "BMD",
          "id": "BMD"
        },
        {
          "name": "BIF",
          "id": "BIF"
        },
        {
          "name": "BHD",
          "id": "BHD"
        },
        {
          "name": "BGN",
          "id": "BGN"
        },
        {
          "name": "BDT",
          "id": "BDT"
        },
        {
          "name": "BBD",
          "id": "BBD"
        },
        {
          "name": "BAM",
          "id": "BAM"
        },
        {
          "name": "AZN",
          "id": "AZN"
        },
        {
          "name": "AWG",
          "id": "AWG"
        },
        {
          "name": "AUD",
          "id": "AUD"
        },
        {
          "name": "ARS",
          "id": "ARS"
        },
        {
          "name": "AOA",
          "id": "AOA"
        },
        {
          "name": "ANG",
          "id": "ANG"
        },
        {
          "name": "AMD",
          "id": "AMD"
        },
        {
          "name": "ALL",
          "id": "ALL"
        },
        {
          "name": "AFN",
          "id": "AFN"
        },
        {
          "name": "AED",
          "id": "AED"
        }
      ],

      activeColumnsDefault: [],
      namePages: [],
      selectedCheckboxes: [],
      activeColumns: [],
      dataExpenseDown: null,
      dataExpenseUp: null,
      dataIncomeDown: null,
      dataIncomeUp: null,
      dataConvertedDown: null,
      dataConvertedUp: null,
      mountedEnd: false,
      ArrayCheckBox: [],
      ArrayDueDate: [],
      ArrayVessel: [],
      ArrayReference: [],
      ArrayAdditionally: [],
      ArrayCurrency: [],
      ArrayPayments: [],
      ArrayIncomes: [],
      ArrayUSD: [],
      ArrayStatus: [],
      ArrayPaidDate: [],
      ArrayCounterparty: [],
      ArrayBankCash: [],
      ArrayRemarks: [],
      ArrayInstructions: [],
      ArrayReceipt: [],
      ArrayAccountRemarks: [],
      ArraySettledVessel: [],
      ArrayDisbursements: [],
      ArrayRealAccount: [],
      ArrayStatementMember: [],
      ArrayEnd: [],
    }
  },
  methods: {
    ...mapActions(['axiosGetGrossNet', 'axiosGetPaymentForVessel', 'axiosGetPayment', 'axiosGetXLSX', 'axiosAddNewPayment', "axiosGetCurrency", "axiosDeleteNote", "axiosChangeNote", "axiosGetPaymentAccount", "axiosGetAllContracts", "axiosGetNoteToPayment", "axiosAddNoteToPayment", "axiosGetPayment", "axiosArchivePayment"]),
    ...mapMutations(["setProfit", "setNetProfit", "setPaymentsList", "setNextLinkPayment", "showLoad", "hideLoad", "addTip", "setPushDataCashFlowList"]),
    getFormatNumberTwo(value) {
      let fixedNumber = parseFloat(value)
      fixedNumber = fixedNumber ? fixedNumber : 0
      fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
      return fixedNumber
    },
    getPermissionBlockBreakDown() {
      return this.getPermissionsByType('block_for_editing_everything_in_the_tab_cash_flow_diagram') && this.paymentBreakdown === true;
    },
    focusFalse() {
      this.focusedElement= {
        id: null,
        name: '',
      }
      this.activeElement= {
        id: null,
        name: '',
      }
      this.selectedConverted = [];
      this.selectedIncome = [];
      this.selectedPayment = [];
    },
    focusInModuleInput(name) {
      // console.log(3)
      setTimeout(()=> {this.$refs[name][0].focusInput()}, 350)
    },
    openAsync(name) {
      setTimeout(()=> {this.$refs[name][0].openDropdown(true)}, 300)
    },
    openDropDownCounter(name) {
      setTimeout(()=> {this.$refs[name][0].openList(true)}, 400)
    },
    inputFocus(e, id, name) {
      if(id && name && (this.activeElement?.id !== id || this.activeElement?.name !== name)) {
        this.activeElement = {id: id, name: name}
        if(e !== null) {
          let input = e.target
          if (window.getSelection) {
            window.getSelection().removeAllRanges();
          } else { // старый IE
            document.selection.empty();
          }
          setTimeout(()=> {
            input.focus()
            input.selectionStart = input.value.length;
          }, 300)
        }
      } else if(!id || !name) {
        // console.log(2)
        this.activeElement = {id: null, name: ''}
      }
    },
    showTitleCal(i, type) {
      if( this.selectedPayment.length || this.selectedIncome.length) {
        let indexPaymentMax = this.selectedPayment.length ? Math.max(...this.selectedPayment.map(a=> {return a.index})) : -1;
        let indexIncomeMax = this.selectedIncome.length ? Math.max(...this.selectedIncome.map(a=> {return a.index})) : -1;
        // let hightIndex = indexPaymentMax >= indexIncomeMax ? indexPaymentMax : indexIncomeMax
        if(type === 'expense') {
          if(indexPaymentMax !== -1 && indexIncomeMax === -1) {
            return indexPaymentMax === i
          } else {
            return false
          }
        } else {
          if(indexIncomeMax !== -1 && (indexPaymentMax === -1 && indexIncomeMax !== -1 || indexIncomeMax >= indexPaymentMax || indexIncomeMax < indexPaymentMax)) {
            if(indexIncomeMax < indexPaymentMax) {
              return indexPaymentMax === i
            } else {
              return indexIncomeMax === i
            }
          } else {
            return false
          }
        }
        // return type === 'expense' ? indexPaymentMax > indexIncomeMax : indexIncomeMax >= indexPaymentMax;
      }
      return false
      // return this.selectedIncome.length ? Math.max(...this.selectedIncome.map(a=> {return a.index})) : null;
    },
    showTitleCalcUSD() {
      return this.selectedConverted.length ? Math.max(...this.selectedConverted.map(a=> {return a.index})) : null;
    },
    setConcertedDownItem(index) {
      if(!this.keyCtrlDown) {
        this.dataConvertedDown = index;
      }
    },
    setConvertedUpItem(index) {
      if(!this.keyCtrlDown) {
        this.dataConvertedUp = index;
        if(this.dataConvertedUp !== null && this.dataConvertedUp !== undefined && this.dataConvertedDown !== null && this.dataConvertedDown !== undefined && this.dataConvertedUp !== this.dataConvertedDown) {
          let num = 0;
          if(this.dataConvertedDown > this.dataConvertedUp) {
            num = this.dataConvertedDown;
            this.dataConvertedDown = this.dataConvertedUp;
            this.dataConvertedUp = num;
          }
          this.selectedPayment = [];
          this.selectedIncome = [];
          this.selectedConverted = []
          this.focusedElement = {
            id: null,
            name: '',
          }
          for(let i=this.dataConvertedDown; i<=this.dataConvertedUp; i++) {
            this.selectedConverted.push({id: this.paymentsList[i].id, index: i, amount: parseFloat(this.paymentsList[i].currency_amount), payment_type: this.paymentsList[i].payment_type})
          }
          if (window.getSelection) {
            window.getSelection().removeAllRanges();
          } else { // старый IE
            document.selection.empty();
          }
        }
      }
    },
    setExpenseDownItem(index) {
      if(!this.keyCtrlDown) {
        this.dataExpenseDown = index;
      }
    },
    setExpenseUpItem(index) {
      if(!this.keyCtrlDown) {
        this.dataExpenseUp = index
        let indexStart = (this.dataExpenseDown !== null && this.dataExpenseDown !== undefined) ? this.dataExpenseDown : this.dataIncomeDown;
        if(((this.dataExpenseDown !== null && this.dataExpenseDown !== undefined) || (this.dataIncomeDown !== null && this.dataIncomeDown !== undefined)) && (this.dataExpenseUp !== null && this.dataExpenseUp !== undefined) && this.dataExpenseUp !== this.dataExpenseDown) {
          this.selectedPayment = [];
          this.selectedIncome = [];
          this.selectedConverted = []
          this.focusedElement = {
            id: null,
            name: '',
          }
          let indexEnd = this.dataExpenseUp
          if(indexStart > indexEnd) {
            let m = indexEnd;
            indexEnd = indexStart;
            indexStart = m;
          }
          for(let i=indexStart; i<=indexEnd; i++) {
            this.selectedPayment.push({id: this.paymentsList[i].id, index: i, amount: this.paymentsList[i].payment_type === 'expense' ?  parseFloat(this.paymentsList[i].payment_amount_expanse) : 0})
            if((this.dataIncomeDown !== null && this.dataIncomeDown !== undefined)) {
              this.selectedIncome.push({id: this.paymentsList[i].id, index: i, amount: this.paymentsList[i].payment_type === 'expense' ?  0 : parseFloat(this.paymentsList[i].payment_amount_income)})
            }
          }
          if (window.getSelection) {
            window.getSelection().removeAllRanges();
          } else { // старый IE
            document.selection.empty();
          }
        }
        this.dataIncomeDown = null;
        this.dataExpenseDown = null;
        this.dataExpenseUp = null;
        this.dataIncomeUp = null;
      }
    },
    setIncomeDownItem(index) {
      if(!this.keyCtrlDown) {
        this.dataIncomeDown = index
      }
    },
    setIncomeUpItem(index) {
      if(!this.keyCtrlDown) {
        this.dataIncomeUp = index;
        let indexStart = (this.dataIncomeDown !== null && this.dataIncomeDown !== undefined) ? this.dataIncomeDown : this.dataExpenseDown;
        if(((this.dataExpenseDown !== null && this.dataExpenseDown !== undefined) || (this.dataIncomeDown !== null && this.dataIncomeDown !== undefined)) && (this.dataIncomeUp !== null && this.dataIncomeUp !== undefined) && this.dataIncomeUp !== this.dataIncomeDown) {
          // if((this.dataExpenseDown || this.dataIncomeDown) && this.dataIncomeUp) {
          this.selectedPayment = [];
          this.selectedIncome = [];
          this.selectedConverted = []
          this.focusedElement = {
            id: null,
            name: '',
          }
          let indexEnd = this.dataIncomeUp
          if(indexStart > indexEnd) {
            let m = indexEnd;
            indexEnd = indexStart;
            indexStart = m;
          }
          for(let i=indexStart; i<=indexEnd; i++) {
            if((this.dataExpenseDown !== null && this.dataExpenseDown !== undefined)) {
              this.selectedPayment.push({id: this.paymentsList[i].id, index: i, amount: this.paymentsList[i].payment_type === 'expense' ?  parseFloat(this.paymentsList[i].payment_amount_expanse) : 0})
            }
            this.selectedIncome.push({id: this.paymentsList[i].id, index: i, amount: this.paymentsList[i].payment_type === 'expense' ?  0 : parseFloat(this.paymentsList[i].payment_amount_income)})
          }
          if (window.getSelection) {
            window.getSelection().removeAllRanges();
          } else { // старый IE
            document.selection.empty();
          }
        }
        this.dataIncomeDown = null;
        this.dataExpenseDown = null;
        this.dataExpenseUp = null;
        this.dataIncomeUp = null;
      }
    },
    setSelectedForCalc(data, index, type) {
      if(this.keyCtrlDown) {
        if(type === 'expense') {
          let payment = this.selectedPayment.find(a=> a.id === data.id);
          if(!payment) {
            this.selectedPayment.push({id: data.id, index: index, amount: data.payment_amount_expanse})
          } else {
            this.selectedPayment = this.selectedPayment.filter(a=> a.id !== data.id)
          }
        } else {
          let income = this.selectedIncome.find(a=> a.id === data.id);
          if(!income) {
            this.selectedIncome.push({id: data.id, index: index, amount: data.payment_amount_income})
          } else {
            this.selectedIncome = this.selectedIncome.filter(a=> a.id !== data.id)
          }
        }
        this.focusedElement = {
          id: null,
          name: '',
        }
        this.selectedConverted = [];
      }
    },
    setSelectedConvert(data, index) {
      if(this.keyCtrlDown) {
        let income = this.selectedConverted.find(a=> a.id === data.id);
        if(!income) {
          this.selectedConverted.push({id: data.id, index: index, amount: parseFloat(data.currency_amount), payment_type: this.paymentsList[index].payment_type})
        } else {
          this.selectedConverted = this.selectedConverted.filter(a=> a.id !== data.id)
        }
        this.focusedElement = {
          id: null,
          name: '',
        }
        this.selectedPayment = [];
        this.selectedIncome = [];
      }
    },
    deleteValuePaymentAfterPast(type, index) {
      if(type === 'expense') {
        this.ArrayIncomes[index].payment_amount_income = '0.00';
        this.paymentsList[index].payment_amount_income = '0.00';
      } else {
        this.ArrayPayments[index].payment_amount_expanse = '0.00';
        this.paymentsList[index].payment_amount_expanse = '0.00';
      }
    },
    setFocused(id, name) {
      if(!this.keyCtrlDown) {
        if(id && name) {
          if(id !== this.focusedElement?.id || name !== this.focusedElement?.name) {
            this.focusedElement.id = id;
            this.focusedElement.name = name;
          }
        } else {
          this.focusedElement.id = null;
          this.focusedElement.name = '';
        }
        this.selectedIncome = [];
        this.selectedPayment = [];
        this.selectedConverted = [];
      }
    },
    compareDate(a) {
      return moment(new Date()).format('DD.MM.YYYY') !== a ? moment(new Date()).isAfter(new Date(moment(a, 'DD.MM.YYYY').format('YYYY.MM.DD'))) : false
    },
    getPermissionBlockChangeBank(status) {
      return this.getPermissionsByType('hide_the_block_bank_and_cash_accounts_in_the_status_not_paid') && status === 'not_paid'
    },
    getPermissionsBlockChangeListInStatusPaid(status) {
      return (this.getPermissionsByType('block_the_payment_income_row_in_the_status_paid_in_the_status_paid_in_the_list_of_payments_incomes') && status === 'paid')
    },
    startMove(e, ind) {
      this.draggable = true;
      this.startOffset = this.columns[ind].width - e.pageX;
      this.selectedCol = ind;
    },
    getValueFromXml(value, number) {
      if((""+value).length !== 0 && value !== null && value !== 0 && value !== undefined) {
        if((''+value).toLowerCase() === 'invalid date') {
          return null
        }
        if(moment(""+value, "YYYY-MM-DD HH:mm:ss", true).isValid() || moment(""+value, "YYYY-MM-DD HH:mm", true).isValid() || moment(""+value, "YYYY-MM-DD", true).isValid()) {
          return moment(value).format("DD.MM.YYYY")
        }
        if(!isNaN(value) && number === true) {
          if(value == 0) {
            return null;
          }
          let fixedNumber = value ? value : 0
          fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
          if((''+fixedNumber).indexOf('.') === -1) {
            fixedNumber+='.00'
          }
          return fixedNumber
        }
        return ""+value
      }
      return null
    },

    downloadXML() {
      if(this.getFilterDataPaymentAxios !== null) {
        this.showLoad()
        let data = this.getFilterDataPaymentAxios;
        let linkNext = this.getNextLinkPayment;
        let payments = this.paymentsList
        data['noLoad'] = true
        data['limit'] = 9999999
        if(this.$route.path.indexOf('open/cash-flow') >= 0) {
          this.axiosGetPaymentForVessel(data).then(()=> {
            let formAxios= {
              sheet_title: "Payment List",
              file_name: "Payment List",
              data: []
            }
            let names = [];
            names.push('Due Date');
            if(this.getPermissionShowVesselAndCpDate()) {
              names.push('Vessel, CP Date')
            }
            names.push('Reference')
            names.push('Currency')
            names.push(this.$route.path.indexOf('accounting-invoice')>=0 ?'Bill':'Payment')
            names.push(this.$route.path.indexOf('accounting-invoice')>=0 ?'Invoice':'Income')
            if(this.$route.name !== 'Accounting Invoice') {
              names.push('Status')
            }
            if(this.cashFlow === true && this.$route.name !== 'Accounting Invoice') {
              names.push('Paid Date')
            }
            names.push('Counterparty')
            if((this.activeColumns.filter(a=> a.name === 'Bank and Cash Accounts').length || (this.$route.path.indexOf('open/cash-flow') === -1 && this.$route.path.indexOf('cashflow/list') === -1 && this.$route.path.indexOf('counterparties') === -1)) && this.$route.name !== 'Accounting Invoice' && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Bank and cash accounts') === -1) {
              names.push('Bank and Cash Accounts')
            }
            names.push('Remark')
            if((this.activeColumns.filter(a=> a.name === 'Attachments').length && this.$route.path.indexOf('counterparties') >= 0) || this.$route.path.indexOf('counterparties') === -1) {
              names.push('Attachments')
            }
            if(this.$route.name !== 'Accounting Invoice' && ((this.activeColumns.filter(a=> a.name === 'Comment from accounting').length && this.$route.path.indexOf('counterparties') >= 0) || this.$route.path.indexOf('counterparties') === -1)) {
              names.push('Comment from accounting')
            }
            if(this.$route.name !== 'Accounting Invoice' && ((this.activeColumns.filter(a=> a.name === 'Bank Receip').length && this.$route.path.indexOf('counterparties') >= 0 ) || this.$route.path.indexOf('counterparties') === -1)) {
              names.push('Bank receipt')
            }
            if(this.activeColumns.filter(a=> a.name === 'USD amount').length) {
              names.push('USD amount')
            }
            if(this.activeColumns.filter(a=> a.name === 'Disbursements').length) {
              names.push('Disbursements')
            }
            if(this.activeColumns.filter(a=> a.name === 'Settled vsl').length && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Settled Vsl') === -1) {
              names.push('Settled Vessel')
            }
            if(this.activeColumns.filter(a=> a.name === 'Real Account').length && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Real Account') === -1) {
              names.push('Real Account')
            }
            if(this.$route.path.indexOf('accounting-invoice') === -1 && this.activeColumns.filter(a=> a.name === 'Statement Member').length && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Statement member') === -1) {
              names.push('Statement member')
            }
            formAxios.data.push({id:formAxios.data.length + 1, values: names});

            if(Array.isArray(this.paymentsList)===true){
              this.paymentsList.map(data=> {
                let values = [];
                values.push(this.getValueFromXml(data.execute_date))
                if((this.activeColumns.filter(a=> a.name === 'Vessel, CP Date').length) && this.getPermissionShowVesselAndCpDate()) {
                  values.push(data.ship ? this.getValueFromXml(data.ship.name + (data.cp_date ? ' ('+this.getValueFromXml(data.cp_date)+')' : '')): null)
                }
                values.push(data.account ? this.getValueFromXml((''+this.getTextAccount2(data)).replaceAll('<br/>', ', ')) : null)
                values.push(this.getValueFromXml(data.currency))
                if(data.payment_type === 'expense') {
                  values.push(this.getValueFromXml(data.payment_amount))
                } else {
                  values.push(null)
                }
                if(data.payment_type === 'income') {
                  values.push(this.getValueFromXml(data.payment_amount))
                } else {
                  values.push(null)
                }
                if(this.$route.name !== 'Accounting Invoice') {
                  values.push(this.getValueFromXml(this.getTextForStatus(data.status)))
                }
                if(this.cashFlow === true && this.$route.name !== 'Accounting Invoice') {
                  values.push(this.getValueFromXml(this.getFormatDate(data.paid_date)))
                }
                values.push(data.client_name ? data.client_name : null)
                if((this.activeColumns.filter(a=> a.name === 'Bank and Cash Accounts').length || (this.$route.path.indexOf('open/cash-flow') === -1 && this.$route.path.indexOf('cashflow/list') === -1 && this.$route.path.indexOf('counterparties') === -1)) && this.$route.name !== 'Accounting Invoice' && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Bank and cash accounts') === -1) {
                  values.push((this.getTextBankAndCashAccounts(data) && ((''+this.getTextBankAndCashAccounts(data).name).length !== 0)) ? this.getTextBankAndCashAccounts(data).name : null)
                }
                values.push(((''+data.comment).length !== 0 && data.comment !== null) ? '+' : null)
                if((this.activeColumns.filter(a=> a.name === 'Attachments').length && this.$route.path.indexOf('counterparties') >= 0) || this.$route.path.indexOf('counterparties') === -1) {
                  values.push(Array.isArray(data.files) === true ? (data.files.length !== 0 ? '+' : null) : null)
                }
                if(this.$route.name !== 'Accounting Invoice' && ((this.activeColumns.filter(a=> a.name === 'Comment from accounting').length && this.$route.path.indexOf('counterparties') >= 0) || this.$route.path.indexOf('counterparties') === -1)) {
                  values.push(data.has_messages ? '+' : null)
                }
                if(this.$route.name !== 'Accounting Invoice' && ((this.activeColumns.filter(a=> a.name === 'Bank Receip').length && this.$route.path.indexOf('counterparties') >= 0 ) || this.$route.path.indexOf('counterparties') === -1)) {
                  values.push(data.receipt_files.length !== 0 ? '+' : null)
                }

                if(this.activeColumns.filter(a=> a.name === 'USD amount').length) {
                  values.push(this.getValueFromXml(data.currency_amount))
                }
                if(this.activeColumns.filter(a=> a.name === 'Disbursements').length) {
                  let text =  ""
                  if(data.ports) {
                    // text = ""+data.ports.name + (data.ports.country ? '('+data.ports.country + ')':'');
                    text = this.getTextFromPorts(data.ports)
                  }
                  values.push(this.getValueFromXml(text))
                }
                if(this.activeColumns.filter(a=> a.name === 'Settled vsl').length && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Settled Vsl') === -1) {
                  values.push((data.settled_ship && (''+data.settled_ship.name).length !== 0) ? data.settled_ship.name : null)
                }
                if(this.activeColumns.filter(a=> a.name === 'Real Account').length && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Real Account') === -1) {
                  values.push(data.real_account ? data.real_account.company_name : null)
                }
                if(this. $route.path.indexOf('accounting-invoice') === -1 && this.activeColumns.filter(a=> a.name === 'Statement Member').length && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Statement member') === -1) {
                  values.push(this.getValueFromXml(data.statement_member))
                }
                formAxios.data.push({id:formAxios.data.length + 1, values: values});
              })
            }

            this.axiosGetXLSX(formAxios).then(response => {

              let link = response.data;
              function download(content, contentType, fileName) {
                var link1 = document.createElement("a");
                link1.href = window.URL.createObjectURL(new Blob([content], {type: contentType}));
                link1.download = fileName;
                link1.click();
              }
              this.hideLoad()
              this.setNextLinkPayment(linkNext)
              this.setPaymentsList(payments)
              download(link, "application/vnd.ms-excel", this.$route.path.indexOf('accounting-invoice') >= 0 ? "Account Invoice.xls" : this.$route.path.indexOf('cash')>=0 ? 'Cashflow.xls' : "PaymentList.xls")
            });
          })

        } else {
          this.axiosGetPayment(data).then(()=> {
            let formAxios= {
              sheet_title: "Payment List",
              file_name: "Payment List",
              data: []
            }
            let names = [];
            names.push('Due Date');
            if((this.activeColumns.filter(a=> a.name === 'Vessel, CP Date').length) && this.getPermissionShowVesselAndCpDate()) {
              names.push('Vessel, CP Date')
            }
            names.push('Reference')
            names.push('Currency')
            names.push(this.$route.path.indexOf('accounting-invoice')>=0 ?'Bill':'Payment')
            names.push(this.$route.path.indexOf('accounting-invoice')>=0 ?'Invoice':'Income')
            if(this.$route.name !== 'Accounting Invoice') {
              names.push('Status')
            }
            if(this.cashFlow === true && this.$route.name !== 'Accounting Invoice') {
              names.push('Paid Date')
            }
            names.push('Counterparty')
            if((this.activeColumns.filter(a=> a.name === 'Bank and Cash Accounts').length || (this.$route.path.indexOf('open/cash-flow') === -1 && this.$route.path.indexOf('cashflow/list') === -1 && this.$route.path.indexOf('counterparties') === -1)) && this.$route.name !== 'Accounting Invoice' && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Bank and cash accounts') === -1) {
              names.push('Bank and Cash Accounts')
            }
            names.push('Remark')
            if((this.activeColumns.filter(a=> a.name === 'Attachments').length && this.$route.path.indexOf('counterparties') >= 0) || this.$route.path.indexOf('counterparties') === -1) {
              names.push('Attachments')
            }
            if(this.$route.name !== 'Accounting Invoice' && ((this.activeColumns.filter(a=> a.name === 'Comment from accounting').length && this.$route.path.indexOf('counterparties') >= 0) || this.$route.path.indexOf('counterparties') === -1)) {
              names.push('Comment from accounting')
            }
            if(this.$route.name !== 'Accounting Invoice' && ((this.activeColumns.filter(a=> a.name === 'Bank Receip').length && this.$route.path.indexOf('counterparties') >= 0 ) || this.$route.path.indexOf('counterparties') === -1)) {
              names.push('Bank receipt')
            }
            if(this.activeColumns.filter(a=> a.name === 'USD amount').length) {
              names.push('USD amount')
            }
            if(this.activeColumns.filter(a=> a.name === 'Disbursements').length) {
              names.push('Disbursements')
            }
            if(this.activeColumns.filter(a=> a.name === 'Settled vsl').length && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Settled Vsl') === -1) {
              names.push('Settled Vessel')
            }
            if(this.activeColumns.filter(a=> a.name === 'Real Account').length && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Real Account') === -1) {
              names.push('Real Account')
            }
            if(this.$route.path.indexOf('accounting-invoice') === -1 && this.activeColumns.filter(a=> a.name === 'Statement Member').length && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Statement member') === -1) {
              names.push('Statement member')
            }
            formAxios.data.push({id:formAxios.data.length + 1, values: names});

            if(Array.isArray(this.paymentsList)===true){
              this.paymentsList.map(data=> {
                let values = [];
                values.push(this.getValueFromXml(data.execute_date))
                if((this.activeColumns.filter(a=> a.name === 'Vessel, CP Date').length) && this.getPermissionShowVesselAndCpDate()) {
                  values.push(data.ship ? this.getValueFromXml(data.ship.name + (data.cp_date ? ' ('+this.getValueFromXml(data.cp_date)+')' : '')): null)
                }
                values.push(data.account ? this.getValueFromXml((''+this.getTextAccount2(data)).replaceAll('<br/>', ', ')) : null)
                values.push(this.getValueFromXml(data.currency))
                if(data.payment_type === 'expense') {
                  values.push(this.getValueFromXml(data.payment_amount, true))
                } else {
                  values.push(null)
                }
                if(data.payment_type === 'income') {
                  values.push(this.getValueFromXml(data.payment_amount, true))
                } else {
                  values.push(null)
                }
                if(this.$route.name !== 'Accounting Invoice') {
                  values.push(this.getValueFromXml(this.getTextForStatus(data.status)))
                }
                if(this.cashFlow === true && this.$route.name !== 'Accounting Invoice') {
                  values.push(this.getValueFromXml(this.getFormatDate(data.paid_date)))
                }
                values.push(data.client_name ? data.client_name : null)
                if((this.activeColumns.filter(a=> a.name === 'Bank and Cash Accounts').length || (this.$route.path.indexOf('open/cash-flow') === -1 && this.$route.path.indexOf('cashflow/list') === -1 && this.$route.path.indexOf('counterparties') === -1)) && this.$route.name !== 'Accounting Invoice' && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Bank and cash accounts') === -1) {
                  values.push(this.getValueFromXml(this.getTextBankAndCashAccounts(data).name))
                }
                values.push(((''+data.comment).length !== 0 && data.comment !== null) ? '+' : null)
                if((this.activeColumns.filter(a=> a.name === 'Attachments').length && this.$route.path.indexOf('counterparties') >= 0) || this.$route.path.indexOf('counterparties') === -1) {
                  values.push(Array.isArray(data.files) === true ? (data.files.length !== 0 ? '+' : null) : null)
                }
                if(this.$route.name !== 'Accounting Invoice' && ((this.activeColumns.filter(a=> a.name === 'Comment from accounting').length && this.$route.path.indexOf('counterparties') >= 0) || this.$route.path.indexOf('counterparties') === -1)) {
                  values.push(data.has_messages ? '+' : null)
                }
                if(this.$route.name !== 'Accounting Invoice' && ((this.activeColumns.filter(a=> a.name === 'Bank Receip').length && this.$route.path.indexOf('counterparties') >= 0 ) || this.$route.path.indexOf('counterparties') === -1)) {
                  values.push(data.receipt_files.length !== 0 ? '+' : null)
                }

                if(this.activeColumns.filter(a=> a.name === 'USD amount').length) {
                  values.push(this.getValueFromXml(data.currency_amount, true))
                }
                if(this.activeColumns.filter(a=> a.name === 'Disbursements').length) {
                  let text =  ""
                  if(data.ports) {
                    // text = ""+data.ports.name + (data.ports.country ? '('+data.ports.country + ')':'');
                    text = this.getTextFromPorts(data.ports)
                  }
                  values.push(this.getValueFromXml(text))
                }
                if(this.activeColumns.filter(a=> a.name === 'Settled vsl').length && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Settled Vsl') === -1) {
                  values.push(this.getValueFromXml(data.settled_ship ? data.settled_ship.name : ''))
                }
                if(this.activeColumns.filter(a=> a.name === 'Real Account').length && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Real Account') === -1) {
                  values.push(data.real_account ? data.real_account.company_name : null)
                }
                if(this.$route.path.indexOf('accounting-invoice') === -1 && this.activeColumns.filter(a=> a.name === 'Statement Member').length && this.getPermissionShowDopColumnsFull().such_columns.indexOf('Statement member') === -1) {
                  values.push(this.getValueFromXml(data.statement_member))
                }
                formAxios.data.push({id:formAxios.data.length + 1, values: values});
              })
            }

            this.axiosGetXLSX(formAxios).then(response => {
              let link = response.data;
              function download(content, contentType, fileName) {
                var link1 = document.createElement("a");
                link1.href = window.URL.createObjectURL(new Blob([content], {type: contentType}));
                link1.download = fileName;
                link1.click();
              }
              this.hideLoad()
              this.setNextLinkPayment(linkNext)
              this.setPaymentsList(payments)
              let name = "Payment List.xls"
              if(this.$route.path.indexOf('accounting-invoice') >= 0) {
                name = "Account Invoice.xls"
              }
              if(this.$route.path.indexOf('cash')>=0) {
                name = 'CashFlow List.xls'
              }
              if(this.$route.path.indexOf('instructions') >= 0) {
                name = "Payment Instructions List.xls"
              }
              download(link, "application/vnd.ms-excel",  name)
            });
          })

        }
      }
    },
    inputFocusEditText(text) {
      this.editText = text;
    },
    changeDueDate(data, index) {
      if(moment(data, 'DD.MM.YYYY', true).isValid() === true || data === null || (''+data).length === 0) {
        this.paymentsList[index].execute_date = data !== null && (''+data).length !== 0 ? data : null;
        this.ArrayDueDate[index].execute_date = data !== null && (''+data).length !== 0 ? data : null;
        this.changePaymentData(this.paymentsList[index].id, 'execute_date', 'date', this.paymentsList[index].execute_date, 'Due Date', index)
      } else {
        this.paymentsList[index].execute_date = JSON.parse(JSON.stringify(this.editText));
        this.ArrayDueDate[index].execute_date = JSON.parse(JSON.stringify(this.editText));
        this.keyRenderDueDate++
        this.addTip('Wrong Due Date format. Enter dd.mm.yyyy')
      }
    },
    changeStatusSort() {
      this.statusSort = !this.statusSort
      this.$emit('changeOrderingStatus', this.statusSort)
    },
    changeDueDateSort() {
      this.dueDateSort = !this.dueDateSort
      this.$emit('changeOrderingDueDate', this.dueDateSort)
    },
    getFormatDateFromCpDate(date) {
      if(date && (moment(""+date, "YYYY-MM-DD", true).isValid())) {
        return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')
      }
      return  ''
    },
    getPermissionEditPaymentInStatusPaid(status) {
      return (!this.getPermissionsByType('can_edit_payment_in_status_paid') && status === 'paid')
    },
    getCPDateListStatement(data, client, real_account) {
      let vessel = JSON.parse(JSON.stringify(data));
      if(vessel !== null) {
        let cp_dates = [];
        if(vessel.cargo_contracts?.length) {
          if(client && !real_account) {
            vessel.cargo_contracts =  vessel.cargo_contracts.filter(a=> a.owner === client.id)
          }
          if(real_account) {
            vessel.cargo_contracts =  vessel.cargo_contracts.filter(a=> a.owner === real_account.id)
          }
          vessel.cargo_contracts.map(a=> {
            if(a.cp_date?.length) {
              cp_dates.push(a.cp_date)
            }
          })
        }
        cp_dates = Array.from(new Set(cp_dates));
        cp_dates = cp_dates.map(a=> {
          return {name: a, id: a}
        })
        cp_dates = cp_dates.sort((a, b) => new Date(a.name) - new Date(b.name));
        return cp_dates;
      }
      let cp_dates = [];

      return cp_dates;
    },

    selectCPDateStatement(data) {
      this.paymentsList[this.indexBeforeChangeStatementMember].statement_member = data ? data.name : null;
      this.ArrayStatementMember[this.indexBeforeChangeStatementMember].statement_member = data ? data.name : null;
    },
    getFormatDateTime(date) {
      return date !== null ? moment(date).format('DD.MM.YYYY HH:mm') : ''
    },
    addNewPayment() {
      this.showLoaderButton = true
      let data_type = this.$route.path.indexOf('accounting-invoice') === -1 ? 'payment' : 'invoice';
      let ship = null
      let cp_date = ''
      if(this.$route.path.indexOf('vessels')>=0) {
        if(this.getDataContractV !== null) {
          ship = this.getDataContractV.id
          if(this.getDataContractV.cp_date !== null && (""+this.getDataContractV.cp_date).length !== 0) {
            cp_date = this.getDataContractV.cp_date
          }
        }
      }
      this.axiosAddNewPayment({data_type: data_type, ship: ship, cp_date: cp_date}).then(()=> {
        this.showLoaderButton = false
      })
    },
    linkDetail() {

    },
    getBoolDontSelect(data) {
      if(data.bank !== null) {
        if(data.bank.currency !== null && (''+data.bank.currency).length !== 0) {
          return true
        }
      }
      if(data.cash !== null) {
        if(data.cash.currency !== null && (''+data.cash.currency).length !== 0) {
          return true
        }
      }
      return false
    },
    setBank(data) {
      // this.paymentsList[this.indexBeforeChangeCurrency].bank = data;
      if(this.indexBeforeChangeBank && !data && !this.paymentsList[this.indexBeforeChangeBank].cash) {
        return
      }
      this.changePaymentData(this.paymentsList[this.indexBeforeChangeBank].id, 'bank', 'object', data, 'Bank and Cash Accounts', this.indexBeforeChangeBank)
    },
    setCash(data) {
      if(this.indexBeforeChangeBank && !data && !this.paymentsList[this.indexBeforeChangeBank].bank) {
        return
      }
      this.changePaymentData(this.paymentsList[this.indexBeforeChangeBank].id, 'cash', 'object', data, 'Bank and Cash Accounts', this.indexBeforeChangeBank)
    },
    getActiveCpDate(cp_date) {
      if(cp_date !== null && (""+cp_date).length !== 0) {
        return {name: ""+cp_date, id: ""+cp_date}
      }
      return {name: "Unselected", id: -1}
    },
    setCPDate(data) {
      this.activeCPDate = data;
    },
    changeCurrency(data) {
      this.paymentsList[this.indexBeforeChangeCurrency].currency = data.id;
      this.ArrayCurrency[this.indexBeforeChangeCurrency].currency = data.id;
    },
    async changeCurrencyAmount(currency, index) {
      if(currency !== 'USD') {
        let paid_date = this.paymentsList[index].paid_date
        let currences = []
        if(paid_date !== null) {
          currences = await this.axiosGetCurrency({currency: currency, created_at: moment(paid_date).format('YYYY-MM-DD')});
        }
        let rate = 0;
        if(paid_date !== null && currences?.data?.results?.length !== 0) {
          rate = Math.round(currences.data.results[0].rate*10000)/10000
        } else {
          currences = await this.axiosGetCurrency({currency: currency});
          rate = Math.round(currences.data.results[0].rate*10000)/10000
        }
        // this.axiosGetCurrency({currency: currency, created_at: moment().add(-1, 'day').format('YYYY-MM-DD')}).then(response=> {
        //   let rate = response.data.results[0].rate;
        this.ArrayUSD[index].currency_amount = this.numberFloatCheck(this.checkNum((Math.round(((this.paymentsList[index].payment_amount / rate)*100)))/100));
        this.paymentsList[index].currency_amount = this.ArrayUSD[index].currency_amount
        this.changePaymentData(this.paymentsList[index].id, 'currency_amount', 'number', this.ArrayUSD[index].currency_amount ? parseFloat(this.ArrayUSD[index].currency_amount) : null, this.paymentBreakdown !== true ? 'USD amount' : null, index)
        // })
      } else {
        let rate = 1;
        this.ArrayUSD[index].currency_amount = this.numberFloatCheck(this.checkNum((Math.round(((this.paymentsList[index].payment_amount / rate)*100)))/100));
        this.paymentsList[index].currency_amount = this.ArrayUSD[index].currency_amount
        console.log(this.paymentsList[index].payment_amount, parseFloat(this.ArrayUSD[index].currency_amount))
        this.changePaymentData(this.paymentsList[index].id, 'currency_amount', 'number',this.ArrayUSD[index].currency_amount ? parseFloat(this.ArrayUSD[index].currency_amount) : null, this.paymentBreakdown !== true ? 'USD amount' : null, index)
      }
      if(this.paymentBreakdown === true) {
        this.ArrayBalances = [];
        let balance = this.getAmountBalance;
        this.paymentsList.forEach((item, index)=> {
          if(index !== 0) {
            balance = this.ArrayBalances[index-1].balance;
          }
          if(this.paymentBreakdown === true) {
            this.ArrayBalances.push({id: item.id, balance: item.payment_type === 'expense' ? balance - parseFloat(item.currency_amount) : balance + parseFloat(item.currency_amount)})
          }
        })
      }
    },
    changePaymentData(id, nameValue, typeData, data, nameColumn, index) {
      let bool = true;
      let form = {}
      if(typeData === 'date') {
        form[nameValue] = (data !== null && (''+data).length !== 0) ? moment(data, 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm') : null
        // moment(new Date(data)).format('YYYY-MM-DD HH:mm')
      }
      if(typeData === 'text') {
        form[nameValue] = (data !== null && (''+data).length !== 0) ? data : null
        if(nameValue === 'currency') {
          setTimeout(()=> {this.changeCurrencyAmount(this.paymentsList[index].currency, index)}, 200)
        }
        if(nameValue === 'client_name') {
          if(data === this.paymentsList[index].client_name || ((data === '' && this.paymentsList[index].client_name === null) || (data === null && this.paymentsList[index].client_name === ''))) {
            bool = false;
          } else if(this.paymentsList[index].client !== null) {
            if(data !== this.paymentsList[index].client.company_name) {
              form['client'] = null;
              this.ArrayCounterparty[index].client = null;
              this.paymentsList[index].client = null;
              form['statement_member'] = ''
              this.paymentsList[index]['statement_member'] = '';
              this.ArrayStatementMember[index]['statement_member'] = '';
              this.addTip('Counterparty removed')
            }
          }
          this.paymentsList[index].client_name = data;
          this.ArrayCounterparty[index].client_name = data;
        }
          if(nameValue === 'account_name') {
            if(data === this.ArrayReference[index].account_name || ((data === '' && this.ArrayReference[index].account_name === null) || (data === null && this.ArrayReference[index].account_name === ''))) {
              bool = false;
            } else if(this.ArrayReference[index].account !== null) {
              if(data !== this.getTextAccountTitle(this.ArrayReference[index].account)) {
                form['account'] = null;
                this.ArrayReference[index].account = null;
                this.paymentsList[index].account = null;
                this.addTip('Reference removed')
              }
            }
            this.paymentsList[index].account_name = data;
            this.ArrayReference[index].account_name = data;
          }
        if(nameValue === 'additionally' && (data !== null && (''+data).length !== 0)) {
          form[nameValue] = (''+data).toUpperCase()
        }
      }
      if(typeData === 'object') {
        if(nameValue === 'bank' || nameValue === 'cash') {
          if(nameValue === 'bank') {
            this.ArrayBankCash[index].bank = data;
            this.ArrayBankCash[index].cash = null;
            this.paymentsList[index].bank = data;
            this.paymentsList[index].cash = null;
            form['bank'] = (data !== null && (''+data).length !== 0) ? data.id : null;
            form['cash'] = null
          } else {
            this.ArrayBankCash[index].bank = null;
            this.ArrayBankCash[index].cash = data;
            this.paymentsList[index].bank = null;
            this.paymentsList[index].cash = data;
            form['cash'] = (data !== null && (''+data).length !== 0) ? data.id : null;
            form['bank'] = null
          }
          if(data && data?.currency !== null) {
            form['currency'] = data.currency
            this.paymentsList[index]['currency'] = data.currency;
            setTimeout(()=> {this.changeCurrencyAmount(this.paymentsList[index].currency, index)}, 200)
          }
        } else {
          if(nameValue === 'ship') {
            form['statement_member'] = ''
            this.paymentsList[index]['statement_member'] = '';
            this.ArrayStatementMember[index]['statement_member'] = '';
            this.paymentsList[index]['ship'] = data;
          }
          if(nameValue === 'client') {
            form['client_name'] = data ? data.company_name : null;
            this.ArrayCounterparty[index].client_name =  data ? data.company_name: "";
            this.paymentsList[index].client_name =  data ? data.company_name: "";
            form['statement_member'] = ''
            this.paymentsList[index]['statement_member'] = '';
            this.ArrayStatementMember[index]['statement_member'] = '';
          }
          console.log(10)
          if(nameValue === 'account') {
            console.log(11)
            form['account_name'] = data ? this.getTextAccountTitle(this.ArrayReference[index].account) : ""
            this.ArrayReference[index].account_name =  data ? this.getTextAccountTitle(this.ArrayReference[index].account) : "";
            this.paymentsList[index].account_name =  data ? this.getTextAccountTitle(this.ArrayReference[index].account): "";
          }
          form[nameValue] = (data !== null && (''+data).length !== 0) ? data.id : null
          console.log(form)
        }
        if(nameValue === 'real_account') {
          form['statement_member'] = ''
          this.paymentsList[index]['statement_member'] = '';
          this.ArrayStatementMember[index]['statement_member'] = '';
        }
      }
      if(typeData === 'ship') {
        form['statement_member'] = ''
        this.paymentsList[index]['statement_member'] = '';
        this.ArrayStatementMember[index]['statement_member'] = '';
        form['cp_date'] = (data !== null && (''+data).length !== 0) ? data.cp_date : null
        form[nameValue] = (data !== null && (''+data).length !== 0) ? data.id : null
      }
      if(typeData === 'array') {
        form[nameValue] = (Array.isArray(data) === true) ? data.map(a=> {return a.id}) : null
      }

      if(typeData === 'number' && (nameValue === 'payment_amount_expanse' || nameValue === 'payment_amount_income')) {
        if((data !== null || this.paymentsList[index].payment_amount !== 0) && ((nameValue === 'payment_amount_expanse' ? this.numberFloatCheck(data) !== this.numberFloatCheck(this.paymentsList[index].payment_amount_expanse) : this.numberFloatCheck(data) !== this.numberFloatCheck(this.paymentsList[index].payment_amount_income)))) {
          data = (data !== null && (''+data).length !== 0) ? parseFloat(data) : 0;
          form['payment_type'] = (nameValue === 'payment_amount_expanse') ? 'expense' : 'income';
          form['payment_amount'] = (data !== null && (''+data).length !== 0) ? data : 0
          this.paymentsList[index].payment_type = form.payment_type;
          this.paymentsList[index].payment_amount = (data !== null && (''+data).length !== 0) ? this.numberFloatCheck(data) : 0
          if(this.paymentsList[index].payment_type === 'income') {
            this.paymentsList[index]['payment_amount_income'] = this.numberFloatCheck(this.paymentsList[index].payment_amount)
            this.paymentsList[index]['payment_amount_expanse'] = '0.00';
            this.ArrayPayments[index]['payment_amount_expanse'] = '0.00';
          } else {
            this.paymentsList[index]['payment_amount_expanse'] = this.numberFloatCheck(this.paymentsList[index].payment_amount)
            this.paymentsList[index]['payment_amount_income'] = '0.00';
            this.ArrayIncomes[index]['payment_amount_income'] = '0.00';
          }
          setTimeout(()=> {this.changeCurrencyAmount(this.paymentsList[index].currency, index)}, 200)
        } else {
          bool = false
        }
      } else if(typeData === 'number') {
        this.paymentsList[index][nameValue] = (data !== null && (''+data).length !== 0) ? this.numberFloatCheck(data) : 0

        form[nameValue] = (data !== null && (''+data).length !== 0) ? this.numberFloatCheck(data) : 0
      }
      // if(nameValue === 'currency_amount') {
        // let amountProfit = 0;
        // let amountNetProfit = 0;
        // this.paymentsList.forEach(item=> {
        //   if(item.payment_type === 'income') {
        //     amountProfit = amountProfit + parseFloat(item.currency_amount);
        //   } else {
        //     amountProfit = amountProfit - parseFloat(item.currency_amount);
        //   }
        //   if(item.status === 'paid') {
        //     if (item.payment_type === 'income') {
        //       amountNetProfit = amountNetProfit + parseFloat(item.currency_amount);
        //     } else {
        //       amountNetProfit = amountNetProfit - parseFloat(item.currency_amount);
        //     }
        //   }
        // })
        // this.setProfit(amountProfit)
        // this.setNetProfit(amountNetProfit)
      this.axiosGetGrossNet(this.getFilterDataPaymentAxios)
      // }
      if(bool) {
        this.paymentsList[index].showLoader = true
        this.axiosChangeNote({id: id, data: form}).then(res=> {
          this.paymentsList[index].showLoader = false
          if(nameValue === 'currency_amount') {
            this.$emit('changeAmount')
          }
          if(nameColumn) {
            if(res) {
              this.addTip(nameColumn+ ' changed')
            } else {
              this.addTip(nameColumn+ ' not changed (network error)')
            }
          }
          if(nameValue === 'currency_amount' || nameValue === 'execute_date') {
            this.$emit('changeChart')
          }
          if(nameValue === 'execute_date') {
            this.$emit('changeList')
          }
        })
      }
      this.inputFocus(null, null)
    },

    getCPDateList(vessel) {
      if(vessel !== null) {
        let cp_dates = [];
        if(vessel.cp_date?.length) {
          cp_dates.push(vessel.cp_date)
        }
        // if(vessel.cargo_contracts?.length) {
        //   vessel.cargo_contracts.map(a=> {
        //     if(a.cp_date?.length) {
        //       cp_dates.push(a.cp_date)
        //     }
        //   })
        // }
        cp_dates = Array.from(new Set(cp_dates));
        cp_dates = cp_dates.map(a=> {
          return {name: a, id: a}
        })
        // cp_dates.push({name: "Unselected", id: -1})
        return cp_dates;
      }
      let cp_dates = [];

      // cp_dates.push({name: "Unselected", id: -1})
      return cp_dates;
    },
    openCashFlowList(data) {
      let account = []
      if(data.account !== null) {
        data.account.map(a=> {
          account.push(a);
        })
      }
      let counterparty = []
      if(data.client !== null) {
        counterparty.push(data.client)
      }
      this.setPushDataCashFlowList({account: account, counterparty: counterparty})
      if(this.$route.path.indexOf('cashflow/list') === -1) {
        this.$router.push({name: 'CashFlow List'});
      }

    },
    getPermissionsForUnArchive() {
      return this.getPermissionsByType("unarchive_payments_incomes");
    },
    deleteNote(id, index) {
      this.axiosDeleteNote(id).then(()=> {
        this.notePayment.splice(index, 1)
      })
    },
    setActiveShip(data) {
      this.activeShip = data;
    },
    setActiveCurrency(data) {
      this.activeCurrency = data;
    },
    activeAccountName(data) {
      if(data !== null) {
        if(data.transformDont === true) {
          data = data.map(a=> {
            {return {name: a.id + ": " + a.name, id:a.id, transformDont: true}}
          })
          data = JSON.parse(JSON.stringify(data));
        }

        return data;
      } else {
        return null;
      }
    },
    setActiveAccount(data) {
      this.activeAccount = data;
    },
    getNumberFormat(output) {
      return output.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
    },
    numberFloatCheck(value) {
      let fixedNumber = value;
      if(!isNaN(value)) {
        if(value) {
          fixedNumber = parseFloat(fixedNumber)
          fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replaceAll(',','')
          if((''+fixedNumber).indexOf('.') === -1) {
            fixedNumber+='.00'
          }
        }
      }
      return fixedNumber ? parseFloat(fixedNumber) : null
    },
    checkNum(num) {

      if((''+num).indexOf(".")>=0) {
        num = ""+num
        let num1 = num.substring(0, num.indexOf("."));
        let num2 = num.substring(num.indexOf(".") + 1, num.length);
        num1 = num1.replace(/[^0-9]/g, "");
        num2 = num2.replace(/[^0-9]/g, "");
        if(num2.length > 2) {
          num2 = num2.substring(0, 2);
        }
        num = num1 + "." + num2;
      } else {
        num = ""+num
        num = num.replace(/[^0-9]/g, "");
      }
      return num;
    },
    changeNote(type, valueChange, value, index, id) {
        let form = {
          id: id,
          data: {}
        }

        if(type === 'date') {
          form.data[valueChange] = value;
          // this.keyRenderCpDate++;
        }
        if(type === "cp_date") {
          value = this.activeCPDate !== "Unselected" ? this.activeCPDate : ""
          form.data[valueChange] = value;
          this.notePayment[index][valueChange] = value
          this.keyRenderCpDate++;
        }

        if(type === 'currency') {
          value = this.activeCurrency
          if(value !== null && value !== {}) {
            form.data[valueChange] = value.name
          } else {
            form.data[valueChange] = null
          }
        }
        if(type === 'currency') {
          this.notePayment[index][valueChange] = value.name
        }
        if(type === 'ship') {
          value = this.notePayment[index][valueChange]
          // this.notePayment[index][valueChange] = value
          this.notePayment[index]["cp_date"] = null
          if(value !== null) {
            form.data[valueChange] = value.id
          } else {
            form.data[valueChange] = value
          }
        }
        if(type === 'account') {
          value = this.activeAccount
          this.notePayment[index][valueChange] = value
          if(value !== null) {
            form.data[valueChange] = value.map(a=> {return a.id})
          } else {
            form.data[valueChange] = null
          }
        }

        if(type === 'expense') {
          this.notePayment[index].income_payment = ""
          form.data['payment_type'] = 'expense'
          if(value.length !== 0) {
            form.data['payment_amount'] = value
          } else {
            form.data['payment_amount'] = null
          }
        }

        if(type === 'income') {
          this.notePayment[index].expanse_payment = ""
          form.data['payment_type'] = 'income'
          if(value.length !== 0) {
            form.data['payment_amount'] = value
          } else {
            form.data['payment_amount'] = null
          }
        }

        this.axiosChangeNote(form).then(()=> {
          // this.axiosGetNoteToPayment().then(res=> {
          //   if (res !== false) {
          //     this.notePayment = res.data.results.map(a=> {
          //       if(a.cp_date !== null && (""+a.cp_date).length !== 0) {
          //         a.cp_date = new Date(a.cp_date);
          //       }
          //       if(a.execute_date !== null && (""+a.execute_date).length !== 0) {
          //         a.execute_date = new Date(a.execute_date);
          //       }
          //       // if(a.account !== null) {
          //       //   a.acount = a.account.map(b=> {
          //       //     return {name: b.id + ": " + b.name, id: b.id}
          //       //   })
          //       // }
          //       a["expanse_payment"] = "";
          //       a["income_payment"] = "";
          //       if(a.payment_type === 'expense') {
          //         a["expanse_payment"] = a.payment_amount;
          //       }else {
          //         a["income_payment"] = a.payment_amount;
          //       }
          //       return a;
          //     });
          //   }
          // })
        })

    },
    addNoteToPayment() {
      this.axiosAddNoteToPayment().then(res=> {
        if(res !== false) {
          this.notePayment.push(res.data);
        }
      })
    },
    clearAllSelected() {
      this.selectAll = false;
      this.selectCheckboxAll();
    },
    unArchivedSelected() {
      for (let i=0; i<this.selectedCheckboxes.length; i++) {
        this.axiosArchivePayment( {id: this.selectedCheckboxes[i], is_archived: !(this.$route.path.indexOf('archive') >= 0)}).then(()=> {
          // this.paymentsList = this.paymentsList.filter(a=> a.id !== this.selectedCheckboxes[i])
        });
        // this.addTip(text + (this.$route.path.indexOf('archive') >= 0 ? ' is unarchived' : ' is archived'));
      }
      this.addTip(this.selectedCheckboxes.length + (this.$route.path.indexOf('archive') >= 0 ? ' is unarchived' : ' is archived'))
      this.selectedCheckboxes.forEach(item=> {
        let id = item
        this.selectedCheckboxes = this.selectedCheckboxes.filter(a => a !== id);
        this.paymentsList = this.paymentsList.filter(a=> a.id !== id)
        this.ArrayCheckBox = this.ArrayCheckBox.filter(a=> a.id !== id)
        this.ArrayDueDate = this.ArrayDueDate.filter(a=> a.id !== id)
        this.ArrayVessel = this.ArrayVessel.filter(a=> a.id !== id)
        this.ArrayReference = this.ArrayReference.filter(a=> a.id !== id)
        this.ArrayAdditionally = this.ArrayAdditionally.filter(a=> a.id !== id)
        this.ArrayCurrency = this.ArrayCurrency.filter(a=> a.id !== id)
        this.ArrayPayments = this.ArrayPayments.filter(a=> a.id !== id)
        this.ArrayIncomes = this.ArrayIncomes.filter(a=> a.id !== id)
        this.ArrayUSD = this.ArrayUSD.filter(a=> a.id !== id)
        this.ArrayStatus = this.ArrayStatus.filter(a=> a.id !== id)
        this.ArrayPaidDate = this.ArrayPaidDate.filter(a=> a.id !== id)
        this.ArrayCounterparty = this.ArrayCounterparty.filter(a=> a.id !== id)
        this.ArrayBankCash = this.ArrayBankCash.filter(a=> a.id !== id)
        this.ArrayRemarks = this.ArrayRemarks.filter(a=> a.id !== id)
        this.ArrayInstructions = this.ArrayInstructions.filter(a=> a.id !== id)
        this.ArrayReceipt = this.ArrayReceipt.filter(a=> a.id !== id)
        this.ArrayAccountRemarks = this.ArrayAccountRemarks.filter(a=> a.id !== id)
        this.ArraySettledVessel = this.ArraySettledVessel.filter(a=> a.id !== id)
        this.ArrayDisbursements = this.ArrayDisbursements.filter(a=> a.id !== id)
        this.ArrayRealAccount = this.ArrayRealAccount.filter(a=> a.id !== id)
        this.ArrayStatementMember = this.ArrayStatementMember.filter(a=> a.id !== id)
        this.ArrayEnd = this.ArrayEnd.filter(a=> a.id !== id)
        if(this.paymentBreakdown === true) {
          this.ArrayBalances = this.ArrayBalances.filter(a=> a.id !== id)
        }
      })
      this.selectedCheckboxes = [];
      // this.$emit("renderList")
    },

    selectCheckboxAll() {
      // this.selectedCheckboxes = [];
      // this.paymentsList.map(a=> {
      //   this.selectedCheckboxes.push(a.id);
      // });

      if(this.selectedCheckboxes.length !== 0) {
        this.selectedCheckboxes = [];
      } else {
        this.selectedCheckboxes = [];
        this.selectedCheckboxes = this.paymentsList.map(a=> {return  a.id})
      }
    },
    unarchivedThis(id) {
      let text = this.paymentsList.filter(a=> a.id === id);
      if(text.length !== 0) {
        text = text[0].client_name ? text[0].client_name : (text[0].payment_type === 'expense' ? 'Payment' : 'Income');
      }
      this.axiosArchivePayment({id: id, is_archived: false}).then(()=> {
      })
      this.addTip(text + " is unarchived");
      this.selectedCheckboxes = this.selectedCheckboxes.filter(a => a !== id);
      this.paymentsList = this.paymentsList.filter(a=> a.id !== id)
      this.ArrayCheckBox = this.ArrayCheckBox.filter(a=> a.id !== id)
      this.ArrayDueDate = this.ArrayDueDate.filter(a=> a.id !== id)
      this.ArrayVessel = this.ArrayVessel.filter(a=> a.id !== id)
      this.ArrayReference = this.ArrayReference.filter(a=> a.id !== id)
      this.ArrayAdditionally = this.ArrayAdditionally.filter(a=> a.id !== id)
      this.ArrayCurrency = this.ArrayCurrency.filter(a=> a.id !== id)
      this.ArrayPayments = this.ArrayPayments.filter(a=> a.id !== id)
      this.ArrayIncomes = this.ArrayIncomes.filter(a=> a.id !== id)
      this.ArrayUSD = this.ArrayUSD.filter(a=> a.id !== id)
      this.ArrayStatus = this.ArrayStatus.filter(a=> a.id !== id)
      this.ArrayPaidDate = this.ArrayPaidDate.filter(a=> a.id !== id)
      this.ArrayCounterparty = this.ArrayCounterparty.filter(a=> a.id !== id)
      this.ArrayBankCash = this.ArrayBankCash.filter(a=> a.id !== id)
      this.ArrayRemarks = this.ArrayRemarks.filter(a=> a.id !== id)
      this.ArrayInstructions = this.ArrayInstructions.filter(a=> a.id !== id)
      this.ArrayReceipt = this.ArrayReceipt.filter(a=> a.id !== id)
      this.ArrayAccountRemarks = this.ArrayAccountRemarks.filter(a=> a.id !== id)
      this.ArraySettledVessel = this.ArraySettledVessel.filter(a=> a.id !== id)
      this.ArrayDisbursements = this.ArrayDisbursements.filter(a=> a.id !== id)
      this.ArrayRealAccount = this.ArrayRealAccount.filter(a=> a.id !== id)
      this.ArrayStatementMember = this.ArrayStatementMember.filter(a=> a.id !== id)
      this.ArrayEnd = this.ArrayEnd.filter(a=> a.id !== id)
      if(this.paymentBreakdown === true) {
        this.ArrayBalances = this.ArrayBalances.filter(a=> a.id !== id)
      }
    },
    selectCheckbox(id) {
      let text = id;
      if(this.selectedCheckboxes.indexOf(text) >=0) {
        this.selectedCheckboxes = this.selectedCheckboxes.filter(a=> a !== text);
      } else {
        this.selectedCheckboxes.push(text);
      }
    },
    getTextFromPorts(ports) {
      // return (""+ports.map(ac=> {return ac.name + (ac.country ? '('+ac.country + ')':'') + "<br/>"})).replaceAll(",", "");
    if(ports) {
      return ports.name + (ports.country ? '('+ports.country + ')':'')
    }
    return ''
    },
    textWithoutBR(text) {
      return text.replaceAll("<br/>", ", ")
    },
    getTextBankAndCashAccounts(payment) {
        if(payment.cash !== null) {
          return {name: payment.cash.name, id: payment.id, id_cash: payment.cash.id, type: 'cash'}
        } else if(payment.bank !== null) {
          return {name: payment.bank.holder, id: payment.id, id_cash: payment.bank.id, type: 'bank'}
        } else {
          return {name: "", id: payment.id, id_cash: "", type: 'bank'}
        }
    },
    getTextForOneAccount(ac) {
      return (this.cashFlow !== true && this.getPermissionShowDopInfoAcc()) ? (ac.account_id + ": " + ac.name + "<br/>") : ac.name + "<br/>";
    },
    getTextAccountTitle(account) {
      if(account) {
        return (this.getPermissionShowDopInfoAcc() ? account.account_id + ": " + account.name : account.name)
      }
      return ''
      // return Array.isArray(account) === true ? (""+account.map(ac=> {return (this.getPermissionShowDopInfoAcc() ? ac.account_id + ": " + ac.name : ac.name) })) : ''
  },
    getTextAccount(a) {
      if(this.cashFlow !== true && this.getPermissionShowDopInfoAcc()) {
        if (a !== null && a !== undefined) {
          if (a.account !== null && a.account !== undefined) {
            if (a.account) {
              return a.account.account_id + ": " + a.account.name
            }
          }
        }
          return "";
        } else {
          if (a !== null && a !== undefined) {
            if (a.account !== null && a.account !== undefined) {
              if (a.account) {
                return "" + a.account.name
              }
            }
            return "";
          }
        }
      }
    ,
    getTextAccount2(a) {
        if (a !== null && a !== undefined && this.getPermissionShowDopInfoAcc()) {
          if (a.account !== null && a.account !== undefined) {
                return a.account.account_id + ": " + a.account.name
          }
          return "";
        } else {
          if (a !== null && a !== undefined) {
            if (a.account !== null && a.account !== undefined) {
                  return a.account.name
            }
            return "";
          }
        }
    },
    setActiveColumns(data) {
      this.activeColumns = data;
      localStorage.removeItem('activeColumns' + this.$route.path);
      localStorage.setItem('activeColumns' + this.$route.path, JSON.stringify(this.activeColumns));
    },
    clickEditPaymentCard(id) {
      this.$emit("clickEditPaymentCard", id)
    },
    clickDuplicatePaymentCard(id) {
      this.$emit("clickDuplicatePaymentCard", id)
    },
    clickArchivePaymentCard(id) {
      this.$emit("clickArchivePaymentCard", id)
    },
    getFormatDate(date) {
      if(date !==null && (""+date).length !==0) {
        return "" + moment(date).format('DD.MM.YYYY')
      } else {
        return ""
      }
    },
    getFirstChartUppercase(str) {
      return str[0].toUpperCase() + str.slice(1);
    },
    getTextForStatus(str) {
      if(str === "in_progress") {
        return "Not Paid"
      }
      return str.replaceAll("_", " ");
    },
    clickCreate() {
      this.$emit("clickCreate")
    },
    clickDelete(id) {
      this.$emit("clickDelete", id)
    },
    beforeRouteLeave(next) {
      next()
    },
    emitGetActiveList() {
      this.$emit("activeList", this.activeColumns);
    },
    getHTMLList() {
      let m = {}
      m = this.$refs.blockPaymentList.innerHTML;
      m = this.textHTMLPageStart + m + this.textHTMLPageEnd;
      this.$emit("HtmlCode", m);
    },
    getPermissionShowDopInfoAcc() {
      return this.getPermissionsByType('show_dop_info_for_acc_in_payments')
    },
    getPermissionShowVesselAndCpDate() {
      return this.getPermissionsByType('show_vessel_and_cp_date_in_payments')
    },
    getPermissionShowDopColumns() {
      return !this.getPermissionsByType('show_dop_menu_choosed_columns_in_payment_list')
    },
    getPermissionShowDopColumnsFull() {
      return this.getPermissionsFullByType('show_dop_menu_choosed_columns_in_payment_list')
    },
    setDataPayment(data) {
      this.ArrayCheckBox= [];
      this.ArrayDueDate= [];
      this.ArrayVessel= [];
      this.ArrayReference= [];
      this.ArrayAdditionally= [];
      this.ArrayCurrency= [];
      this.ArrayPayments= [];
      this.ArrayIncomes= [];
      this.ArrayUSD= [];
      this.ArrayStatus= [];
      this.ArrayPaidDate= [];
      this.ArrayCounterparty= [];
      this.ArrayBankCash= [];
      this.ArrayRemarks= [];
      this.ArrayInstructions= [];
      this.ArrayReceipt= [];
      this.ArrayAccountRemarks= [];
      this.ArraySettledVessel= [];
      this.ArrayDisbursements= [];
      this.ArrayRealAccount= [];
      this.ArrayStatementMember= [];
      this.ArrayBalances=[];
      this.ArrayEnd = [];
      // let amountProfit = 0;
      // let amountNetProfit = 0;
      this.paymentsList = JSON.parse(JSON.stringify(data));
      let balance = this.getAmountBalance;
      this.paymentsList.forEach((item, index)=> {
        if(this.paymentBreakdown) {
          if(index !== 0) {
            balance = this.ArrayBalances[index-1].balance;
          }
          this.ArrayBalances.push({id: item.id, balance: item.payment_type === 'expense' ? balance - parseFloat(item.currency_amount) : balance + parseFloat(item.currency_amount)})
        }
        this.ArrayCheckBox.push({id: item.id})
        this.ArrayDueDate.push({id: item.id, execute_date: item.execute_date, status: item.status})
        this.ArrayVessel.push({id: item.id, status: item.status, ship: item.ship})
        this.ArrayReference.push({id: item.id, account: item.account, status: item.status, account_name: item.account_name})
        this.ArrayAdditionally.push({id: item.id, status: item.status, additionally: item.additionally})
        this.ArrayCurrency.push({id: item.id, status: item.status, currency: item.currency})
        this.ArrayPayments.push({id: item.id, status: item.status, payment_amount_expanse: item.payment_amount_expanse})
        this.ArrayIncomes.push({id: item.id, status: item.status, payment_amount_income: item.payment_amount_income})
        this.ArrayUSD.push({id: item.id, status: item.status, payment_amount_income: item.payment_amount_income, currency_amount: item.currency_amount})
        this.ArrayStatus.push({id: item.id, status: item.status, payment_type: item.payment_type})
        this.ArrayPaidDate.push({id: item.id, paid_date: item.paid_date})
        this.ArrayCounterparty.push({id: item.id, status: item.status, client_name: item.client_name, client: item.client})
        this.ArrayBankCash.push({id: item.id, status: item.status, bank: item.bank, cash: item.cash})
        this.ArrayRemarks.push({id: item.id, comment: item.comment})
        this.ArrayInstructions.push({id: item.id, status: item.status, files: item.files})
        this.ArrayReceipt.push({id: item.id, receipt_files: item.receipt_files})
        this.ArrayAccountRemarks.push({id: item.id, has_messages: item.has_messages})
        this.ArraySettledVessel.push({id: item.id, status: item.status, settled_ship: item.settled_ship})
        this.ArrayDisbursements.push({id: item.id, status: item.status, ports: item.ports})
        this.ArrayRealAccount.push({id: item.id, status: item.status, real_account: item.real_account})
        this.ArrayStatementMember.push({id: item.id, status: item.status, statement_member: item.statement_member})
        this.ArrayEnd.push({id: item.id})

        // if(item.status === 'paid') {
        //   if(item.payment_type === 'income') {
        //     amountProfit = amountProfit + parseFloat(item.currency_amount);
        //   } else {
        //     amountProfit = amountProfit - parseFloat(item.currency_amount);
        //   }
        // }
        // if(item.payment_type === 'income') {
        //   amountNetProfit = amountNetProfit + parseFloat(item.currency_amount);
        // } else {
        //   amountNetProfit = amountNetProfit - parseFloat(item.currency_amount);
        // }
      })
      // this.paymentsList.forEach(item=> {
      //   if(item.payment_type === 'income') {
      //     amountProfit = amountProfit + parseFloat(item.currency_amount);
      //   } else {
      //     amountProfit = amountProfit - parseFloat(item.currency_amount);
      //   }
      //   if(item.status === 'paid') {
      //     if (item.payment_type === 'income') {
      //       amountNetProfit = amountNetProfit + parseFloat(item.currency_amount);
      //     } else {
      //       amountNetProfit = amountNetProfit - parseFloat(item.currency_amount);
      //     }
      //   }
      // })
      // this.setProfit(amountProfit)
      // this.setNetProfit(amountNetProfit)
      this.axiosGetGrossNet(this.getFilterDataPaymentAxios)
    },
    pushLoadData(data) {
      data = data.map(a=> {
        let item = this.paymentsList.find(b=> b.id === a.id)
        if(!item) {
          return a;
        }
      }).filter(a=> a)
      this.paymentsList = this.paymentsList.concat(data);
      let balance = this.getAmountBalance;

      data.forEach((item, index)=> {
        if(this.paymentBreakdown) {
          if(index !== 0) {
            balance = this.ArrayBalances[index-1].balance;
          }
          this.ArrayBalances.push({id: item.id, balance: item.payment_type === 'expense' ? balance - parseFloat(item.currency_amount) : balance + parseFloat(item.currency_amount)})
        }
        this.ArrayCheckBox.push({id: item.id})
        this.ArrayDueDate.push({id: item.id, execute_date: item.execute_date, status: item.status})
        this.ArrayVessel.push({id: item.id, status: item.status, ship: item.ship})
        this.ArrayReference.push({id: item.id, account: item.account, status: item.status, account_name: item.account_name})
        this.ArrayAdditionally.push({id: item.id, status: item.status, additionally: item.additionally})
        this.ArrayCurrency.push({id: item.id, status: item.status, currency: item.currency})
        this.ArrayPayments.push({id: item.id, status: item.status, payment_amount_expanse: item.payment_amount_expanse})
        this.ArrayIncomes.push({id: item.id, status: item.status, payment_amount_income: item.payment_amount_income})
        this.ArrayUSD.push({id: item.id, status: item.status, payment_amount_income: item.payment_amount_income, currency_amount: item.currency_amount})
        this.ArrayStatus.push({id: item.id, status: item.status, payment_type: item.payment_type})
        this.ArrayPaidDate.push({id: item.id, paid_date: item.paid_date})
        this.ArrayCounterparty.push({id: item.id, status: item.status, client_name: item.client_name, client: item.client})
        this.ArrayBankCash.push({id: item.id, status: item.status, bank: item.bank, cash: item.cash})
        this.ArrayRemarks.push({id: item.id, comment: item.comment})
        this.ArrayInstructions.push({id: item.id, status: item.status, files: item.files})
        this.ArrayReceipt.push({id: item.id, receipt_files: item.receipt_files})
        this.ArrayAccountRemarks.push({id: item.id, has_messages: item.has_messages})
        this.ArraySettledVessel.push({id: item.id, status: item.status, settled_ship: item.settled_ship})
        this.ArrayDisbursements.push({id: item.id, status: item.status, ports: item.ports})
        this.ArrayRealAccount.push({id: item.id, status: item.status, real_account: item.real_account})
        this.ArrayStatementMember.push({id: item.id, status: item.status, statement_member: item.statement_member})
        this.ArrayEnd.push({id: item.id})
      })
      // let amountNetProfit = 0;
      // let amountProfit = 0;
      // this.paymentsList.forEach(item=> {
      //   if(item.payment_type === 'income') {
      //     amountProfit = amountProfit + parseFloat(item.currency_amount);
      //   } else {
      //     amountProfit = amountProfit - parseFloat(item.currency_amount);
      //   }
      //   if(item.status === 'paid') {
      //     if (item.payment_type === 'income') {
      //       amountNetProfit = amountNetProfit + parseFloat(item.currency_amount);
      //     } else {
      //       amountNetProfit = amountNetProfit - parseFloat(item.currency_amount);
      //     }
      //   }
      // })
      // this.setProfit(amountProfit)
      // this.setNetProfit(amountNetProfit)
      this.axiosGetGrossNet(this.getFilterDataPaymentAxios)
    },
    addNewPaymentData(data) {
      this.paymentsList.push(data)
      data = [data]

      // = data.concat(this.paymentsList);
      let balance = this.getAmountBalance;
      data.forEach((item, index)=> {
        if(this.paymentBreakdown) {
          if(index !== 0) {
            balance = this.ArrayBalances[index-1].balance;
          }
          this.ArrayBalances.push({id: item.id, balance: item.payment_type === 'expense' ? balance - parseFloat(item.currency_amount) : balance + parseFloat(item.currency_amount)})
              // = [{id: item.id, balance: item.payment_type === 'expense' ? balance - parseFloat(item.currency_amount) : balance + parseFloat(item.currency_amount)}].concat(this.ArrayBalances)
        }
        this.ArrayCheckBox.push({id: item.id})
         // = [{id: item.id}].concat(this.ArrayCheckBox)
        this.ArrayDueDate.push({id: item.id, execute_date: item.execute_date, status: item.status})
            // = [{id: item.id, execute_date: item.execute_date, status: item.status}].concat(this.ArrayDueDate)
        this.ArrayVessel.push({id: item.id, status: item.status, ship: item.ship})
         // = [{id: item.id, status: item.status, ship: item.ship}].concat(this.ArrayVessel)
        this.ArrayReference.push({id: item.id, account: item.account, status: item.status, account_name: item.account_name})
         // = [{id: item.id, account: item.account, status: item.status}].concat(this.ArrayReference)
        this.ArrayAdditionally.push({id: item.id, status: item.status, additionally: item.additionally})
         // = [{id: item.id, status: item.status, additionally: item.additionally}].concat(this.ArrayAdditionally)
        this.ArrayCurrency.push({id: item.id, status: item.status, currency: item.currency})
            // = [{id: item.id, status: item.status, currency: item.currency}].concat(this.ArrayCurrency)
        this.ArrayPayments.push({id: item.id, status: item.status, payment_amount_expanse: item.payment_amount_expanse})
         // = [ {id: item.id, status: item.status, payment_amount_expanse: item.payment_amount_expanse}].concat(this.ArrayPayments)
        this.ArrayIncomes.push({id: item.id, status: item.status, payment_amount_income: item.payment_amount_income})
         // = [{id: item.id, status: item.status, payment_amount_income: item.payment_amount_income}].concat(this.ArrayIncomes)
        this.ArrayUSD.push({id: item.id, status: item.status, payment_amount_income: item.payment_amount_income, currency_amount: item.currency_amount})
// = [{id: item.id, status: item.status, payment_amount_income: item.payment_amount_income, currency_amount: item.currency_amount}].concat(this.ArrayUSD)
        this.ArrayStatus.push({id: item.id, status: item.status, payment_type: item.payment_type})
            // = [ {id: item.id, status: item.status, payment_type: item.payment_type}].concat(this.ArrayStatus)
        this.ArrayPaidDate.push({id: item.id, paid_date: item.paid_date})
         // = [{id: item.id, paid_date: item.paid_date}].concat(this.ArrayPaidDate)
        this.ArrayCounterparty.push({id: item.id, status: item.status, client_name: item.client_name, client: item.client})
         // = [{id: item.id, status: item.status, client_name: item.client_name, client: item.client}].concat(this.ArrayCounterparty)
        this.ArrayBankCash.push({id: item.id, status: item.status, bank: item.bank, cash: item.cash})
         // = [{id: item.id, status: item.status, bank: item.bank, cash: item.cash}].concat(this.ArrayBankCash)
        this.ArrayRemarks.push({id: item.id, comment: item.comment})
         // = [{id: item.id, comment: item.comment}].concat(this.ArrayRemarks)
        this.ArrayInstructions.push({id: item.id, status: item.status, files: item.files})
            // = [{id: item.id, status: item.status, files: item.files}].concat(this.ArrayInstructions)
        this.ArrayReceipt.push({id: item.id, receipt_files: item.receipt_files})
         // = [{id: item.id, receipt_files: item.receipt_files}].concat(this.ArrayReceipt)
        this.ArrayAccountRemarks.push({id: item.id, has_messages: item.has_messages})
         // = [{id: item.id, has_messages: item.has_messages}].concat(this.ArrayAccountRemarks)
        this.ArraySettledVessel.push({id: item.id, status: item.status, settled_ship: item.settled_ship})
         // = [{id: item.id, status: item.status, settled_ship: item.settled_ship}].concat(this.ArraySettledVessel)
        this.ArrayDisbursements.push({id: item.id, status: item.status, ports: item.ports})
         // = [{id: item.id, status: item.status, ports: item.ports}].concat(this.ArrayDisbursements)
        this.ArrayRealAccount.push({id: item.id, status: item.status, real_account: item.real_account})
         // = [{id: item.id, status: item.status, real_account: item.real_account}].concat(this.ArrayRealAccount)
        this.ArrayStatementMember.push({id: item.id, status: item.status, statement_member: item.statement_member})
         // = [{id: item.id, status: item.status, statement_member: item.statement_member}].concat(this.ArrayStatementMember)
        this.ArrayEnd.push({id: item.id})
         // = [{id: item.id}].concat(this.ArrayEnd)
      })
    },
  },
  mounted() {
    if(this.namePages.indexOf(this.$route.name) === -1) {
      this.namePages = [];
      this.namePages.push(this.$route.name);
    }
    if(localStorage.getItem("activeColumns" + this.$route.path) !== null && localStorage.getItem("activeColumns" + this.$route.path) !== undefined) {
      this.activeColumnsDefault = JSON.parse(localStorage.getItem("activeColumns" + this.$route.path));
      this.activeColumns = JSON.parse(localStorage.getItem("activeColumns" + this.$route.path));
    }
    if(this.cashFlow !== true) {
      this.nameColumns = [
        {name: "Vessel, CP Date", id: '3228734654s'+0},
        {name: "Statement Member", id: '3224s'+0},
        {name: "Settled vsl", id: '32423s'+0},
        {name: "USD amount", id: '324s'+1},
        {name: "Disbursements", id: '324s'+2},
        {name: "Real Account", id: '324s'+3},
      ]
      if(this.$route.path.indexOf('vessels') >= 0) {
        this.nameColumns.push({name: "Bank and Cash Accounts", id: '324s'+3},)
      }
    } else {
      this.nameColumns = [
        {name: "Vessel, CP Date", id: '3228734654s'+0},
        {name: "Statement Member", id: '312224s'+0},
        {name: "Settled vsl", id: '324s'+0},
        {name: "USD amount", id: '324s'+1},
        {name: "Disbursements", id: '324s'+2},
        {name: "Real Account", id: '324s'+3},
      ]
      if(this.$route.path.indexOf('open/cash-flow') >= 0) {
        this.nameColumns = [
          {name: "Vessel, CP Date", id: '3228734654s'+0},
          {name: "Statement Member", id: '32323224s'+0},
          {name: "Bank and Cash Accounts", id: '3224s'+3},
          {name: "Settled vsl", id: '324s'+0},
          {name: "USD amount", id: '324s'+1},
          {name: "Disbursements", id: '324s'+2},
          {name: "Real Account", id: '324s'+3},
        ]
      }
      if(this.namePages.indexOf('Accounting Invoice') >= 0) {
        this.nameColumns = [
          {name: "Vessel, CP Date", id: '3228734654s'+0},
          {name: "Statement Member", id: '3212324s'+0},
          {name: "Settled vsl", id: '324s'+0},
          {name: "USD amount", id: '324s'+1},
          {name: "Disbursements", id: '324s'+2},
          // {name: "Real Account", id: '324s'+3},
        ]
      }
    }
    if(this.$route.path.indexOf('counterparties') >=0 && this.$route.path.indexOf('payments') >=0) {
      this.nameColumns = [
        {name: 'Attachments', id: '867sd'+144},
        {name: 'Comment from accounting', id: '867sd'+2},
        {name: 'Bank Receip', id: '867sd'+1},
        {name: "Bank and Cash Accounts", id: '3224s'+3},
        {name: "Vessel, CP Date", id: '3228734654s'+0},
        {name: "Statement Member", id: '322123124s'+0},
        {name: "Settled vsl", id: '324s'+0},
        {name: "USD amount", id: '324s'+1},
        {name: "Disbursements", id: '324s'+2},
        {name: "Real Account", id: '324s'+3},
      ]
    }
    if(this.$route.path.indexOf('cashflow/list') >= 0) {
      this.nameColumns = [
        {name: "Bank and Cash Accounts", id: '3224s'+3},
        {name: "Vessel, CP Date", id: '3228734654s'+0},
        {name: "Statement Member", id: '322234ff4s'+0},
        {name: "Settled vsl", id: '324s'+0},
        {name: "USD amount", id: '324s'+1},
        {name: "Disbursements", id: '324s'+2},
        {name: "Real Account", id: '324s'+3},
      ]
    }
    if(!this.getPermissionShowVesselAndCpDate()) {
      this.nameColumns = this.nameColumns.filter(a=> a.name !== "Vessel, CP Date")
    }
    if(!this.getPermissionShowDopColumns()) {
      this.nameColumns = this.nameColumns.filter(a=> (this.getPermissionShowDopColumnsFull().such_columns.map(a=> {return a.toLowerCase()})).indexOf(a.name.toLowerCase()) === -1)
    }
    if(this.$route.path.indexOf('accounting-invoice')>=0) {
      this.nameColumns = this.nameColumns.filter(a=> a.name !== "Statement Member")
    }
    let vm= this;
    document.addEventListener('keydown', function(event) {
      if (event.key === 'Control' || event.key === 'Meta') {
        vm.keyCtrlDown = true
      } else {
        vm.keyCtrlDown = false
      }
    });
    document.addEventListener('keyup', function() {
        vm.keyCtrlDown = false
    });
    this.mountedEnd = true;
  },
  watch: {
    dataNewPayment: function () {
      this.addNewPaymentData(this.dataNewPayment);
    },
    pushDataPaymentList: function () {
      this.pushLoadData(JSON.parse(JSON.stringify(this.pushDataPaymentList)));
    },
    // getPaymentListOriginal: function () {
    //
    // },
  $route: function () {
    if(this.namePages.indexOf(this.$route.name) === -1) {
      this.namePages = [];
      this.namePages.push(this.$route.name);
    }
    if(this.cashFlow !== true) {
      this.nameColumns = [
        {name: "Vessel, CP Date", id: '3228734654s'+0},
        {name: "Statement Member", id: '3224s'+0},
        {name: "Settled vsl", id: '324s'+0},
        {name: "USD amount", id: '324s'+1},
        {name: "Disbursements", id: '324s'+2},
        {name: "Real Account", id: '324s'+3},
      ]
    } else {
      this.nameColumns = [
        {name: "Vessel, CP Date", id: '3228734654s'+0},
        {name: "Statement Member", id: '3224s'+0},
        {name: "Settled vsl", id: '324s'+0},
        {name: "USD amount", id: '324s'+1},
        {name: "Disbursements", id: '324s'+2},
        {name: "Real Account", id: '324s'+3},
      ]
      if(this.$route.path.indexOf('open/cash-flow') >= 0) {
        this.nameColumns = [
          {name: "Vessel, CP Date", id: '3228734654s'+0},
          {name: "Statement Member", id: '3224s'+0},
          {name: "Bank and Cash Accounts", id: '3224s'+3},
          {name: "Settled vsl", id: '324s'+0},
          {name: "USD amount", id: '324s'+1},
          {name: "Disbursements", id: '324s'+2},
          {name: "Real Account", id: '324s'+3},
        ]
      }
      if(this.namePages.indexOf('Accounting Invoice') >= 0) {
        this.nameColumns = [
          {name: "Vessel, CP Date", id: '3228734654s'+0},
          {name: "Statement Member", id: '3224s'+0},
          {name: "Settled vsl", id: '324s'+0},
          {name: "USD amount", id: '324s'+1},
          {name: "Disbursements", id: '324s'+2},
          // {name: "Real Account", id: '324s'+3},
        ]
      }
    }
    if(this.$route.path.indexOf('counterparties') >=0 && this.$route.path.indexOf('payments') >=0) {
      this.nameColumns = [
        {name: 'Attachments', id: '867sd'+144},
        {name: 'Comment from accounting', id: '867sd'+2},
        {name: 'Bank Receip', id: '867sd'+1},
        {name: "Bank and Cash Accounts", id: '3224s'+3},
        {name: "Vessel, CP Date", id: '3228734654s'+0},
        {name: "Statement Member", id: '3224s'+0},
        {name: "Settled vsl", id: '324s'+0},
        {name: "USD amount", id: '324s'+1},
        {name: "Disbursements", id: '324s'+2},
        {name: "Real Account", id: '324s'+3},
      ]
    }
    if(this.$route.path.indexOf('cashflow/list') >= 0) {
      this.nameColumns = [
        {name: "Bank and Cash Accounts", id: '3224s'+3},
        {name: "Vessel, CP Date", id: '3228734654s'+0},
        {name: "Statement Member", id: '3224s'+0},
        {name: "Settled vsl", id: '324s'+0},
        {name: "USD amount", id: '324s'+1},
        {name: "Disbursements", id: '324s'+2},
        {name: "Real Account", id: '324s'+3},
      ]
    }
    if(!this.getPermissionShowVesselAndCpDate()) {
      this.nameColumns = this.nameColumns.filter(a=> a.name !== "Vessel, CP Date")
    }
    if(!this.getPermissionShowDopColumns()) {
      this.nameColumns = this.nameColumns.filter(a=> (this.getPermissionShowDopColumnsFull().such_columns.map(a=> {return a.toLowerCase()})).indexOf(a.name.toLowerCase()) === -1)
    }
    if(this.$route.path.indexOf('accounting-invoice')>=0) {
      this.nameColumns = this.nameColumns.filter(a=> a.name !== "Statement Member")
    }
  },
    // getPaymentListOriginal: function () {
    //   if(this.cashFlow !== true) {
    //     this.list = this.getPaymentList.map(a=> {
    //       let item = JSON.parse(JSON.stringify(a))
    //       return item);
    //     })
    //   } else {
    //     this.list = this.getPaymentListCashFlow.map(a=> {
    //       return JSON.parse(JSON.stringify(a));
    //     })
    //   }
    //
    // },
  }
}
</script>

<style scoped lang="scss">
  @import "paymentListComponent";
</style>