<template>
  <div class="">
    <div class="border border-dark overflow-hidden rounded-lg shadow">
      <ocean-edit
        v-if="getOceanFreight"
        :activeCurrency="invoice.currency"
        :total_quantity="parseFloat(invoice.total_quantity)"
        :detail="getOceanFreight"
        @changeMtAmount="changeMtAmount"
      />
      <ocean-ilow-edit
        v-if="getOceanFreight && getOceanFreight.additional_info.is_ilow"
        :detail="getOceanFreight"
        @on-delete="onDeleteBlock('freight_ilow')"
      />
      <dead-edit
        v-if="getDeadFreight"
        :activeCurrency="invoice.currency"
        :detail="getDeadFreight"
        @on-delete="onDeleteBlock('DEAD_FREIGHT')"
      />
      <dead-ilow-edit
        v-if="getDeadFreight && getDeadFreight.additional_info.is_ilow"
        :detail="getDeadFreight"
        @on-delete="onDeleteBlock('dead_ilow')"
      />
      <extra-edit
        v-if="getExtraFreight"
        :activeCurrency="invoice.currency"
        :detail="getExtraFreight"
        @on-delete="onDeleteBlock('EXTRA_OCEAN_FREIGHT')"
      />
      <extra-ilow-edit
        v-if="getExtraFreight && getExtraFreight.additional_info.is_ilow"
        :detail="getExtraFreight"
        @on-delete="onDeleteBlock('extra_ilow')"
      />
      <div class="invoice_table border-t border-blue-dark">
        <div class="colspan-1-8 fw500 p-x-2 p-y-2 flex items-center justify-between">
          TOTAL
          <span class="fw500" v-if="getOceanFreight">
            {{getOceanFreight.total_amount >= 0 ? '':'('}}
            {{Math.abs(getOceanFreight.total_amount) | formatNumberTwo}}
            {{getOceanFreight.total_amount >= 0 ? '':')'}}
          </span>
        </div>
      </div>
    </div>

    <thereof-edit 
      class="m-t-3"
      v-for="(row, i) in getByKey('THEREOF')" 
      :key="row.id ? row.id+'id': row.count+'count'"
      :detail="row"
      :total_amount="parseFloat(getOceanFreight.total_amount)"
      @on-delete="onDeleteBlock('THEREOF', i)"
    />

    <address-edit 
      class="m-t-3"
      v-for="(row, i) in getByKey('LESS_ADDRESS_COMMISSION')"
      :key="row.id ? row.id+'id': row.count+'count'"
      :detail="row"
      :total_amount="parseFloat(getOceanFreight.total_amount)"
      @on-delete="onDeleteBlock('LESS_ADDRESS_COMMISSION', i)"
    />

    <brokerage-edit 
      class="m-t-3"
      v-for="(row, i) in getByKey('LESS_BROKERAGE_COMMISSION')"
      :key="row.id ? row.id+'id': row.count+'count'"
      :detail="row"
      :total_amount="parseFloat(getOceanFreight.total_amount)"
      @on-delete="onDeleteBlock('LESS_BROKERAGE_COMMISSION', i)"
    />

    <demurrage-edit 
      class="m-t-3"
      v-for="(row, i) in getByKey('PLUS_DEMURRAGE')"
      :key="row.id ? row.id+'id': row.count+'count'"
      :detail="row"
      :cpDate="invoice.cp_date"
      @on-delete="onDeleteBlock('PLUS_DEMURRAGE', i)"
    />

    <dispatch-edit 
      class="m-t-3"
      v-for="(row, i) in getByKey('LESS_DISPATCH')"
      :key="row.id ? row.id+'id': row.count+'count'"
      :detail="row"
      :cpDate="invoice.cp_date"
      @on-delete="onDeleteBlock('LESS_DISPATCH', i)"
    />

    <detention-edit 
      class="m-t-3"
      v-for="(row, i) in getByKey('PLUS_DETENTION')"
      :key="row.id ? row.id+'id': row.count+'count'"
      :detail="row"
      @on-delete="onDeleteBlock('PLUS_DETENTION', i)"
    />

    <charterers-edit 
      class="m-t-3"
      v-for="(row, i) in getByKey('PLUS_CHARTERERS_EXPENSES')"
      :key="row.id ? row.id+'id': row.count+'count'"
      :detail="row"
      @on-delete="onDeleteBlock('PLUS_CHARTERERS_EXPENSES', i)"
    />

    <owners-edit 
      class="m-t-3"
      v-for="(row, i) in getByKey('LESS_OWNERS_EXPENSES')"
      :key="row.id ? row.id+'id': row.count+'count'"
      :detail="row"
      @on-delete="onDeleteBlock('LESS_OWNERS_EXPENSES', i)"
    />
    <received-edit
      class="m-t-3"
      v-if="getByKey('LESS_RECEIVED').length && ($route.path.indexOf('soa')>=0 || $route.path.indexOf('additional_freight') >= 0)"
      :detail="getByKey('LESS_RECEIVED')[0]"
      :chartererId="getCharterer.owner ? getCharterer.owner.id : null"
    />
<!--    :chartererId="invoice.charterer"-->


    <modal-slots
      :showMod="isShowModalDelete"
      :isBorder="false"
      textForSecondButton="Delete"
      @apply="deleteBlockAfterModal"
      @close="isShowModalDelete = false"
    >
      <template v-slot:title>
        <h2 class="fw500">Chrts Invoices</h2>
      </template>

      <p class="m-b-4 p-x-6 fz14">Are you sure you want to delete this block?</p>
    </modal-slots>
  </div>
</template>

<script>
import oceanEdit from "@/components/vessels/invoice/oceanEdit";
import oceanIlowEdit from "@/components/vessels/invoice/oceanIlowEdit";
import deadEdit from "@/components/vessels/invoice/deadEdit";
import deadIlowEdit from "@/components/vessels/invoice/deadIlowEdit";
import extraEdit from "@/components/vessels/invoice/extraEdit";
import extraIlowEdit from "@/components/vessels/invoice/extraIlowEdit";
import thereofEdit from "@/components/vessels/invoice/thereofEdit";
import addressEdit from "@/components/vessels/invoice/addressEdit";
import brokerageEdit from "@/components/vessels/invoice/brokerageEdit";
import demurrageEdit from "@/components/vessels/invoice/demurrageEdit";
import dispatchEdit from "@/components/vessels/invoice/dispatchEdit";
import detentionEdit from "@/components/vessels/invoice/detentionEdit";
import charterersEdit from "@/components/vessels/invoice/charterersEdit";
import ownersEdit from "@/components/vessels/invoice/ownersEdit";
import receivedEdit from "@/components/vessels/invoice/receivedEdit";
import modalSlots from '@/components/modal/modal-slots'
import {mapState} from "vuex";

export default {
  components: {
    oceanEdit,
    oceanIlowEdit,
    deadEdit,
    deadIlowEdit,
    extraEdit,
    extraIlowEdit,
    thereofEdit,
    addressEdit,
    brokerageEdit,
    demurrageEdit,
    dispatchEdit,
    detentionEdit,
    charterersEdit,
    ownersEdit,
    receivedEdit,
    modalSlots
  },
  props: {
    invoice: Object
  },
  data() {
    return {
      isShowModalDelete: false,
      deletedBlock: null
    }
  },
  watch: {
    'getOceanFreight.percentage_amount': function() {this.countTotalFreight()},
    'getDeadFreight.percentage_amount': function() {this.countTotalFreight()},
    'getExtraFreight.percentage_amount': function() {this.countTotalFreight()},
  },
  computed: {
    ...mapState({
      contract: state => state.contract.activeContract
    }),
    getCharterer() {
      return this.contract.cargo_contracts.find(el => el.id == this.$route.params.subId)
    },
    getOceanFreight() {
      return this.invoice.blocks.find(el => el.block_type == 'ILOW')
    },
    getDeadFreight() {
      return this.invoice.blocks.find(el => el.block_type == 'DEAD_FREIGHT')
    },
    getExtraFreight() {
      return this.invoice.blocks.find(el => el.block_type == 'EXTRA_OCEAN_FREIGHT')
    },
  },
  methods: {
    changeMtAmount(amount) {
      console.log(amount)
      // if(this.$route.path.indexOf('vsoa')>= 0 || this.$route.path.indexOf('freight')>= 0 || this.$route.path.indexOf('additional_freight')>= 0) {
      //   if(this.invoice.ports.length) {
      //     this.invoice.ports[this.invoice.ports.length-1].total_quantity = amount
      //   }
      // }
    },
    //...mapMutations(['removeBlockInvoice']),
    getByKey(type) {
      return this.invoice.blocks.filter(el => el.block_type == type)
    },
    onDeleteBlock(type, i) {
      this.deletedBlock = {type, index: i}
      this.isShowModalDelete = true
    },
    deleteBlockAfterModal() {
      this.$emit('on-delete', this.deletedBlock)
      this.deletedBlock = null
    },

    countTotalFreight() {
      let result = 0
      result += +this.getOceanFreight?.percentage_amount
      if(this.getDeadFreight)
        result += +this.getDeadFreight?.percentage_amount
      if(this.getExtraFreight)
        result += +this.getExtraFreight?.percentage_amount
      this.getOceanFreight.total_amount = result
    }
  },
};
</script>

<style lang="scss">
</style>