import axios from 'axios'
import QueryString from "querystring";
// import {filter} from "@amcharts/amcharts4/.internal/core/utils/Iterator";
import Vue from 'vue';
import moment from 'moment';
Vue.prototype.$moment = moment;

// 'Authorization': 'token ' + ctx.getters.getToken,


export default {
    actions: {
        axiosGetPaymentBreakdown(ctx, data) {
            let ct = axios.CancelToken.source();
            ctx.commit("cancelPostPayment")
            ctx.commit("cancelTokenSource", ct);
            return axios.get(process.env.VUE_APP_URL+'/payment/cash/breakdown/', {
                params: {
                    limit: 25,
                    is_archive: data.is_archived,
                    exclude_date_before: data.before,
                    exclude_date_after: data.after
                },
                cancelToken: ct.token,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(response=> {
                ctx.commit("setAmountBalance", response.data.bank_balance)
                ctx.commit("setNextLinkPayment", response.data.payments.next)
                ctx.commit("setPaymentsList", response.data.payments.results);
            })
        },
        async axiosCreatePayment(ctx, data) {
                let filesId = []
            if(Array.isArray(data.file)) {
                if(data.file.length) {
                    for (let i = 0; i < data.file.length; i++) {
                        const file = data.file[i];
                        let formData = new FormData();
                        formData.append("file", file);
                        formData.append("name", file.name);

                        filesId.push(await ctx.dispatch('axiosAddFile', formData, {root: true}))
                    }
                }
            }

                if((""+data.execute_date).length === 0) {
                    data.execute_date = null;
                }
                if(data.execute_date !== null) {
                    data.execute_date = moment(data.execute_date).format("YYYY-MM-DD HH:mm")
                }
                if(Array.isArray(data.fileWithID) === true)
                    filesId = data.fileWithID.concat(filesId)
                return await axios.post(process.env.VUE_APP_URL+"/payment/", {
                    'receipt_files': data.receipt_files ? data.receipt_files : [],
                    "data_type": data.data_type,
                    "record_type": "PAYMENT",
                    "bank": data.bank,
                    "cash": data.cash,
                    'client_name': data.client_name,
                    'account_name': data.account_name ? data.account_name : '',
                    'additionally': data.additionally ? data.additionally : '',
                    "account": data.account,
                    "payment_type": data.payment_type,
                    "currency": data.currency,
                    "currency_amount": (data.currency_amount !== undefined && data.currency_amount !== null) ? (Math.round(data.currency_amount*100))/100 : 0,
                    "payment_amount": (data.payment_amount !== undefined && data.payment_amount !== null) ? (Math.round(data.payment_amount*100))/100 : 0,
                    "execute_date": data.execute_date,
                    "paid_date": (data.paid_date !== undefined && data.paid_date !== null && (""+data.paid_date).length !== 0) ? moment(data.paid_date).format("YYYY-MM-DD HH:mm") : null,
                    "statement_member": data.statement_member,
                    "cp_date": data.cp_date,
                    "status": data.status,
                    "comment": data.comment,
                    "is_archive": data.is_archive,
                    "client": data.client,
                    "real_account": data.real_account,
                    "ship": data.ship,
                    "settled_ship": data.settled_ship,
                    "comment_creator": data.comment_creator,
                    'files': filesId,
                    "ports": data.ports,
                    linked_payments: data.linked_payments,
                    linked_owners: data.linked_owners,
                    linked_invoices: data.linked_invoices
                }, {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                }).then(response=> {
                    return response;
                }).catch(error=> {
                    return error
                })

        },
        axiosGetPDF(ctx, data) {
            ctx.commit('showLoader')
          return axios.post(process.env.VUE_APP_URL+"/export/pdf/", {
              "html": data.html,
              "name": data.name,
          }, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
          }).then(response => {
              ctx.commit('hideLoader')
              return response;
          })
        },
        axiosGetXLSX(ctx, data) {
            return axios.post(process.env.VUE_APP_URL+"/export/excel/", {
                "sheet_title": data.sheet_title,
                "file_name": data.file_name,
                "data": data.data,
            }, {
                responseType: 'blob',
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                    // "Content-Type": "application/json",
                    // "Accept-Encoding": "gzip, deflate"
                }
            }).then(response => {
                return response;
            })
        },
        axiosChangeBankAndCashAccountForPayment(ctx, data) {
            // ctx.commit("showLoad");
            return axios.patch(process.env.VUE_APP_URL+"/payment/" + data.id, {
              "bank": data.bank,
              "cash": data.cash,
                currency: data.currency,
          }, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
          }).then(res=> {
              return res
            }).catch(()=> {
                return false
            })
            //     .then(()=> {
            //     ctx.commit("hideLoad");
            // }).catch(()=> {
            //     ctx.commit("hideLoad");
            //
            // })
        },
        axiosSetDatePaid(ctx, data) {
            // ctx.commit("showLoad");

            return axios.patch(process.env.VUE_APP_URL+"/payment/" + data.id, {
              'paid_date': (data.date !== null && (""+data.date).length !== 0) ? moment(data.date).format("YYYY-MM-DD HH:mm") : null,
            }, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
            }).then(()=> {
                // ctx.commit("hideLoad");
                return true;
            }).catch(()=> {
                // ctx.commit("hideLoad");
                return false
            });
        },
        async axiosPaymentAddFilesReceipt(ctx, data) {
            let notAddFile = false;
            let filesId = [];
            filesId = data.fileInPayment.map(a=> {return a.id});
            if(data.file.length) {
                for (let i = 0; i < data.file.length; i++) {
                    let file = data.file[i];
                    let formData = new FormData();
                    formData.append("file", file);
                    formData.append("name", file.name);
                    formData.append("file_type", (file.file_type ? file.file_type : 'FILE'));
                    if(file.parent_id !== undefined && file.parent_id !== null) {
                        notAddFile = true;
                        formData.append("parent_id", file.parent_id);
                        formData.append("parent_file", file.parent_id);
                    }
                    filesId.push(await ctx.dispatch('axiosAddFile', formData, {root: true}))
                }
            }
            if(!notAddFile) {
                await axios.patch(process.env.VUE_APP_URL+"/payment/" + data.id, {
                    'receipt_files': filesId
                }, {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                });
            } else {
                return null
            }

        },
        axiosPaymentRemoveFile(ctx, data) {
            return  axios.patch(process.env.VUE_APP_URL+"/payment/" + data.id, {
                'files': data.filesId
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            });
        },
        axiosPaymentRemoveFileReceipt(ctx, data) {
            return  axios.patch(process.env.VUE_APP_URL+"/payment/" + data.id, {
                'receipt_files': data.filesId
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            });
        },
        async axiosPaymentAddFiles(ctx, data) {
            let filesId = [];
            filesId = data.fileInPayment.map(a=> {return a.id});
            let notAddFile = false;

            if(data.file.length) {
                for (let i = 0; i < data.file.length; i++) {
                    let file = data.file[i];
                    let formData = new FormData();
                    formData.append("file", file);
                    formData.append("name", file.name);
                    formData.append("file_type", (file.file_type ? file.file_type : 'FILE'));
                    if(file.parent_id !== undefined && file.parent_id !== null) {
                        notAddFile = true;
                        formData.append("parent_id", file.parent_id);
                        formData.append("parent_file", file.parent_id);
                    }
                    filesId.push(await ctx.dispatch('axiosAddFile', formData, {root: true}))
                }
            }
            if(!notAddFile) {
                await axios.patch(process.env.VUE_APP_URL+"/payment/" + data.id, {
                    'files': filesId
                }, {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                });
            } else {
                return null
            }

        },
        async axiosChangePayment(ctx, data) {
            ctx.commit("showLoad");
            let filesId = [];
            filesId = data.fileInPayment.map(a=> {return a.id});
            if(data.file.length) {
                for (let i = 0; i < data.file.length; i++) {
                    const file = data.file[i];
                    let formData = new FormData();
                    formData.append("file", file);
                    formData.append("name", file.name);

                    filesId.push(await ctx.dispatch('axiosAddFile', formData, {root: true}))
                }
            }
            if(data.execute_date.length === 0) {
                data.execute_date = null;
            }
            if(data.execute_date !== null) {
                data.execute_date = moment(data.execute_date).format("YYYY-MM-DD HH:mm")
            }
            await axios.put(process.env.VUE_APP_URL+"/payment/" + data.id, {
                "data_type": data.data_type,
                "bank": data.bank,
                "cash": data.cash,
                "account": data.account,
                "payment_type": data.payment_type,
                "currency": data.currency,
                "currency_amount": data.currency_amount,
                "payment_amount": data.payment_amount,
                "execute_date": data.execute_date,
                "paid_date": (data.paid_date !== null && (""+data.paid_date).length !== 0) ? moment(data.paid_date).format("YYYY-MM-DD HH:mm") : null,
                "statement_member": data.statement_member,
                "cp_date": data.cp_date,
                "status": data.status,
                "comment": data.comment,
                "is_archive": data.is_archive,
                "client": data.client,
                "real_account": data.real_account,
                "ship": data.ship,
                "settled_ship": data.settled_ship,
                "comment_creator": data.comment_creator,
                'files': filesId,
                "ports": data.ports,
                linked_payments: data.linked_payments,
                linked_owners: data.linked_owners,
                linked_invoices: data.linked_invoices
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                ctx.commit("hideLoad");
            }).catch(()=> {
                ctx.commit("hideLoad");
            })
        },
        axiosChangePaymentLinked(ctx, data) {
            return axios.patch(process.env.VUE_APP_URL+'/payment/' + data.id, {
                linked_payments: data.linked_payments,
                linked_owners: data.linked_owners,
                linked_invoices: data.linked_invoices
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            })
        },
        axiosArchivePayment(ctx, data) {
            // ctx.commit("showLoad");
            ctx.state.paymentsList = ctx.state.paymentsList.filter(a=> a.id !== data.id)
            return axios.patch(process.env.VUE_APP_URL+'/payment/' + data.id, {
              'is_archive': data.is_archived,
          }, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
          }).then(()=> {
                // ctx.commit("hideLoad");
            }).catch(()=> {
                // ctx.commit("hideLoad");
            })
        },
        axiosChangeStatusPayment(ctx, data) {
            // ctx.commit("showLoad");

            return axios.patch(process.env.VUE_APP_URL+'/payment/' + data.id, {
                'status': data.status,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                // ctx.commit("hideLoad");
            }).catch(()=> {
                // ctx.commit("hideLoad");
            })
        },
        axiosGetPayment(ctx, data) {
            // return axios.get(process.env.VUE_APP_URL+"/payment/?execute_date_after="+ data.after +"&execute_date_before=" + data.before, {
            let link = process.env.VUE_APP_URL+"/payment/list/";
            let dopLink = "";
            console.log('data', data)
            if(data.filter !== null && data.filter !== undefined) {
                if(data.filter?.account_name?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= data.filter.account_name.map(a=> {return "&account=" + a});
                }
                if(data.filter?.bank?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= data.filter.bank.map(a=> {return "&bank=" + a});
                }
                if(data.filter?.cash?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= data.filter.cash.map(a=> {return "&cash=" + a});
                }
                if(data?.filter.counterparty?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= data.filter.counterparty.map(a=> {return "&client=" + a});
                }

                // if(data?.filter?.statement_member_empty) {
                //     if(dopLink.length === 0) {
                //         dopLink = "?"
                //     }
                //     dopLink+= '&statement_member_empty='+data?.filter?.statement_member_empty
                // }
                if(data?.filter?.currency?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= data.filter.currency.map(a=> {return "&currency=" + a});
                }
                if(data?.filter?.statement_member) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= '&statement_member='+data?.filter?.statement_member
                }
                if(data?.filter?.client__charter_name) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= data?.filter?.client__charter_name.map(a=> {return "&search=" + a});

                }
                let status = [];
                if(data.filter.paymentCheckbox?.length) {
                    data.filter.paymentCheckbox.map(a=> {status.push(a)});
                }
                if(data.filter.incomeCheckbox?.length) {
                    data.filter.incomeCheckbox.map(a=> {status.push(a)});
                }
                status = Array.from(new Set(status));
                if(status?.length) {
                    if(data.filter?.paymentCheckbox?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= "&payment_type=expense";
                    }
                    if(data.filter?.incomeCheckbox?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= "&payment_type=income";
                    }
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= status.map(a=> {return "&status=" + a.toLowerCase()});
                }
                if(data.filter?.paymentFrom?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= "&currency_amount_min=" + data.filter.paymentFrom
                }
                if(data.filter?.paymentTo?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= "&currency_amount_max=" + data.filter.paymentTo
                }

            }
            if(data.ship !== null && data.ship !== undefined){
                if(dopLink.length === 0) {
                    dopLink = "?"
                }
                dopLink+= "&ship=" + data.ship;
            }
            if(data) {
                if(data?.files_exists !== null && data?.files_exists !== undefined) {
                    dopLink+= "&files_exists=" + data.files_exists;
                }
            }
            if(dopLink.length) {
                link+=dopLink.replaceAll(",", "");
            }



            let ct = axios.CancelToken.source();
            ctx.commit("cancelPostPayment")
            ctx.commit("cancelTokenSource", ct);
            // if(data.after === null) {
            //     data.after = "";
            // }
            // if(data.before === null) {
            //     data.before = "";
            // }
            let is_archived = false;
            if(data.is_archived !== null && data.is_archived !== undefined) {
                is_archived = data.is_archived;
            }
            // console.log("data")
            // console.log(data)
            let fs = [];
            if(data.filter !== null && data.filter !== undefined) {
                if(data.filter.fs !== null && data.filter.fs) {
                    fs = data.filter.fs
                }
            }
            // if((""+data.after).length !== 0 && data.after !== null && data.after !== undefined) {
            //     data.after = moment(new Date(data.after)).add(-1, 'day')
            //
            // }
            // if((""+data.before).length !== 0 && data.before !== null && data.before !== undefined) {
            //     console.log("data before")
            //     console.log(data.after)
            //     data.before = moment(new Date(data.before)).add(-1, 'day')
            // }
            let limit = 25;
            if(data.limit !== null && data.limit !== undefined) {
                limit = data.limit
            }
            // console.log(7)
            if(data.noLoad !== true) {
                ctx.commit("showLoad")
            }
            console.log(data.sortById)
            return axios.get(link, {
                params: {
                    limit: limit,
                    ordering: ((data.ordering !== null && data.ordering !== undefined && (""+data.ordering).length !== 0) ? data.ordering : "-execute_date")+ (data.sortById !== false ? ',id' : ''),
                    data_type: data.type,
                    record_type: ["PAYMENT"],
                    is_archive: is_archived,
                    execute_date_before: data.after,
                    execute_date_after: data.before,
                    financial_statement: fs,
                },
                cancelToken: ct.token,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(response => {
                if(data.noLoad !== true) {
                    ctx.commit("hideLoad")
                }
                ctx.commit("setNextLinkPayment", response.data.next)
                ctx.commit("setPaymentsList", response.data.results);
                return null
            }).catch(() => {
                return null
            })
        },

        axiosGetPaymentForVessel(ctx, data) {
            console.log('axiosGetPaymentForVessel')
            // return axios.get(process.env.VUE_APP_URL+"/payment/?execute_date_after="+ data.after +"&execute_date_before=" + data.before, {
            // console.log(data);
            let link = process.env.VUE_APP_URL+"/payment/list/";
            let dopLink = "";
            // console.log(1)
            // console.log(data)
            if(data.filter !== null && data.filter !== undefined) {
                if(data.filter?.account_name?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= data.filter.account_name.map(a=> {return "&account=" + a});
                }
                // console.log(2)
                if(data.filter?.bank?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= data.filter.bank.map(a=> {return "&bank=" + a});
                }
                if(data.filter?.cash?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= data.filter.bank.map(a=> {return "&cash=" + a});
                }
                // console.log(3)
                if(data?.filter.counterparty?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= data.filter.counterparty.map(a=> {return "&client=" + a});
                }
                // console.log(4)
                if(data?.filter?.currency?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= data.filter.currency.map(a=> {return "&currency=" + a});
                }
                // console.log(5)
                let status = [];
                data.filter.paymentCheckbox.map(a=> {status.push(a)});
                data.filter.incomeCheckbox.map(a=> {status.push(a)});
                status = Array.from(new Set(status));
                if(status?.length) {
                    if(data.filter?.paymentCheckbox?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= "&payment_type=expense";
                    }
                    if(data.filter?.incomeCheckbox?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= "&payment_type=income";
                    }
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= status.map(a=> {return "&status=" + a.toLowerCase()});
                }
                if(data.filter?.paymentFrom?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= "&currency_amount_min=" + data.filter.paymentFrom
                }
                if(data.filter?.paymentTo?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= "&currency_amount_max=" + data.filter.paymentTo
                }

            }
            if(data.cash_flow_ship !== null && data.cash_flow_ship !== undefined){
                if(dopLink.length === 0) {
                    dopLink = "?"
                }
                dopLink+= "&cash_flow_ship=" + data.cash_flow_ship;
            }
            if(data.ship !== null && data.ship !== undefined){
                if(dopLink.length === 0) {
                    dopLink = "?"
                }
                dopLink+= "&ship=" + data.ship;
            }
            if(data.settled_ship !== null && data.settled_ship !== undefined){
                if(dopLink.length === 0) {
                    dopLink = "?"
                }
                dopLink+= "&settled_ship=" + data.settled_ship;
            }
            if(dopLink.length) {
                link+=dopLink.replaceAll(",", "");
            }

            let ct = axios.CancelToken.source();
            // ctx.commit("cancelPostPayment")
            ctx.commit("cancelTokenSource", ct);
            // if(data.after === null) {
            //     data.after = "";
            // }
            // if(data.before === null) {
            //     data.before = "";
            // }
            let is_archived = false;
            if(data.is_archived !== null && data.is_archived !== undefined) {
                is_archived = data.is_archived;
            }
            // console.log("data")
            // console.log(data)
            let fs = [];
            if(data.filter !== null && data.filter !== undefined) {
                if(data.filter.fs !== null && data.filter.fs) {
                    fs = data.filter.fs
                }
            }
            // if((""+data.after).length !== 0 && data.after !== null && data.after !== undefined) {
            //     data.after = moment(new Date(data.after)).add(-1, 'day')
            //
            // }
            // if((""+data.before).length !== 0 && data.before !== null && data.before !== undefined) {
            //     console.log("data before")
            //     console.log(data.after)
            //     data.before = moment(new Date(data.before)).add(-1, 'day')
            // }
            let limit = 20;
            if(data.limit !== null && data.limit !== undefined) {
                limit = data.limit
            }
            // console.log(7)
            return axios.get(link, {
                params: {
                    limit: limit,
                    ordering: ((data.ordering !== null && data.ordering !== undefined && (""+data.ordering).length !== 0) ? data.ordering : "-execute_date") + ',id',
                    data_type: data.type,
                    record_type: ["PAYMENT"],
                    is_archive: is_archived,
                    execute_date_before: data.after,
                    execute_date_after: data.before,
                    financial_statement: fs,
                },
                cancelToken: ct.token,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(response => {
                console.log('response', response.data.results)
                ctx.commit("setPaymentsList", response.data.results);
                return response.data.next
            })
        },

        axiosGetPaymentArchive(ctx, data) {
            // alert(data.client)
            // ctx.commit("showLoad");
            let link = process.env.VUE_APP_URL+"/payment/list/";
            let dopLink = "";
            if(data.client !== undefined && data.client !== null) {
                dopLink = "?client=" + data.client
            }
            if(data.limit !== undefined && data.limit !== null) {
                if(dopLink.length === 0) {
                    dopLink = "?"
                }
                dopLink += "&limit="+ data.limit;
            }
            if(data.offset !== undefined && data.offset !== null) {
                if(dopLink.length === 0) {
                    dopLink = "?"
                }
                dopLink += "&offset="+ data.offset;
            }
            let status = [];
            let is_archived = true;
            if(data.filter.is_archived === false) {
                is_archived = false;
            }
            data.filter.paymentCheckbox.map(a=> {status.push(a)});
            data.filter.incomeCheckbox.map(a=> {status.push(a)});
            status = Array.from(new Set(status));
            if(status.length) {
                if(data.filter.paymentCheckbox.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= "&payment_type=expense";
                }
                if(data.filter.incomeCheckbox.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= "&payment_type=income";
                }
                if(dopLink.length === 0) {
                    dopLink = "?"
                }
                dopLink+= status.map(a=> {return "&status=" + a.toLowerCase()});
            }
            if(data.filter.searchText.length !== 0) {
                if(dopLink.length === 0) {
                    dopLink = "?"
                }
                dopLink += "&search="+ data.filter.searchText;
            }
            if(data.filter.sortText.length !== 0) {
                if(dopLink.length === 0) {
                    dopLink = '?'
                }
                dopLink+= "&ordering=" + data.filter.sortText;
            }
            if(dopLink.length) {
                link+=dopLink.replaceAll(",", "");
            }
            let ct = axios.CancelToken.source();
            ctx.commit("cancelTokenSource", ct);
            ctx.commit("showLoad")
            return axios.get(link, {
                params: {
                    record_type: ["PAYMENT"],
                    execute_date_before: data.after,
                    execute_date_after: data.before,
                    is_archive: is_archived,
                },
                cancelToken: ct.token,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(response => {
                ctx.getters.nextPayment = response.data.next;
                ctx.commit("setPaymentsList", response.data.results);
                ctx.commit("hideLoad");
                return response.data.next;
            }).catch(()=> {
                ctx.commit("hideLoad");

            })
        },
        axiosGetPaymentNext(ctx) {
            if(ctx.getters.nextPayment !== null && ctx.getters.nextPayment.length !== 0) {
                return axios.get(ctx.getters.nextPayment, {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                }).then(response=> {
                    ctx.commit("pushPaymentsList", response.data.results);
                    ctx.getters.nextPayment = response.data.next;
                    return response.data.next;
                }).catch(() => {
                    // console.log(error);
                })
            } else {
                return false;
            }
        },
        axiosGetFullInfoForDetailPayment(ctx, id) {
            return axios.get(process.env.VUE_APP_URL+"/payment/" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("setInfoForPaymentDetail", response);
            }).catch(() => {
                // console.log(error);
            })
        },

        axiosGetPaymentCash(ctx) {
            return axios.get(process.env.VUE_APP_URL+"/payment/cash/?limit=999999999", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("setPaymentsCash", response.data.results);
            })
        },
        axiosGetPaymentAccount(ctx) {
            return axios.get(process.env.VUE_APP_URL+"/payment/account/?limit=999999999", {
                params: {
                    is_archive: false,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("setPaymentsAccount", response.data.results);
            })
        },
        async axiosGetCurrency(ctx, data) {
            return await axios.get(process.env.VUE_APP_URL+"/currency/", {
                params: {
                    'ordering':'-created_at',
                    "currency": data.currency,
                    "created_at": data.created_at,
                    'limit': 1,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            })
        },
        async axiosGetPaymentCashFlow(ctx, data) {
            // console.log("date1")
            // console.log(data);
            await axios.get(process.env.VUE_APP_URL+"/payment/cash/flow/", {
                params: {
                    date_range_after: data.date_after,
                    date_range_before: data.date_before,
                    group_by: data.group_by === "week" ? "day" : data.group_by,
                    // group_by: "day",

                    limit: 9999,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("setCashFlow", response.data);
            })
        },
        async axiosGetCashFlowDiagramDouble(ctx, data) {
            // console.log("date1")
            // console.log(data);
            await axios.get(process.env.VUE_APP_URL+"/payment/cash/flow/graph/new/", {
                params: {
                    date_range_after: data.date_after,
                    date_range_before: data.date_before,
                    group_by: data.group_by === "week" ? "day" : data.group_by,
                    // group_by: "day",
                    limit: 9999,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("setCashFlowDiagramDouble", response.data);
            })
        },
        async axiosGetCashFlowDiagram(ctx, data) {
            // console.log("date1")
            // console.log(data);
            await axios.get(process.env.VUE_APP_URL+"/payment/cash/flow/graph", {
                params: {
                    date_range_after: data.date_after,
                    date_range_before: data.date_before,
                    group_by: data.group_by === "week" ? "day" : data.group_by,
                    // group_by: "day",
                    limit: 9999,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("setCashFlowDiagram", response.data);
            })
        },
        axiosAddNoteToPayment(ctx) {
            return axios.post(process.env.VUE_APP_URL+"/payment/",{
                "record_type": "NOTE",
                "payment_type": "expense"
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(res=> {
                return res;
            }).catch(()=> {
                return false;
            })
        },
        axiosGetNoteToPayment(ctx) {
            return axios.get(process.env.VUE_APP_URL+"/payment/",{
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: {
                    "record_type": ["NOTE"],
                },
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(res=> {
                return res;
            }).catch(()=> {
                return false;
            })
        },
        axiosChangeNote(ctx, data) {
            return axios.patch(process.env.VUE_APP_URL+"/payment/" + data.id, data.data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(()=> {
                return true;
            }).catch(()=> {
                return false;
            })
        },
        axiosDeleteNote(ctx, id) {
            return axios.delete(process.env.VUE_APP_URL+"/payment/" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            })
        },
        axiosPaymentNextLink(ctx, breakdown) {
            return axios.get(ctx.getters.getNextLinkPayment, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(response=> {
                ctx.commit("setNextLinkPayment", response.data.next)
                ctx.commit("pushNextPayment", breakdown === true ? response.data.payments.results : response.data.results);
            })
        },
        async axiosPaymentNextLinkPdf(ctx, breakdown) {
            // console.log(ctx, breakdown, , ctx.getters.getPaymentListOriginal.length)
            let limit = ctx.getters.getPaymentListOriginal.length < 800 ? (800-ctx.getters.getPaymentListOriginal.length) : 25
            return await axios.get(ctx.getters.getNextLinkPayment.replace('limit=25', 'limit='+limit), {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(response=> {
                ctx.commit("setNextLinkPayment", response.data.next)
                ctx.commit("pushNextPayment", breakdown === true ? response.data.payments.results : response.data.results);
            })
        },
        axiosPaymentNextLinkForVessel(ctx, link) {
            if(link !== null && link !== undefined && (""+link).length !== 0 && link !== false) {
                return axios.get(link, {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    },
                }).then(response=> {
                    ctx.commit("pushNextPayment", response.data.results);
                    return response.data.next
                })
            }
            return false
        },
        async axiosPaymentNextLinkForVesselPdf(ctx, link) {
            // if(link !== null && link !== undefined && (""+link).length !== 0 && link !== false) {
            let limit = ctx.getters.getPaymentListOriginal.length < 800 ? (800-ctx.getters.getPaymentListOriginal.length) : 25
            return await axios.get(link.replace('limit=25', 'limit='+limit), {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    },
                }).then(response=> {
                    ctx.commit("pushNextPayment", response.data.results);
                    return response.data.next
                })
            // }
            // return false
        },
        async axiosGetPaymentsForCounterparty(ctx, data) {
            // alert(data.client)
            // ctx.commit("showLoad");
            data["limit"] = 25
            let link = process.env.VUE_APP_URL+"/payment/list/";
            let dopLink = "";
            if(data.counterparty !== undefined && data.counterparty !== null) {
                dopLink = "?counterparty=" + data.counterparty
            }
            if(data.limit !== undefined && data.limit !== null) {
                if(dopLink.length === 0) {
                    dopLink = "?"
                }
                dopLink += "&limit="+ data.limit;
            }
            // if(data.offset !== undefined && data.offset !== null) {
            //     if(dopLink.length === 0) {
            //         dopLink = "?"
            //     }
            //     dopLink += "&offset="+ data.offset;
            // }
            let status = [];
            let is_archived = true;
            if(data.filter.is_archived === false) {
                is_archived = false;
            }
            data.filter.paymentCheckbox.map(a=> {status.push(a)});
            data.filter.incomeCheckbox.map(a=> {status.push(a)});
            status = Array.from(new Set(status));
            if(status.length) {
                if(data.filter.paymentCheckbox.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= "&payment_type=expense";
                }
                if(data.filter.incomeCheckbox.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= "&payment_type=income";
                }
                if(dopLink.length === 0) {
                    dopLink = "?"
                }
                dopLink+= status.map(a=> {return "&status=" + a.toLowerCase()});
            }
            if(data.filter.searchText.length !== 0) {
                if(dopLink.length === 0) {
                    dopLink = "?"
                }
                dopLink += "&search="+ data.filter.searchText;
            }
            // if(data.ordering.length !== 0) {
            //     if(dopLink.length === 0) {
            //         dopLink = '?'
            //     }
            //     dopLink+= "&ordering=" + data.ordering;
            // }
            if(dopLink.length) {
                link+=dopLink.replaceAll(",", "");
            }
            let ct = axios.CancelToken.source();
            ctx.commit("cancelTokenSource", ct);
            ctx.commit("showLoad")
            await axios.get(link.replace('limit=10', 'limit=13'), {
                params: {
                    "data_type": data.data_type,
                    record_type: ["PAYMENT"],
                    execute_date_before: data.after,
                    execute_date_after: data.before,
                    is_archive: is_archived,
                    ordering: data.ordering
                },
                cancelToken: ct.token,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(response => {
                ctx.state.totalPaymentsCounterparties = response.data.count
                ctx.commit("setNextLinkPaymentForCounterparty", response.data.next)
                ctx.commit("setPaymentsList", response.data.results);
            })
            // let ct1 = axios.CancelToken.source();
            // ctx.commit("cancelTokenSource1", ct1);
            // link = link.replaceAll('client=', 'real_account=')
            // await axios.get(link.replace('limit=10', 'limit=12'), {
            //     params: {
            //         "data_type": data.data_type,
            //         record_type: ["PAYMENT"],
            //         execute_date_before: data.after,
            //         execute_date_after: data.before,
            //         is_archive: is_archived,
            //     },
            //     cancelToken: ct1.token,
            //     headers: {
            //         'Authorization': 'token ' + ctx.getters.getToken,
            //     },
            //     paramsSerializer: function (params) {
            //         return QueryString.stringify(params)
            //     },
            // }).then(response => {
            //     ctx.commit('setNextLinkPaymentForCounterparty1', response.data.next)
            //     response.data.results.reduce((acc, n) => {
            //         const i = acc.findIndex(m => m.id === n.id);
            //         if (!~i || !acc[i].checked) {
            //             acc.push(n);
            //             if (~i) {
            //                 acc.splice(i, 1);
            //             }
            //         }
            //         return acc;
            //     }, [])
            //     ctx.commit("pushNextPayment", response.data.results);
            // })
            ctx.commit("hideLoad");
        },
        async axiosNextPaymentForCounterparties(ctx, data) {
            // ctx.commit('showLoad')
            if(data.link1 !== null && data.link1 !== undefined && (''+data.link1).length !== 0) {
                await axios.get(data.link1, {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    },
                }).then(response=> {
                    ctx.commit('setNextLinkPaymentForCounterparty', response.data.next)
                    ctx.commit("pushNextPayment", response.data.results);
                })
            }
            if(data.link2 !== null && data.link2 !== undefined && (''+data.link2).length !== 0) {
                await axios.get(data.link2, {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    },
                }).then(response=> {
                    ctx.commit('setNextLinkPaymentForCounterparty1', response.data.next)
                    ctx.commit("pushNextPayment", response.data.results);
                })
            }
            // ctx.commit('hideLoad')
        },
        getPaymentBalanceCounterparty(ctx, data) {
            return axios.get(process.env.VUE_APP_URL+"/payment/amount", {
                params: {
                    client: data.client,
                    is_archive: data.is_archive
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                return res.data
            })
        },
        axiosAddNewPayment(ctx, data) {
            return axios.post(process.env.VUE_APP_URL+"/payment/", {
                "data_type": data.data_type,
                "record_type": "PAYMENT",
                "bank": null,
                "cash": null,
                "account": null,
                "payment_type": 'expense',
                "currency": 'USD',
                "currency_amount": 0,
                "payment_amount": 0,
                "execute_date": null,
                "paid_date": null,
                "statement_member": '',
                "cp_date": (data?.cp_date !== undefined && data?.cp_date !== null && (''+data?.cp_date).length !== 0) ? data.cp_date : '',
                "status": 'not_paid',
                "comment": '',
                "is_archive": false,
                "client": null,
                "real_account": null,
                "ship": (data?.ship !== undefined && data?.ship !== null && (''+data?.ship).length !== 0) ? data.ship : null,
                "settled_ship": null,
                "comment_creator": null,
                'files': [],
                "ports": null,
                linked_payments: [],
                linked_owners: [],
                linked_invoices: []
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                function checkNum(value) {
                    if(!isNaN(value)) {
                        if(value) {
                            value = parseFloat(value)
                            value = value.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replaceAll(',','')
                            if((''+value).indexOf('.') === -1) {
                                value+='.00'
                            }
                        }
                    }
                    return value !== 'NaN' && value !== 'NaN.00' ? value : '0.00'
                }
                let a = response.data
                if(a.execute_date !== null && (''+a.execute_date).length !== 0) {
                    a.execute_date = moment(a.execute_date, 'YYYY-MM-DD').format('DD.MM.YYYY')
                }
                a['showLoader'] = false
                a['payment_amount_expanse'] = '0.00';
                a['payment_amount_income'] = '0.00';
                a.currency_amount = checkNum(a.currency_amount)
                a.payment_amount = checkNum(a.payment_amount)
                if(a.payment_type === 'income') {
                    a['payment_amount_income'] = (a.payment_amount !== 0 && a.payment_amount !== '0.00') ? a.payment_amount : '0.00'
                } else {
                    a['payment_amount_expanse'] = (a.payment_amount !== 0 && a.payment_amount !== '0.00') ? a.payment_amount : '0.00'
                }
                // ctx.state.paymentsList.push(a)
                    // = [a].concat(ctx.state.paymentsList);
                ctx.state.dataNewPayment = a;
                return a;
            }).catch(()=> {
                return null
            })
        },
        axiosGetGrossNet(ctx, data) {
            if(data) {
                let link = process.env.VUE_APP_URL+"/payment/grossnet/";
                let dopLink = "";
                if(data.counterparty !== undefined && data.counterparty !== null) {
                    dopLink = "?counterparty=" + data.counterparty
                }
                if(data && data.filter !== null && data.filter !== undefined) {
                    if(data.filter?.account_name?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= data.filter.account_name.map(a=> {return "&account=" + a});
                    }
                    if(data.filter?.bank?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= data.filter.bank.map(a=> {return "&bank=" + a});
                    }
                    if(data.filter?.cash?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= data.filter.bank.map(a=> {return "&cash=" + a});
                    }
                    if(data?.filter.counterparty?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= data.filter.counterparty.map(a=> {return "&client=" + a});
                    }
                    if(data?.filter?.currency?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= data.filter.currency.map(a=> {return "&currency=" + a});
                    }
                    let status = [];
                    if(data.filter.paymentCheckbox?.length) {
                        data.filter.paymentCheckbox.map(a=> {status.push(a)});
                    }
                    if(data.filter.incomeCheckbox?.length) {
                        data.filter.incomeCheckbox.map(a=> {status.push(a)});
                    }
                    status = Array.from(new Set(status));
                    if(status?.length) {
                        if(data.filter?.paymentCheckbox?.length) {
                            if(dopLink.length === 0) {
                                dopLink = "?"
                            }
                            dopLink+= "&payment_type=expense";
                        }
                        if(data.filter?.incomeCheckbox?.length) {
                            if(dopLink.length === 0) {
                                dopLink = "?"
                            }
                            dopLink+= "&payment_type=income";
                        }
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= status.map(a=> {return "&status=" + a.toLowerCase()});
                    }
                    if(data.filter?.paymentFrom?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= "&currency_amount_min=" + data.filter.paymentFrom
                    }
                    if(data.filter?.paymentTo?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= "&currency_amount_max=" + data.filter.paymentTo
                    }

                }
                if(data.ship !== null && data.ship !== undefined){
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= "&ship=" + data.ship;
                }
                if(data) {
                    if(data?.files_exists !== null && data?.files_exists !== undefined) {
                        dopLink+= "&files_exists=" + data.files_exists;
                    }
                }
                if(data.cash_flow_ship !== null && data.cash_flow_ship !== undefined){
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= "&cash_flow_ship=" + data.cash_flow_ship;
                }

                if(dopLink.length) {
                    link+=dopLink.replaceAll(",", "");
                }
                let is_archived = false;
                if(data.is_archived !== null && data.is_archived !== undefined) {
                    is_archived = data.is_archived;
                }
                let fs = [];
                if(data.filter !== null && data.filter !== undefined) {
                    if(data.filter.fs !== null && data.filter.fs) {
                        fs = data.filter.fs
                    }
                }
                return axios.get(link, {
                    params: {
                        data_type: data.type ? data.type : data.data_type ? data.data_type : '',
                        record_type: ["PAYMENT"],
                        is_archive: is_archived,
                        financial_statement: fs,
                    },
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    },
                    paramsSerializer: function (params) {
                        return QueryString.stringify(params)
                    },
                }).then(res=> {
                    ctx.commit('setProfit', res.data.gross)
                    ctx.commit('setNetProfit', res.data.net)
                })
            }

        },
        axiosGetTotalCashFlow(ctx, data) {
            if(data) {
                let link = process.env.VUE_APP_URL+"/payment/amount/currency/";
                let dopLink = "";
                if(data.counterparty !== undefined && data.counterparty !== null) {
                    dopLink = "?counterparty=" + data.counterparty
                }
                if(data && data.filter !== null && data.filter !== undefined) {
                    if(data.filter?.account_name?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= data.filter.account_name.map(a=> {return "&account=" + a});
                    }
                    if(data.filter?.bank?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= data.filter.bank.map(a=> {return "&bank=" + a});
                    }
                    if(data.filter?.cash?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= data.filter.bank.map(a=> {return "&cash=" + a});
                    }
                    if(data?.filter.counterparty?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= data.filter.counterparty.map(a=> {return "&client=" + a});
                    }
                    if(data?.filter?.currency?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= data.filter.currency.map(a=> {return "&currency=" + a});
                    }
                    let status = [];
                    if(data.filter.paymentCheckbox?.length) {
                        data.filter.paymentCheckbox.map(a=> {status.push(a)});
                    }
                    if(data.filter.incomeCheckbox?.length) {
                        data.filter.incomeCheckbox.map(a=> {status.push(a)});
                    }
                    status = Array.from(new Set(status));
                    if(status?.length) {
                        if(data.filter?.paymentCheckbox?.length) {
                            if(dopLink.length === 0) {
                                dopLink = "?"
                            }
                            dopLink+= "&payment_type=expense";
                        }
                        if(data.filter?.incomeCheckbox?.length) {
                            if(dopLink.length === 0) {
                                dopLink = "?"
                            }
                            dopLink+= "&payment_type=income";
                        }
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= status.map(a=> {return "&status=" + a.toLowerCase()});
                    }
                    if(data.filter?.paymentFrom?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= "&currency_amount_min=" + data.filter.paymentFrom
                    }
                    if(data.filter?.paymentTo?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= "&currency_amount_max=" + data.filter.paymentTo
                    }

                }
                if(data.ship !== null && data.ship !== undefined){
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= "&ship=" + data.ship;
                }
                if(data) {
                    if(data?.files_exists !== null && data?.files_exists !== undefined) {
                        dopLink+= "&files_exists=" + data.files_exists;
                    }
                }
                if(data.cash_flow_ship !== null && data.cash_flow_ship !== undefined){
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= "&cash_flow_ship=" + data.cash_flow_ship;
                }

                if(dopLink.length) {
                    link+=dopLink.replaceAll(",", "");
                }
                let is_archived = false;
                if(data.is_archived !== null && data.is_archived !== undefined) {
                    is_archived = data.is_archived;
                }
                let fs = [];
                if(data.filter !== null && data.filter !== undefined) {
                    if(data.filter.fs !== null && data.filter.fs) {
                        fs = data.filter.fs
                    }
                }
                return axios.get(link, {
                    params: {
                        data_type: data.type ? data.type : data.data_type ? data.data_type : '',
                        record_type: ["PAYMENT"],
                        is_archive: is_archived,
                        financial_statement: fs,
                    },
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    },
                    paramsSerializer: function (params) {
                        return QueryString.stringify(params)
                    },
                }).then(res=> {
                    ctx.commit('setTotalCashFlow', res.data)
                    // ctx.commit('setNetProfit', res.data.net)
                })
            }

        },


    },
    mutations: {
        setTotalCashFlow(state, data) {
            state.totalCashFlow = data;
        },
        setDateForViewPayments(state, data) {
            state.dateForViewPayments = data
        },
        setNext(state, data) {
            state.next = data;
        },
        setInfoForPaymentDetail(state, data) {
            function checkNum(value) {
                if(!isNaN(value)) {
                    value = parseFloat(value)
                    value = value.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replaceAll(',','')
                    if((''+value).indexOf('.') === -1) {
                        value+='.00'
                    }
                }
                return value
            }
          state.infoDetailPayment = data.data;
            if( state.infoDetailPayment) {
                state.infoDetailPayment.payment_amount = checkNum(state.infoDetailPayment.payment_amount)
                state.infoDetailPayment.currency_amount = checkNum(state.infoDetailPayment.currency_amount)
            }
        },
        cancelTokenSource(state, data) {
            state.cancelTokenSource = data;
        },
        cancelTokenSource1(state, data) {
            state.cancelTokenSource1 = data;
        },
        cancelPostPayment(state) {
            if(state.cancelTokenSource !== null) {
                state.cancelTokenSource.cancel();
            }
        },
        cancelPostPayment1(state) {
            if(state.cancelTokenSource1 !== null) {
                state.cancelTokenSource1.cancel();
            }
        },
        setPaymentsList(state, data) {
            function checkNum(value) {
                if(!isNaN(value)) {
                        value = parseFloat(value)
                        value = value.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replaceAll(',','')
                        if((''+value).indexOf('.') === -1) {
                            value+='.00'
                        }
                }
                return value !== 'NaN' && value !== 'NaN.00' ? value : '0.00'
            }
            data = data.map(a=> {
                if(a.execute_date !== null && (''+a.execute_date).length !== 0) {
                    a.execute_date = moment(a.execute_date, 'YYYY-MM-DD').format('DD.MM.YYYY')
                }
                a['showLoader'] = false
                a['payment_amount_expanse'] = '0.00';
                a['payment_amount_income'] = '0.00';
                // a['is_focused'] = false;
                // a['is_selected'] = false;
                a.currency_amount = checkNum(a.currency_amount)
                a.payment_amount = checkNum(a.payment_amount)
                if(a.payment_type === 'income') {
                    a['payment_amount_income'] = (a.payment_amount !== 0 && a.payment_amount !== '0.00') ? a.payment_amount : '0.00'
                } else {
                    a['payment_amount_expanse'] = (a.payment_amount !== 0 && a.payment_amount !== '0.00') ? a.payment_amount : '0.00'
                }
                return a;
            })
            // console.log(data)

            state.paymentsList = data;

        },
        pushNextPayment(state, data) {
            function checkNum(value) {
                if(!isNaN(value)) {
                    if(value) {
                        value = parseFloat(value)
                        value = value.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replaceAll(',','')
                        if((''+value).indexOf('.') === -1) {
                            value+='.00'
                        }
                    }
                }
                return value !== 'NaN' && value !== 'NaN.00' ? value : '0.00'
            }
            data = data.map(a=> {
                if(a.execute_date !== null && (''+a.execute_date).length !== 0) {
                    a.execute_date = moment(a.execute_date, 'YYYY-MM-DD').format('DD.MM.YYYY')
                }
                a['showLoader'] = false
                a['payment_amount_expanse'] = '0.00';
                a['payment_amount_income'] = '0.00';
                a.currency_amount = checkNum(a.currency_amount)
                a.payment_amount = checkNum(a.payment_amount)
                if(a.payment_type === 'income') {
                    a['payment_amount_income'] = (a.payment_amount !== 0 && a.payment_amount !== '0.00') ? a.payment_amount : '0.00'
                } else {
                    a['payment_amount_expanse'] = (a.payment_amount !== 0 && a.payment_amount !== '0.00') ? a.payment_amount : '0.00'
                }
                return a
            })
            let paymentsList = JSON.parse(JSON.stringify(state.paymentsList)).concat(data)

            // console.log(state.paymentsList.length)
            paymentsList = paymentsList.reduce((acc, n) => {
                const i = acc.findIndex(m => m.id === n.id);
                if (!~i || !acc[i].checked) {
                    acc.push(n);
                    if (~i) {
                        acc.splice(i, 1);
                    }
                }
                return acc;
            }, [])
            state.pushDataPaymentList = data;
            state.paymentsList = paymentsList
            // console.log(state.paymentsList.length)
        },
        pushPaymentsList(state, data) {
            data.map(a=> {
                state.paymentsList.push(a);
            })
        },
        setPaymentsCash(state, data) {
            state.paymentCash = data;
        },
        setPaymentsAccount(state, data) {
            state.paymentsAccount = data;
        },
        setCashFlow(state, data) {
            state.paymentCashFlow = data.filter(d=> d.date !== null);
        },
        setCashFlowDiagram(state, data) {
            state.CashFlowDiagram = data.filter(d=> d.date !== null);
        },
        setCashFlowDiagramDouble(state, data) {
            state.CashFlowDiagramDouble = data.filter(d=> d.date !== null);
        },
        setDataPaymentModal(state, data) {
            state.dataPaymentModal = data;
        },
        setNextLinkPayment(state, link) {
            state.linkNextPayment = link;
        },
        setPushDataCashFlowList(state, data) {
            state.pushDataCashFlowList = data;
        },
        setNextLinkPaymentForCounterparty(state, data) {
            state.NextLinkPaymentForCounterparty = data;
        },
        setNextLinkPaymentForCounterparty1(state, data) {
            state.NextLinkPaymentForCounterparty1 = data;
        },
        setFilterDataPaymentAxios(state, data) {
            state.filterDataPaymentAxios = data;
        },
        setProfit(state, data) {
            state.amountProfit = data;
        },
        setNetProfit(state, data) {
            state.amountNetProfit = data;
        },
        setAmountBalance(state, data) {
            state.AmountBalance = data;
        },
        setTotalPaymentsCounterparties(state, data) {
            state.totalPaymentsCounterparties = data;
        },
        setEventOpenDetailInvoice(state, data) {
            state.eventOpenDetailInvoice = data;
        }
    },
    state: {
        eventOpenDetailInvoice: null,
        totalPaymentsCounterparties: 0,
        pushDataPaymentList: [],
        dataNewPayment: null,
        NextLinkPaymentForCounterparty: null,
        NextLinkPaymentForCounterparty1: null,
        pushDataCashFlowList: {
            counterparty: [],
            account: [],
        },
        linkNextPayment: null,
        dateForViewPayments: null,
        next: false,
        paymentCashFlow: [],
        CashFlowDiagramDouble: [],
        CashFlowDiagram: [],
        infoDetailPayment: {},
        cancelTokenSource: null,
        cancelTokenSource1: null,
        paymentsList: [],
        paymentCash: [],
        nextPayment: "",
        valutaList: [
            {
                "name": "USD",
                "id": "USD"
            },
            {
                "name": "AED",
                "id": "AED"
            },
            {
                "name": "AFN",
                "id": "AFN"
            },
            {
                "name": "ALL",
                "id": "ALL"
            },
            {
                "name": "AMD",
                "id": "AMD"
            },
            {
                "name": "ANG",
                "id": "ANG"
            },
            {
                "name": "AOA",
                "id": "AOA"
            },
            {
                "name": "ARS",
                "id": "ARS"
            },
            {
                "name": "AUD",
                "id": "AUD"
            },
            {
                "name": "AWG",
                "id": "AWG"
            },
            {
                "name": "AZN",
                "id": "AZN"
            },
            {
                "name": "BAM",
                "id": "BAM"
            },
            {
                "name": "BBD",
                "id": "BBD"
            },
            {
                "name": "BDT",
                "id": "BDT"
            },
            {
                "name": "BGN",
                "id": "BGN"
            },
            {
                "name": "BHD",
                "id": "BHD"
            },
            {
                "name": "BIF",
                "id": "BIF"
            },
            {
                "name": "BMD",
                "id": "BMD"
            },
            {
                "name": "BND",
                "id": "BND"
            },
            {
                "name": "BOB",
                "id": "BOB"
            },
            {
                "name": "BRL",
                "id": "BRL"
            },
            {
                "name": "BSD",
                "id": "BSD"
            },
            {
                "name": "BTN",
                "id": "BTN"
            },
            {
                "name": "BWP",
                "id": "BWP"
            },
            {
                "name": "BYN",
                "id": "BYN"
            },
            {
                "name": "BZD",
                "id": "BZD"
            },
            {
                "name": "CAD",
                "id": "CAD"
            },
            {
                "name": "CDF",
                "id": "CDF"
            },
            {
                "name": "CHF",
                "id": "CHF"
            },
            {
                "name": "CKD",
                "id": "CKD"
            },
            {
                "name": "CLP",
                "id": "CLP"
            },
            {
                "name": "CNY",
                "id": "CNY"
            },
            {
                "name": "COP",
                "id": "COP"
            },
            {
                "name": "CRC",
                "id": "CRC"
            },
            {
                "name": "CUC",
                "id": "CUC"
            },
            {
                "name": "CUP",
                "id": "CUP"
            },
            {
                "name": "CVE",
                "id": "CVE"
            },
            {
                "name": "CZK",
                "id": "CZK"
            },
            {
                "name": "DJF",
                "id": "DJF"
            },
            {
                "name": "DKK",
                "id": "DKK"
            },
            {
                "name": "DOP",
                "id": "DOP"
            },
            {
                "name": "DZD",
                "id": "DZD"
            },
            {
                "name": "EGP",
                "id": "EGP"
            },
            {
                "name": "ERN",
                "id": "ERN"
            },
            {
                "name": "ETB",
                "id": "ETB"
            },
            {
                "name": "EUR",
                "id": "EUR"
            },
            {
                "name": "FJD",
                "id": "FJD"
            },
            {
                "name": "FKP",
                "id": "FKP"
            },
            {
                "name": "FOK",
                "id": "FOK"
            },
            {
                "name": "GBP",
                "id": "GBP"
            },
            {
                "name": "GEL",
                "id": "GEL"
            },
            {
                "name": "GGP",
                "id": "GGP"
            },
            {
                "name": "GHS",
                "id": "GHS"
            },
            {
                "name": "GIP",
                "id": "GIP"
            },
            {
                "name": "GMD",
                "id": "GMD"
            },
            {
                "name": "GNF",
                "id": "GNF"
            },
            {
                "name": "GTQ",
                "id": "GTQ"
            },
            {
                "name": "GYD",
                "id": "GYD"
            },
            {
                "name": "HKD",
                "id": "HKD"
            },
            {
                "name": "HNL",
                "id": "HNL"
            },
            {
                "name": "HRK",
                "id": "HRK"
            },
            {
                "name": "HTG",
                "id": "HTG"
            },
            {
                "name": "HUF",
                "id": "HUF"
            },
            {
                "name": "IDR",
                "id": "IDR"
            },
            {
                "name": "ILS",
                "id": "ILS"
            },
            {
                "name": "IMP",
                "id": "IMP"
            },
            {
                "name": "INR",
                "id": "INR"
            },
            {
                "name": "IQD",
                "id": "IQD"
            },
            {
                "name": "IRR",
                "id": "IRR"
            },
            {
                "name": "ISK",
                "id": "ISK"
            },
            {
                "name": "JEP",
                "id": "JEP"
            },
            {
                "name": "JMD",
                "id": "JMD"
            },
            {
                "name": "JOD",
                "id": "JOD"
            },
            {
                "name": "JPY",
                "id": "JPY"
            },
            {
                "name": "KES",
                "id": "KES"
            },
            {
                "name": "KGS",
                "id": "KGS"
            },
            {
                "name": "KHR",
                "id": "KHR"
            },
            {
                "name": "KID",
                "id": "KID"
            },
            {
                "name": "KMF",
                "id": "KMF"
            },
            {
                "name": "KPW",
                "id": "KPW"
            },
            {
                "name": "KRW",
                "id": "KRW"
            },
            {
                "name": "KWD",
                "id": "KWD"
            },
            {
                "name": "KYD",
                "id": "KYD"
            },
            {
                "name": "KZT",
                "id": "KZT"
            },
            {
                "name": "LAK",
                "id": "LAK"
            },
            {
                "name": "LBP",
                "id": "LBP"
            },
            {
                "name": "LKR",
                "id": "LKR"
            },
            {
                "name": "LRD",
                "id": "LRD"
            },
            {
                "name": "LSL",
                "id": "LSL"
            },
            {
                "name": "LYD",
                "id": "LYD"
            },
            {
                "name": "MAD",
                "id": "MAD"
            },
            {
                "name": "MDL",
                "id": "MDL"
            },
            {
                "name": "MGA",
                "id": "MGA"
            },
            {
                "name": "MKD",
                "id": "MKD"
            },
            {
                "name": "MMK",
                "id": "MMK"
            },
            {
                "name": "MNT",
                "id": "MNT"
            },
            {
                "name": "MOP",
                "id": "MOP"
            },
            {
                "name": "MRU",
                "id": "MRU"
            },
            {
                "name": "MUR",
                "id": "MUR"
            },
            {
                "name": "MVR",
                "id": "MVR"
            },
            {
                "name": "MWK",
                "id": "MWK"
            },
            {
                "name": "MXN",
                "id": "MXN"
            },
            {
                "name": "MYR",
                "id": "MYR"
            },
            {
                "name": "MZN",
                "id": "MZN"
            },
            {
                "name": "NAD",
                "id": "NAD"
            },
            {
                "name": "NGN",
                "id": "NGN"
            },
            {
                "name": "NIO",
                "id": "NIO"
            },
            {
                "name": "NOK",
                "id": "NOK"
            },
            {
                "name": "NPR",
                "id": "NPR"
            },
            {
                "name": "NZD",
                "id": "NZD"
            },
            {
                "name": "OMR",
                "id": "OMR"
            },
            {
                "name": "PAB",
                "id": "PAB"
            },
            {
                "name": "PEN",
                "id": "PEN"
            },
            {
                "name": "PGK",
                "id": "PGK"
            },
            {
                "name": "PHP",
                "id": "PHP"
            },
            {
                "name": "PKR",
                "id": "PKR"
            },
            {
                "name": "PLN",
                "id": "PLN"
            },
            {
                "name": "PND",
                "id": "PND"
            },
            {
                "name": "PRB",
                "id": "PRB"
            },
            {
                "name": "PYG",
                "id": "PYG"
            },
            {
                "name": "QAR",
                "id": "QAR"
            },
            {
                "name": "RON",
                "id": "RON"
            },
            {
                "name": "RSD",
                "id": "RSD"
            },
            {
                "name": "RUB",
                "id": "RUB"
            },
            {
                "name": "RWF",
                "id": "RWF"
            },
            {
                "name": "SAR",
                "id": "SAR"
            },
            {
                "name": "SBD",
                "id": "SBD"
            },
            {
                "name": "SCR",
                "id": "SCR"
            },
            {
                "name": "SDG",
                "id": "SDG"
            },
            {
                "name": "SEK",
                "id": "SEK"
            },
            {
                "name": "SGD",
                "id": "SGD"
            },
            {
                "name": "SHP",
                "id": "SHP"
            },
            {
                "name": "SLL",
                "id": "SLL"
            },
            {
                "name": "SLS",
                "id": "SLS"
            },
            {
                "name": "SOS",
                "id": "SOS"
            },
            {
                "name": "SRD",
                "id": "SRD"
            },
            {
                "name": "SSP",
                "id": "SSP"
            },
            {
                "name": "STN",
                "id": "STN"
            },
            {
                "name": "SYP",
                "id": "SYP"
            },
            {
                "name": "SZL",
                "id": "SZL"
            },
            {
                "name": "THB",
                "id": "THB"
            },
            {
                "name": "TJS",
                "id": "TJS"
            },
            {
                "name": "TMT",
                "id": "TMT"
            },
            {
                "name": "TND",
                "id": "TND"
            },
            {
                "name": "TOP",
                "id": "TOP"
            },
            {
                "name": "TRY",
                "id": "TRY"
            },
            {
                "name": "TTD",
                "id": "TTD"
            },
            {
                "name": "TVD",
                "id": "TVD"
            },
            {
                "name": "TWD",
                "id": "TWD"
            },
            {
                "name": "TZS",
                "id": "TZS"
            },
            {
                "name": "UAH",
                "id": "UAH"
            },
            {
                "name": "UGX",
                "id": "UGX"
            },
            {
                "name": "UYU",
                "id": "UYU"
            },
            {
                "name": "UZS",
                "id": "UZS"
            },
            {
                "name": "VES",
                "id": "VES"
            },
            {
                "name": "VND",
                "id": "VND"
            },
            {
                "name": "VUV",
                "id": "VUV"
            },
            {
                "name": "WST",
                "id": "WST"
            },
            {
                "name": "XAF",
                "id": "XAF"
            },
            {
                "name": "XCD",
                "id": "XCD"
            },
            {
                "name": "XOF",
                "id": "XOF"
            },
            {
                "name": "XPF",
                "id": "XPF"
            },
            {
                "name": "YER",
                "id": "YER"
            },
            {
                "name": "ZAR",
                "id": "ZAR"
            },
            {
                "name": "ZMW",
                "id": "ZMW"
            }
        ],
        paymentsAccount: [],
        dataPaymentModal: null,
        filterDataPaymentAxios: null,
        amountProfit: 0,
        amountNetProfit: 0,
        AmountBalance: 0,
        totalCashFlow: {
            income: 0, expense: 0,
        }
    },
    getters: {
        getTotalPaymentsCounterparties(state) {
            return state.totalPaymentsCounterparties
        },
        getAmountBalance(state) {
          return state.AmountBalance
        },
        getNextLinkPaymentForCounterparty(state) {
            return state.NextLinkPaymentForCounterparty;
        },
        getNextLinkPaymentForCounterparty1(state) {
            return state.NextLinkPaymentForCounterparty1;
        },
        getPushDataCashFlowList(state) {
            return state.pushDataCashFlowList;
        },
        getNextLinkPayment(state) {
          return state.linkNextPayment;
        },
        getDataPaymentModal(state) {
          return state.dataPaymentModal;
        },
        getNextPayment(state) {
            return state.next;
        },
        getPaymentCashFlow(state) {
          return state.paymentCashFlow;
        },
        getCashFlowDiagram(state) {
            return state.CashFlowDiagram;
        },
        getCashFlowDiagramDouble(state) {
            return state.CashFlowDiagramDouble;
        },
        getPaymentListOriginal(state) {
          return state.paymentsList;
        },
        getPaymentList(state) {
            let payment = [];
            //DueDate
            let formDueDate = {
                title: "Due Date",
                type: "due_date",
                array: []
            }
            formDueDate.array = state.paymentsList.map(a=> {
                return {execute_date: a.execute_date, id: a.id}
            });
            //console.log(1);
            payment.push(formDueDate)
            //Member
            let formMember = {
                title: "Vessel, Statement Member",
                type: "member",
                array: []
            }
            formMember.array = state.paymentsList.map(a=> {
                let form = {
                    name: "",
                    date: "",
                    id: a.id
                }
                if(a.ship !== null) {
                    form.name = a.ship.name;
                    form.date = a.ship.cp_date;
                }
                return form;
            });
            //console.log(2);
            payment.push(formMember)
            //CP_Date
            let formCPDate = {
                title: "CP Date",
                type: "cp_date",
                array: []
            }
            formCPDate.array = state.paymentsList.map(a=> {
                return {name: a.cp_date, id: a.id}
            });
            //console.log(3);
            payment.push(formCPDate)
            //Account
            let formAccount = {
                title: "Account",
                type: "account",
                array: []
            }
            formAccount.array = state.paymentsList.map(a=> {
                return {account: a.account ?  a.account.id + ": " + a.account.name : ''}
            });
            //console.log(4);
            payment.push(formAccount);
            //Currency
            let formCurrency = {
                title: "Curr.",
                type: "curr",
                array: []
            }
            formCurrency.array = state.paymentsList.map(a=> {
                return {currency: a.currency, id: a.id}
            });
            //console.log(5);
            payment.push(formCurrency);
            //Payment
            let formPayment = {
                title: "Payment",
                type: "payment",
                array: []
            }
            formPayment.array = state.paymentsList.map(a=> {
                return {payment_amount: a.payment_amount, payment_type: a.payment_type, id: a.id}
            });
            payment.push(formPayment);
            //Income
            let formIncome = {
                title: "Income",
                type: "income",
                array: []
            }
            //console.log(6);
            formIncome.array = state.paymentsList.map(a=> {
                return {payment_amount: a.payment_amount, payment_type: a.payment_type, id: a.id}
            });
            payment.push(formIncome);
            //Status
            let formStatus = {
                title: "Status",
                type: "status",
                array: []
            }
            //console.log(7);
            formStatus.array = state.paymentsList.map(a=> {
                return {status: a.status, payment_type: a.payment_type,  id: a.id}
            });
            payment.push(formStatus);
            //Counterparty
            let formCounterparty = {
                title: "Counterparty",
                type: "counterparty",
                array: []
            }
            formCounterparty.array = state.paymentsList.map(a=> {
                return {client: a.client, id: a.id}
            });
            //console.log(8);
            payment.push(formCounterparty);
            //Bank and Cash Accounts
            let formBCAcc = {
                title: "Bank and Cash Accounts",
                type: "bank_and_cash_accounts",
                array: []
            }
            //console.log(9);
            // formBCAcc.array = state.paymentsList.map(a=> {
            //     return {cash: a.cash, id: a.id}
            // });
            for(let i=0; i<state.paymentsList.length; i++) {
                if(state.paymentsList[i].cash !== null) {
                    formBCAcc.array.push({name: state.paymentsList[i].cash.name, id: state.paymentsList[i].id, id_cash: state.paymentsList[i].cash.id, type: 'cash'})
                } else if(state.paymentsList[i].bank !== null) {
                    formBCAcc.array.push({name: state.paymentsList[i].bank.bank, id: state.paymentsList[i].id, id_cash: state.paymentsList[i].bank.id, type: 'bank'})
                } else {
                    formBCAcc.array.push({name: "", id: state.paymentsList[i].id, id_cash: "", type: 'bank'})
                }
            }
            //console.log(10);
            payment.push(formBCAcc);
            // Remark
            let formRemark = {
                title: "Remark",
                type: "remark",
                array: []
            }
            formRemark.array = state.paymentsList.map(a=> {
                return {remark: a.comment, id: a.id}
            });
            //console.log(11);
            payment.push(formRemark);
            //Attachment
            let formAttachment = {
                title: "Attachm.",
                type: "attachment",
                array: []
            }
            formAttachment.array = state.paymentsList.map(a=> {
                return {files: a.files, id: a.id}
            });
            //console.log(12);
            payment.push(formAttachment);
            //Comment from accounting
            let formCom = {
                title: "Comment from accounting",
                type: "comment_from_accounting",
                array: []
            }
            //console.log(13);
            formCom.array = state.paymentsList.map(a=> {
                return {has_messages: a.has_messages, client: a.client, id: a.id}
            });
            payment.push(formCom);
            //Bank Receipt
            let formReceipt = {
                title: "Bank Receipt",
                type: "bank_receipt",
                array: []
            }
            formReceipt.array = state.paymentsList.map(a=> {
                return {receipt_files: a.receipt_files, id: a.id}
            });
            //console.log(14);
            payment.push(formReceipt);
            //converted_amt
            let formConv = {
                title: "USD amount",
                type: "converted_amt",
                array: []
            }
            formConv.array = state.paymentsList.map(a=> {
                return {name: a.currency_amount, id: a.id}
            });
            //console.log(15);
            payment.push(formConv);
            //Disbursements
            let formDisbursements = {
                title: "Disbursements",
                type: "disbursements",
                array: []
            }
            formDisbursements.array = state.paymentsList.map(a=> {
                return {name: "Ijmuiden, Netherlands", id: a.id}
            });
            formDisbursements.array = state.paymentsList.map(a=> {
                return {name: (""+a.ports.map(ac=> {return ac.name + "<br/>"})).replaceAll(",", ""), id: a.id}
            });
            //console.log(16);
            payment.push(formDisbursements);
            //sattled_vsl
            let formSattled = {
                title: "Settled vsl",
                type: "sattled_vsl",
                array: []
            }
            formSattled.array = state.paymentsList.map(a=> {
                let form = {
                    name: "",
                    date: "",
                    id: a.id
                }
                if(a.settled_ship !== null) {
                    form.name = a.settled_ship.name;
                    form.date = a.settled_ship.cp_date;
                }
                return form;
            });
            //console.log(17);
            payment.push(formSattled);
            //real_account
            let formreal_account = {
                title: "Real Account",
                type: "real_account",
                array: []
            }
            formreal_account.array = state.paymentsList.map(a=> {
                let name = "";
                if(a.real_account !== null) {
                    name = a.real_account.company_name;
                }
                return {name: name, id: a.id}
            });
            //console.log(18);
            payment.push(formreal_account);
            //end
            let formEnd = {
                title:"end",
                type: "end",
                array: []
            }
            formEnd.array = state.paymentsList.map(a=> {
                return {status: true, id: a.id}
            });
            //console.log(19);
            payment.push(formEnd);

            return payment;
        },
        getPaymentListCashFlow(state) {
            let payment = [];
            //DueDate
            let formDueDate = {
                title: "Due Date",
                type: "due_date",
                array: []
            }
            formDueDate.array = state.paymentsList.map(a=> {
                return {execute_date: a.execute_date, id: a.id}
            });
            //console.log(1);
            payment.push(formDueDate)
            //Member
            let formMember = {
                title: "Vessel, CP Date",
                type: "member",
                array: []
            }
            formMember.array = state.paymentsList.map(a=> {
                let form = {
                    name: "",
                    date: "",
                    id: a.id
                }
                if(a.ship !== null) {
                    form.name = a.ship.name;
                    form.date = a.cp_date;
                }
                return form;
            });
            //console.log(2);
            payment.push(formMember)
            //Account
            let formAccount = {
                title: "Reference",
                type: "account",
                array: []
            }
            formAccount.array = state.paymentsList.map(a=> {
                return {account: (""+a.account.map(ac=> {return ac.name + "<br/>"})).replaceAll(",", ""), id: a.id}
            });
            //console.log(4);
            payment.push(formAccount);
            //Currency
            let formCurrency = {
                title: "Curr.",
                type: "curr",
                array: []
            }
            formCurrency.array = state.paymentsList.map(a=> {
                return {currency: a.currency, id: a.id}
            });
            //console.log(5);
            payment.push(formCurrency);
            //Payment
            let formPayment = {
                title: "Payment",
                type: "payment",
                array: []
            }
            formPayment.array = state.paymentsList.map(a=> {
                return {payment_amount: a.payment_amount, payment_type: a.payment_type, id: a.id}
            });
            payment.push(formPayment);
            //Income
            let formIncome = {
                title: "Income",
                type: "income",
                array: []
            }
            //console.log(6);
            formIncome.array = state.paymentsList.map(a=> {
                return {payment_amount: a.payment_amount, payment_type: a.payment_type, id: a.id}
            });
            payment.push(formIncome);
            //Status
            let formStatus = {
                title: "Status",
                type: "status",
                array: []
            }
            //console.log(7);
            formStatus.array = state.paymentsList.map(a=> {
                return {status: a.status, payment_type: a.payment_type,  id: a.id}
            });
            payment.push(formStatus);
            //CP_Date
            let formCPDate = {
                title: "Paid Date",
                type: "cp_date",
                array: []
            }
            formCPDate.array = state.paymentsList.map(a=> {
                return {name: a.paid_date, id: a.id}
            });
            //console.log(3);
            payment.push(formCPDate)
            //Counterparty
            let formCounterparty = {
                title: "Counterparty",
                type: "counterparty",
                array: []
            }
            formCounterparty.array = state.paymentsList.map(a=> {
                return {client: a.client, id: a.id}
            });
            //console.log(8);
            payment.push(formCounterparty);
            //Bank and Cash Accounts
            let formBCAcc = {
                title: "Bank and Cash Accounts",
                type: "bank_and_cash_accounts",
                array: []
            }
            //console.log(9);
            // formBCAcc.array = state.paymentsList.map(a=> {
            //     return {cash: a.cash, id: a.id}
            // });
            for(let i=0; i<state.paymentsList.length; i++) {
                if(state.paymentsList[i].cash !== null) {
                    formBCAcc.array.push({name: state.paymentsList[i].cash.name, id: state.paymentsList[i].id, id_cash: state.paymentsList[i].cash.id, type: 'cash'})
                } else if(state.paymentsList[i].bank !== null) {
                    formBCAcc.array.push({name: state.paymentsList[i].bank.bank, id: state.paymentsList[i].id, id_cash: state.paymentsList[i].bank.id, type: 'bank'})
                } else {
                    formBCAcc.array.push({name: "", id: state.paymentsList[i].id, id_cash: "", type: 'bank'})
                }
            }
            //console.log(10);
            payment.push(formBCAcc);
            // Remark
            let formRemark = {
                title: "Remark",
                type: "remark",
                array: []
            }
            formRemark.array = state.paymentsList.map(a=> {
                return {remark: a.comment, id: a.id}
            });
            //console.log(11);
            payment.push(formRemark);
            //Attachment
            let formAttachment = {
                title: "Attachm.",
                type: "attachment",
                array: []
            }
            formAttachment.array = state.paymentsList.map(a=> {
                return {files: a.files, id: a.id}
            });
            //console.log(12);
            payment.push(formAttachment);
            //Comment from accounting
            let formCom = {
                title: "Comment from accounting",
                type: "comment_from_accounting",
                array: []
            }
            //console.log(13);
            formCom.array = state.paymentsList.map(a=> {
                return {has_messages: a.has_messages, client: a.client, id: a.id}
            });
            payment.push(formCom);
            //Bank Receipt
            let formReceipt = {
                title: "Bank Receipt",
                type: "bank_receipt",
                array: []
            }
            formReceipt.array = state.paymentsList.map(a=> {
                return {receipt_files: a.receipt_files, id: a.id}
            });
            //console.log(14);
            payment.push(formReceipt);
            //converted_amt
            let formConv = {
                title: "USD amount",
                type: "converted_amt",
                array: []
            }
            formConv.array = state.paymentsList.map(a=> {
                return {name: a.currency_amount, id: a.id}
            });
            //console.log(15);
            payment.push(formConv);
            //Statement member
            let formStatementMember = {
                title: "Statement Member",
                type: "statement_member",
                array: []
            }
            formStatementMember.array = state.paymentsList.map(a=> {
                return {text: "Ijmuiden, Netherlands", id: a.id}
            });
            //console.log(16);
            payment.push(formStatementMember);
            //Disbursements
            let formDisbursements = {
                title: "Disbursements",
                type: "disbursements",
                array: []
            }
            // formDisbursements.array = state.paymentsList.map(a=> {
            //     return {name: "Ijmuiden, Netherlands", id: a.id}
            // });
            formDisbursements.array = state.paymentsList.map(a=> {
                return {name: (""+a.ports.map(ac=> {return ac.name + "<br/>"})).replaceAll(",", ""), id: a.id}
            });
            //console.log(16);
            payment.push(formDisbursements);
            //sattled_vsl
            let formSattled = {
                title: "Settled vsl",
                type: "sattled_vsl",
                array: []
            }
            // formSattled.array = state.paymentsList.map(a=> {
            //     return {name: "New Katerina", id: a.id}
            // });
            formSattled.array = state.paymentsList.map(a=> {
                let form = {
                    name: "",
                    date: "",
                    id: a.id
                }
                if(a.settled_ship !== null) {
                    form.name = a.settled_ship.name;
                    form.date = a.settled_ship.cp_date;
                }
                return form;
            });
            //console.log(17);
            payment.push(formSattled);
            //real_account
            let formreal_account = {
                title: "Real Account",
                type: "real_account",
                array: []
            }
            formreal_account.array = state.paymentsList.map(a=> {
                let name = "";
                if(a.real_account !== null) {
                    name = a.real_account.company_name;
                }
                return {name: name, id: a.id}
            });
            //console.log(18);
            payment.push(formreal_account);
            //end
            let formEnd = {
                title:"end",
                type: "end",
                array: []
            }
            formEnd.array = state.paymentsList.map(a=> {
                return {status: true, id: a.id}
            });
            //console.log(19);
            payment.push(formEnd);

            return payment;
        },
        getPaymentsAccount(state) {
            //console.log("state.paymentsAccount")
            //console.log(state.paymentsAccount);
            state.paymentsAccount = state.paymentsAccount.map(p=> {
                p.name = p.account_id + ": " + p.name;
                return p;
            })
            return state.paymentsAccount;
        },
        getValutaList(state) {
            return state.valutaList;
        },
        getInfoDetailPayment(state) {
            if(state.infoDetailPayment?.execute_date) {
                state.infoDetailPayment.execute_date = new Date(state.infoDetailPayment.execute_date)
            }
          return state.infoDetailPayment;
        },
        getDateForViewPayments(state) {
            return state.dateForViewPayments;
        },
        getFilterDataPaymentAxios(state) {
            return state.filterDataPaymentAxios
        },
        getPaymentsCash(state) {
            let payment  = [];
            // let bankName  = [];
            //console.log("vue.runtime.esm.js?2b0e:619 [Vue warn]: Duplicate keys detected: '232318'. This may cause an update error.")
            // state.paymentCash = state.paymentCash.filter(a=> a.is_archive === false);
            //console.log(state.paymentCash);
            let filterBank = state.paymentCash.filter(a=> a.type === "bank");
            let filterCash = state.paymentCash.filter(a=> a.type === "cash");
            //console.log(state.filterBank);

            if(filterBank.length) {
                // bankName = filterBank.map(a=> {
                //     return a.cash.name;
                // })
                // console.log(bankName)
                // bankName = Array.from(new Set(bankName));
                // console.log("BANK")
                // console.log(bankName);
                // for(let i=0; i<bankName.length; i++) {
                //     payment.push({id: 'a'+i, name: bankName[i], disabled: true});
                //     let bank = (filterBank.filter(a => a.cash.name === bankName[i])).map(a=> {
                //         return {id: a.id, name: a.bank, disabled: false, type: a.cash.type}
                //     })
                //     bank.forEach(b=> payment.push(b))
                // }
                for(let i=0; i<filterBank.length; i++) {
                    payment.push({id: 'a'+i, name: filterBank[i].name, disabled: true});
                    let requisites = filterBank[i].requisites.map(a=> {
                        return {id: a.id, name: a.holder, disabled: false, type: a.cash.type, currency: a.currency}
                    });
                    requisites.forEach(b=> payment.push(b))
                }

            }
            let cash = filterCash.map(a=> {
                return {id: a.id, name: a.name, disabled: false, type: a.type}
            });
            cash.forEach(b=> payment.push(b))
            //console.log('payment');

            return payment;

//type
//             return state.paymentCash.map(a=> {
//                 return  {id: a.id, name: a.bank}
//             });
        }
    },
}