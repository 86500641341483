<template>
  <div class="counterparties-drop">
    <p class="counterparties-drop_title fz13 text-gray">{{title}}</p>
    <b-dropdown ref="dropdown">
      <template #button-content>
        <span class="button-text" v-html="printDropResult"></span>
        <span class="button-drop"><svg class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2c2c2c"/></svg></span>
      </template>

      <div class="search">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.1535 14.2913L10.9827 10.1205C11.7906 9.12262 12.277 7.85457 12.277 6.47348C12.277 3.27251 9.67246 0.667969 6.47149 0.667969C3.27053 0.667969 0.666016 3.27248 0.666016 6.47345C0.666016 9.67442 3.27056 12.279 6.47152 12.279C7.85261 12.279 9.12066 11.7925 10.1186 10.9846L14.2894 15.1554C14.4085 15.2746 14.565 15.3345 14.7214 15.3345C14.8779 15.3345 15.0343 15.2746 15.1535 15.1554C15.3924 14.9165 15.3924 14.5303 15.1535 14.2913ZM6.47152 11.0567C3.94398 11.0567 1.88824 9.00099 1.88824 6.47345C1.88824 3.94591 3.94398 1.89016 6.47152 1.89016C8.99906 1.89016 11.0548 3.94591 11.0548 6.47345C11.0548 9.00099 8.99903 11.0567 6.47152 11.0567Z" fill="#2C2C2C"/></svg>
        <input type="text" v-model="searchText" placeholder="Search">
      </div>
      <li v-if="searchText && !onSearch.length"><span class="dropdown-item text-dark1">Nothing found</span></li>

      <li>
        <div v-for="(list, i) in onSearch" :key="i">
          <button class="w-full flex justify-between items-center p-x-3 p-y-1.5 fz14 fw500 text-gray-text bg-gray-lightest" v-b-toggle="'account-'+i" v-if="list.name">
            {{list.name}}
            <svg class="flex-shrink-0 when-not-collapsed-rotate-180" width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.276 4.398a.3.3 0 0 1 .212.513L6.212 8.186a.3.3 0 0 1-.424 0L2.512 4.911a.3.3 0 0 1 .212-.513h6.552Z" fill="#2C2C2C"/></svg>
          </button>

          <b-collapse :id="'account-'+i" :visible="!list.name">
            <div class="relative" v-for="item in list.list" :key="item.id">
              <a 
                href="#" 
                class="dropdown-item text-dark-gray fz14" 
                :class="{
                  'border-b border-gray-dark': item.border,
                  'fw500 text-dark': item.strong,
                }"
                @click.prevent="select(item)" 
                :title="item.name"
              >{{item.name}}</a>
              <svg v-if="activeItem.indexOf(item.id) >= 0 " class="dropdown-check" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.9783 0.267503C11.6651 -0.0805703 11.1483 -0.0902217 10.8239 0.245941C10.8172 0.252974 10.8105 0.260161 10.8039 0.267503L4.53496 8.89149L1.38355 6.26105C1.05922 5.92489 0.542408 5.93454 0.22918 6.28262C-0.0763934 6.62217 -0.0763934 7.16045 0.22918 7.5L3.95778 10.7499C4.2766 11.092 4.79337 11.092 5.11214 10.7499L11.9582 1.50645C12.2826 1.17023 12.2915 0.615576 11.9783 0.267503Z" fill="#319FEF"/></svg>
            </div>
          </b-collapse>
        </div>
      </li>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array
    },
    activeItem: {
      type: Array,
    },
    title: {
      type: String,
      default: 'Field'
    },
  },
  data() {
    return {
      searchText: ''
    }
  },
  watch: {
  },
  computed: {
    printDropResult() {
      let result = this.activeItem
      // console.log('printDropResult', this.activeItem)
      result = result.map(item=> {
        if(item.indexOf('SUBLET_') == 0) {
          item = item.substr(7)
        }
        if(item && item === 'Bunkers on Redelivery:Bunkers on Dev./Redev.: C/E/V') {
          item = 'C/E/V'
        }
        return item
      })
      return result.length > 1 ? result[0] + '<span>+'+(result.length-1)+'</span>' : result[0]
    },
    onSearch() {
      if(this.searchText){
        let filteredList = JSON.parse(JSON.stringify(this.list))
        filteredList.forEach(el => {
          el.list = el.list.filter(item => {
            return item.id.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
          })
        });
        return filteredList
      }
      return this.list
    }
  },
  methods: {
    openList() {
      this.$refs.dropdown.show(true);
    },
    select(item) {
      console.log('select', item)
      // if(item.id == this.activeItem)
      //   this.$emit('select', 'N/A')
      // else {
      //   this.$emit('select', item.id)
      // }
      // this.$refs.dropdown.hide(true);
      if(this.activeItem.indexOf(item.id) >= 0) {
        this.$emit('select', this.activeItem.filter(a=> a !== item.id))
      } else {
        let active = JSON.parse(JSON.stringify(this.activeItem))
        active.push(item.id)
        this.$emit('select', active)
      }
    },
  }
}
</script>

<style scoped lang="scss">
  @import "../../style-element/dropdown";
  .button-text {
    /deep/ {
      span {
        border: 1px solid var(--color-dark);
        border-radius: 20px;
        width: 25px;
        height: 25px;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 15;
      }
    }
  }
  /deep/ {
    .btn {
      height: 35px !important;
      box-sizing: border-box;
    }
  }
</style>

<style lang="scss">
  .counterparties-drop{
    margin-top: 14px;
    &_title{
      margin-bottom: 0.25rem !important;
    }
    .dropdown{
      width: 100%;
    }
    .dropdown-menu{
      max-height: 300px;
      overflow: auto;
    }
    .dropdown-toggle{
      display: flex;
      width: 100%;
      align-items: center;
      box-sizing: border-box;
      padding: 8px 7px 7px !important;
      font-size: 16px !important;
      border-radius: 4px;
      border: 1px solid var(--color-border) !important;
      background: var(--color-input) !important;
      line-height: normal;
      &:after{
        display: none !important;
      }
      .button-drop{
        width: auto !important;
      }
    }
    .button-text{
      display: inline-flex;
      text-align: left;
      font-size: 14px;
      color: var(--color-dark);
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      align-items: center;
      b{
        display: inline-flex;
        min-width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid var(--color-dark);
        font-weight: 400;
        font-size: 11px;
      }
    }
    .dropdown-menu{
      width: 100%;
      li{
        position: relative;
      }
    }
    .dropdown-check{
      position: absolute;
      top: 11px;
      right: 11px;
      user-select: none;
    }
    .dropdown-item{
      padding: 6px 30px 6px 12px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .search{
      position: relative;
      display: flex;
      align-items: center;
      padding: 6px 12px;
      border-bottom: 1px solid var(--color-card-border);
      input{
        height: auto;
        margin-left: 12px;
        padding: 0;
        border: none;
        background-color: transparent;
      }
    }
  }
  .not-collapsed > .when-not-collapsed-rotate-180{
    transform: rotate(180deg);
  }

</style>