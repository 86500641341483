<template>
    <div class="wrap">
      <b-dropdown left ref="dropdown">
        <template #button-content>
          <button class="button-drop fz13 fw500 text-dark svg-white-dart-stroke">
            <svg class="svg-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.12822 6.09999C5.12822 6.04476 5.17299 5.99999 5.22822 5.99999H18.1996C18.2549 5.99999 18.2996 6.04476 18.2996 6.09999C18.2996 6.15522 18.2549 6.19999 18.1996 6.19999H5.22822C5.17299 6.19999 5.12822 6.15522 5.12822 6.09999ZM5.12822 17.7C5.12822 17.6448 5.17299 17.6 5.22822 17.6H10.1996C10.2549 17.6 10.2996 17.6448 10.2996 17.7C10.2996 17.7552 10.2549 17.8 10.1996 17.8H5.22822C5.17299 17.8 5.12822 17.7552 5.12822 17.7ZM5.12822 11.8103C5.12822 11.7551 5.17299 11.7103 5.22822 11.7103H14.1996C14.2549 11.7103 14.2996 11.7551 14.2996 11.8103C14.2996 11.8655 14.2549 11.9103 14.1996 11.9103H5.22822C5.17299 11.9103 5.12822 11.8655 5.12822 11.8103Z" stroke="#2C2C2C" stroke-width="1.4"/>
            </svg>
            Sort By
  
          </button>
        </template>
        <div class="content">
          <button class="title">
            <button @click="hide" class=" fz13 fw500 text-gray">
              Sort by
            </button>
            <button class="svg-white-dart" @click="clickSortReverse" :class="{'rotate': reverseSort}">
              <svg class="arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </button>
          <button v-for="btn in buttons" :key="btn.value" :class="{'active-button': btn.value.indexOf(activeSort.replace('-', '')) >= 0}" @click="changeActiveButton(btn.value)" class="fz13 text-dark">
            {{btn.name}}
          </button>
        </div>
      </b-dropdown>
      <button class="svg-white-dart" @click="clickSortReverse" :class="{'rotate': activeSort.indexOf('-') >= 0}">
        <svg class="arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
          <path d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"/>
        </svg>
      </button>
    </div>
  </template>
  
  <script>
  export default {
    props: ['activeSort'],
    data() {
      return {
        buttons: [
          {
            name: "Name",
            value: 'name'
          },
          {
            name: "CP Date",
            value: 'cp_date'
          },
        ],
        indexActive: 'name',
        reverseSort: false,
      }
    },
    methods: {
        hide() {
            this.$refs.dropdown.hide(true)
        },
      clickSortReverse() {
        this.reverseSort = !this.reverseSort;
        this.$emit('changeSort', (this.reverseSort ? '-' : '')+this.indexActive);
        this.hide()
      },
      changeActiveButton(val) {
        this.indexActive = val;
        this.$emit('changeSort', (this.reverseSort ? '-' : '')+this.indexActive);
        this.hide()
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  @import "./style-element/dropdown";
  .wrap {
    display: flex;
    align-items: center;
  }
  
  .active-button {
    background-image: url("./../assets/ic_chsdeck.svg");
    background-position-x: calc(100% - 4px);
    background-position-y: center;
    background-repeat: no-repeat;
  }
  .svg-1 {
    margin-right: 8px;
  }
  .arrow {
    margin-left: 4px;
  }
  /deep/ .dropdown-menu {
    top: -29px !important;
    border-radius: 4px;
    border: none;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
    padding: 4px 0 !important;
  }
  .content {
    width: 137px;
    background-color: var(--color-modal);
    border-radius: 4px;
    .title {
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      padding: 4px 12px 5px 12px;
      display: flex;
      align-items: center;
      transition: .1s linear;
      button {
        width: max-content;
        &:hover {
          color: #2C2C2C !important;
        }
      }
    }
    &>button:not(:first-child) {
      height: 28px;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 12px;
      box-sizing: border-box;
      transition: background-color .1s linear;
      &:hover {
        background-color: var(--color-card-border);
      }
    }
  }
  
  .rotate {
    transform: rotateX(180deg);
  }
  .button-drop {
    display: flex;
    align-items: center;
  }
  </style>