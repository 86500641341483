<template>
  <div class="wrap-page">

    <actual-vessel-filter ref="filter" class="vessel-filter" @changeFilter="changeFilter" @changeShowNotLinkedCargo="changeShowNotLinkedCargo"></actual-vessel-filter>
    <div class="top-panel">
      <p class="fz20 fw500 text-dark"><span v-if="$route.path.indexOf('archive') !== -1" class="fz20 fw500 text-dark">Archive </span> <span v-if="$route.path.indexOf('actual')>=0"> Actual Vessels</span> <span v-if="$route.path.indexOf('redelivered')>=0">Redelivered Vessels</span></p>
      <button class="blue-button fz13 fw500 h32" v-on:click="openModalClean(null, null)" v-if="$route.path.indexOf('archive') === -1 && getPermissionAddVessel() && $route.path.indexOf('redelivered') === -1">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.7778 18.2221L12.7778 12.7778L18.2223 12.7778C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2223 11.2222L12.7778 11.2222L12.7778 5.77773C12.7778 5.34849 12.4293 5 12.0001 5C11.5707 5 11.2222 5.34849 11.2222 5.77773L11.2222 11.2222L5.77787 11.2222C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77787 12.7778L11.2222 12.7778L11.2222 18.2221C11.2222 18.6515 11.5707 19 12.0001 19C12.4293 19 12.7778 18.6515 12.7778 18.2221Z" fill="white"/>
        </svg>
        Create new vessel
      </button>
      <button class="archive fz13 fw500 text-dark1 button-dark-link" v-on:click="linkArchive" v-if="$route.path.indexOf('archive') === -1 && getPermissionSeeArchive()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Archive Vessels
      </button>
      <sort-vessel class="ml-auto" :activeSort="activeSort" @changeSort="changeSort"></sort-vessel>
      <input type="text" class="search ml-12" placeholder="Search" v-model="searchText" @focusout="checkSearch" v-on:keydown.enter="checkSearch">
      <button class="filter fz14 fw500 text-dark" v-on:click="showFilter">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.28634 3.73746C8.22907 3.73746 7.3304 4.45987 7.03965 5.44983H4.63436C4.26432 5.44983 4 5.74415 4 6.11873C4 6.49331 4.29075 6.78763 4.66079 6.78763H7.06608C7.3304 7.77759 8.25551 8.5 9.31277 8.5C10.3965 8.5 11.2952 7.77759 11.5859 6.78763H18.3392C18.7093 6.78763 19 6.49331 19 6.11873C19 5.74415 18.7093 5.44983 18.3392 5.44983H11.5859C11.2687 4.45987 10.37 3.73746 9.28634 3.73746ZM9.31277 5.07525C9.86784 5.07525 10.3436 5.5301 10.3436 6.11873C10.3436 6.70736 9.86784 7.16221 9.31277 7.16221C8.75771 7.16221 8.28194 6.70736 8.28194 6.11873C8.28194 5.5301 8.75771 5.07525 9.31277 5.07525Z" fill="#2C2C2C"/>
          <path d="M14.2423 9.61377C13.185 9.61377 12.2863 10.3362 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6205 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.6205 18.7093 11.3261 18.3392 11.3261H16.5154C16.2247 10.3362 15.326 9.61377 14.2423 9.61377ZM14.2687 10.9516C14.8238 10.9516 15.2996 11.4064 15.2996 11.995C15.2996 12.5837 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5837 13.2379 11.995C13.2379 11.4064 13.6872 10.9516 14.2687 10.9516Z" fill="#2C2C2C"/>
          <path d="M9.45815 15.7627C8.40088 15.7627 7.5022 16.4851 7.21145 17.4751H4.66079C4.26432 17.4751 4 17.7694 4 18.144C4 18.5186 4.29075 18.8129 4.66079 18.8129H7.23789C7.5022 19.8029 8.42731 20.5253 9.48458 20.5253C10.5683 20.5253 11.467 19.8029 11.7577 18.8129H18.3392C18.7093 18.8129 19 18.5186 19 18.144C19 17.7694 18.7093 17.4751 18.3392 17.4751H11.7313C11.4405 16.4851 10.5419 15.7627 9.45815 15.7627ZM9.48458 17.1005C10.0396 17.1005 10.5154 17.5554 10.5154 18.144C10.5154 18.7326 10.0396 19.1875 9.48458 19.1875C8.92951 19.1875 8.45374 18.7326 8.45374 18.144C8.48018 17.5554 8.92951 17.1005 9.48458 17.1005Z" fill="#2C2C2C"/>
        </svg>
        Filter
      </button>
    </div>
    <div class="no-vessels" v-if="contracts.length === 0 && contractsCargo.length === 0 && $route.path.indexOf('actual') >=0">
      <svg width="320" height="160" viewBox="0 0 320 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M57 127.5L33 105.476V86.0029H46.1595M252.5 129.5L287 86.0029H212.22L203.865 93.5407H92.1135M46.1595 86.0029H72.6875L81.0428 93.5407H92.1135M46.1595 86.0029V73.0211M46.1595 73.0211H92.1135M46.1595 73.0211H41.773V69.2522H52.6349V56.4797M92.1135 73.0211H96.2911V69.2522H92.1135V56.4797M92.1135 73.0211V93.5407M92.1135 56.4797H52.6349M92.1135 56.4797H96.2911V52.7108H92.1135V42.2416H84.5938M52.6349 56.4797H48.4572V52.7108H67.4655M84.5938 42.2416L74.1497 52.7108H67.4655M84.5938 42.2416V30.7255M67.4655 52.7108L70.8711 30.7255M71.8766 24.2346L72.6875 19H84.5938V24.2346M71.8766 24.2346H84.5938M71.8766 24.2346L70.8711 30.7255M84.5938 24.2346V30.7255M84.5938 30.7255H70.8711" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M165 17.3386C165 13.3948 162.388 13.2425 160.896 12.8812C160.564 11.5442 159.438 8.27753 155.259 8.01736C151.08 7.75719 148.955 10.4845 148.333 11.966C145.97 11.966 140 12.7857 140 17.3386C140 20.981 143.814 21.9639 145.721 22H159.776C161.517 21.8193 165 20.6341 165 17.3386Z" stroke="#DEE3EA" stroke-width="2"/>
        <path d="M212 43.3454C212 34.3309 217.537 33.9829 220.701 33.157C221.405 30.101 223.792 22.6344 232.651 22.0397C241.511 21.445 246.015 27.6789 247.333 31.0652C252.343 31.0652 265 32.9387 265 43.3454C265 51.6709 256.914 53.9174 252.871 54H223.075C219.383 53.587 212 50.878 212 43.3454Z" stroke="#DEE3EA" stroke-width="2"/>
        <path d="M52 131C55.1285 131 57.86 128.989 59.3214 126C60.7829 128.989 63.5144 131 66.6429 131C69.7714 131 72.5029 128.989 73.9643 126C75.4257 128.989 78.1572 131 81.2857 131C84.4142 131 87.1457 128.989 88.6071 126C90.0686 128.989 92.8001 131 95.9286 131C99.0571 131 101.789 128.989 103.25 126C104.711 128.989 107.443 131 110.571 131C113.7 131 116.431 128.989 117.893 126C119.354 128.989 122.086 131 125.214 131C128.343 131 131.074 128.989 132.536 126C133.997 128.989 136.729 131 139.857 131M161.821 126C163.283 128.989 166.014 131 169.143 131C172.271 131 175.003 128.989 176.464 126C177.926 128.989 180.657 131 183.786 131C186.914 131 189.646 128.989 191.107 126C192.569 128.989 195.3 131 198.429 131C201.557 131 204.289 128.989 205.75 126C207.211 128.989 209.943 131 213.071 131C216.2 131 218.931 128.989 220.393 126C221.854 128.989 224.586 131 227.714 131C230.843 131 233.574 128.989 235.036 126C236.497 128.989 239.229 131 242.357 131C245.486 131 248.217 128.989 249.679 126C251.14 128.989 253.872 131 257 131" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M103.32 135C104.782 137.989 107.513 140 110.642 140C113.77 140 116.502 137.989 117.963 135C119.425 137.989 122.156 140 125.285 140C128.413 140 131.145 137.989 132.606 135C134.067 137.989 136.799 140 139.927 140C143.056 140 145.787 137.989 147.249 135C148.71 137.989 151.442 140 154.57 140C157.699 140 160.43 137.989 161.892 135C163.353 137.989 166.085 140 169.213 140C172.342 140 175.073 137.989 176.535 135C177.996 137.989 180.728 140 183.856 140C186.985 140 189.716 137.989 191.177 135C192.639 137.989 195.37 140 198.499 140M220.463 135C221.925 137.989 224.656 140 227.785 140C230.913 140 233.645 137.989 235.106 135C236.567 137.989 239.299 140 242.427 140C245.556 140 248.287 137.989 249.749 135C251.21 137.989 253.942 140 257.07 140C260.199 140 262.93 137.989 264.392 135C265.853 137.989 268.585 140 271.713 140" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M37.7852 140C40.9137 140 43.6451 137.989 45.1066 135C46.568 137.989 49.2995 140 52.428 140C55.5565 140 58.288 137.989 59.7494 135C61.2109 137.989 63.9424 140 67.0709 140C70.1994 140 72.9309 137.989 74.3923 135C75.8537 137.989 78.5852 140 81.7137 140" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <circle cx="48" cy="98" r="3" stroke="#DEE3EA" stroke-width="2"/>
        <circle cx="64" cy="98" r="3" stroke="#DEE3EA" stroke-width="2"/>
      </svg>
      <p class="fw500 fz18 text-dark">The list of actual vessels is empty</p>
      <p class="fz14 text text-dark">The vessels at our disposal will be shown here. You can create a new vessel or move here from Redelivered Vessels.</p>
      <button v-on:click="openModalClean(null, null)" class="blue-button-text fz14">Create new vessel</button>
    </div>
    <div class="no-vessels" v-if="getListVesselView().length === 0 && getListVesselView().length === 0 && $route.path.indexOf('redelivered') >=0">
      <svg width="320" height="160" viewBox="0 0 320 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M57 127.5L33 105.476V86.0029H46.1595M252.5 129.5L287 86.0029H212.22L203.865 93.5407H92.1135M46.1595 86.0029H72.6875L81.0428 93.5407H92.1135M46.1595 86.0029V73.0211M46.1595 73.0211H92.1135M46.1595 73.0211H41.773V69.2522H52.6349V56.4797M92.1135 73.0211H96.2911V69.2522H92.1135V56.4797M92.1135 73.0211V93.5407M92.1135 56.4797H52.6349M92.1135 56.4797H96.2911V52.7108H92.1135V42.2416H84.5938M52.6349 56.4797H48.4572V52.7108H67.4655M84.5938 42.2416L74.1497 52.7108H67.4655M84.5938 42.2416V30.7255M67.4655 52.7108L70.8711 30.7255M71.8766 24.2346L72.6875 19H84.5938V24.2346M71.8766 24.2346H84.5938M71.8766 24.2346L70.8711 30.7255M84.5938 24.2346V30.7255M84.5938 30.7255H70.8711" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M165 17.3386C165 13.3948 162.388 13.2425 160.896 12.8812C160.564 11.5442 159.438 8.27753 155.259 8.01736C151.08 7.75719 148.955 10.4845 148.333 11.966C145.97 11.966 140 12.7857 140 17.3386C140 20.981 143.814 21.9639 145.721 22H159.776C161.517 21.8193 165 20.6341 165 17.3386Z" stroke="#DEE3EA" stroke-width="2"/>
        <path d="M212 43.3454C212 34.3309 217.537 33.9829 220.701 33.157C221.405 30.101 223.792 22.6344 232.651 22.0397C241.511 21.445 246.015 27.6789 247.333 31.0652C252.343 31.0652 265 32.9387 265 43.3454C265 51.6709 256.914 53.9174 252.871 54H223.075C219.383 53.587 212 50.878 212 43.3454Z" stroke="#DEE3EA" stroke-width="2"/>
        <path d="M52 131C55.1285 131 57.86 128.989 59.3214 126C60.7829 128.989 63.5144 131 66.6429 131C69.7714 131 72.5029 128.989 73.9643 126C75.4257 128.989 78.1572 131 81.2857 131C84.4142 131 87.1457 128.989 88.6071 126C90.0686 128.989 92.8001 131 95.9286 131C99.0571 131 101.789 128.989 103.25 126C104.711 128.989 107.443 131 110.571 131C113.7 131 116.431 128.989 117.893 126C119.354 128.989 122.086 131 125.214 131C128.343 131 131.074 128.989 132.536 126C133.997 128.989 136.729 131 139.857 131M161.821 126C163.283 128.989 166.014 131 169.143 131C172.271 131 175.003 128.989 176.464 126C177.926 128.989 180.657 131 183.786 131C186.914 131 189.646 128.989 191.107 126C192.569 128.989 195.3 131 198.429 131C201.557 131 204.289 128.989 205.75 126C207.211 128.989 209.943 131 213.071 131C216.2 131 218.931 128.989 220.393 126C221.854 128.989 224.586 131 227.714 131C230.843 131 233.574 128.989 235.036 126C236.497 128.989 239.229 131 242.357 131C245.486 131 248.217 128.989 249.679 126C251.14 128.989 253.872 131 257 131" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M103.32 135C104.782 137.989 107.513 140 110.642 140C113.77 140 116.502 137.989 117.963 135C119.425 137.989 122.156 140 125.285 140C128.413 140 131.145 137.989 132.606 135C134.067 137.989 136.799 140 139.927 140C143.056 140 145.787 137.989 147.249 135C148.71 137.989 151.442 140 154.57 140C157.699 140 160.43 137.989 161.892 135C163.353 137.989 166.085 140 169.213 140C172.342 140 175.073 137.989 176.535 135C177.996 137.989 180.728 140 183.856 140C186.985 140 189.716 137.989 191.177 135C192.639 137.989 195.37 140 198.499 140M220.463 135C221.925 137.989 224.656 140 227.785 140C230.913 140 233.645 137.989 235.106 135C236.567 137.989 239.299 140 242.427 140C245.556 140 248.287 137.989 249.749 135C251.21 137.989 253.942 140 257.07 140C260.199 140 262.93 137.989 264.392 135C265.853 137.989 268.585 140 271.713 140" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M37.7852 140C40.9137 140 43.6451 137.989 45.1066 135C46.568 137.989 49.2995 140 52.428 140C55.5565 140 58.288 137.989 59.7494 135C61.2109 137.989 63.9424 140 67.0709 140C70.1994 140 72.9309 137.989 74.3923 135C75.8537 137.989 78.5852 140 81.7137 140" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <circle cx="48" cy="98" r="3" stroke="#DEE3EA" stroke-width="2"/>
        <circle cx="64" cy="98" r="3" stroke="#DEE3EA" stroke-width="2"/>
      </svg>
      <p class="fw500 fz18 text-dark">The list of redelivered vessels is empty</p>
      <p class="fz14 text text-dark">Here will be shown the vessels that are transferred to the owner after the completion of the contract.</p>
    </div>

    <div class="list-vessel" v-for="(list, q) in getListVesselView()" :key="q">
      <p class="fz12 fw500 text-gray text-line d-flex align-items-center" v-if="$route.path.indexOf('redelivered')>=0">{{list.title}}
        <button class="cursor-pointer ml4 d-flex align-items-center justify-center" v-on:click="selectHideYear(list.title)" :class="{'rotate180': selectedHideYear.find(a=> a === list.title)}">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 4.40039L6.00001 8.40039L10 4.40039H2Z" fill="#2C2C2C"/>
          </svg>
        </button></p>
      <div class="title-block" v-if="list.array.length !== 0 && ((!selectedHideYear.find(a=> a === list.title) && $route.path.indexOf('redelivered')>=0) || $route.path.indexOf('redelivered') === -1)">
        <button class="button-checkbox" v-on:click="selectCheckboxAll">
          <svg v-if="selectedCheckboxes.length === 0" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
          </svg>
          <svg v-if="selectedCheckboxes.length !==0" class="active-checkbox-style" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="18" rx="4" fill="#094172"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.6687 8.99989C4.6687 8.47177 5.09683 8.04364 5.62495 8.04364H12.375C12.9031 8.04364 13.3312 8.47177 13.3312 8.99989C13.3312 9.52801 12.9031 9.95614 12.375 9.95614H5.62495C5.09683 9.95614 4.6687 9.52801 4.6687 8.99989Z" fill="white"/>
          </svg>
        </button>
       <button class="button-drop" v-on:click="selectExpandedViewAll" :class="{'rotate180': expandedView.length !== 0}">
         <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M2 4.40039L6.00001 8.40039L10 4.40039H2Z" fill="#2C2C2C"/>
         </svg>
       </button>
        <p class="fz13 text-gray">Vessel’s Name</p>
        <p class="fz13 text-gray">CP Date (Owner)</p>
        <p class="fz13 text-gray">Built</p>
        <p class="fz13 text-gray">DWT</p>
        <p class="fz13 text-gray">Chartering Manager</p>
        <p class="fz13 text-gray">Operation Manager</p>
      </div>
      <div class="wrap-list" :key="keyRender" v-if="((!selectedHideYear.find(a=> a === list.title) && $route.path.indexOf('redelivered')>=0) || $route.path.indexOf('redelivered') === -1)">
        <div class="list-block" v-for="(item, k) in list.array" :key="k">
          <div class="list-block-close-view" v-if="item.cp_date.indexOf(list.title) >=0 || list.title === ''">
            <button class="button-checkbox" v-on:click="selectCheckbox(JSON.stringify(item))">
              <svg v-if="selectedCheckboxes.indexOf(JSON.stringify(item)) === -1" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
              </svg>
              <svg class="active-checkbox-style" v-if="selectedCheckboxes.indexOf(JSON.stringify(item)) !== -1" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="18" height="18" rx="4" fill="#094172"/>
                <path d="M14.5 5.02141C14.1868 4.67334 13.67 4.66368 13.3456 4.99985C13.3388 5.00688 13.3321 5.01407 13.3255 5.02141L7.76395 10.9193L4.88355 8.7889C4.55922 8.45274 4.04241 8.46239 3.72918 8.81046C3.42361 9.15001 3.42361 9.68829 3.72918 10.0278L7.18677 12.7777C7.50559 13.1198 8.02236 13.1198 8.34114 12.7777L14.4799 6.26035C14.8042 5.92414 14.8132 5.36948 14.5 5.02141Z" fill="white"/>
              </svg>
            </button>
            <button class="button-drop" :class="{'rotate180': expandedView.indexOf(item.name + '' + item.id) >= 0}">
              <svg v-if="item.cargo_contracts.length" v-on:click="expandedViewClickButton(item.name, item.id, item)" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 4.40039L6.00001 8.40039L10 4.40039H2Z" fill="#2C2C2C"/>
              </svg>
            </button>
            <p class="fz13 text-dark">{{item.name}}</p>
            <p class="fz13 text-dark">{{item.cp_date | formatDateFromCpDateWithotTimeZome}}</p>
            <p class="fz13 text-dark">{{item.built}}</p>
            <p class="fz13 text-dark">{{item.dwt}}</p>
            <div class="fz13 text-dark">
              <div class="user-block" v-if="item.chartering_manager !== null">
                <img v-if="item.chartering_manager.avatar !== null" :src="getUrlForAvatar(item.chartering_manager.avatar)" alt="">
                <svg v-if="item.chartering_manager.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="80" cy="80" r="80" fill="white"/>
                  <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
                </svg>
                <p class="fz13 text-dark" v-if="item.chartering_manager !== null">{{item.chartering_manager.first_name}} {{item.chartering_manager.last_name}}</p>
              </div>
            </div>
            <div class="fz13 text-dark">
              <div class="user-block" v-if="item.operation_manager !== null">
                <img v-if="item.operation_manager.avatar !== null" :src="getUrlForAvatar(item.operation_manager.avatar)" alt="">
                <svg v-if="item.operation_manager.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="80" cy="80" r="80" fill="white"/>
                  <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
                </svg>
                <p class="fz13 text-dark" v-if="item.operation_manager !== null">{{item.operation_manager.first_name}} {{item.operation_manager.last_name}}</p>
              </div>
            </div>
            <button class="blue-button fz13 fw500" v-on:click="moveToActualOrRedelivery(item, k)" v-if="getPermissionsMove() && $route.path.indexOf('archive') === -1">Move to <span class="fz13 fw500" v-if="$route.path.indexOf('actual')>=0">Redelivered</span>  <span class="fz13 fw500" v-if="$route.path.indexOf('redelivered')>=0"> Actual</span></button>
            <drop-menu-actual-vesssel v-if="$route.path.indexOf('redelivered') === -1 || $route.path.indexOf('archive') >=0" :archive="item.is_archive" @edit="openModalClean(item.id, k)" @archive="archiveContract(item)" class="drop"></drop-menu-actual-vesssel>
            <button class="drop button-delete" v-on:click="archiveContract(item)" v-if="$route.path.indexOf('redelivered') !== -1 && $route.path.indexOf('archive') === -1">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
              </svg>
            </button>
            <router-link :to="item.id +'/open/cash-flow'" class="link-router" v-if="$route.path.indexOf('archive') === -1"></router-link>
            <app-loader v-if="item.show_load" :is-local="true" class="item-loader"></app-loader>
          </div>
          <div class="list-block-open-view list-block-open-view-title" v-if="(expandedView.indexOf(item.name + '' + item.id) !== -1 && item.cargo_contracts.length !== 0) && (item.cp_date.indexOf(list.title) >=0 || list.title === '')" >
            <p class="fz13 text-gray">Charterer</p>
            <p class="fz13 text-gray">CP Date</p>
            <p class="fz13 text-gray">Type</p>
            <p class="fz13 text-gray">Chartering Manager</p>
            <p class="fz13 text-gray">Attachments</p>
          </div>
<!--          <pre>{{item.cargo_contracts}}</pre>-->
          <div v-if="(expandedView.indexOf(item.name + '' + item.id) !== -1 && item.cargo_contracts.length !== 0) && (item.cp_date.indexOf(list.title) >=0 || list.title === '')" >
            <div class="list-block-open-view" v-for="(itemCargo, f) in item.cargo_contracts" :key="f">
              <p class="fz13 text-dark"><span v-if="itemCargo.owner !== null && !Number.isInteger(itemCargo.owner)">{{itemCargo.owner.company_name}}</span></p>
              <p class="fz13 text-dark">{{itemCargo.cp_date | formatDateFromCpDateWithotTimeZome}}</p>
              <p class="fz13 text-dark"><span v-if="itemCargo.contract_type === 'SUBLET'">Sublet</span> <span v-if="itemCargo.contract_type === 'CARGO'">Voyage</span></p>
              <span>
                   <div class="user-block" v-if="itemCargo.chartering_manager !== null">
                  <img v-if="itemCargo.chartering_manager.avatar !== null" :src="getUrlForAvatar(itemCargo.chartering_manager.avatar)" alt="">
                  <svg v-if="itemCargo.chartering_manager.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="80" cy="80" r="80" fill="white"/>
                    <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
                  </svg>
                  <p class="fz13 text-dark" v-if="itemCargo.chartering_manager !== null">{{itemCargo.chartering_manager.first_name}} {{itemCargo.chartering_manager.last_name}}</p>
                </div>
              </span>
              <p class="fz13 text-dark">
                <svg v-if="itemCargo.files.length !== 0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 20C9.24268 20 7 17.7573 7 15V8C7 7.63135 7.29871 7.33337 7.66663 7.33337C8.03467 7.33337 8.33337 7.63135 8.33337 8V15C8.33337 17.0214 9.97803 18.6666 12 18.6666C14.022 18.6666 15.6666 17.0214 15.6666 15V7.66663C15.6666 6.38 14.62 5.33337 13.3334 5.33337C12.0466 5.33337 11 6.38 11 7.66663V14.3334C11 14.8846 11.4486 15.3334 12 15.3334C12.5514 15.3334 13 14.8846 13 14.3334V8C13 7.63135 13.2987 7.33337 13.6666 7.33337C14.0347 7.33337 14.3334 7.63135 14.3334 8V14.3334C14.3334 15.62 13.2866 16.6666 12 16.6666C10.7134 16.6666 9.66663 15.62 9.66663 14.3334V7.66663C9.66663 5.64539 11.3113 4 13.3334 4C15.3553 4 17 5.64539 17 7.66663V15C17 17.7573 14.7573 20 12 20Z" fill="#4B4B4B"/>
                </svg>
              </p>
              <router-link 
                class="link-router" 
                v-if="$route.path.indexOf('archive') === -1"
                :to="`/vessels/${item.id}/open/charterers/${itemCargo.id}/details`" 
              ></router-link>
              <drop-menu-actual-vesssel :type="itemCargo.contract_type" :archive="itemCargo.is_archive" class="drop" :dop="true" @edit="openModalClean(item.id, k, f, null, itemCargo.contract_type.toLowerCase())" @unlink="openAcceptModalUnLinkVessels(item, itemCargo, k)" @archive="archiveContractInContract(item, itemCargo, k, f)"></drop-menu-actual-vesssel>
            </div>
          </div>
        </div>
      </div>
      <pagination-panel-default class="button-load-more" :if-active="loadVesselsAxios" v-if="$route.path.indexOf('redelivered') >=0 && list.nextLink !== null && list.nextLink.linkNext !== null && (''+list.nextLink.linkNext).length !== 0 && list.array.length !== 0" @load-more="getNextLinkForYear(list.nextLink)"></pagination-panel-default>
    </div>
    <pagination-panel-default class="button-load-more" :class="{'button-load-more-actual': $route.path.indexOf('vessels/actual') >= 0}" :if-active="loadVesselsAxios" v-if="getNextVessels !== null && (''+getNextVessels).length !== 0 && contracts.length !== 0 && $route.path.indexOf('redelivered') === -1" @load-more="getNextVesselsAxios()"></pagination-panel-default>




    <div class="list-cargos" v-if="$route.path.indexOf('actual')>=0 && contractsCargo.length && showNotLinkedCargo">
      <p class="title">Not Linked Cargos</p>
      <div class="wrap-list-cargos-block">
        <div class="list-cargos-block block-title">
          <button class="button-checkbox" v-on:click="selectCheckboxAllCargo">
            <svg v-if="selectedCheckboxesCargo.length === 0" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
            </svg>
            <svg v-if="selectedCheckboxesCargo.length !==0" class="active-checkbox-style" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="18" height="18" rx="4" fill="#094172"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.6687 8.99989C4.6687 8.47177 5.09683 8.04364 5.62495 8.04364H12.375C12.9031 8.04364 13.3312 8.47177 13.3312 8.99989C13.3312 9.52801 12.9031 9.95614 12.375 9.95614H5.62495C5.09683 9.95614 4.6687 9.52801 4.6687 8.99989Z" fill="white"/>
            </svg>
          </button>
          <p class="fz13 text-gray">Charterer</p>
          <p class="fz13 text-gray">Cargo</p>
          <p class="fz13 text-gray">CP Date (Charterer)</p>
          <p class="fz13 text-gray">Laycan Date</p>
          <p class="fz13 text-gray">Port of Loading</p>
          <p class="fz13 text-gray">Port of Discharge</p>
          <p class="fz13 text-gray">Attachments</p>
        </div>
        <div class="block-cargo-main">
          <div class="list-cargos-block" v-for="(cargo, j) in contractsCargo" :key="j">
            <button class="button-checkbox" v-on:click="selectCheckboxCargo(JSON.stringify(cargo))">
              <svg v-if="selectedCheckboxesCargo.indexOf(JSON.stringify(cargo)) === -1" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
              </svg>
              <svg class="active-checkbox-style" v-if="selectedCheckboxesCargo.indexOf(JSON.stringify(cargo)) !== -1" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="18" height="18" rx="4" fill="#094172"/>
                <path d="M14.5 5.02141C14.1868 4.67334 13.67 4.66368 13.3456 4.99985C13.3388 5.00688 13.3321 5.01407 13.3255 5.02141L7.76395 10.9193L4.88355 8.7889C4.55922 8.45274 4.04241 8.46239 3.72918 8.81046C3.42361 9.15001 3.42361 9.68829 3.72918 10.0278L7.18677 12.7777C7.50559 13.1198 8.02236 13.1198 8.34114 12.7777L14.4799 6.26035C14.8042 5.92414 14.8132 5.36948 14.5 5.02141Z" fill="white"/>
              </svg>
            </button>
            <p class="fz13 text-gray"><span v-if="cargo.owner !== null && !Number.isInteger(cargo.owner)">{{cargo.owner.company_name}}</span></p>
            <p class="fz13 text-gray">{{cargo.name}}</p>
            <p class="fz13 text-gray">{{cargo.cp_date | formatDateFromCpDateWithotTimeZome}}</p>
            <p class="fz13 text-gray">{{cargo.laycan_date}}</p>
            <p class="fz13 text-gray"><span v-if="cargo.delivery_place.length !== 0">{{cargo.delivery_place[0].name}}: {{cargo.delivery_place[0].country}}</span></p>
            <p class="fz13 text-gray"><span v-if="cargo.redelivery_place.length !== 0">{{cargo.redelivery_place[0].name}}: {{cargo.redelivery_place[0].country}}</span></p>
            <p class="fz13 text-gray">
              <svg v-if="cargo.files.length !== 0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 20C9.24268 20 7 17.7573 7 15V8C7 7.63135 7.29871 7.33337 7.66663 7.33337C8.03467 7.33337 8.33337 7.63135 8.33337 8V15C8.33337 17.0214 9.97803 18.6666 12 18.6666C14.022 18.6666 15.6666 17.0214 15.6666 15V7.66663C15.6666 6.38 14.62 5.33337 13.3334 5.33337C12.0466 5.33337 11 6.38 11 7.66663V14.3334C11 14.8846 11.4486 15.3334 12 15.3334C12.5514 15.3334 13 14.8846 13 14.3334V8C13 7.63135 13.2987 7.33337 13.6666 7.33337C14.0347 7.33337 14.3334 7.63135 14.3334 8V14.3334C14.3334 15.62 13.2866 16.6666 12 16.6666C10.7134 16.6666 9.66663 15.62 9.66663 14.3334V7.66663C9.66663 5.64539 11.3113 4 13.3334 4C15.3553 4 17 5.64539 17 7.66663V15C17 17.7573 14.7573 20 12 20Z" fill="#4B4B4B"/>
              </svg>
            </p>
            <button class="blue-button fz13 fw500" v-on:click="openModalLinkVessels(cargo.id, cargo)" v-if="getPermissionsLinkContract()">Link with vessel</button>
            <drop-menu-actual-vesssel :archive="cargo.is_archive" class="drop" @edit="openModalClean(cargo.id, j, null, 'contractsCargo', 'cargo')" @archive="archiveCargo(cargo, j)"></drop-menu-actual-vesssel>
          </div>
        </div>
      </div>
      <pagination-panel-default class="button-load-more" :if-active="loadVesselsCargoAxios" v-if="getNextVesselsCargo !== null && (''+getNextVesselsCargo).length !== 0 && contractsCargo.length !== 0" @load-more="getNextVesselsCargoAxios()"></pagination-panel-default>
    </div>
      <div class="bottom-panel" v-if="(selectedCheckboxes.length + selectedCheckboxesCargo.length) !== 0">
        <p class="fz13 fw500 text text-gray">
          Selected <span>{{selectedCheckboxesCargo.length + selectedCheckboxes.length}}/{{contracts.length + contractsCargo.length}}</span>
        </p>
        <button class="unarchive blue-button fz14 fw500" v-on:click="unarchiveSelected">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="$route.path.indexOf('archive') >= 0">
            <path  d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"></path>
            <path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#9AA3B0"></path>
          </svg>
          <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
          </svg>
          {{$route.path.indexOf('archive') >= 0 ? 'Unarchive' : 'Archive'}}
        </button>
        <button class="cancel" v-on:click="cancelSelected">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"></path>
            <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"></path>
          </svg>
        </button>
      </div>
<!--    <pre>{{contractsCargo}}</pre>-->
<!--    <pre>{{contracts.map(a=> {return a.owner}).filter(a=>a !== null).map(a=> {return a.company_name})}}</pre>-->
    <modal-slots class="modal-link-vessels" :show-mod="showModalLinkVessels" @close="closeModalLinkVessel">
      <template v-slot:title>
        <p class="fz18 fw500 text-dark">Link Contracts</p>
      </template>
      
      <input type="text" class="search" placeholder="Search" v-model="searchText2" @focusout="searchContractForLink" v-on:keydown.enter="searchContractForLink">
      <div class="link-block" v-if="contractsForLink.length !== 0">
        <p class="fz13 text-gray">Vessel</p>
        <p class="fz13 text-gray">Chartering Manager</p>
      </div>
      <div class="link-block link-vessel" v-for="(item, i) in contractsForLink" :key="i" v-on:click="openAcceptModalLinkVessels(item, i)">
        <p class="fz13 text-dark">{{item.name}}</p>
        <span>
          <div class="user-block" v-if="item.chartering_manager !== null">
            <img v-if="item.chartering_manager.avatar !== null" :src="getUrlForAvatar(item.chartering_manager.avatar)" alt="">
            <svg v-if="item.chartering_manager.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="80" cy="80" r="80" fill="white"/>
              <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
            </svg>
            <p class="fz13 text-dark" v-if="item.chartering_manager !== null">{{item.chartering_manager.first_name}} {{item.chartering_manager.last_name}}</p>
          </div>
        </span>
      </div>
      <app-loader class="load-modal" v-if="loadVesselsAxios2 && contractsForLink.length === 0"></app-loader>
      <pagination-panel-default class="button-load-more" v-if="getNextVessels2 !== null && (''+getNextVessels2).length !== 0 && contractsForLink.length !== 0" :if-active="loadVesselsAxios2"  @load-more="showMoreContractsForLink()"></pagination-panel-default>
    </modal-slots>

    <modal-slots class="acceptModalLinkVessels" :textForSecondButton="'Link'" :show-mod="showAcceptModalLinkVessels" @close="closeAcceptModalLinkVessels" @apply="clickLinkVessels(contractAccept, contractAcceptIndex)">
      <template v-slot:title>
        <p class="fz18 fw500 text-dark">Link Contracts</p>
      </template>
      <p class="fz14 text text-dark">Are you sure you want to link the <span class="fw500" v-if="contractAccept !== null"> ”{{contractAccept.name}} {{contractAccept.cp_date}}” </span> and <span class="fw500" v-if="contractCargoAccept !== null"> ”<span v-if="contractCargoAccept.owner !== null && !Number.isInteger(contractCargoAccept.owner)"> {{contractCargoAccept.owner.company_name}} </span> {{contractCargoAccept.cp_date}}” </span> contracts together?</p>
    </modal-slots>

    <modal-slots class="acceptModalLinkVessels" :show-mod="showAcceptModalUnLinkVessels" @close="closeAcceptModalUnLinkVessels" @apply="unlink(contractAccept, contractCargoAccept,contractAcceptIndex)">
      <template v-slot:title>
        <p class="fz18 fw500">Unlink Contracts</p>
      </template>
      <p class="fz14 text">This contract is already linked to the contract <span class="fw500" v-if="contractAccept !== null"> ”{{contractAccept.name}} {{contractAccept.cp_date}}” </span>. Are you sure you want to unlink this contract?</p>
    </modal-slots>
    <counterparties-add class="add-client-modal" @close="showAddClient = false" :inside-block-type-modal="true" :key="showAddClient" v-if="showAddClient"></counterparties-add>
    <modal-slots :is-pointer-bg="false" :class="{'displayNone': showAddClient}" :no-close-after-apply="true" :show-mod="showModalEditClean" @close="closeModalEditClean" @apply="applyCleanFixed" class="modal-edit-clean">
      <template v-slot:title>
        <p class="fz18 fw500" v-if="modalCreateText">Create new vessel</p>
        <p class="fz18 fw500" v-if="typeForModalVessel === 'ship' && !modalCreateText">Edit Vessel</p>
        <p class="fz18 fw500" v-if="typeForModalVessel === 'cargo' && !modalCreateText">Edit Voyage</p>
        <p class="fz18 fw500" v-if="typeForModalVessel === 'sublet' && !modalCreateText">Edit Sublet</p>
      </template>
      <div class="modal-edit-clean-content">
        <async-select
            :class="{'block-input_error': $v.activeOwner.$error}"
            class="block-input"
            :imported-data="true"
            id="counterparty"
            :label="(typeForModalVessel !== 'cargo') ? 'Owner' : 'Charterer'"
            url="/client/dropdown"
            :params="{is_archived: false, ordering: 'company_name'}"
            watchKey="company_name"
            :allowEmpty="true"
            :closeOnSelect="true"
            v-model="activeOwner"
        >
          <template v-slot:additional-label>
            <button class="m-b-1 text-blue-dark fz13" v-on:click="showAddClient = true">Create new counterparty</button>
          </template>
        </async-select>
        <div class="block-from-inputs" :class="{ 'block-input_error': $v.vesselNameEdit.$error }">
          <p class="fz13 text-gray">Vessel Name <span class="text-red">*</span></p>
          <input type="text" class="h36" maxlength="100" v-model="vesselNameEdit" @focusout="$v.vesselNameEdit.$touch()">
        </div>
        <div class="block-from-inputs">
          <p class="fz13 text-gray">CP Date <span class="text-red">*</span></p>
          <date-picker
              v-model="CPDate"
              class="datepick"
              :class="{ 'block-input_error': $v.CPDate.$error }"
              ref="datePickDue"
              @change="$v.CPDate.$touch()"
              format="DD.MM.YYYY"
          >
          </date-picker>
        </div>
        <div class="block-from-inputs">
          <p class="fz13 text-gray">Laycan Date</p>
          <!--          <input type="text" class="h36" maxlength="300" v-model="laycanDateEdit">-->
          <date-picker
              value-type="format"
              placeholder=""
              class="datepickeer w100Proc"
              v-model="laycanDateEdit"
              range
              format="DD.MM.YYYY"
              popup-class="dateLeft0"
          ></date-picker>
        </div>
        <div class="block-from-inputs" v-if="typeForModalVessel !== 'cargo'">
          <p class="fz13 text-gray">DWT</p>
          <input type="text" maxlength="50" class="h36" v-model="dwtEdit" @input="dwtEdit=checkNumIn(dwtEdit)">
        </div>
        <div class="block-from-inputs" v-if="typeForModalVessel !== 'cargo'">
          <p class="fz13 text-gray">Built</p>
          <input type="text" class="h36" maxlength="50" v-model="builtEdit" @input="builtEdit=checkNumIn(builtEdit)">
        </div>
        <div class="block-from-inputs" v-if="typeForModalVessel !== 'cargo'">
          <p class="fz13 text-gray">Percentage</p>
          <input type="text" maxlength="50" class="h36" v-model="perEdit" @input="perEdit=checkNumIn(perEdit)">
        </div>
        <div v-if="typeForModalVessel !== 'cargo'">
          <div class="two-inputs">
            <div class="position-relative box-border">
              <div class="blue-button-text button-new-port fz13" v-on:click="isShowModal = true">Create new port</div>
              <async-select-port
                  :key="keyRenderPorts"
                  class="drop block-from-inputs"
                  :label="(typeForModalVessel !== 'cargo') ? 'Delivery' : 'Port of Loading'"
                  v-model="delivery_place"
              ></async-select-port>
            </div>
            <div class="block-from-inputs">
              <p class="fz13 text-gray">Delivery Date</p>
              <date-picker
                  v-model="deliveryDate"
                  type="datetime"
                  class="datepick"
                  :show-time-panel="showTimePanel"
                  ref="datePickDue"
                  format="DD.MM.YYYY, HH:mm"
                  @close="showTimePanel=false"
              >
                <template v-slot:footer>
                  <button class="mx-btn mx-btn-text" @click="showTimePanel= !showTimePanel">
                    {{ showTimePanel ? 'Select date' : 'Select time' }}
                  </button>
                </template>
              </date-picker>
            </div>
          </div>
          <div class="two-inputs">
            <div class="position-relative box-border">
              <div class="blue-button-text button-new-port fz13" v-on:click="isShowModal = true">Create new port</div>
              <async-select-port
                  :key="keyRenderPorts"
                  class="drop block-from-inputs"
                  :label="(typeForModalVessel !== 'cargo') ? 'Redelivery' : 'Port of Discharge'"
                  v-model="redelivery_place"
              ></async-select-port>
            </div>
            <div class="block-from-inputs">
              <p class="fz13 text-gray">Redelivery Date</p>
              <date-picker
                  v-model="redeliveryDate"
                  type="datetime"
                  class="datepick"
                  :show-time-panel="showTimePanel"
                  ref="datePickDue"
                  format="DD.MM.YYYY, HH:mm"
                  @close="showTimePanel=false"
              >
                <template v-slot:footer>
                  <button class="mx-btn mx-btn-text" @click="showTimePanel= !showTimePanel">
                    {{ showTimePanel ? 'Select date' : 'Select time' }}
                  </button>
                </template>
              </date-picker>
            </div>
          </div>
        </div>
        <div class="block-from-inputs" v-if="typeForModalVessel === 'cargo'">
          <p class="fz13 text-gray">Cargo</p>
          <textarea maxlength="2000" v-model="cargoCommentEdit"></textarea>
        </div>
        <div class="position-relative box-border" v-if="typeForModalVessel === 'cargo'">
          <div class="blue-button-text button-new-port fz13" v-on:click="isShowModal = true">Create new port</div>
          <async-select-port
              :key="keyRenderPorts"
              class="drop block-from-inputs"
              :label="(typeForModalVessel !== 'cargo') ? 'Delivery' : 'Port of Loading'"
              v-model="delivery_place"
          ></async-select-port>
        </div>
        <div class="position-relative box-border" v-if="typeForModalVessel === 'cargo'">
          <div class="blue-button-text button-new-port fz13" v-on:click="isShowModal = true">Create new port</div>
          <async-select-port
              :key="keyRenderPorts"
              class="drop block-from-inputs"
              :label="(typeForModalVessel !== 'cargo') ? 'Redelivery' : 'Port of Discharge'"
              v-model="redelivery_place"
          ></async-select-port>
        </div>

        <div class="block-from-inputs" v-if="typeForModalVessel !== 'cargo'">
          <p class="fz13 text-gray">Period</p>
          <!--          <date-picker-->
          <!--              v-model="periodEdit"-->
          <!--              class="datepick"-->
          <!--              ref="datePickDue"-->
          <!--              format="DD.MM.YYYY"-->
          <!--              range-->
          <!--          >-->
          <!--          </date-picker>-->
          <input type="text" v-model="periodEdit">
        </div>

        <div class="block-from-inputs" v-if="typeForModalVessel !== 'cargo'">
          <p class="fz13 text-gray">Hire rate</p>
          <input type="text" class="h36" maxlength="50" v-model="hireEdit" @input="hireEdit =checkNum(hireEdit)">
          <counterparties-drop
              :removeSelectItemBeforeClick="false"
              class="valute-drop"
              title=""
              :list="valutaList"
              @select="setValuta"
              :active-item="defaultActiveValuta"
          ></counterparties-drop>
        </div>
        <div class="block-from-inputs" v-if="typeForModalVessel !== 'cargo'">
          <p class="fz13 text-gray">Operations Manager</p>
          <async-select-employees :height36="true" :is-multiple="false" v-model="assignedOperations" :permission-assigned="typeForModalVessel"></async-select-employees>
          <!--          <assignee-task :default-active="defaultActiveOperations" :permissionAssigned="typeForModalVessel === 'cargo' ? 'voyage' : typeForModalVessel" :one-user="true" ref="assigneeTask" @oneElementClick="setAssignedOperations"></assignee-task>-->
        </div>
        <div class="block-from-inputs">
          <p class="fz13 text-gray">Chartering Manager</p>
          <async-select-employees :height36="true" :is-multiple="false" v-model="assignedChartering" :permission-assigned="typeForModalVessel"></async-select-employees>
          <!--          <assignee-task :default-active="defaultActiveChartering" :permissionAssigned="typeForModalVessel === 'cargo' ? 'voyage' : typeForModalVessel" :one-user="true" ref="assigneeTask1" @oneElementClick="setAssignedChartering"></assignee-task>-->
        </div>
        <!--          <counterparties-drop-->
        <!--              class="drop block-from-inputs"-->
        <!--              title="Operations Manager"-->
        <!--              :list = "regionList"-->
        <!--              :active-item="defaultRegionActive"-->
        <!--          ></counterparties-drop>-->
        <!--          <counterparties-drop-->
        <!--              class="drop block-from-inputs"-->
        <!--              title="Chartering Manager"-->
        <!--              :list = "regionList"-->
        <!--              :active-item="defaultRegionActive"-->
        <!--          ></counterparties-drop>-->
        <!--        <counterparties-drop-->
        <!--            :removeSelectItemBeforeClick="true"-->
        <!--            class="drop block-from-inputs"-->
        <!--            title="Broker"-->
        <!--            :list = "getClientsFormAddModal"-->
        <!--            @select="setActiveBroker"-->
        <!--            :active-item="defaultActiveBroker"-->
        <!--        ></counterparties-drop>-->
        <async-select
            id="counterparty"
            label="Broker"
            url="/client/dropdown"
            :params="{is_archived: false, ordering: 'company_name', has_broker: true}"
            watchKey="company_name"
            :allowEmpty="true"
            :closeOnSelect="true"
            v-model="activeBroker"
        >
          <template v-slot:additional-label>
            <button class="m-b-1 text-blue-dark fz13" v-on:click="showAddClient = true">Create new broker</button>
          </template>
        </async-select>
        <div class="block-from-inputs">
          <p class="fz13 text-gray">Comment</p>
          <textarea maxlength="2000" v-model="commentEdit"></textarea>
        </div>
        <div class="block-from-inputs" :class="{ 'block-input_error': $v.attachmentsForModal.$error }">
          <p class="fz13 text-gray">Attachments <span class="text-red">*</span></p>
          <div class="file-block">
            <vue-dropzone :options="dropzoneOptions" :useCustomSlot=true ref="dropzone" id="dropzone" @vdropzone-file-added="test" @vdropzone-files-added="test" @vdropzone-success="test" @vdropzone-removed-file="test">
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
                <div class="subtitle">...or click to select a file from your computer</div>
              </div>
            </vue-dropzone>
          </div>
        </div>
      </div>
    </modal-slots>
    <add-modal
        :no-redirect="true"
        :isShowModal="isShowModal"
        @on-close="isShowModal = false"
        @added="reloadPorts"
    ></add-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import addModal from '@/components/ports/list/add'
import DropMenuActualVesssel from "@/components/vessels/actual-vessel/drop-menu-actual-vesssel";
import ModalSlots from "@/components/modal/modal-slots";
import Vue from 'vue'
import moment from 'moment';
import CounterpartiesDrop from "@/components/counterparties/counterpartiesAdd/counterpartiesDrop";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import DatePicker from 'vue2-datepicker';
import ActualVesselFilter from "@/components/vessels/actual-vessel/actual-vessel-filter";
import { validationMixin } from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import AsyncSelectPort from "@/components/reuse/asyncSelectPort";
import PaginationPanelDefault from "@/components/ports/paginationDefault";
import axios from "axios";
import AppLoader from "@/components/loader";
import AsyncSelect from "@/components/reuse/asyncSelect";
import AsyncSelectEmployees from "@/components/tasks/async-select-employees";
import CounterpartiesAdd from "@/views/counterparties/counterpartiesAdd";
import sortVessel from "../../../components/sort-vessel.vue";


export default {
  mixins: [validationMixin],

  validations: {
    vesselNameEdit: {
      required
    },
    activeOwner: {
      required
    },
    CPDate: {
      required
    },
    attachmentsForModal: {
      required,
    }
  },
  name: "actual-vessels",
  components: {
    sortVessel,
    CounterpartiesAdd,
    addModal,
    AsyncSelectEmployees,
    AsyncSelect,
    AppLoader,
    PaginationPanelDefault,
    AsyncSelectPort, ActualVesselFilter, vueDropzone: vue2Dropzone, DatePicker, CounterpartiesDrop, ModalSlots, DropMenuActualVesssel},
  computed: {
    ...mapGetters(["getStatusUnlink", "getToken", "getNextVesselsCargo", "getNextVessels", "getContractsCargo", "getPermissionsForUser", "getPermissionsByType", "getPortsForVessel", "getContracts", "getResponseContract"]),
    ...mapState({
      yearsRedelivery: state=> state.ships.yearsRedelivery,
      clients: state => state.counterparties.clientsDrop,
    }),
    getClientsFormAddModal() {
      let clients = [];
      clients = this.clients.map(a=> {
        return {id: a.id, name: a.company_name}
      })
      return clients
    },
    getLinkToCreateClient() {
      if(this.modalCreateText || !this.dataShipModal?._v_ship) return `/counterparties/add-counterparties?from=vessels`
      else if(this.typeForModalVessel === 'ship') return `/counterparties/add-counterparties?from=vessels&vessel=${this.dataShipModal?.id}`
      else if(this.dataShipModal?._v_ship) return `/counterparties/add-counterparties?from=vessels&vessel=${this.dataShipModal?._v_ship}&subcontract=${this.dataShipModal?.id}`
      else return ''
    }
  },
  data() {
    return {
      activeSort: 'cp_date',
      paramsData: null,
      selectedHideYear: [],
      isShowModal: false,
      searchText: "",
      searchText1: "",
      redelivery_place: null,
      delivery_place: null,
      selectedCheckboxes: [],
      selectedCheckboxesCargo: [],
      expandedView: [],
      contracts: [],
      contractsAll: [],
      contractsCargo: [],
      showModalLinkVessels: false,
      idCargoForModalLinkVessels: null,
      showAcceptModalLinkVessels: false,
      contractAccept: null,
      contractAcceptIndex: null,
      contractCargoAccept: null,
      showAcceptModalUnLinkVessels: false,
      modalCreateText: true,

      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        addRemoveLinks: true,
      },
      regionList: [
        {name: "AG", id: "AG"},
        {name: "ARAG", id: "ARAG"},
        {name: "BALTIC", id: "BALTIC"},
        {name: "BLACK SEA", id: "BLACK SEA"},
        {name: "CARIBS", id: "CARIBS"},
        {name: "CASPIAN", id: "CASPIAN"},
        {name: "CENTRAL ATLANTIC", id: "CENTRAL ATLANTIC"},
        {name: "E AFRICA", id: "E AFRICA"},
        {name: "E AUSSIE", id: "E AUSSIE"},
        {name: "E MED", id: "E MED"},
        {name: "EC CAN", id: "EC CAN"},
        {name: "EC INDIA", id: "EC INDIA"},
        {name: "ECCA", id: "ECCA"},
        {name: "ECSA", id: "ECSA"},
        {name: "FEAST", id: "FEAST"},
        {name: "French Atl", id: "French Atl"},
        {name: "GREAT LAKES", id: "GREAT LAKES"},
        {name: "INDIAN OCEAN", id: "INDIAN OCEAN"},
        {name: "MID PACIFIC", id: "MID PACIFIC"},
        {name: "MIDNORTH ATLANTIC", id: "MIDNORTH ATLANTIC"},
        {name: "N CONT", id: "N CONT"},
        {name: "NCSA", id: "NCSA"},
        {name: "NEST PASSAGE", id: "NEST PASSAGE"},
        {name: "NOPAC", id: "NOPAC"},
        {name: "NW AFRICA", id: "NW AFRICA"},
        {name: "NWST PASSAGE", id: "NWST PASSAGE"},
        {name: "NZ", id: "NZ"},
        {name: "RED SEA", id: "RED SEA"},
        {name: "SE ASIA", id: "SE ASIA"},
        {name: "ST LAWRENCE", id: "ST LAWRENCE"},
        {name: "STH AFRICA", id: "STH AFRICA"},
        {name: "STH ANTARCTICA", id: "STH ANTARCTICA"},
        {name: "STH INDIAN OCEAN", id: "STH INDIAN OCEAN"},
        {name: "STH PACIFIC", id: "STH PACIFIC"},
        {name: "SW AFRICA", id: "SW AFRICA"},
        {name: "Spain Atl", id: "Spain Atl"},
        {name: "UK EIRE", id: "UK EIRE"},
        {name: "USEC", id: "USEC"},
        {name: "ASG", id: "ASG"},
        {name: "W AUSSIE", id: "W AUSSIE"},
        {name: "W MED", id: "W MED"},
        {name: "WC INDIA", id: "WC INDIA"},
        {name: "WCCA", id: "WCCA"},
        {name: "WCSA", id: "WCSA"},
      ],
      valutaList: [
        {
          "name": "USD",
          "id": "USD"
        },
        {
          "name": "ZMW",
          "id": "ZMW"
        },
        {
          "name": "ZAR",
          "id": "ZAR"
        },
        {
          "name": "YER",
          "id": "YER"
        },
        {
          "name": "XPF",
          "id": "XPF"
        },
        {
          "name": "XOF",
          "id": "XOF"
        },
        {
          "name": "XCD",
          "id": "XCD"
        },
        {
          "name": "XAF",
          "id": "XAF"
        },
        {
          "name": "WST",
          "id": "WST"
        },
        {
          "name": "VUV",
          "id": "VUV"
        },
        {
          "name": "VND",
          "id": "VND"
        },
        {
          "name": "VES",
          "id": "VES"
        },
        {
          "name": "UZS",
          "id": "UZS"
        },
        {
          "name": "UYU",
          "id": "UYU"
        },
        {
          "name": "UGX",
          "id": "UGX"
        },
        {
          "name": "UAH",
          "id": "UAH"
        },
        {
          "name": "TZS",
          "id": "TZS"
        },
        {
          "name": "TWD",
          "id": "TWD"
        },
        {
          "name": "TVD",
          "id": "TVD"
        },
        {
          "name": "TTD",
          "id": "TTD"
        },
        {
          "name": "TRY",
          "id": "TRY"
        },
        {
          "name": "TOP",
          "id": "TOP"
        },
        {
          "name": "TND",
          "id": "TND"
        },
        {
          "name": "TMT",
          "id": "TMT"
        },
        {
          "name": "TJS",
          "id": "TJS"
        },
        {
          "name": "THB",
          "id": "THB"
        },
        {
          "name": "SZL",
          "id": "SZL"
        },
        {
          "name": "SYP",
          "id": "SYP"
        },
        {
          "name": "STN",
          "id": "STN"
        },
        {
          "name": "SSP",
          "id": "SSP"
        },
        {
          "name": "SRD",
          "id": "SRD"
        },
        {
          "name": "SOS",
          "id": "SOS"
        },
        {
          "name": "SLL",
          "id": "SLL"
        },
        {
          "name": "SHP",
          "id": "SHP"
        },
        {
          "name": "SGD",
          "id": "SGD"
        },
        {
          "name": "SEK",
          "id": "SEK"
        },
        {
          "name": "SDG",
          "id": "SDG"
        },
        {
          "name": "SCR",
          "id": "SCR"
        },
        {
          "name": "SBD",
          "id": "SBD"
        },
        {
          "name": "SAR",
          "id": "SAR"
        },
        {
          "name": "RWF",
          "id": "RWF"
        },
        {
          "name": "RUB",
          "id": "RUB"
        },
        {
          "name": "RSD",
          "id": "RSD"
        },
        {
          "name": "RON",
          "id": "RON"
        },
        {
          "name": "QAR",
          "id": "QAR"
        },
        {
          "name": "PYG",
          "id": "PYG"
        },
        {
          "name": "PRB",
          "id": "PRB"
        },
        {
          "name": "PND",
          "id": "PND"
        },
        {
          "name": "PLN",
          "id": "PLN"
        },
        {
          "name": "PKR",
          "id": "PKR"
        },
        {
          "name": "PHP",
          "id": "PHP"
        },
        {
          "name": "PGK",
          "id": "PGK"
        },
        {
          "name": "PEN",
          "id": "PEN"
        },
        {
          "name": "PAB",
          "id": "PAB"
        },
        {
          "name": "OMR",
          "id": "OMR"
        },
        {
          "name": "NZD",
          "id": "NZD"
        },
        {
          "name": "NPR",
          "id": "NPR"
        },
        {
          "name": "NOK",
          "id": "NOK"
        },
        {
          "name": "NIO",
          "id": "NIO"
        },
        {
          "name": "NGN",
          "id": "NGN"
        },
        {
          "name": "NAD",
          "id": "NAD"
        },
        {
          "name": "MZN",
          "id": "MZN"
        },
        {
          "name": "MYR",
          "id": "MYR"
        },
        {
          "name": "MXN",
          "id": "MXN"
        },
        {
          "name": "MWK",
          "id": "MWK"
        },
        {
          "name": "MVR",
          "id": "MVR"
        },
        {
          "name": "MUR",
          "id": "MUR"
        },
        {
          "name": "MRU",
          "id": "MRU"
        },
        {
          "name": "MOP",
          "id": "MOP"
        },
        {
          "name": "MNT",
          "id": "MNT"
        },
        {
          "name": "MMK",
          "id": "MMK"
        },
        {
          "name": "MKD",
          "id": "MKD"
        },
        {
          "name": "MGA",
          "id": "MGA"
        },
        {
          "name": "MDL",
          "id": "MDL"
        },
        {
          "name": "MAD",
          "id": "MAD"
        },
        {
          "name": "LYD",
          "id": "LYD"
        },
        {
          "name": "LSL",
          "id": "LSL"
        },
        {
          "name": "LRD",
          "id": "LRD"
        },
        {
          "name": "LKR",
          "id": "LKR"
        },
        {
          "name": "LBP",
          "id": "LBP"
        },
        {
          "name": "LAK",
          "id": "LAK"
        },
        {
          "name": "KZT",
          "id": "KZT"
        },
        {
          "name": "KYD",
          "id": "KYD"
        },
        {
          "name": "KWD",
          "id": "KWD"
        },
        {
          "name": "KRW",
          "id": "KRW"
        },
        {
          "name": "KPW",
          "id": "KPW"
        },
        {
          "name": "KMF",
          "id": "KMF"
        },
        {
          "name": "KHR",
          "id": "KHR"
        },
        {
          "name": "KGS",
          "id": "KGS"
        },
        {
          "name": "KES",
          "id": "KES"
        },
        {
          "name": "JPY",
          "id": "JPY"
        },
        {
          "name": "JOD",
          "id": "JOD"
        },
        {
          "name": "JMD",
          "id": "JMD"
        },
        {
          "name": "ISK",
          "id": "ISK"
        },
        {
          "name": "IRR",
          "id": "IRR"
        },
        {
          "name": "IQD",
          "id": "IQD"
        },
        {
          "name": "INR",
          "id": "INR"
        },
        {
          "name": "ILS",
          "id": "ILS"
        },
        {
          "name": "IDR",
          "id": "IDR"
        },
        {
          "name": "HUF",
          "id": "HUF"
        },
        {
          "name": "HTG",
          "id": "HTG"
        },
        {
          "name": "HRK",
          "id": "HRK"
        },
        {
          "name": "HNL",
          "id": "HNL"
        },
        {
          "name": "HKD",
          "id": "HKD"
        },
        {
          "name": "GYD",
          "id": "GYD"
        },
        {
          "name": "GTQ",
          "id": "GTQ"
        },
        {
          "name": "GNF",
          "id": "GNF"
        },
        {
          "name": "GMD",
          "id": "GMD"
        },
        {
          "name": "GIP",
          "id": "GIP"
        },
        {
          "name": "GHS",
          "id": "GHS"
        },
        {
          "name": "GGP",
          "id": "GGP"
        },
        {
          "name": "GEL",
          "id": "GEL"
        },
        {
          "name": "GBP",
          "id": "GBP"
        },
        {
          "name": "FOK",
          "id": "FOK"
        },
        {
          "name": "FKP",
          "id": "FKP"
        },
        {
          "name": "FJD",
          "id": "FJD"
        },
        {
          "name": "EUR",
          "id": "EUR"
        },
        {
          "name": "ETB",
          "id": "ETB"
        },
        {
          "name": "ERN",
          "id": "ERN"
        },
        {
          "name": "EGP",
          "id": "EGP"
        },
        {
          "name": "DZD",
          "id": "DZD"
        },
        {
          "name": "DOP",
          "id": "DOP"
        },
        {
          "name": "DKK",
          "id": "DKK"
        },
        {
          "name": "DJF",
          "id": "DJF"
        },
        {
          "name": "CZK",
          "id": "CZK"
        },
        {
          "name": "CVE",
          "id": "CVE"
        },
        {
          "name": "CUP",
          "id": "CUP"
        },
        {
          "name": "CUC",
          "id": "CUC"
        },
        {
          "name": "CRC",
          "id": "CRC"
        },
        {
          "name": "COP",
          "id": "COP"
        },
        {
          "name": "CNY",
          "id": "CNY"
        },
        {
          "name": "CLP",
          "id": "CLP"
        },
        {
          "name": "CKD",
          "id": "CKD"
        },
        {
          "name": "CHF",
          "id": "CHF"
        },
        {
          "name": "CDF",
          "id": "CDF"
        },
        {
          "name": "CAD",
          "id": "CAD"
        },
        {
          "name": "BZD",
          "id": "BZD"
        },
        {
          "name": "BYN",
          "id": "BYN"
        },
        {
          "name": "BWP",
          "id": "BWP"
        },
        {
          "name": "BTN",
          "id": "BTN"
        },
        {
          "name": "BSD",
          "id": "BSD"
        },
        {
          "name": "BRL",
          "id": "BRL"
        },
        {
          "name": "BOB",
          "id": "BOB"
        },
        {
          "name": "BND",
          "id": "BND"
        },
        {
          "name": "BMD",
          "id": "BMD"
        },
        {
          "name": "BIF",
          "id": "BIF"
        },
        {
          "name": "BHD",
          "id": "BHD"
        },
        {
          "name": "BGN",
          "id": "BGN"
        },
        {
          "name": "BDT",
          "id": "BDT"
        },
        {
          "name": "BBD",
          "id": "BBD"
        },
        {
          "name": "BAM",
          "id": "BAM"
        },
        {
          "name": "AZN",
          "id": "AZN"
        },
        {
          "name": "AWG",
          "id": "AWG"
        },
        {
          "name": "AUD",
          "id": "AUD"
        },
        {
          "name": "ARS",
          "id": "ARS"
        },
        {
          "name": "AOA",
          "id": "AOA"
        },
        {
          "name": "ANG",
          "id": "ANG"
        },
        {
          "name": "AMD",
          "id": "AMD"
        },
        {
          "name": "ALL",
          "id": "ALL"
        },
        {
          "name": "AFN",
          "id": "AFN"
        },
        {
          "name": "AED",
          "id": "AED"
        }
      ],
      showModalEditClean: false,
      CPDate: "",
      showTimePanel: false,
      deliveryDate: "",
      redeliveryDate: "",
      errorCPDate: false,
      activeBroker: null,
      defaultActiveBroker: null,
      vesselNameEdit: "",
      builtEdit: "",
      showAddClient: false,
      dwtEdit: "",
      perEdit: "",
      defaultOwnerActive: null,
      activeOwner: null,
      deliveryActive: null,
      redeliveryActive: null,
      defaultDeliveryActive: null,
      defaultRedeliveryActive: null,
      activeValuta: null,
      defaultActiveValuta: {name: "USD", id: "USD"},
      laycanDateEdit: "",
      hireEdit: "",
      assignedOperations: null,
      assignedChartering: null,
      commentEdit: "",
      cargoCommentEdit: "",
      periodEdit: "",
      defaultActiveOperations: null,
      defaultActiveChartering: null,
      idShipForModal: null,
      ports: [],
      contractCreateModal: true,
      indexShipForModal: null,
      keyRender: -9999,
      contractValueName: "contracts",
      indexShipForModalInContract: null,
      typeForModalVessel: 'ship',
      attachmentsForModal: [],
      dataShipModal: null,
      firstRequest: true,
      dataFilter: null,
      countRequestAxios: 0,
      loadVesselsCargoAxios: false,
      loadVesselsAxios: false,
      showNotLinkedCargo: true,
      contractsForLink: [],
      getNextVessels2: "",
      loadVesselsAxios2: false,
      searchText2: "",
      searchText3: "",
      keyRenderPorts: -328746234,
      linksNextRedelivery: [],
    }
  },
  watch: {
    selectedHideYear: function () {
      let years = this.getListVesselView().filter(a=> this.selectedHideYear.indexOf(a.title) === -1 && !a.array.length).map(a=> {return a.title})
      this.loadContractForYears(years)
    },
    $route: function () {
      this.firstRequest = true;
      this.searchText = "";
      this.searchText1 = "";
      this.dataFilter = null;
      // if(this.$route.path.indexOf('actual') >= 0) {
      //   this.axiosGetAllContracts({is_redelivery: false});
      // }
      // if(this.$route.path.indexOf('redelivered') >= 0) {
      //   this.axiosGetAllContracts({is_redelivery: true});
      // }
      // let is_archive = false;
      // if(this.$route.path.indexOf('archive')>=0) {
      //   is_archive = true;
      // }
      // if(this.$route.path.indexOf('actual') >= 0) {
      //   this.axiosGetAllContracts({is_redelivery: false, is_archive: is_archive});
      // }
      // if(this.$route.path.indexOf('redelivered') >= 0) {
      //   this.axiosGetAllContracts({is_redelivery: true, is_archive: is_archive});
      // }
      this.selectedCheckboxes = [];
      this.selectedCheckboxesCargo = [];
      this.expandedView = [];
      this.contracts = [];
      this.contractsCargo = [];
    },
    getContracts:  function () {
      if(this.getContracts !== null) {
        // let cargo_contracts = [];
        this.contracts = this.getContracts.map(a=> {a['show_load'] = false; a['is_loaded'] = false; return a})
        // this.contracts = this.contracts.map(a=> {
        //   if(a.owner && Number.isInteger(a.owner)) {
        //     let data = null;
        //     (async () => {
        //       data = await this.axiosGetClientsByIdReturnResponse(a.owner)
        //       a.owner = data
        //     })()
        //   }
        //   if(a.cargo_contracts.length !== 0) {
        //     a.cargo_contracts.map(c=> {
        //       cargo_contracts.push(c);
        //     })
        //   }
        //   return a;
        // })
        // this.contractsAll = this.contracts;
        // // this.contractsCargo = this.contracts.filter(a=> a.contract_type === 'CARGO' || a.contract_type === 'SUBLET')
        // this.contracts = this.contracts.filter(a=> a.contract_type !== 'CARGO' && a.contract_type !== 'SUBLET');
        // this.contractsAll = this.contractsCargo;
        // this.cargoContactSet(cargo_contracts)
        // if(cargo_contracts.length !== 0) {
        //   this.contractsCargo = this.contractsCargo.filter(b=> cargo_contracts.indexOf(b.id) === -1)
        // }

      }
    },
    getContractsCargo: function () {
      if(this.getContractsCargo !== null) {
        // let cargo_contracts = [];
        this.contractsCargo = this.getContractsCargo
        // this.contracts = this.contracts.map(a=> {
        //   if(a.owner && Number.isInteger(a.owner)) {
        //     let data = null;
        //     (async () => {
        //       data = await this.axiosGetClientsByIdReturnResponse(a.owner)
        //       a.owner = data
        //     })()
        //   }
        //   if(a.cargo_contracts.length !== 0) {
        //     a.cargo_contracts.map(c=> {
        //       cargo_contracts.push(c);
        //     })
        //   }
        //   return a;
        // })
        // this.contractsAll = this.contracts;
        // this.contractsCargo = this.contracts.filter(a=> a.contract_type === 'CARGO' || a.contract_type === 'SUBLET')
        // this.contracts = this.contracts.filter(a=> a.contract_type !== 'CARGO' && a.contract_type !== 'SUBLET');
        // this.contractsAll = this.contractsCargo;

        // this.cargoContactSet(cargo_contracts)
        // if(cargo_contracts.length !== 0) {
        //   this.contractsCargo = this.contractsCargo.filter(b=> cargo_contracts.indexOf(b.id) === -1)
        // }

      }
    },
    countRequestAxios: function () {
      console.log('req')
      console.log(this.countRequestAxios)
      if(this.countRequestAxios === 0) {
        this.hideLoad();
      } else {
        this.showLoad()
      }
    },
    getPermissionsForUser: function() {
      if(this.getPermissionsByType("show_archive_of_actual_vessels") === false && this.$route.path.indexOf("vessels/actual/archive") >= 0) {
        this.$router.push('/employees')
      }
      if(this.getPermissionsByType("show_archive_of_redelivered_vessels") === false && this.$route.path.indexOf("vessels/redelivered/archive") >= 0) {
        this.$router.push('/employees')
      }
    },
    activeOwner: function () {
      this.$v.activeOwner.$touch();
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      if(to.path.indexOf("vessels/actual/archive") >= 0) {
        if(vm.$store.getters.getPermissionsByType("show_archive_of_actual_vessels") === false && vm.$store.getters.getPermissionsForUser.length !== 0) {
          next(from);
        }
      }
      if(to.path.indexOf("vessels/redelivered/archive") >= 0) {
        if(vm.$store.getters.getPermissionsByType("show_archive_of_redelivered_vessels") === false && vm.$store.getters.getPermissionsForUser.length !== 0) {
          next(from);
        }
      }
    })
  },
  methods: {
    ...mapActions(['axiosGetCargoContracts', 'axiosGetYearsFoeRedeliveryVessels', "axiosGetShipsForLinkCargo", "axiosGetNextVesselsCargo", "axiosGetNextVessels", "axiosGetAllContractsCargo", "axiosChangeParametersForContractAsync", "axiosAsyncChangeParametersForContract","axiosChangeParametersForContract", "axiosChangeContractForShip", "axiosCreatContractForShip", "axiosGetClientsForDropdown","getAxiosPortsForVessel", "axiosGetContractByIdReturnResponse", "axiosChangeContract", "axiosGetAllContracts", "axiosGetClientsByIdReturnResponse"]),
    ...mapMutations(["setContracts", "setStatusUnlink", "hideLoad", "showLoad", "cancelTokenContractCANCEL", "setResponseContract", "addTip"]),
    changeSort(sort) {
      this.activeSort = sort
      console.log('changeSort', sort)

      this.changeFilter(this.dataFilter)
    },
    loadContractForYears(years) {
      years.forEach(year=> {
        this.countRequestAxios++
        this.paramsData.propsData.cp_date_from = year+'-01-01 00:00:00'
        this.paramsData.propsData.cp_date_to = year+'-12-31 23:59:59'
        this.axiosGetAllContracts({...this.paramsData, notSetContract: true, pushContract: true, year: year}).then(res=> {
          this.countRequestAxios--
          // alert(2)
          this.linksNextRedelivery.push(res)
          this.firstRequest = false;
        }).catch(()=> {
          this.countRequestAxios--
        });
      })

    },
    selectHideYear(year) {
      if(this.selectedHideYear.find(a=> a === year)) {
        this.selectedHideYear = this.selectedHideYear.filter(a=> a !== year)
      } else {
        this.selectedHideYear.push(year)
      }
    },
    reloadPorts() {
      this.keyRenderPorts++;
    },
    changeShowNotLinkedCargo(data) {
      this.showNotLinkedCargo = data;
    },
    cancelSelected() {
      this.selectedCheckboxes = [];
      this.selectedCheckboxesCargo = [];
    },
    getNextVesselsAxios() {
      this.loadVesselsAxios = true
      this.axiosGetNextVessels(this.getNextVessels).then(()=> {
        this.loadVesselsAxios = false
      });
    },
    getNextLinkForYear(link) {
      this.loadVesselsAxios = true
      this.axiosGetNextVessels(link.linkNext).then(res=> {
        this.loadVesselsAxios = false
        let item = this.linksNextRedelivery.find(a=> a.year === link.year)
        if(item) {
          item.linkNext = res
        }
      });
    },
    getNextVesselsCargoAxios() {
      this.loadVesselsCargoAxios = true
      this.axiosGetNextVesselsCargo(this.getNextVesselsCargo).then(()=> {
        this.loadVesselsCargoAxios = false
      });
    },
    checkSearch() {
      if(this.searchText1 !== this.searchText) {
        this.searchText1 = this.searchText;
        this.changeFilter(this.dataFilter)
      }
    },
    unarchiveSelected() {
      for(let i=0; i<this.selectedCheckboxes.length; i++) {
        let data = JSON.parse(this.selectedCheckboxes[i])
        let form = {
          "is_archive": !data.is_archive
        }
        this.axiosChangeParametersForContractAsync({id: data.id, data: form}).then(res=> {
          console.log(res);
          if(res === true) {
            data.cargo_contracts.map(a=> {
              let form = {
                "is_archive": !data.is_archive
              }
              if(Number.isInteger(a) === true) {
                this.axiosAsyncChangeParametersForContract({id: a, data: form});
              } else {
                this.axiosAsyncChangeParametersForContract({id: a.id, data: form});
              }
            })
            this.contracts = this.contracts.filter(a=> a.id !== data.id);
          }
        });
      }
      for(let i=0; i<this.selectedCheckboxesCargo.length; i++) {
        let data = JSON.parse(this.selectedCheckboxesCargo[i])
        let form = {
          "is_archive": !data.is_archive
        }
        this.axiosChangeParametersForContractAsync({id: data.id, data: form}).then(res=> {
          if(res === true) {
            this.contractsCargo = this.contractsCargo.filter(a=> a.id !== data.id);
          }
        });
      }
      this.addTip(this.selectedCheckboxes.length + this.selectedCheckboxesCargo.length +" vessels/cargo are unarchived");
      this.selectedCheckboxes = [];
      this.selectedCheckboxesCargo = [];
    },
    getPermissionsLinkContract() {
      return this.getPermissionsByType("link_and_unlink_voyage_contracts");
    },
    getPermissionsMove() {
      if(this.$route.path.indexOf("actual")>=0) {
        return this.getPermissionsByType("move_the_actual_vessel_to_redelivered_vessels");
      }
      if(this.$route.path.indexOf("redelivered")>=0) {
        return this.getPermissionsByType("move_the_redelivered_vessel_to_actual_vessels");
      }
    },
    linkArchive() {
      if(this.$route.path.indexOf('redelivered') >= 0) {
        this.$router.push('redelivered/archive');
      } else {
        this.$router.push('actual/archive');
      }
    },
    getPermissionSeeArchive() {
      return this.getPermissionsByType("show_archive_of_actual_vessels");
    },
    getPermissionAddVessel() {
      return this.getPermissionsByType("create_a_new_vessel");
    },
    archiveCargo(data) {
      let form = {
        "is_archive": !data.is_archive
      }
      this.axiosChangeParametersForContract({id: data.id, data: form}).then(res=> {
        if(res === true) {
          this.contractsCargo = this.contractsCargo.filter(a=> a.id !== data.id);
          this.addTip(!data.is_archive ? "Archive" : "Unarchive" + " cargo")
        }
      });
    },
    archiveContractInContract(item, itemCargo, k) {
      if(item.is_archive === false) {
        let form = {
          "is_archive": !itemCargo.is_archive
        }
        this.axiosChangeParametersForContract({id: itemCargo.id, data: form}).then(res=> {
          if(res === true) {
            this.addTip(itemCargo.is_archive ? "Archive" : "Unarchive" + " cargo")
            this.contracts[k].cargo_contracts = this.contracts[k].cargo_contracts.filter(a=> a.id !== itemCargo.id);

          }
        });
      } else {
        let form = {
          "is_archive": false,
        }
        this.axiosAsyncChangeParametersForContract({id: item.id, data: form});
        this.axiosAsyncChangeParametersForContract({id: itemCargo.id, data: form});
        this.contracts = this.contracts.filter(a=> a.id !== item.id);
      }
    },
    archiveContract(data) {
      let form = {
        "is_archive": !data.is_archive
      }
      this.axiosChangeParametersForContract({id: data.id, data: form}).then(res=> {
        if(res === true) {
          data.cargo_contracts.map(a=> {
            let form = {
              "is_archive": !data.is_archive
            }
            if(Number.isInteger(a) === true) {
              this.axiosAsyncChangeParametersForContract({id: a, data: form});
            } else {
              this.axiosAsyncChangeParametersForContract({id: a.id, data: form});
            }
          })
          this.contracts = this.contracts.filter(a=> a.id !== data.id);
          this.addTip(!data.is_archive ? "Archive" : "Unarchive" + " vessels")
        }
      });
    },
    test() {
      this.attachmentsForModal = this.$refs.dropzone.getAcceptedFiles();
      console.log(this.attachmentsForModal)
      this.$v.attachmentsForModal.touch();
    },
    showFilter() {
      this.$refs.filter.showMenu();
    },
    checkNum(num) {
      if(num.indexOf(".")>=0) {
        let num1 = num.substring(0, num.indexOf("."));
        let num2 = num.substring(num.indexOf(".") + 1, num.length);

        num1 = num1.replace(/[^0-9]/g, "");
        num2 = num2.replace(/[^0-9]/g, "");

        if(num2.length > 4) {
          num2 = num2.substring(0, 4);
        }
        num = num1 + "." + num2;
      } else {
        num = num.replace(/[^0-9]/g, "");
      }
      return num;
    },
    validateCheck() {
      this.$v.$touch()
      if (this.$v.$invalid)
        return false
      return true
    },
    applyCleanFixed() {
      this.$v.$touch()

      this.axiosBool = true;
      let cp_date = null;
      if(this.CPDate !== null && (""+this.CPDate).length !== 0) {
        if(this.CPDate.length !== 0) {
          cp_date = moment(this.CPDate).format("YYYY-MM-DD")
          this.errorCPDate = false;
        } else {
          this.errorCPDate = true;
        }
      } else {
        this.errorCPDate = true;
      }
      if (!this.errorCPDate) {
        let currency = "";
        if(this.activeValuta !== null) {
          currency = this.activeValuta.name
        }
        let owners = null;
        let ownersArray = [];
        if(this.activeOwner !== null) {
          // owners = this.activeOwner.map(a=> {return a.id});
          owners = this.activeOwner.id;
          ownersArray = this.activeOwner;
        }
        let broker = "";
        if(this.activeBroker !== null) {
          broker = this.activeBroker.id;
        }
        // let delivery = "";
        // if(this.deliveryActive !== null) {
        //   delivery = this.deliveryActive.id;
        // }
        let redelivery = [];
        if(this.redelivery_place !== null) {
          redelivery.push(this.redelivery_place.id);
        }
        let deliveryPlace = [];
        if(this.delivery_place !== null) {
          deliveryPlace.push(this.delivery_place.id);
        }
        // let period_from = null;
        // let period_to = null;
        // if(this.periodEdit.length !== 0) {
        //   if(this.periodEdit[0] !== null && this.periodEdit[1] !== null) {
        //     if((""+this.periodEdit[0]).length !== 0 && (""+this.periodEdit[1]).length !== 0) {
        //       period_from = moment(this.periodEdit[0]).format("YYYY-MM-DDTHH:mm")+ "Z";
        //       period_to = moment(this.periodEdit[1]).format("YYYY-MM-DDTHH:mm")+ "Z";
        //     }
        //   }
        // }
        let period = this.periodEdit;
        let hire_rate = null;
        if((""+this.hireEdit).length !== 0) {
          hire_rate = this.hireEdit;
        }

        let operation_manager = null;
        if(this.assignedOperations !== null) {
          operation_manager = this.assignedOperations.id;
        }
        let chartering_manager = null;
        if(this.assignedChartering !== null) {
          chartering_manager = this.assignedChartering.id;
        }
        let delivery = null;
        if(this.deliveryDate !== null && (""+this.deliveryDate).length !== 0) {
          delivery = moment(this.deliveryDate).format("YYYY-MM-DDTHH:mm") + "Z";
        }
        let redeliveryDate = null;
        if(this.redeliveryDate !== null && (""+this.redeliveryDate).length !== 0) {
          redeliveryDate = moment(this.redeliveryDate).format("YYYY-MM-DDTHH:mm") + "Z";
        }
        let name = this.vesselNameEdit;
        let dwt = this.dwtEdit;
        if((""+this.dwtEdit).length === 0) {
          dwt = null
        }
        let build = null;
        if(this.builtEdit !== null) {
          if(this.builtEdit.length !==0) {
            build = this.builtEdit;
          }
        }

        let percentage = this.perEdit;
        if((""+this.perEdit).length === 0) {
          percentage = null;
        }
        let laycan_date = this.laycanDateEdit ? JSON.stringify(this.laycanDateEdit) : '';
        let comment = this.commentEdit;
        let cargo = this.cargoCommentEdit;
        let ship = null;
        let task = null;
        let files = this.$refs.dropzone.getAcceptedFiles();
        let fileContract = [];
        let dataShip = this.dataShipModal;
        // if(this.indexShipForModal !== null) {
        //   if(this.indexShipForModalInContract !== null) {
        //     console.log(11111)
        //     console.log(this.indexShipForModalInContract)
        //     // alert(1)
        //     dataShip = this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract];
        //   } else {
        //     // alert(2)
        //     dataShip = this.contracts[this.indexShipForModal];
        //   }
        // }
        console.log(dataShip)

        if(dataShip !== null) {
          console.log("dataShip");
          console.log(dataShip);
          fileContract = dataShip.files;
          console.log(22);
        }
        let contract_type = 'SHIP';
        if (dataShip !== null) {
          contract_type = dataShip.contract_type;
        }
        let form = {
          "name": name,
          "dwt": dwt,
          "built": build,
          "percentage": percentage,
          "cp_date": cp_date,
          "delivery": delivery,
          "redelivery": redeliveryDate,
          "period": period,
          "laycan_date": laycan_date,
          "hire_rate": hire_rate,
          "currency": currency,
          "comment": comment,
          "cargo_comment": cargo,
          "ship": ship,
          "task": task,
          "operation_manager": operation_manager,
          "chartering_manager": chartering_manager,
          "broker": broker,
          "owners": owners,
          "delivery_place": deliveryPlace,
          "redelivery_place": redelivery,
          "filesContract": fileContract,
          "files": files,
          "contract_type": contract_type,
        }
        // console.log(form)
        if(this.validateCheck()) {
        // if('false' === form) {

          if(this.indexShipForModal !== null) {
            form["id"] = dataShip.id;
            this.showModalEditClean = false;
            this.showLoad()
            this.axiosChangeContractForShip(form).then(res=> {
              if(res !== false) {
                if(this.indexShipForModalInContract !== null) {
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["id"] = res.data.id;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["files"] = res.file;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["name"] = name;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["dwt"] = dwt;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["built"] = build;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["percentage"] = percentage;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["localInfo"] = true;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["cp_date"] = cp_date;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["delivery"] = delivery;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["redelivery"] = redeliveryDate;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["period"] = period;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["laycan_date"] = laycan_date;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["hire_rate"] = hire_rate;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["currency"] = currency;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["comment"] = comment;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["cargo_comment"] = cargo;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["ship"] = ship;
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["task"] = task;
                  // if(this.assignedOperations !== null) {
                  //   if(this.assignedOperations.length !== 0) {
                      this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["operation_manager"] = this.assignedOperations;
                    // }
                  // }
                  // if(this.assignedChartering !== null) {
                  //   if(this.assignedChartering.length !== 0) {
                      this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["chartering_manager"] = this.assignedChartering;
                    // }
                  // }
                  if(this.activeBroker !== null) {
                    this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["broker"] = {company_name: this.activeBroker.name, id: this.activeBroker.id};
                  }
                  // this.contracts[this.indexShipForModal]["owners"] = ownersArray.map(a=>{ return {company_name: a.name, id: a.id}});
                  this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["owner"] = {company_name: ownersArray.name, id: ownersArray.id};
                  console.log("this.deliveryActive")
                  console.log(this.delivery_place)
                  if(this.delivery_place !== null) {
                    this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["delivery_place"] = [];
                    this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["delivery_place"].push(this.delivery_place);
                  }
                  console.log("this.redeliveryActive")
                  console.log(this.redelivery_place)
                  if(this.redelivery_place !== null) {
                    this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["redelivery_place"] = [];
                    this.contracts[this.indexShipForModal].cargo_contracts[this.indexShipForModalInContract]["redelivery_place"].push(this.redelivery_place);
                  }
                } else {
                  this[this.contractValueName][this.indexShipForModal]["id"] = res.data.id;
                  this[this.contractValueName][this.indexShipForModal]["files"] = res.file;
                  this[this.contractValueName][this.indexShipForModal]["name"] = name;
                  this[this.contractValueName][this.indexShipForModal]["dwt"] = dwt;
                  this[this.contractValueName][this.indexShipForModal]["built"] = build;
                  this[this.contractValueName][this.indexShipForModal]["percentage"] = percentage;
                  this[this.contractValueName][this.indexShipForModal]["localInfo"] = true;
                  this[this.contractValueName][this.indexShipForModal]["cp_date"] = cp_date;
                  this[this.contractValueName][this.indexShipForModal]["delivery"] = delivery;
                  this[this.contractValueName][this.indexShipForModal]["redelivery"] = redeliveryDate;
                  this[this.contractValueName][this.indexShipForModal]["period"] = period;
                  this[this.contractValueName][this.indexShipForModal]["laycan_date"] = laycan_date;
                  this[this.contractValueName][this.indexShipForModal]["hire_rate"] = hire_rate;
                  this[this.contractValueName][this.indexShipForModal]["currency"] = currency;
                  this[this.contractValueName][this.indexShipForModal]["comment"] = comment;
                  this[this.contractValueName][this.indexShipForModal]["cargo_comment"] = cargo;
                  this[this.contractValueName][this.indexShipForModal]["ship"] = ship;
                  this[this.contractValueName][this.indexShipForModal]["task"] = task;
                  // if(this.assignedOperations !== null) {
                  //   if(this.assignedOperations.length !== 0) {
                      this[this.contractValueName][this.indexShipForModal]["operation_manager"] = this.assignedOperations;
                    // }
                  // }
                  // if(this.assignedChartering !== null) {
                  //   if(this.assignedChartering.length !== 0) {
                      this[this.contractValueName][this.indexShipForModal]["chartering_manager"] = this.assignedChartering;
                    // }
                  // }
                  if(this.activeBroker !== null) {
                    this[this.contractValueName][this.indexShipForModal]["broker"] = {company_name: this.activeBroker.name, id: this.activeBroker.id};
                  }
                  // this.contracts[this.indexShipForModal]["owners"] = ownersArray.map(a=>{ return {company_name: a.name, id: a.id}});
                  this[this.contractValueName][this.indexShipForModal]["owner"] = {company_name: ownersArray.name, id: ownersArray.id};
                  console.log("this.deliveryActive")
                  console.log(this.delivery_place)
                  if(this.delivery_place !== null) {
                    this[this.contractValueName][this.indexShipForModal]["delivery_place"] = [];
                    this[this.contractValueName][this.indexShipForModal]["delivery_place"].push(this.delivery_place);
                  }
                  console.log("this.redeliveryActive")
                  console.log(this.redelivery_place)
                  if(this.redelivery_place !== null) {
                    this[this.contractValueName][this.indexShipForModal]["redelivery_place"] = [];
                    this[this.contractValueName][this.indexShipForModal]["redelivery_place"].push(this.redelivery_place);
                  }
                }
                console.log("contract")
                console.log(this[this.contractValueName][this.indexShipForModal])
                // this.contracts[this.indexShipForModal] = JSON.parse(JSON.stringify(this.contracts[this.indexShipForModal]))
                this.axiosBool = false;
                this.closeModalEditClean();
                this.hideLoad()
                this.keyRender++;
              }
            }).catch(()=> {
              this.axiosBool = false;
              this.closeModalEditClean();
              this.hideLoad()
            })
          } else {
            this.showLoad()
            this.showModalEditClean = false;
            this.axiosCreatContractForShip(form).then(res=> {
              if(res !== false) {
                console.log(1);
                let form = {};
                form["id"] = res.data.id;
                form["files"] = res.file;
                form["name"] = name;
                form["dwt"] = dwt;
                form["built"] = build;
                form["percentage"] = percentage;
                form["cargo_contracts"] = [];
                form["localInfo"] = true;
                form["cp_date"] = cp_date;
                form["delivery"] = delivery;
                form["redelivery"] = redeliveryDate;
                form["period"] = period;
                form["laycan_date"] = laycan_date;
                form["hire_rate"] = hire_rate;
                form["currency"] = currency;
                form["comment"] = comment;
                form["cargo_comment"] = cargo;
                form["ship"] = ship;
                form["task"] = task;
                form["operation_manager"] = null;
                if(this.assignedOperations !== null) {
                  if(this.assignedOperations.length !== 0) {
                    form["operation_manager"] = this.assignedOperations;
                  }
                }
                console.log(2);
                form["chartering_manager"] = null;
                if(this.assignedChartering !== null) {
                  if(this.assignedChartering.length !== 0) {
                    form["chartering_manager"] = this.assignedChartering;
                  }
                }
                console.log(3);
                if(this.activeBroker !== null) {
                  form["broker"] = {company_name: this.activeBroker.name, id: this.activeBroker.id};
                }
                console.log(4);
                // this.contracts[this.indexShipForModal]["owners"] = ownersArray.map(a=>{ return {company_name: a.name, id: a.id}});
                form["owner"] = {company_name: ownersArray.name, id: ownersArray.id};
                if(this.delivery_place !== null) {
                  form["delivery_place"] = [];
                  form["delivery_place"].push(this.delivery_place);
                } else {
                  form["delivery_place"] = [];
                }
                console.log(5);
                if(this.redelivery_place !== null) {
                  form["redelivery_place"] = [];
                  form["redelivery_place"].push(this.redelivery_place);
                } else {
                  form["redelivery_place"] = [];
                }
                form["is_archive"] = false
                this.contracts.push(form)
                console.log(6);
                // this.contracts[this.indexShipForModal] = JSON.parse(JSON.stringify(this.contracts[this.indexShipForModal]))
                console.log("contract")
                // console.log(this.contracts[this.indexShipForModal])
                this.keyRender++;
                this.axiosBool = false;
                this.closeModalEditClean();
                this.hideLoad()
              }
            }).catch(()=> {
              this.axiosBool = false;
              this.closeModalEditClean();
              this.hideLoad()
            })
          }
        }

      }
    },

    setAssignedOperations(data) {
      console.log(data);
      this.assignedOperations = data;
    },
    setAssignedChartering(data) {
      console.log(data);
      this.assignedChartering = data;
    },
    selectOwner(data) {
      this.activeOwner = data;
      this.$v.activeOwner.$touch();
    },
    setValuta(data) {
      this.activeValuta = data;
    },
    setDeliveryPlace(data) {
      this.deliveryActive = data;
    },
    setRedeliveryPlace(data) {
      this.redeliveryActive = data;
    },
    setActiveBroker(data) {
      this.activeBroker = data;
    },
    openModalClean(id, index, indexTwo, nameValue, type) {
      this.modalCreateText = true;
      this.typeForModalVessel = 'ship'
      if(type !== undefined && type !== null) {
        this.typeForModalVessel = type
      }
      this.showModalEditClean = true;
      this.indexShipForModal = index;
      if(indexTwo !== null && indexTwo !== undefined) {
        this.indexShipForModalInContract = indexTwo;
      }
      this.idShipForModal = id;
      this.contractValueName = 'contracts';
      if(nameValue!== null && nameValue !== undefined) {
        this.contractValueName = nameValue;
      }
      let dataShip = null;
      if(index !== null && index !== undefined) {
        dataShip = this.contracts[index];
        dataShip._v_ship = this.contracts[index].id
      }
      if(index !== null && index !== undefined && indexTwo !== undefined && indexTwo !== null) {
        dataShip = this.contracts[index].cargo_contracts[indexTwo];
        dataShip._v_ship = this.contracts[index].id
      }
      if(index !== null && index !== undefined && nameValue !== undefined && nameValue !== null) {
        dataShip = this.contractsCargo[index];
      }
      this.dataShipModal = dataShip;
      // dataShip = this.contracts[index];
      this.attachmentsForModal = [];
      if(dataShip !== null) {
        this.attachmentsForModal = dataShip.files;
        this.modalCreateText = false;
        this.contractCreateModal = false;
        this.vesselNameEdit = dataShip.name;
        this.builtEdit = dataShip.built;
        this.cargoCommentEdit = dataShip.cargo_comment;
        this.commentEdit = dataShip.comment;
        this.laycanDateEdit = dataShip.laycan_date ? JSON.parse(''+dataShip.laycan_date) : '';
        this.dwtEdit = dataShip.dwt;
        this.perEdit = dataShip.percentage;
        if(dataShip.redelivery_place.length !== 0 || dataShip.delivery_place.length !== 0) {
          if(dataShip.delivery_place.length !== 0) {
            this.delivery_place = dataShip.delivery_place[0];
            this.defaultDeliveryActive = {name: dataShip.delivery_place[0].name + ": " + dataShip.delivery_place[0].country, id: dataShip.delivery_place[0].id, namePort: dataShip.delivery_place[0].name, countryPort: dataShip.delivery_place[0].country}
            // return list.map(a=>{return {name: a.name + ": " + a.country, id: a.id, namePort: a.name, countryPort: a.country}})

          }
          if(dataShip.redelivery_place.length !== 0) {
            this.redelivery_place = dataShip.delivery_place[0];
            this.defaultRedeliveryActive = {name: dataShip.redelivery_place[0].name + ": " + dataShip.redelivery_place[0].country, id: dataShip.redelivery_place[0].id, namePort: dataShip.redelivery_place[0].name, countryPort: dataShip.redelivery_place[0].country}
            // this.defaultRedeliveryActive = {name: dataShip.redelivery_place[0].name, id: dataShip.redelivery_place[0].id}
          }
        } else {
          // if(dataShip.delivery_place.length !== 0) {
          //   this.defaultDeliveryActive = {name: dataShip.delivery_place[0].name+ ": " + dataShip.delivery_place[0].country, id: dataShip.delivery_place[0].id}
          // }
          // if(dataShip.redelivery_place.length !== 0) {
          //   this.defaultRedeliveryActive = {name: dataShip.redelivery_place[0].name+ ": " + dataShip.redelivery_place[0].country, id: dataShip.redelivery_place[0].id}
          // }
        }
        if(dataShip.broker !== null && dataShip.broker !== undefined) {
          this.activeBroker = dataShip.broker
        }

        console.log("owner")
        console.log(dataShip.owner)

        if(dataShip.owner !== null) {
          console.log("dataShip.owner")
          this.activeOwner = dataShip.owner
          console.log(this.defaultOwnerActive)
        }
        if(dataShip.cp_date !== null) {
          if(dataShip.cp_date.length !== 0) {
            this.CPDate = (new Date(dataShip.cp_date));
          }
        }
        if(dataShip.period !== null) {
          this.periodEdit = dataShip.period;
        }
        if(dataShip.delivery !== null) {
          this.deliveryDate = (new Date(dataShip.delivery));
        }
        if(dataShip.redelivery !== null) {
          this.redeliveryDate = (new Date(dataShip.redelivery));
        }
        this.laycanDateEdit = dataShip.laycan_date ? JSON.parse(''+dataShip.laycan_date) : '';
        this.hireEdit = dataShip.hire_rate;
        if(dataShip.currency !== null) {
          this.defaultActiveValuta = {name: dataShip.currency, id: dataShip.currency}
        }
        if(dataShip.chartering_manager !== null && dataShip.chartering_manager !== undefined) {
          this.assignedChartering = dataShip.chartering_manager;
        }
        if(dataShip.operation_manager !== null && dataShip.operation_manager !== undefined) {
          this.assignedOperations = dataShip.operation_manager;
        }
      } else {
        this.contractCreateModal = true;
      }
      // if(dataShip.owners.length !== 0) {
      //   this.defaultOwnerActive = dataShip.owners.map(a=> {
      //     return {name: a.company_name, id: a.id}
      //   })
      // }

    },

    closeModalEditClean() {
      this.$v.$reset();
      this.dataShipModal = null;
      this.indexShipForModalInContract = null;
      this.contractValueName = 'contracts';
      this.contractCreateModal = true;
      // if(!this.axiosBool) {
        this.showModalEditClean = false;
        this.errorCPDate = false;
        this.CPDate = null;
        this.deliveryDate = null;
        this.redeliveryDate = null;
        this.activeBroker = null;
        this.defaultActiveBroker = null;
        this.vesselNameEdit = "";
        this.builtEdit = "";
        this.dwtEdit = "";
        this.perEdit = "";
        this.defaultOwnerActive = null;
        this.activeOwner = null;
        this.deliveryActive = null;
        this.redeliveryActive = null;
        this.redelivery_place = null;
        this.delivery_place = null;
        this.defaultDeliveryActive = null;
        this.defaultRedeliveryActive = null;
        this.activeValuta = null;
        this.defaultActiveValuta = {name: "USD", id: "USD"};
        this.laycanDateEdit = "";
        this.hireEdit = "";
        this.assignedOperations = [];
        this.assignedChartering = [];
        this.commentEdit = "";
        this.cargoCommentEdit = "";
        this.periodEdit = "";
        this.defaultActiveOperations = null;
        this.defaultActiveChartering = null;
        this.idShipForModal = null;
        if(this.$refs.dropzone)
      this.$refs.dropzone.removeAllFiles(true);
        if(this.$refs.assigneeTask)
      this.$refs.assigneeTask.clearData();
      if(this.$refs.assigneeTask1)
        this.$refs.assigneeTask1.clearData();
    },

    getListVesselView() {
      if(this.$route.path.indexOf('actual')>=0) {
        return [{title: "", array: this.contracts}]
      } else {
        let data = []
        this.yearsRedelivery.forEach(year=> {
          let link = this.linksNextRedelivery.find(a=> a.year === year)
          data.push({title: year, array: this.contracts.filter(a=>a.cp_date.indexOf(year)>=0), nextLink: link ? link : null})
        })
        // let data = this.contracts.map(a=> {return moment(a.cp_date).format("YYYY")});
        // data = Array.from(new Set(data));
        // data.sort(function (a,b) {
        //   return b - a;
        // })
        // data = data.map(a=> {return {title: a, array: this.contracts, nextLink: ''}});
        return data;
      }
    },
    async cargoContactSet() {
      for(let i=0; i<this.contracts.length; i++) {
        if(this.contracts[i].cargo_contracts.length !==0) {
          for(let j=0; j<this.contracts[i].cargo_contracts.length; j++) {
            let value = this.contractsAll.filter(a=> a.id === this.contracts[i].cargo_contracts[j].owner);
            if(value.length !== 0) {
              this.contracts[i].cargo_contracts[j] = value;
            } else {
              // await this.axiosGetContractByIdReturnResponse(this.contracts[i].cargo_contracts[j]);
              // this.contracts[i].cargo_contracts[j] = this.getResponseContract;
              this.setResponseContract(null);
              if(this.contracts[i].cargo_contracts[j].owner && Number.isInteger(this.contracts[i].cargo_contracts[j].owner)) {
                this.contracts[i].cargo_contracts[j].owner = await this.axiosGetClientsByIdReturnResponse(this.contracts[i].cargo_contracts[j].owner)
              }
            }
          }
        }
      }
    },
    checkNumIn(num) {
      return num.replace(/[^0-9]/g, "");
    },
    moveToActualOrRedelivery(contract, index) {
      let form = {
        "is_redelivery": !contract.is_redelivery,
      }
      this.axiosChangeContract({id: contract.id, data: form}).then(res=> {
        if(res) {
          this.contracts.splice(index,1)
        }
      });
    },
    openAcceptModalLinkVessels(contract, index) {
      this.showAcceptModalLinkVessels = true;
      this.contractAccept = contract;
      this.contractAcceptIndex = index;
    },
    closeAcceptModalLinkVessels() {
      this.showAcceptModalLinkVessels = false;
      this.contractAccept = null;
      this.contractAcceptIndex = null;
    },
    openAcceptModalUnLinkVessels(contract, contractCargo, index) {
      this.showAcceptModalUnLinkVessels = true;
      this.contractAccept = contract;
      this.contractCargoAccept = contractCargo
      this.contractAcceptIndex = index;
    },
    closeAcceptModalUnLinkVessels() {
      this.showAcceptModalUnLinkVessels = false;
      this.contractAccept = null;
      this.contractAcceptIndex = null;
      this.contractCargoAccept = null;
    },
    clickLinkVessels(contract, index) {
      console.log(contract)
      if(this.idCargoForModalLinkVessels !== null) {
        let cargo_contracts = contract.cargo_contracts.map(a=> {return a.id});
        cargo_contracts.push(this.idCargoForModalLinkVessels)
        let form = {
          "cargo_contracts": cargo_contracts,
        }
        let id = this.idCargoForModalLinkVessels;
        this.axiosChangeContract({id: contract.id, data: form}).then(res=> {
          if(res) {
            let value = this.contractsCargo.filter(a=> a.id === id)[0];
            // contract = this.contracts[index];
            contract = this.contractsForLink[index];
            contract.cargo_contracts.push(value);
            // Vue.set(this.contracts, index, contract);
            Vue.set(this.contractsForLink, index, contract);

            let elementPos = this.contracts.map(function(x) {return x.id; }).indexOf(this.contractsForLink[index].id);
            if(elementPos !== -1) {
              Vue.set(this.contracts, elementPos, contract);
            }

            this.contractsCargo = this.contractsCargo.filter(a=> a.id !== id)
          }
        });
      }
      this.closeModalLinkVessel();
    },
    unlink(contract, contractCargo, indexContract) {
      let cargo_contracts = contract.cargo_contracts.filter(a=> a.id !== contractCargo.id).map(a=> {return a.id});
      let form = {
        "cargo_contracts": cargo_contracts,
      }
      this.axiosChangeContract({id: contract.id, data: form}).then(res=> {
        if(res) {
          this.contracts[indexContract].cargo_contracts = this.contracts[indexContract].cargo_contracts.filter(a=> a.id !== contractCargo.id);
          this.contractsCargo.push(contractCargo);
        }
      });
    },
    closeModalLinkVessel() {
      this.idCargoForModalLinkVessels = null;
      this.showModalLinkVessels = false;
      this.contractCargoAccept = null;
    },
    setText() {
      this.searchText3 = this.searchText2
    },
    openModalLinkVessels(id, contractCargo) {
      this.idCargoForModalLinkVessels = id;
      this.contractCargoAccept = contractCargo;
      this.showModalLinkVessels = true;
        if(this.contractsForLink.length === 0) {
          this.searchContractForLink()
        }
      },
    searchContractForLink() {
      if(this.searchText2 !== this.searchText3 || this.searchText2.length === 0) {
        this.contractsForLink = [];
        this.loadVesselsAxios2 = true
        this.axiosGetShipsForLinkCargo(this.searchText2).then(res=> {
          this.loadVesselsAxios2 = false;
          this.getNextVessels2 = res.data.next;
          this.contractsForLink = res.data.results;
          console.log(this.contractsForLink)
        })
      }
      this.searchText3 = this.searchText2


    },
    showMoreContractsForLink() {
      this.loadVesselsAxios2 = true
      axios.get(this.getNextVessels2, {
        headers: {
          'Authorization': 'token ' + this.getToken,
        },
      }).then(res=> {
        this.loadVesselsAxios2 = false;
        this.getNextVessels2 = res.data.next;
        res.data.results.map(a=> {
          this.contractsForLink.push(a)
        })
      })
    },
    getShipContracts() {
      return this.contracts;
    },
    getURL() {
      return process.env.VUE_APP_URL_SIMPLE
    },
    getUrlForAvatar(link) {
      if(link !== null && link !== undefined && (""+link).length !== 0) {
        if(link.indexOf(this.getURL()) >= 0) {
          return link;
        }
      }
      return this.getURL() + link;
    },
    selectCheckbox(name) {
      let text = name;
      if(this.selectedCheckboxes.indexOf(text) >=0) {
        this.selectedCheckboxes = this.selectedCheckboxes.filter(a=> a !== text);
      } else {
        this.selectedCheckboxes.push(text);
      }
    },
    selectCheckboxAll() {
      if(this.selectedCheckboxes.length !== 0) {
        this.selectedCheckboxes = [];
      } else {
        this.selectedCheckboxes = [];
        this.selectedCheckboxes = this.getShipContracts().map(a=> {return JSON.stringify(a)})
      }
    },
    selectCheckboxCargo(name) {
      let text = name;
      if(this.selectedCheckboxesCargo.indexOf(text) >=0) {
        this.selectedCheckboxesCargo = this.selectedCheckboxesCargo.filter(a=> a !== text);
      } else {
        this.selectedCheckboxesCargo.push(text);
      }
    },
    selectCheckboxAllCargo() {
      if(this.selectedCheckboxesCargo.length !== 0) {
        this.selectedCheckboxesCargo = [];
      } else {
        this.selectedCheckboxesCargo = [];
        this.selectedCheckboxesCargo = this.contractsCargo.map(a=> {return JSON.stringify(a)})
      }
    },
    async selectExpandedViewAll() {
      if(this.expandedView.length !== 0) {
        this.expandedView = [];
      } else {
        this.expandedView = [];
        for(let i=0; i<this.contracts.length; i++) {
          let item = this.contracts[i]
          if(!item.is_loaded) {
            item.show_load = true
            item.show_load = true
            let contracts = await this.axiosGetCargoContracts(item.cargo_contracts)
            item.cargo_contracts = contracts
            item.is_loaded = true
            item.show_load = false
          }

          this.expandedView.push(item.name + '' + item.id);

        }
        this.expandedView = this.getShipContracts().map(a=> {return a.name + "" + a.id})
      }
    },
    async expandedViewClickButton(name, id, item) {
      let text = name + "" + id;
      if(this.expandedView.indexOf(text) >=0) {
        this.expandedView = this.expandedView.filter(a=> a !== text);
      } else {
        if(!item.is_loaded) {
          item.show_load = true
          let contracts = await this.axiosGetCargoContracts(item.cargo_contracts)
          item.cargo_contracts = contracts
          item.is_loaded = true
          item.show_load = false
        }
        this.expandedView.push(text);
      }
    },

    getPortsForDrop(list) {
      return list.map(a=>{return {name: a.name + ": " + a.country, id: a.id, namePort: a.name, countryPort: a.country}})
    },
    changeFilter(data) {
      // this.cancelTokenContractCANCEL();

      this.dataFilter = data;
      data.search = this.searchText1;
      let is_archive = false;
      if(this.$route.path.indexOf('archive')>=0) {
        is_archive = true;
      }
      // if(this.firstRequest) {
      //   this.countRequestAxios++
      //   this.countRequestAxios++
      // }
      // if(this.$route.path.indexOf('actual') >= 0) {
      let is_redelivered = false;
      if(this.$route.path.indexOf('redelivered') >= 0) {
        is_redelivered = true
        data['ordering'] = '-cp_date'
      }
      data['ordering'] = this.activeSort
        this.showNotLinkedCargo = data.not_linked_cargo;
      if(this.$route.path.indexOf('redelivered') === -1) {
        this.countRequestAxios++
        this.axiosGetAllContracts({is_redelivery: is_redelivered, propsData: data, is_archive: is_archive}).then(()=> {
          if(this.getStatusUnlink) {
            this.countRequestAxios--
            this.setStatusUnlink(false)
          }
          this.countRequestAxios--
          this.firstRequest = false;
        }).catch(()=> {
          this.countRequestAxios--
        });
      } else {
        this.countRequestAxios++
        this.paramsData= {is_redelivery: is_redelivered, propsData: data, is_archive: is_archive}
        this.setContracts([])
        this.axiosGetYearsFoeRedeliveryVessels({is_redelivery: is_redelivered, propsData: data, is_archive: is_archive}).then(()=> {
          this.countRequestAxios--
          this.selectedHideYear = this.yearsRedelivery
        })
      }


      if(this.showNotLinkedCargo && this.$route.path.indexOf('actual') >= 0) {
        this.countRequestAxios++
        this.axiosGetAllContractsCargo({is_redelivery: is_redelivered, propsData: data, is_archive: is_archive}).then(()=> {
          this.countRequestAxios--
          // alert(2)
          this.firstRequest = false;
        }).catch(()=> {
          this.countRequestAxios--
        });
      }
    },
  },
  mounted() {
    // if(!this.getPortsForVessel.length) {
    //   this.getAxiosPortsForVessel().then(()=> {
    //     this.ports = this.getPortsForDrop(this.getPortsForVessel);
    //   });
    // } else {
    //   this.ports = this.getPortsForDrop(this.getPortsForVessel);
    // }
    // if(!this.clients.length) {
    //   this.axiosGetClientsForDropdown();
    // }
  },
  beforeMount() {
    if(this.getStatusUnlink) {
      this.countRequestAxios++
    }

    if(this.$route.query.from == 'counterparties'){
      this.showModalEditClean = true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "actual-vessels";
  .button-new-port {
    position: absolute;
    right: 0;
    top: 12px;
    z-index: 10;
    cursor: pointer;
  }
  .add-client-modal {
    height: calc(100vh - 56px);
    width: 100vw;
    z-index: 400;
    position: absolute;
    top: 56px;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    /deep/ {
      .counterparties-add_bread {
        display: none;
      }
    }
  }
  .item-loader {
    position: absolute;
    right: 12px;
    background-color: var(--color-card) !important;
    width: max-content;
    height: max-content;
    left: auto;
    top: 5px;
    /deep/ {
      .sk-fading-circle {
        width: 24px;
        height: 24px;
      }
    }
  }
  .ml-12 {
    margin-left: 12px !important;
  }
</style>